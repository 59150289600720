import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';


export const Cookies = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);


        var data = GetUser();
        setLogged(data.logged);

        GoHome();

        return () => {
        };

    }, []);



    const GotoBack = () => {
        navigate(-1);
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div style={{ paddingTop: 20, paddingBottom: 30 }}>
                <h1>Cookies</h1>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 20, alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: global.screen_max }}>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left' }}>Čo sú to Cookies?</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Cookies sú dátovo malé súbory zasielané spolu s web stránkou zo servera do internetového prehliadača užívateľa. Úlohou cookies  je uchovanie nastavení užívateľa, ktoré vykonal na konkrétnej web stránke. Súbory sa ukladajú do priečinku vo vašom prehliadači, obsahujú názov webovej stránky, z ktorej pochádzajú, platnosť a nejaký obsah (zväčša čísla a písmenká). Bežne je takýmto spôsobom riešené automatické prihlasovanie sa, voľby skrytého a zobrazeného obsahu na web stránkach, či iné. Pri nasledujúcej návšteve tejto istej stránky prehliadač pošle informácie, ktoré sú uložené v súboroch cookies naspäť na stránku, ktorá súbory pôvodne vytvorila. Internetová stránka vďaka tomu vie, že ste už našu stránku navštívili. Cookies vám môžu uľahčiť používanie stránky. Ak tieto cookies zakážete nebudete môcť pridávať obsah na našu stránku, písať diskusné komentáre, alebo podať inzerát, keďže bez povolenia cookies to technicky nebude možné.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 50 }}>Cookies tretích strán</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>V dnešnom svete sa nedá fungovať bez prepojenia webu na iné webové služby napr. sociálne siete, kde môžete používať tlačítka na zdielanie či lajkovanie obsahu na sociálnych sietiach ako napr. na Facebook. Pre využitie týchto služieb stránka načítava kódy tretích strán, ktoré pre plnú funkčnosť môžu vyžadovať ukladanie cookies. Nemáme možnosť ovplyvniť ukladanie týchto cookies tretích strán, ak máte záujem prečítať si bližšie informácie o cookies týchto služieb, prosím, navštívte príslušné služby a prečítajte si ich zásady ochrany súkromia alebo pravidlá používania služby.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 50 }}>Ako si môžem upraviť cookies v mojom prehliadači?</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Otvorte si nastavenia svojho prehliadača Mozilla Firefox, Chrome…  a nájdite v nich lištu s nastaveniami súkromia a v nej súbory cookies. Tu si viete prezrieť, aké súbory cookies vo svojom zariadení máte, môžete ich manuálne alebo hromadne vymazať. Podrobnejšie inštrukcie nájdete v nápovede každého z týchto prehliadačov. Vo svojom prehliadači môžete v nastaveniach prehliadača zakázať automatické akceptovanie súborov cookies. Rešpektujeme vaše rozhodnutie o akceptácií cookies vo vašom prehliadači. Vypnutie cookies však môže mať dopad na funkčnosť tejto webovej stránky. Používame dočasné a trvalé cookies, dočasné sa uchovávajú vo vašom zariadení kým nezavriete stránku. Naopak trvalé cookies zostávajú vo vašom zariadení do uplynutia svojej platnosti (napr. pár týždňov, mesiacov) alebo dokým ich ručne nevymažete.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 30 }}>Webové stránky {global.www} používajú cookies len na základe Vášho predchádzajúceho výslovného súhlasu.</p>

                    </div>
                </div>
                <Button onClick={() => GotoBack()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 40 }} variant='outlined'>{lang.back}</Button>
            </div>

            {isLoading == true ?
                <Loading></Loading>
                : null}
        </div>
    )
};

