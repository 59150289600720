import React, { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useParams } from "react-router-dom";
import { Button } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, Menu, MenuTop, MenuLogo, CategoriesMenu, Loading, FilterBar, LoadSettings, SaveSettings, LoadLogin, LoadUser, ProductBox, ShoppingQuantity, GetCost, GetPrice, ProductBuy, ProductFavorites, useScrollDirection } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Pagination from '@mui/material/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import Fade from '@mui/material/Fade';


export const Catalog = (props) => {
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const [web, setWeb] = useState([]);

    // FOOTER    
    const [subcompanies, setSubcompanies] = useState([]);
    const [blogLabel, setBlogLabel] = useState('');
    const [catalogueLabel, setCatalogueLabel] = useState('');
    const [actionLabel, setActionLabel] = useState('');
    const [newsletterSended, setNewsletterSended] = useState('');

    const [label, setLabel] = useState('');
    const [page, setPage] = useState(0);
    const [shoppingCardQuantity, setShoppingCardQuantity] = useState(0);
    const [isDPH, setDPH] = useState(false); // Je platitel DPH
    const [allCategories, setAllCategories] = useState([]);
    const [user, setUser] = useState(null);

    const [coeficient, setcoeficient] = useState(1);
    const [showProductBuy, setShowProductBuy] = useState(false);
    const [buyItem, setBuyItem] = useState([]);

    const [sections, setSections] = useState([]);
    const [section, setSection] = useState('');
    const [sectionID, setSectionID] = useState(0);
    const [sectionSlug, setSectionSlug] = useState('');
    const [subsections, setSubsections] = useState([]);
    const [subsectionsList, setSubsectionsList] = useState([]);
    const [subsection, setSubsection] = useState('');
    const [subsectionID, setSubsectionID] = useState(0);
    const [subsectionSlug, setSubsectionSlug] = useState('');

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [categorySlug, setCategorySlug] = useState('');
    const [categoryID, setCategoryID] = useState(0);
    const [categoriesFilter, setCategoriesFilter] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);

    const [subCategories, setSubCategories] = useState([]);
    const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [subCategory, setSubCategory] = useState('');
    const [subcategorySlug, setSubcategorySlug] = useState('');
    const [subCategoryID, setSubCategoryID] = useState(0);
    const [subCategoriesFilter, setSubCategoriesFilter] = useState([]);

    const [products, setProducts] = useState([]);
    const [productsFiltered, setProductsFiltered] = useState([]);

    const [subProducts, setSubProducts] = useState([]);
    const [subProductsFilter, setSubProductsFilter] = useState([]);
    const [subProductsID, setSubProductsID] = useState(0);
    const [subProduct, setSubProduct] = useState('');

    const [productsMax, setProductsMax] = useState(0);
    const [showMore, setShowMore] = useState(false);

    const [settingsShow, setSettingsShow] = useState(global.show_1);
    const [settingsDisplay, setSettingsDisplay] = useState(0);
    const [settingsStock, setSettingsStock] = useState(false);

    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);

    const [showFavorites, setShowFavorites] = useState(false);
    const [favoriteProduct, setFavoriteProduct] = useState([]);

    const [callNote, setCallNote] = useState('');

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(1);
    const [pageMax, setPageMax] = useState(0);

    const [sortButton, setSortButton] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const scrollDirection = useScrollDirection();


    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)
    const lastRef = useRef(null)
    const listRef = useRef(null)

    const logo = require('./react/app/logo.png');

    useEffect(() => {
        func(0, false);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        db_web(data.id);

        var tmp = LoadSettings();

        setSettingsShow(tmp.show);
        setSettingsDisplay(tmp.display);
        setSettingsStock(tmp.stock);
        setSortButton(tmp.sort);

        var tmp = LoadPage();
        setPage(tmp[0]);

        GoHome();
        return () => {
        };

    }, []);

    const db_web = async (user_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: user_id,
                    banners_typ: -1
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                // --------------------------------------
                // WEB - základné údaje o webe
                // --------------------------------------
                setWeb(json.web);
                setCallNote(json.txt_call_note.string);

                // --------------------------------------
                // VSETKY KATEGORIE
                // --------------------------------------
                var tmp = json.all;
                setAllCategories(json.all);
                var allcategories = tmp;

                // --------------------------------------
                // SECTIONS
                // --------------------------------------
                var tmp = json.sections;
                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                var sections = tmp;
                setSections(tmp);

                // --------------------------------------
                // SUBSECTIONS
                // --------------------------------------
                var tmp = json.subsections;
                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                var subsections = tmp;
                setSubsections(tmp);

                // --------------------------------------
                // CATEGORIES
                // --------------------------------------
                var tmp = json.categories;
                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                var categories = tmp;
                setCategories(tmp);

                // --------------------------------------
                // SUBCATEGORIES
                // --------------------------------------
                var tmp = json.sub_categories;
                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                var subcategories = tmp;
                setSubCategories(tmp);

                // --------------------------------------
                // SUBPRODUCTS
                // --------------------------------------
                var tmp = json.sub_products;
                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                var subproducts = tmp;
                setSubProducts(tmp);

                // --------------------------------------
                // KOEFICIENT PREPOCTU CIEN
                // --------------------------------------
                var tmp = json.coeficient;
                setcoeficient(parseFloat(tmp));

                // --------------------------------------
                // WEB - PREVADZKY - FOOTER
                // --------------------------------------
                //setSubcompanies(json.subcompanies);

                // --------------------------------------
                // TEXTY
                // --------------------------------------                
                /*
                setActionLabel(json.txt_action.string);
                setBlogLabel(json.txt_blog.string);
                setCatalogueLabel(json.txt_catalogue.string);
                setNewsletterSended(json.txt_newsletter_ok.string);
                */
                // --------------------------------------
                // POCET POLOZIEK V KOSIKU AK JE PRIHLASENY
                // --------------------------------------
                if (user_id > 0) {
                    setShoppingCardQuantity(json.shopping_quantity);
                    // pocet poloziek v kosiku
                    func(1, json.shopping_quantity);
                } else {
                    setShoppingCardQuantity(ShoppingQuantity());
                    // pocet poloziek v kosiku
                    func(1, ShoppingQuantity());

                }

                // --------------------------------------------------
                // PARSE URL
                // --------------------------------------------------                

                if (props.type == 0) {
                    DeleteScroll();
                }

                if (props.type > 0) {
                    // DEKODOVANIE Z URL

                    if (props.type == 1) {
                        var arr = [params.section];
                    }
                    if (props.type == 2) {
                        var arr = [params.section, params.subsection];
                    }
                    if (props.type == 3) {
                        var arr = [params.section, params.subsection, params.category];
                    }
                    if (props.type == 4) {
                        var arr = [params.section, params.subsection, params.category, params.subcategory];
                    }
                    if (props.type == 5) {
                        var arr = [params.section, params.subsection, params.category, params.subcategory, params.subproduct];
                    }

                    var n = 0;
                    arr.forEach(item => {
                        var tmp = allcategories.filter(x => x.slug == item);
                        if (tmp.length > 0) {
                            if (n == 0) {
                                // SEKCIA
                                setSection(tmp[0].label);
                                setSectionID(tmp[0].id);

                                if (arr.length == (n + 1)) {
                                    // posledný záznam
                                    var data = subsections.filter(x => x.parent == tmp[0].id);
                                    setSubsectionsList(data);
                                    var a = [];
                                    data.forEach(y => {
                                        a.push(y.id);
                                    })

                                    var data = categories.filter(x => {
                                        return (a.includes(x.parent));
                                    });
                                    setCategoriesFilter(data);

                                    var items = GetProductsArray(allcategories, tmp[0].id);
                                    if (items == null) {
                                        ChangeProducts([tmp[0].id]);
                                    } else {
                                        ChangeProducts(items);

                                    }

                                }
                            }

                            if (n == 1) {
                                //subsekcia
                                setSubsection(tmp[0].label);
                                setSubsectionID(tmp[0].id);

                                var data = categories.filter(x => x.parent == tmp[0].id);
                                setCategoriesFilter(data);
                                setCategoriesList(data);

                                if (arr.length == (n + 1)) {
                                    // posledný záznam
                                    var items = GetProductsArray(allcategories, tmp[0].id);
                                    if (items == null) {
                                        ChangeProducts([tmp[0].id]);
                                    } else {
                                        ChangeProducts(items);
                                    }
                                }
                            }

                            if (n == 2) {
                                //kategória
                                setCategory(tmp[0].label);
                                setCategoryID(tmp[0].id);

                                var data = subcategories.filter(x => x.parent == tmp[0].id);
                                setSubCategoriesFilter(data);
                                setSubCategoriesList(data);

                                if (tmp[0].id >= 9426 && tmp[0].id <= 9431) {
                                    // AKCIOVE PRODUKTY
                                    setSubsectionsList([]);
                                    setCategoriesList([]);

                                    ChangeActionProducts([tmp[0].id]);
                                } else {
                                    if (arr.length == (n + 1)) {
                                        // posledný záznam
                                        var items = GetProductsArray(allcategories, tmp[0].id);
                                        if (items == null) {
                                            ChangeProducts([tmp[0].id]);
                                        } else {
                                            ChangeProducts(items);
                                        }
                                    }
                                }
                            }

                            if (n == 3) {
                                //subkategória
                                setSubCategory(tmp[0].label);
                                setSubCategoryID(tmp[0].id);

                                var data = subproducts.filter(x => x.parent == tmp[0].id);
                                setSubProductsFilter(data);

                                if (arr.length == (n + 1)) {
                                    // posledný záznam
                                    var items = GetProductsArray(allcategories, tmp[0].id);
                                    if (items == null) {
                                        ChangeProducts([tmp[0].id]);
                                    } else {
                                        ChangeProducts(items);
                                    }
                                }
                            }

                            if (n == 4) {
                                //subkategória
                                setSubProduct(tmp[0].label);
                                setSubProductsID(tmp[0].id);

                                // posledný záznam
                                var items = GetProductsArray(allcategories, tmp[0].id);
                                if (items == null) {
                                    ChangeProducts([tmp[0].id]);
                                } else {
                                    ChangeProducts(items);
                                }
                            }

                            n++;
                        } else {
                            navigate('/');
                        }
                    });
                }
                setLoading(false);
            }
        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const db_products = async (arr, page_, action) => {

        setBusy(true);
        var tmp = LoadPage();
        var pagination = tmp[1];

        var data = LoadLogin();
        var user_id = 0;
        if (data != null) {
            if (data.logged == true) {
                var user = LoadUser();
                user_id = user.id;
            }
        }

        var tmp = LoadSettings();
        var stock = tmp.stock;
        var maxItems = tmp.show;
        var sort_button = tmp.sort;

        setProducts([]);
        setProductsFiltered([]);
        try {
            const response = await fetch(
                global.db_url + 'products2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    products: arr,
                    action: action,
                    user_id: user_id
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                var tmp = json.products.filter(x => x.ok == true);
                setProducts(tmp);

                // ZOBRAZOVAT LEN SKLADOM ?
                if (stock == true) {
                    var filtered = tmp.filter(x => x.count > 0);
                } else {
                    var filtered = tmp;
                }

                // NAJNOVSIE
                if (sort_button == 0) {
                    var filtered = filtered.sort((a, b) => {
                        if (parseInt(a.id) < parseInt(b.id)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                // OD NAJNIZSEJ CENY
                if (sort_button == 1) {
                    var filtered = filtered.sort((a, b) => {
                        if (parseFloat(a.cost) < parseFloat(b.cost)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                // OD NAJVYSSEJ CENY
                if (sort_button == 2) {
                    var filtered = filtered.sort((a, b) => {
                        if (parseFloat(a.cost) > parseFloat(b.cost)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }


                // ROZDELENIE                
                if (pagination == false) {
                    var tmp = filtered.slice(0, (page_ * maxItems) + maxItems);
                } else {
                    var tmp = filtered.slice(maxItems * page_, (maxItems * page_) + maxItems);
                }


                setProductsFiltered(tmp);
                setProductsMax(filtered.length);


                if (pagination == true && page_ > 0) {
                    setShowMore(false);
                } else {
                    if ((page_ * maxItems) + maxItems < filtered.length) {
                        setShowMore(true);
                    } else {
                        setShowMore(false);
                    }
                }

                // pagination
                var tmp = filtered.length;
                tmp = Math.ceil(tmp / maxItems);
                setPageMax(tmp);


                LoadScroll();
                setPage(page_);
            }
        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const Init = () => {
        setLabel('');
        setSection('');
        setSectionID(0);
        setSubsection('');
        setSubsectionID(0);
        setCategory('');
        setCategoryID(0);
        setCategoriesFilter([]);
        setSubCategory('');
        setSubCategoryID(0);
        setSubCategoriesFilter([]);
        setSubsectionsList([]);
        setCategoriesList([]);
        setSubCategoriesList([]);
        setProducts([]);
        setProductsFiltered([]);
    }



    const GetProductsArray = (allcategories, id) => {
        var arr = [];
        if (id != global.menu_subaction) {
            arr = [id];
        }
        var data = allcategories.filter(x => x.parent == id);
        data.forEach(element => {
            arr.push(element.id);
            var data1 = allcategories.filter(x => x.parent == element.id);
            data1.forEach(element1 => {
                arr.push(element1.id);
                var data2 = allcategories.filter(x => x.parent == element1.id);
                data2.forEach(element2 => {
                    arr.push(element2.id);
                    var data3 = allcategories.filter(x => x.parent == element2.id);
                    data3.forEach(element3 => {
                        arr.push(element3.id);
                        var data4 = allcategories.filter(x => x.parent == element3.id);
                        data4.forEach(element4 => {
                            arr.push(element4.id);
                        });
                    });
                });
            });
        });

        if (arr.length == 0) {
            return null;
        } else {
            return arr;
        }

    }

    const SaveScroll = () => {
        sessionStorage.setItem('scrollPosition', window.pageYOffset);
    }

    const LoadScroll = () => {
        var y = (sessionStorage.getItem('scrollPosition'));
        if (y != null) {
            const timer = setTimeout(() => {
                window.scrollTo(0, parseInt(y));
            }, 50);
        }
    }

    const DeleteScroll = () => {
        sessionStorage.removeItem('scrollPosition')
    }

    const SavePage = (page, pagination) => {
        sessionStorage.setItem('page', page);
        sessionStorage.setItem('pagination', pagination);
    }

    const LoadPage = () => {
        var page_ = sessionStorage.getItem('page');
        var pagination_ = sessionStorage.getItem('pagination');

        if (page_ == null) {
            var page = 0;
        } else {
            var page = parseInt(page_);
        }
        if (pagination_ == null) {
            var pagination = false;
        } else {
            var pagination = pagination_ == 'true' ? true : false;
        }

        return [page, pagination];
    }

    const DeletePage = () => {
        sessionStorage.removeItem('page')
    }

    const MenuTopPress = (type, value) => {
        if (type == 0) {
            // registracia
            navigate('/user_register');
        }
    }

    const MenuLogoPress = (type, value) => {
        if (type == 0) {
            // KLIKNUTIE NA LOGO
            Init();
            navigate('/');
        }
    }

    const MenuPress = (type, id) => {
        SavePage(0, false);
        DeleteScroll();
        if (type == 0) {
            //KLIKNUTIE NA KATEGÓRIU            
            if (id == global.menu_subaction) {
                // AKCIA
                setSectionID(global.menu_action);
                setSection(lang.action);
            }
            ChangeSubsection(allCategories, subsections, categories, subCategories, id, true);
            GoHome();
        }
        if (type == 1) {
            // KLIKNUTIE NA SEKCIU
            ChangeSection(allCategories, sections, subsections, categories, subCategories, id, true);
            GoHome();
        }
        if (type == 2) {
            // DOMOV
            GotoHome();
        }
    }

    const CategoriesMenuPress = (type, id) => {
        // KLIKNUTIE NA KATEGÓRIU
        SavePage(0, false);
        DeleteScroll();
        if (type == 0) {
            ChangeCategory(allCategories, categories, subCategories, subsectionSlug, id, true);
        }
        if (type == 1) {
            // zobrazenie produktov v subkategórii
            ChangeSubcategory(allCategories, subCategories, id);
        }
        if (type == 2) {
            // zobrazenie produktov v subproduktoch
            ChangeSubproduct(allCategories, subProducts, [id]);
        }
    }

    const ChangeUrl = (allcategories, id_) => {
        //navigate('/');
        var id = id_;
        var path = '';
        var tmp = allcategories.filter(x => x.id == id);
        if (tmp.length > 0) {
            var n = 10;
            while (n > 0) {
                if (path.length > 0) {
                    path = tmp[0].slug + '/' + path;
                } else {
                    path = tmp[0].slug + path;
                }

                id = tmp[0].parent;
                var tmp = allcategories.filter(x => x.id == id);
                if (tmp.length == 0) {
                    break;
                }
                n--;
            }
        }
        navigate('/catalog/' + path);
    }

    const ChangeSection = (allcategories, sections, subsections, categories, subcategories, id, redraw) => {
        // KLIKNUTIE NA HLAVNU SEKCIU - ZMENA LISTINGU SUB-SEKCII
        if (id == global.menu_action) {
            // AKCIA
            ChangeSubsection(allCategories, subsections, categories, subCategories, global.menu_subaction, true);
        } else {
            var tmp = sections.filter(x => x.id == id);
            //setSubProducts([]);        
            if (tmp.length > 0) {
                if (redraw == true) {
                    ChangeUrl(allcategories, tmp[0].id);
                }
                setSection(tmp[0].label);
                setSectionID(tmp[0].id);

                tmp = subsections.filter(x => x.parent == id);
                setLabel(tmp[0].label);
                //setSubsection(tmp[0].label);
                //setSubsectionID(tmp[0].id);
                setSubsectionsList(tmp);

                var arr = [];
                tmp.forEach(element => {
                    arr.push(element.id);
                })
                var categories_ = categories.filter(x => {
                    return (arr.includes(x.parent));
                });

                setCategoryID(0);
                setSubsection('');
                setCategory('');
                setSubCategoriesList([]);
                setCategoriesFilter(categories_);
                setCategoriesList([]);

                if (redraw == true) {
                    var items = GetProductsArray(allcategories, id);
                    if (items == null) {
                        ChangeProducts([id]);
                    } else {
                        ChangeProducts(items);
                    }
                }
            }
        }
    }

    const ChangeSubsection = (allcategories, subsections, categories, subcategories, id, redraw) => {
        // KLIKNUTIE NA SUB-SEKCIU - ZMENA LISTING KATEGÓRII

        //setSubProducts([]);
        var tmp = subsections.filter(x => x.id == id);

        if (tmp.length > 0) {
            setSubsection(tmp[0].label);
            setLabel(tmp[0].label);
            setSubsectionID(tmp[0].id);
            setCategoryID(0);

            if (redraw == true) {
                ChangeUrl(allcategories, tmp[0].id);
            }
        }
        // FILTROVANIE KATEGORII
        var categories_ = categories.filter(x => x.parent == id);

        setCategoriesFilter(categories_);
        setCategoriesList(categories_);
        setSubsectionsList([]);
        setSubCategoriesList([]);
        setCategory('');


        if (redraw == true) {
            var items = GetProductsArray(allcategories, id);
            if (items == null) {
                ChangeProducts([id]);
            } else {
                ChangeProducts(items);
            }
        }

        // NA ZOZNAM PRODUKTOV
        if (isLarge == true) {
            GotoList();
        }
    }

    const ChangeCategory = (allcategories, categories, subcategories, subsectionslug, id, redraw) => {
        // KLIKNUTIE NA KATEGÓRIU - ZMENA LISTING SUB-KATEGÓRII        
        //setSubProducts([]);        
        if (id >= 9426 && id <= 9431) {
            // AKCIOVE PRODUKTY - NEZOBRAZOVAT SUBCATEGORIES
            var categories_ = categories.filter(x => x.id == id);
            if (categories_.length > 0) {
                var subsection_ = subsections.filter(x => x.id == categories_[0].parent);
                if (subsection_.length > 0) {
                    setSubsection(subsection_[0].label);
                    setSubsectionID(subsection_[0].id);
                }
                setCategory(categories_[0].label);
                setLabel(categories_[0].label);
                setCategoryID(categories_[0].id);
                setSubCategoryID(0);
                setSubCategory('');
                setSubsectionsList([]);
                setCategoriesList([]);
                setSubCategoriesFilter([]);
                if (redraw == true) {
                    ChangeUrl(allcategories, categories_[0].id);
                }
            }
            ChangeActionProducts([id]);
        } else {
            // ZMENA KATEGORIE
            var categories_ = categories.filter(x => x.id == id);
            if (categories_.length > 0) {
                var subsection_ = subsections.filter(x => x.id == categories_[0].parent);
                if (subsection_.length > 0) {
                    setSubsection(subsection_[0].label);
                    setSubsectionID(subsection_[0].id);
                }
                setCategory(categories_[0].label);
                setLabel(categories_[0].label);
                setCategoryID(categories_[0].id);
                setSubCategoryID(0);
                setSubCategory('');

                if (redraw == true) {
                    ChangeUrl(allcategories, categories_[0].id);
                }
            }
            // FILTROVANIE SUB-KATEGORII
            var subcategories_ = subcategories.filter(x => x.parent == id);

            setSubsectionsList([]);
            setCategoriesList([]);
            setSubCategoriesFilter(subcategories_);
            setSubCategoriesList(subcategories_);


            if (redraw == true) {
                var items = GetProductsArray(allcategories, id);
                if (items == null) {
                    ChangeProducts([id]);
                } else {
                    ChangeProducts(items);
                }
            }
            // NA ZOZNAM PRODUKTOV
            if (isLarge == true) {
                GotoList();
            }


        }
    }

    const ChangeSubcategory = (allcategories, subcategories, id) => {
        // KLIKNUTIE NA SUB-KATEGÓRIU - LISTING PRODUKTOV V DANEJ SUB-KATEGÓRII
        //setSubProducts([]);
        var tmp = subcategories.filter(x => x.id == id);

        if (tmp.length > 0) {
            setLabel(tmp[0].label);
            setSubCategory(tmp[0].label);
            setSubCategoryID(tmp[0].id);
            setSubsectionsList([]);
            setSubCategoriesList([]);
            setCategoriesList([]);

            ChangeUrl(allcategories, tmp[0].id);

            // FILTROVANIE SUB-PRODUKTOV
            var subproducts = subProducts.filter(x => x.parent == id);
            setSubProductsFilter(subproducts);
            setSubProduct('');
            setSubProductsID(0);

            var items = GetProductsArray(allcategories, id);
            if (items == null) {
                ChangeProducts([id]);
            } else {
                ChangeProducts(items);
            }


            // NA ZOZNAM PRODUKTOV
            GotoList();

        }

    }

    const ChangeSubproduct = (allcategories, subproducts, id) => {
        // KLIKNUTIE NA SUB-KATEGÓRIU - LISTING PRODUKTOV V DANEJ SUB-KATEGÓRII
        //setSubProducts([]);
        var tmp = subproducts.filter(x => x.id == id);

        if (tmp.length > 0) {
            setLabel(tmp[0].label);
            setSubProduct(tmp[0].label);
            setSubProductsID(tmp[0].id);

            ChangeUrl(allcategories, tmp[0].id);
            ChangeProducts([parseInt(id)]);
            /*
            var items = GetProductsArray(allcategories, id);
            if (items == null) {
                ChangeProducts([id]);
            } else {
                ChangeProducts(items);
            }
*/

            // NA ZOZNAM PRODUKTOV
            GotoList();

        }

    }

    const GoHome = () => {
        window.scrollTo(0, 0);
        //homeRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const GotoList = () => {
        // PREJST NA ZOZNAM PRODUKTOV
        const timer = setTimeout(() => {
            if (listRef != null) {
                //Debug('TIMER');
                listRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ChangeProducts = (arr) => {
        // LISTING PRODUKTOV    
        var page_ = LoadPage();
        db_products(arr, page_[0], false);
    }

    const ChangeActionProducts = (arr) => {
        // LISTING PRODUKTOV - AKCIOVYCH
        var page_ = LoadPage();
        db_products(arr, page_[0], true);
    }

    const RedrawItems = (page_, maxitems_, stock, pagination_, sort) => {
        // ZOBRAZOVAT LEN SKLADOM ?
        if (stock == true) {
            var filtered = products.filter(x => x.count > 0);
        } else {
            var filtered = products;
        }

        // NAJNOVSIE
        if (sort == 0) {
            var filtered = filtered.sort((a, b) => {
                if (parseInt(a.id) < parseInt(b.id)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }

        // OD NAJNIZSEJ CENY
        if (sort == 1) {
            var filtered = filtered.sort((a, b) => {
                if (parseFloat(a.cost) < parseFloat(b.cost)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }

        // OD NAJVYSSEJ CENY
        if (sort == 2) {
            var filtered = filtered.sort((a, b) => {
                if (parseFloat(a.cost) > parseFloat(b.cost)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }

        if (pagination_ == true && page_ > 0) {
            setShowMore(false);
        } else {
            if ((page_ * maxitems_ + maxitems_) < filtered.length) {
                setShowMore(true);
            } else {
                setShowMore(false);
            }
        }

        // ROZDELENIE
        if (pagination_ == false) {
            var tmp = filtered.slice(0, (maxitems_ * page_) + maxitems_);
        } else {
            var tmp = filtered.slice(maxitems_ * page_, (maxitems_ * page_) + maxitems_);
        }

        // pagination
        var len = filtered.length;
        var val = Math.ceil(len / maxitems_);
        setPageMax(val);

        setProductsMax(filtered.length);
        setProductsFiltered(tmp);
        setPage(page_);

        if (pagination_ == true) {
            GotoList();
        }
    }

    const FilterBarPress = (type, value) => {

        if (type == 0) {
            // počet položiek k zobrazeniu
            SaveSettings(value, settingsDisplay, settingsStock, sortButton);
            setSettingsShow(value);
            RedrawItems(0, value, settingsStock, false, sortButton);
        }
        if (type == 1) {
            // štýl zoznamu
            SaveSettings(settingsShow, value, settingsStock, sortButton);
            setSettingsDisplay(value);
        }
        if (type == 2) {
            // zobrazovat len produkty skladom
            SaveSettings(settingsShow, settingsDisplay, value, sortButton);
            setSettingsStock(value);

            RedrawItems(0, settingsShow, value, false, sortButton);

            LoadScroll();
            setPage(0);

        }
        if (type == 3) {
            setSortButton(value);
            SaveSettings(settingsShow, settingsDisplay, settingsStock, value);
            RedrawItems(0, settingsShow, settingsStock, false, value);
        }

    }


    const MenuBottomPress = (value) => {
        Debug(value)
    }

    const GotoHome = () => {
        navigate('/');
    }

    const SelectProduct = (id) => {
        var tmp = products.filter(x => x.id == id);
        SaveScroll();
        if (tmp.length > 0) {
            Debug(tmp[0].tid);
            navigate('/product/' + tmp[0].tid);
        }

    }

    const ProductPress = (item, type) => {
        if (type == 0) {
            // zobrazit produkt
            SelectProduct(item.id)
        }
        if (type == 1) {
            // kupit produkt
            setBuyItem(item);
            setShowProductBuy(true);
        }
        if (type == 2) {
            // do oblubených
            if (item.favorite == false) {
                setFavoriteProduct(item);
                setShowFavorites(true);
            }
        }
    }

    const ProductBuyPress = (type, quantity) => {
        if (type == false) {
            setShowProductBuy(false);
        } else {
            var quantitySum = 0;
            if (logged == true) {
                quantitySum = shoppingCardQuantity + quantity;
                setShoppingCardQuantity(quantitySum);
            }
            func(1, quantitySum);
            setShowProductBuy(false);
        }
    }

    const ProductFavoritesPress = (value) => {
        if (value == true) {
            var tmp = favoriteProduct;
            tmp.favorite = true;
        }
        setShowFavorites(false);
    }

    const ShowMore = () => {

        var page_ = page;

        if (page_ * settingsShow < (productsMax - settingsShow)) {
            page_ += 1;
        }
        setPage(page_);
        SavePage(page_, false);

        RedrawItems(page_, settingsShow, settingsStock, false);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        SavePage(page_, true);

        RedrawItems(page_, settingsShow, settingsStock, true, sortButton);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%', paddingTop: isSmall ? 160 : 240 }}>

            <Fade in={scrollDirection == 'up'} timeout={300}>
                <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1000 }}>

                    <MenuTop subsections={subsections} loged={false} mobil={web.mobil} email={'info'} name={''} surname={''} time={callNote} func={MenuTopPress.bind(this)} />
                    <MenuLogo quantity={shoppingCardQuantity} func={MenuLogoPress.bind(this)} />

                    <Menu sections={sections} subsections={subsections} func={MenuPress.bind(this)} />
                </div>
            </Fade>

            <div style={{ display: 'flex', flexDirection: 'column', width: isSmall ? '100%' : '95%', alignSelf: 'center' }}>
                {isLoading == true ? <div style={{ height: height }}></div> : null}

                {isLoading == false ?
                    <div ref={homeRef}>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, flexDirection: isSmall ? 'column' : 'row', paddingTop: 20 }}>
                            <CategoriesMenu categories={categoriesFilter} categoryID={categoryID} subcategories={subCategoriesFilter} subCategoryID={subCategoryID} subProducts={subProductsFilter} subProductID={subProductsID} func={CategoriesMenuPress.bind(this)} />
                            <div style={{ padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>

                                {section != '' && isSmall == false ?
                                    <Breadcrumbs>
                                        <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => GotoHome()} >
                                            {lang.home}
                                        </Button>
                                        <Breadcrumbs>
                                            <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => ChangeSection(allCategories, sections, subsections, categories, subCategories, sectionID, true)} >
                                                {section}
                                            </Button>
                                            {subsection != '' ?
                                                <Breadcrumbs>
                                                    <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => ChangeSubsection(allCategories, subsections, categories, subCategories, subsectionID, true)} >
                                                        {subsection}
                                                    </Button>
                                                    {category != '' ?
                                                        <Breadcrumbs>
                                                            <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => ChangeCategory(allCategories, categories, subCategories, subsectionSlug, categoryID, true)}>
                                                                {category}
                                                            </Button>
                                                            {subCategory != '' ?
                                                                <Breadcrumbs>
                                                                    <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => ChangeSubcategory(allCategories, subCategories, subCategoryID)}>
                                                                        {subCategory}
                                                                    </Button>
                                                                    {subProduct != '' ?
                                                                        <Breadcrumbs>
                                                                            <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => ChangeSubproduct(allCategories, subProducts, subProductsID)}>
                                                                                {subProduct}
                                                                            </Button>
                                                                        </Breadcrumbs>
                                                                        : null}
                                                                </Breadcrumbs>
                                                                : null}
                                                        </Breadcrumbs>
                                                        : null}
                                                </Breadcrumbs>
                                                : null}
                                        </Breadcrumbs>
                                    </Breadcrumbs>
                                    : null}


                                <div ref={listRef} style={{ ...styles.Block }}>
                                    <p style={{ margin: 0, marginTop: 15, marginBottom: 15, fontSize: global.font_label, color: global.theme_dark }}>{''}</p>
                                </div>


                                {subsectionsList.length > 0 ?
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginBottom: 20, width: '100%', backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20 }}>
                                        {subsectionsList.map(item => {
                                            return (
                                                <Button key={item.id} onClick={() => {
                                                    SavePage(0, false);
                                                    DeleteScroll();
                                                    ChangeSubsection(allCategories, subsections, categories, subCategories, item.id, true);
                                                }} style={{ width: 300, height: 100, margin: 5, textTransform: 'none', border: '1px solid ' + global.theme_light_gray, backgroundColor: global.theme_back }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 80 }}>
                                                        <div style={{ display: 'flex', height: 80, width: 100, }}>
                                                            {item.image != '' ?
                                                                <img src={global.images + 'catalog/' + item.image + '?id=sub-' + item.id} height={80} width={80} style={{ borderRadius: 2 }} />
                                                                : null}
                                                        </div>
                                                        <div style={{ display: 'flex', height: 80, width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10 }}>
                                                            <p style={{ margin: 0, fontSize: global.font_tiny, color: global.theme_black, fontWeight: 'biold' }}>{item.label}</p>
                                                        </div>
                                                    </div>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                    : null}

                                {categoriesList.length > 1 && isSmall == false ?
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', width: '100%', marginBottom: 20, backgroundColor: global.theme_gray, paddingTop: 20, paddingBottom: 20 }}>
                                        {categoriesList.map(item => {

                                            return (
                                                <Button key={item.id} onClick={() => {
                                                    SavePage(0, false);
                                                    DeleteScroll();
                                                    ChangeCategory(allCategories, categories, subCategories, subsectionSlug, item.id, true);
                                                }} style={{ width: 300, height: 100, margin: 5, textTransform: 'none', border: '1px solid ' + global.theme_light_gray, backgroundColor: global.theme_back }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                        <div style={{ display: 'flex', height: 80, width: 100, }}>
                                                            {item.image != '' ?
                                                                <img src={global.images + 'catalog/' + item.image + '?id=cat-' + item.slug} height={80} width={80} />
                                                                : null}
                                                        </div>
                                                        <div style={{ display: 'flex', height: 80, width: '100%', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10 }}>
                                                            <p style={{ margin: 0, fontSize: global.font_tiny, color: global.theme_black, fontWeight: 'bold' }}>{item.label}</p>
                                                        </div>
                                                    </div>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                    : null}

                                {/*productsFiltered.length > 0 ?*/}
                                <FilterBar show={settingsShow} display={settingsDisplay} stock={settingsStock} buttonID={sortButton} func={FilterBarPress.bind(this)} />
                                {/*}: null*/}

                                {productsFiltered.length > 0 ?
                                    <div style={{ ...styles.Block }}>
                                        {settingsDisplay == 0 ?
                                            // DLAŽDICOVÉ ZOBRAZENIE
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: isSmall ? 'center' : 'flex-start' }}>
                                                {productsFiltered.map(item => {
                                                    return (
                                                        <ProductBox price={GetPrice(GetCost(b2b == true ? 0 : 1, b2b == true ? isDPH == true ? item.cost : item.cost_dph : item.cost_dph, coeficient))} dph={b2b == true ? false : true} key={item.id} typ={0} item={item} func={ProductPress.bind(this)} />
                                                    )
                                                })}
                                            </div>
                                            :
                                            // RIADKOVÉ ZOBRAZENIE
                                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start' }}>
                                                {productsFiltered.map(item => {
                                                    return (
                                                        <ProductBox price={GetPrice(GetCost(b2b == true ? 0 : 1, b2b == true ? isDPH == true ? item.cost : item.cost_dph : item.cost_dph, coeficient))} dph={b2b == true ? false : true} key={item.id} typ={1} item={item} func={ProductPress.bind(this)} />
                                                    )
                                                })}
                                            </div>
                                        }
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                {productsFiltered.length > 4 ?
                                                    <Button onClick={() => GoHome()} style={{ textTransform: 'none', color: global.theme_dark_gray, fontWeight: 'bold' }}>
                                                        <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_dark_gray }} icon={faAngleUp} />
                                                        {lang.go_upper}
                                                    </Button>
                                                    : null}
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                {showMore == true ?
                                                    <Button onClick={() => ShowMore()} variant="outlined" style={{ margin: 20, width: 200, height: 40, color: global.theme_black, borderColor: global.theme_black }}>{lang.show_more}</Button>
                                                    : null}
                                            </div>

                                        </div>
                                    </div>

                                    :
                                    <div style={{ height: height }}></div>
                                }

                                {pageMax > 1 ?
                                    <div style={{ ...styles.Block, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <Pagination count={pageMax} size={isLarge ? 'medium' : 'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : null}

                            </div>
                        </div>

                        <div style={{ height: 20 }}></div>

                        {/* FOOTER */}
                        {/*isBusy == false ?
                        <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                                            : null*/}

                        {isBusy == true ?
                            <Loading></Loading>
                            : null}

                        {showProductBuy == true ?
                            <ProductBuy user={user} logged={logged} b2b={b2b} isDPH={isDPH} coeficient={coeficient} item={buyItem} func={ProductBuyPress.bind(this)} />
                            : null}

                        {showFavorites == true ?
                            <ProductFavorites user={user} item={favoriteProduct} func={ProductFavoritesPress.bind(this)} />
                            : null}
                    </div>
                    :
                    <Loading></Loading>
                }
            </div>
        </div >
    )
};



