import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, Footer, Loading, DivideProducts, DivideText, GalleryView, PlusMinus, LoadUser, Debug, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronCircleRight, faCircle, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis'
import { styles } from './styles.js';

export const Trade = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    const [web, setWeb] = useState([]);
    const [logged, setLogged] = useState(false);

    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [itemsA, setItemsA] = useState([]);
    const [itemsB, setItemsB] = useState([]);

    const textWidth = '70%';

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');

    const boxHeight = 50;
    const boxHeightSmall = 100;

    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setLogged(data.logged);

        GoHome();
        db_Items();

        return () => {
        };

    }, []);

    const db_Items = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'delivery_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({

                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                setItemsA(json.towns_a);
                setItemsB(json.towns_b);
                setText1(json.text1);
                setText2(json.text2);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }



    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_white }}>
            {isBusy == false ?
                <div style={{ ...styles.Block }}>

                    <div style={{ ...styles.BlockMaxLarge, marginBottom: 20 }}>

                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, marginTop: 50, paddingBottom: 40 }}>
                            <div style={{ borderBottom: '1px solid ' + global.theme_light_gray, paddingBottom: 15 }}>
                                <p style={{ ...styles.TextLabel, fontWeight: 'bold' }}>{lang.trades}</p>
                            </div>
                            <p style={{ ...styles.TextNormal, fontWeight: 'bold', marginTop: 15 }}>{'Zoznam rozvozových smerov'}</p>
                        </div>

                        {/* ************************************************************************
                            PARNY TYZDEN 
                        ************************************************************************ */}
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, marginTop: 50, paddingBottom: 40 }}>
                            <p style={{ ...styles.TextXLarge, fontWeight: 'bold' }}>{'Párny týždeň'}</p>
                        </div>

                        <div style={{ ...styles.BlockRow, margin: 0, height: isSmall ? 120 : 70, backgroundColor: global.theme_lighter, alignItems: 'center' }}>
                            <div style={{ ...styles.BlockLeft, width: 120 }}>
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '30%', paddingLeft: 10, paddingRight: 10 }}>
                                <img src={global.images + '/app/dealer_a.png'} style={{ width: 60, height: 60 }}></img>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginTop: isSmall ? 5 : 20, marginLeft: isSmall ? 0 : 10 }}>Díler A</p>
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '30%', paddingLeft: 10, paddingRight: 10 }}>
                                <img src={global.images + '/app/dealer_b_2.png'} style={{ width: 60, height: 60 }}></img>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginTop: isSmall ? 5 : 20, marginLeft: isSmall ? 0 : 10 }}>Díler B</p>
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '30%', paddingLeft: 10, paddingRight: 10 }}>
                                <img src={global.images + '/app/dealer_c.png'} style={{ width: 60, height: 60 }}></img>
                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginTop: isSmall ? 5 : 20, marginLeft: isSmall ? 0 : 10 }}>Díler C</p>
                            </div>
                        </div>


                        {itemsA.map((item, index) => (
                            <div key={index} style={{ ...styles.BlockRow, margin: 0, paddingTop: 20, paddingBottom: 20, ...styles.BorderTop }}>
                                <div style={{ ...styles.BlockLeft, width: 120, height: isSmall ? undefined : boxHeight }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_black }}>{lang.days[index + 1]}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: isSmall ? undefined : boxHeight, paddingLeft: 10, paddingRight: 10 }}>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_dark_gray }}>{item[0].towns}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: isSmall ? undefined : boxHeight, paddingLeft: 10, paddingRight: 10 }}>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_dark_gray }}>{item[1].towns}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '30%', height: isSmall ? undefined : boxHeight, paddingLeft: 10, paddingRight: 10 }}>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_dark_gray }}>{item[2].towns}</p>
                                </div>
                            </div>
                        ))}

                    </div>


                    <div style={{ ...styles.Block, backgroundColor: global.theme_gray, paddingBottom: 40 }}>
                        <div style={{ ...styles.BlockMaxLarge }}>

                            {/* ************************************************************************
                                NEPARNY TYZDEN 
                            ************************************************************************ */}
                            <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, marginTop: 50, paddingBottom: 40 }}>
                                <p style={{ ...styles.TextXLarge, fontWeight: 'bold' }}>{'Nepárny týždeň'}</p>
                            </div>

                            <div style={{ ...styles.BlockRow, margin: 0, height: isSmall ? 120 : 70, backgroundColor: global.theme_lighter, alignItems: 'center' }}>
                                <div style={{ ...styles.BlockLeft, width: 120 }}>
                                </div>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '30%', paddingLeft: 10, paddingRight: 10 }}>
                                    <img src={global.images + '/app/dealer_a.png'} style={{ width: 60, height: 60 }}></img>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginTop: isSmall ? 5 : 20, marginLeft: isSmall ? 0 : 10 }}>Díler A</p>
                                </div>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '30%', paddingLeft: 10, paddingRight: 10 }}>
                                    <img src={global.images + '/app/dealer_b_2.png'} style={{ width: 60, height: 60 }}></img>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginTop: isSmall ? 5 : 20, marginLeft: isSmall ? 0 : 10 }}>Díler B</p>
                                </div>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '30%', paddingLeft: 10, paddingRight: 10 }}>
                                    <img src={global.images + '/app/dealer_c.png'} style={{ width: 60, height: 60 }}></img>
                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_black, marginTop: isSmall ? 5 : 20, marginLeft: isSmall ? 0 : 10 }}>Díler C</p>
                                </div>
                            </div>


                            {itemsB.map((item, index) => (
                                <div key={index} style={{ ...styles.BlockRow, margin: 0, paddingTop: 20, paddingBottom: 20, ...styles.BorderTop }}>
                                    <div style={{ ...styles.BlockLeft, width: 120, height: boxHeight }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_black }}>{lang.days[index + 1]}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: isSmall ? undefined : boxHeight, paddingLeft: 10, paddingRight: 10 }}>
                                        <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_dark_gray }}>{item[0].towns}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: isSmall ? undefined : boxHeight, paddingLeft: 10, paddingRight: 10 }}>
                                        <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_dark_gray }}>{item[1].towns}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '30%', height: isSmall ? undefined : boxHeight, paddingLeft: 10, paddingRight: 10 }}>
                                        <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', color: global.theme_dark_gray }}>{item[2].towns}</p>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>

                    <div style={{ ...styles.Block, marginTop: 30, marginBottom: 60 }}>
                        <p style={{ ...styles.TextNormal }}>{text1}</p>
                        <p style={{ ...styles.TextNormal }}>{text2}</p>
                    </div>

                </div>
                :
                <div style={{ width: '100%', height: 800 }}></div>
            }

            {isBusy == true ?
                <Loading></Loading>
                : null}

        </div>

    )
};

