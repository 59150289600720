import React, { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { IconButton, Dialog, Paper } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, Debug, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome, DialogVideo } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faPlayCircle, faAngleRight, faArrowAltCircleLeft, faArrowAltCircleRight, faXmark, faQuestion, faHome } from '@fortawesome/free-solid-svg-icons';
import { styles } from './styles.js';
import './themes.js';


/*
*
* =================================================================================================================
* SPUSTENIE PREZENTACIE
*
*   <PresentationPlay stats={false} item={itemSelected} lang={lang} func={PresentationResult.bind()} />
*
*   PARAMS:
* -----------------------------------------------------------------------------------------------------------------
*   stats         -> true (ukladanie štatistiky pozeriania)
*   customer_id    -> ID zákazníka, ktorému bola prezentovaná (undefined = 0)
* =================================================================================================================
*
*/
export const PresentationPlay = (props) => {

    let params = useParams();

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[0];

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');
    const logo = require('./react/app/logo.png');
    const background = require('./react/app/presentations_background.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [presentation, setPresentation] = useState(false);
    const [images, setImages] = useState(false);
    const [imageIndex, setImageIndex] = useState(-1);
    const [imageLast, setImageLast] = useState(-1);
    const [controls, setControls] = useState(true);
    const [thumbs, setThumbs] = useState(false);
    const [showIntro, setShowIntro] = useState(true);
    const [started, setStarted] = useState(false);
    const [disabled, setDisabled] = useState(false);

    // MP4 VIDEO
    const [introVideo, setIntroVideo] = useState('');
    const [endingVideo, setEndingVideo] = useState('');
    const [showIntroVideo, setShowIntroVideo] = useState(false);
    const [showEndingVideo, setShowEndingVideo] = useState(false);

    // CASOVAC
    const [timing, setTiming] = useState([]);

    const [loading, setLoading] = useState({ value: 0 });
    const [loaded, setLoaded] = useState(false);
    const [showFinish, setShowFinish] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width;
    const dialogHeight = height;
    const buttonSize = 40;
    const thumbSize = 80;

    const timeOffset = 3000; // Ak je cas stráveny na snímku mensi ako predefinovaný -> nepočítať 

    let { func } = props;
    var running = false;

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }

        if (running == false) {

            window.addEventListener('resize', handleResize);

            db_get();

            running = true;
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);



    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'presentation_get', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tid: params.id
                })
            })

            const json = await response.json();

            Debug(json);
            if (json.ok > 0) {
                const items = json.items;

                if (items != false) {
                    setPresentation(json.presentation);
                    setImages(items);

                    var intro_link = global.presentation_intro.find(x => x.id == json.presentation.intro_video).video;
                    var ending_link = global.presentation_ending.find(x => x.id == json.presentation.ending_video).video;
                    setIntroVideo(intro_link);
                    setEndingVideo(ending_link);

                    setShowIntroVideo(true);
                    setShowEndingVideo(true);
                } else {
                    setDisabled(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        if (started == true) {
            if (props.stats == true) {
                //GetActualSlide();
                //setEndedTime(Date.now());
                setShowFinish(true);
            } else {
                func(false);
            }
        } else {
            func(false);
        }
    }

    const ChangeIndex = (new_index, start) => {

        let index = imageIndex;
        setImageLast(index);

        /*
        if (start == false) {
            GetActualSlide();
        }
            */

        if (imageIndex >= 0) {
            const audio_last = document.getElementById('audio-' + String(imageIndex));
            if (audio_last != null && audio_last != false) {
                audio_last.pause();
                audio_last.currentTime = 0;
            }
        }

        const audio_new = document.getElementById('audio-' + String(new_index));
        if (audio_new != null && audio_new != false) {
            audio_new.currentTime = 0;
            audio_new.play();
        }

        setImageIndex(new_index);

    }

    const NextSlide = () => {
        if (imageIndex < images.length - 1) {

            let index = imageIndex;
            let new_index = index + 1;

            setImageLast(index);

            if (index >= 0) {
                const audio_last = document.getElementById('audio-' + String(index));
                if (audio_last != null && audio_last != false) {
                    audio_last.pause();
                    audio_last.currentTime = 0;
                }
            }

            const audio_new = document.getElementById('audio-' + String(new_index));
            if (audio_new != null && audio_new != false) {
                audio_new.currentTime = 0;
                audio_new.play();
            }
            setImageIndex(new_index);


        } else {
            setShowEndingVideo(true);
            setImageIndex(-1);
            const video = document.getElementById('ending-video');
            Debug(video);
            if (video != null && video != false) {
                video.currentTime = 0;
                video.play();
            }
        }
    }

    const Controls = () => {
        let value = !controls;
        setThumbs(false);
        setControls(value);
    }

    const Start = () => {
        //ChangeIndex(0, true);
        //setStarted(true);
        /*
        setTimeout(() => {
            setShowIntro(false);
        }, 1500);
        */
        setShowIntroVideo(true);
        setShowIntro(false);
        const video = document.getElementById('intro-video');
        if (video != null && video != false) {
            video.currentTime = 0;
            video.play();
            setRedraw(!redraw);
        }

    }

    const IntroEnded = () => {
        setShowEndingVideo(false);
        ChangeIndex(0, true);
        setStarted(true);
        setShowIntroVideo(false);
    }

    const EndingEnded = () => {
        setShowIntro(true);
        setShowIntroVideo(true);
    }

    const Loaded = () => {
        loading.value = loading.value + 1;
        if (loading.value >= images.length - 2) {
            setBusy(false);
            setLoaded(true);
        }
    }

    const FinishResult = (value) => {
        setShowFinish(false);
        /*
        if (props.stats == true && value == true && started == true) {
            Debug('Ulozenie statistiky');
            var items = [];
            timing.forEach(item => {
                if (parseInt(item.time) > 0) {
                    let data = {
                        presentation_id: presentation.id,
                        slide_id: item.id,
                        elapsed: item.time
                    };
                    items.push(data);
                }
            });
            // ZACIATOK PREZENTACIE -> slide = 0
            let data = {
                created: startedDateTime,
                presentation_id: presentation.id,
                slide_id: 0,
                elapsed: (endedTime - startedTime) / 1000
            };
            items.push(data);

            //db_stats_update(items);
        } else {
            Debug('Neukladanie statistiky', 'red');
            func(false);
        }
            */
    }

    return (
        <Dialog fullScreen open={true} style={{ zIndex: 2000, backgroundColor: '#000000FF' }}>
            <div style={{ ...styles.Block, width: width, height: height, backgroundImage: `url(${background})` }}>

                {/*
                *
                * =================================================================================================================
                * ENDING VIDEO (opacity enabled)
                * =================================================================================================================
                *
                */}
                {endingVideo != '' ?
                    <div style={{ ...styles.Block, width: width, height: height, backgroundImage: `url(${background})`, position: 'absolute', opacity: showEndingVideo == true ? 1 : 0 }}>
                        <video id='ending-video' playsInline onEnded={() => EndingEnded()} style={{ maxWidth: '100%', maxHeight: height - 100 }}>
                            <source src={global.web + '/public/uploads/presentations/' + endingVideo} type="video/mp4" />
                        </video>
                    </div>
                    : null}

                {/*
                *
                * =================================================================================================================
                * IMAGE SLIDESHOW
                * =================================================================================================================
                *
                */}
                {images != false ? images.map((item, index) => (
                    <div key={index} style={{ ...styles.Block }}>
                        <img onLoad={() => Loaded()} onClick={() => Controls(!controls)} key={index} src={global.web + '/' + item.image} style={{ width: '100%', height: '100%', maxWidth: width, maxHeight: height, objectFit: 'contain', position: 'absolute', opacity: imageIndex == index ? 1 : loaded == false ? 1 : 0, transitionDuration: presentation.effect == 1 || presentation.effect == 2 ? '0.75s' : undefined, transform: presentation.effect == 2 ? imageIndex == index ? 'scale(1.00)' : 'scale(1.08)' : undefined, display: imageIndex == index || imageIndex == index - 1 || imageIndex == index + 1 || index == imageLast ? 'block' : 'none' }}></img >
                        {item.voice != '' ?
                            <audio id={'audio-' + index} onEnded={() => NextSlide()} src={global.web + '/' + item.voice} type='audio/mpeg' />
                            :
                            <audio id={'audio-' + index} onEnded={() => NextSlide()} src={global.web + '/public/uploads/presentations/none.mp3'} type='audio/mpeg' />
                        }
                    </div>
                )) : null
                }

                {/*
                *
                * =================================================================================================================
                * INTRO VIDEO
                * =================================================================================================================
                *
                */}
                {showIntroVideo == true ?
                    <div style={{ ...styles.Block, width: width, height: height, backgroundImage: `url(${background})`, position: 'absolute' }}>
                        {introVideo != '' ?
                            <video id='intro-video' playsInline onEnded={() => IntroEnded()} style={{ maxWidth: '100%', maxHeight: height - 100 }}>
                                <source src={global.web + '/public/uploads/presentations/' + introVideo} type="video/mp4" />
                            </video>
                            : null}
                    </div>
                    : null}

                {/*
                *
                * =================================================================================================================
                * START - SPUSTENIE PREZENTACIE
                * =================================================================================================================
                *
                */}
                {showIntro == true ?
                    <div style={{ ...styles.Block, width: width, height: height, backgroundColor: global.theme_black, position: 'absolute', opacity: imageIndex < 0 ? 1 : 0, transitionDuration: '0.75s' }}>
                        <div style={{ ...styles.Block, width: width, height: height, backgroundImage: `url(${background})` }}>
                            <Paper elevation={3} style={{ ...styles.Block, width: 600, height: height < 650 ? height - 50 : 650, borderRadius: 20, backgroundColor: '#FFFFFFA0' }}>
                                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 320, maxHeight: 100, objectFit: 'contain' }}></img>

                                {disabled == false ?
                                    <>
                                        {height < 600 ? null : presentation != false ? presentation.image != '' ?
                                            <img src={global.web + '/' + presentation.image} style={{ width: '100%', height: '100%', maxWidth: 320, maxHeight: 320, objectFit: 'contain' }}></img>
                                            : null : null}
                                        <p style={{ ...styles.TextXLarge, color: color.black, marginTop: 10, marginBottom: 50, textAlign: 'center', fontWeight: '500' }}>{presentation.name}</p>
                                        {images != false && loaded == true ?
                                            <>
                                                <IconButton onClick={() => Start()} style={{ width: 64, height: 64 }}>
                                                    <FontAwesomeIcon style={{ height: 64, color: color.black }} icon={faPlayCircle} />
                                                </IconButton>
                                                <p style={{ ...styles.TextXSmall, color: color.black, marginTop: 5, textAlign: 'center', letterSpacing: 1.1 }}>{lang.presentation_run_}</p>
                                            </>
                                            : null}
                                    </>
                                    :
                                    <p style={{ ...styles.TextXLarge, color: color.black, marginTop: 10, marginBottom: 50, textAlign: 'center', fontWeight: '500' }}>{lang.presentation_disabled}</p>
                                }
                            </Paper>
                        </div>
                    </div>
                    : null}

                {imageIndex >= 0 && images != false ?
                    <div onClick={() => ChangeIndex(imageIndex > 0 ? imageIndex - 1 : imageIndex, false)} style={{ ...styles.Block, width: 300, height: height / 2, position: 'absolute', left: 0, top: height / 4 }}>
                        <FontAwesomeIcon style={{ height: 80, color: color.white, opacity: showIntro == true ? 0.6 : 0, transitionDuration: '1.00s' }} icon={faAngleLeft} />
                    </div>
                    : null}

                {imageIndex >= 0 && images != false ?
                    <div onClick={() => ChangeIndex(imageIndex < images.length - 2 ? imageIndex + 1 : images.length - 1, false)} style={{ ...styles.Block, width: 300, height: height / 2, position: 'absolute', right: 0, top: height / 4 }}>
                        <FontAwesomeIcon style={{ height: 80, color: color.white, opacity: showIntro == true ? 0.6 : 0, transitionDuration: '1.00s' }} icon={faAngleRight} />
                    </div>
                    : null}

                {imageIndex >= 0 && controls == true && images != false ?
                    <div style={{ ...styles.Block, width: buttonSize + buttonSize + 200 + 10, height: buttonSize + 10, position: 'absolute', bottom: buttonSize / 2, backgroundColor: '#00000070', borderRadius: (buttonSize + 10) / 2 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block, width: buttonSize, height: buttonSize }}>
                                {imageIndex > 0 ?
                                    <IconButton onClick={() => ChangeIndex(imageIndex > 0 ? imageIndex - 1 : imageIndex, false)} style={{ ...styles.ButtonIcon, width: buttonSize, height: buttonSize, width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: '#00000040' }}>
                                        <FontAwesomeIcon style={{ height: buttonSize, color: color.white, opacity: 0.7 }} icon={faArrowAltCircleLeft} />
                                    </IconButton>
                                    : null}
                            </div>
                            <div onClick={() => setThumbs(!thumbs)} style={{ ...styles.Block, width: 200, height: buttonSize, cursor: 'pointer' }}>
                                <p style={{ ...styles.TextLarge, color: color.white }}>{imageIndex + 1} / {images.length}</p>
                            </div>
                            <div style={{ ...styles.Block, width: buttonSize, height: buttonSize }}>
                                {imageIndex < images.length - 1 ?
                                    <IconButton onClick={() => ChangeIndex(imageIndex < images.length - 2 ? imageIndex + 1 : images.length - 1, false)} style={{ ...styles.ButtonIcon, width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: '#00000040' }}>
                                        <FontAwesomeIcon style={{ height: buttonSize, color: color.white, opacity: 0.7 }} icon={faArrowAltCircleRight} />
                                    </IconButton>
                                    : null}
                            </div>
                        </div>
                    </div>
                    : null}

                {thumbs == true && images != false && controls == true ?
                    <div style={{ ...styles.Block, width: '80%', height: thumbSize + 10, position: 'absolute', bottom: buttonSize / 2 + thumbSize, backgroundColor: '#000000A0' }}>
                        <div style={{ ...styles.BlockCenter, alignItems: 'flex-start', height: thumbSize, flexDirection: 'row', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowX: 'scroll' }} className='container'>
                            {images.map((item, index) => (
                                <img onClick={() => ChangeIndex(index, false)} key={index} src={global.web_presentations + item.thumb} style={{ width: '100%', height: '100%', maxWidth: thumbSize, maxHeight: thumbSize, objectFit: 'contain', marginRight: 5, borderRadius: 5 }}></img>
                            ))}
                        </div>
                    </div>
                    : null}


                {controls == true ?
                    <div style={{ ...styles.Block, width: buttonSize, height: buttonSize, position: 'absolute', top: 16, right: 16 }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose, width: 40, height: 40, border: '1px solid #000000', borderRadius: 10 }}>
                            <FontAwesomeIcon style={{ height: 18 }} icon={faHome} />
                        </IconButton>
                    </div>
                    : null}

            </div >

            {/*isBusy == true ?
                <Loading center></Loading>
                : null*/}


        </Dialog >
    );
}