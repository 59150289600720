import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faCar, faMinus, faMinusCircle, faMinusSquare, faPersonWalking, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import { style } from '@mui/system';
import GoogleMapReact from 'google-map-react';
import { layer } from '@fortawesome/fontawesome-svg-core';


export const Visit = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const lat = 48.382249;
    const lng = 18.395264;
    const mapLink = "http://www.google.com/maps/place/" + lat + "," + lng
    
    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const img1 = require('./react/app/img_1.jpg');
    const pin = require('./react/app/pin.png');

    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        GoHome();

        return () => {
        };

    }, []);



    const GotoBack = () => {
        navigate(-1);
    }

    const AnyReactComponent = ({ text }) => (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
            <img src={pin} height={40} />
            <p style={{ ...styles.TextSmall, marginTop: 12, fontWeight: 'bold', color: global.theme_dark_red }}>Polplast</p>
        </div>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef} style={{ ...styles.Block, marginTop: 30 }}>

                <div style={{ ...isSmall ? styles.Block : styles.BlockMaxLarge, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_gray }}>
                        <div style={{ ...styles.Block, width: isSmall ? '95%' : '50%', padding: isSmall ? 0 : 20 }}>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockLeft }}>
                                <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: isSmall ? 'center' : 'left' }}>Neváhajte nás navštíviť:</h1>
                                <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 40, textAlign: 'left', color: global.theme_dark_gray }}>Milí obchodní priatelia!</p>
                                <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_dark_gray }}>Navštívte nás v našej kamennej predajni pre maloobchodných a veľkoobchodných zákazníkov.</p>
                                <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_dark_gray }}>Okrem online nákupu z pohodlia domova a z displeja mobilného telefónu, náš tovar môžte reálne vziať do rúk a vnímať jeho detaily, vzory a materiály.</p>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, marginTop: isLarge ? 50 : 20, marginBottom: isLarge ? 0 : 30 }}>
                                    <Button onClick={() => { navigate('/reserve') }} variant="contained" style={{ ...styles.ButtonGreen }}>{lang.reserve_termin}</Button>
                                </div>

                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '95%' : '50%' }}>
                            <img src={img1} style={{ width: '100%', height: '100%' }} />
                        </div>

                    </div>

                    <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 40 }}>Prečo je kamenná predajňa výhodou?</p>
                    <ul style={{ ...style.TextSmall, textAlign: 'left' }}>
                        <li>rozmery tovaru v 3D rozhraní sa ľahšie premerajú, lebo fyzickú veľkosť vnímame naživo oveľa ľahšie ako online</li>
                        <li>primeranie rastlín pre výsadbou k vybraným produktom</li>
                        <li>farby online môžu kvôli kalibrácii monitoru a displeja v telefóne trochu skresľovať</li>
                        <li>zladenie farieb pred aranžovaním</li>
                        <li>tipy a triky z našich denných skúseností</li>
                    </ul>

                    <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 40 }}>Služby zákazníkom:</p>
                    <ul style={{ ...style.TextSmall, textAlign: 'left' }}>
                        <li>parkovisko v samostatnom dvore pred obchodom</li>
                        <li>asistencia personálu pri nakladaní ťažkého zakúpeného tovaru ( substrát, exteriérová keramika)</li>
                        <li>platba kartou nad 7,00 €</li>
                        <li>zákaznícky zľavový systém</li>
                        <li>odborné poradenstvo pre individuálne zákazky ( záhradní architekti, dizajnéri interiérov, koncoví zákazníci)</li>
                    </ul>
                </div>

                <div style={{ ...styles.Block, backgroundColor: global.theme_gray, marginTop: 50 }}>
                    <div style={{ ...isSmall ? styles.BlockSmall : styles.BlockMaxLarge, justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 50, marginBottom: 50 }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faCar} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0, textAlign: 'left' }}>Ako nás nájdete autom?</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left' }}>Do navigácie si zadajte Ul. 1. mája 4 v Zlatých Moravciach.</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left' }}>GPS Vás nasmeruje do centra mesta a pod mestským úradom zabočíte hneď na naše parkovisko!</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left', marginTop:10 }}>Súradnice GPS (LAT: {lat}, LNG: {lng})</p>
                                <a style={{marginTop:20 }} href={mapLink} target="_blank">Google maps</a>
                            </div>
                        </div>
                        {/*
                        <div style={{ ...styles.BlockRow, marginTop: 40 }}>
                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faPersonWalking} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0, textAlign: 'left' }}>Ako k nám prísť pešo?</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left' }}>Z námestia A. Hlinku 9. Vchod nájdete priamo z námestia pod Slovenskou sporiteľňou.</p>
                                <p style={{ ...styles.TextSmall, fontWeight: 'normal', textAlign: 'left' }}>Vyznačené dvere s reklamou sú na konci dvora s butikmi a kaviarňami.</p>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>

            <div style={{ height: 400, width: isSmall ? '100%' : '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g' }}
                    defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                    defaultZoom={17}
                >
                    <AnyReactComponent
                        lat={lat}
                        lng={lng}
                    />
                </GoogleMapReact>

            </div>
            {isLoading == true ?
                <Loading></Loading>
                : null}
        </div >

    )
};
