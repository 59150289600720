import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, MenuTop, LoadLogin, ShoppingQuantity, Loading, Footer, LoadSettings, GalleryView, PlusMinus, LoadUser, MenuBottom, UserData, GoHome, SaveUser } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faList, faPaperclip, faUser } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { Orders } from './orders.js';
import { styles } from './styles.js';
import { Reklamacie } from './reklamacie.js';

export const User = (props) => {
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [web, setWeb] = useState([]);

    const [user, setUser] = useState(null);
    const [b2b, setB2B] = useState(false);
    const [index, setIndex] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);


        var data = GetUser();
        setUser(data.user);

        GoHome();
        db_user();

        return () => {
        };

    }, []);

    const db_user = async () => {
        setLoading(true);
        var user = LoadUser();
        if (user != null) {
            try {
                const response = await fetch(
                    global.db_url + 'user', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        language: language,
                        client_id: user.id
                    })
                })

                const json = await response.json();
                if (json.ok > 0) {
                    // --------------------------------------
                    // WEB - základné údaje o webe
                    // --------------------------------------
                    setWeb(json.web);
                    if (json.user != null) {
                        setUser(json.user);
                        SaveUser(json.user);
                        setB2B(json.user.trieda == 0 ? true : false);
                    }
                }
                setLoading(false);

                if (json.ok > 0) {
                    if (json.user == null) {
                        navigate('/');
                    }
                }
            } catch (error) {
                Debug(error);
                setLoading(false);
            }
        }
    }



    const ChangeScreen = (id) => {
        setIndex(id);

    }

    const UserDataPress = (value) => {
        if (value == 0) {
            // navrat bez ukladania zmien
            ChangeScreen(0);
        }
        if (value == 1) {
            // zmena údajov
            ChangeScreen(3);
        }
        if (value == 2) {
            // udaje boli zmenené
            ChangeScreen(0);
            db_user();
        }
        if (value == 3) {
            // zmena hesla
            ChangeScreen(4);
        }
    }

    const GotoBack = () => {
        navigate('/');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>
            <div ref={homeRef}>
                {/*
                <MenuTop loged={false} mobil={web.mobil} email={web.email} func={MenuTopPress.bind(this)} />
                <MenuLogo quantity={shoppingCardQuantity} func={MenuLogoPress.bind(this)} />
                */}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: global.font_label, color: global.theme_black }}>{lang.my_account}</p>
                    {b2b == true ?
                        <div style={{ paddingTop: 0, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: 'bold' }}>{lang.b2b_prices}</p>
                        </div>
                        : null}
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_dark }}>
                        <Button onClick={() => GotoBack()} style={{ display: 'flex', width: 200, height: 40, textTransform: 'none', color: index == -1 ? global.theme_white : global.theme_white, backgroundColor: index == -1 ? global.theme_light : global.theme_dark, borderRadius: 0 }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_white }} icon={faChevronLeft} />
                            <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny, color: global.theme_white, textAlign: 'left' }}>
                                {lang.back}
                            </p>
                        </Button>
                        <Button onClick={() => ChangeScreen(0)} style={{ display: 'flex', width: 200, height: 40, textTransform: 'none', color: index == 0 || index == 3 ? global.theme_white : global.theme_white, backgroundColor: index == 0 ? global.theme_light : global.theme_dark, borderRadius: 0 }}>
                            {isSmall ? null :
                                <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_white }} icon={faUser} />
                            }
                            <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny, color: global.theme_white, textAlign: 'center', lineHeight: 1.2 }}>
                                {lang.account_settings}
                            </p>
                        </Button>
                        <Button onClick={() => ChangeScreen(1)} style={{ display: 'flex', width: 200, height: 40, textTransform: 'none', color: index == 1 ? global.theme_white : global.theme_white, backgroundColor: index == 1 ? global.theme_light : global.theme_dark, borderRadius: 0 }}>
                            {isSmall ? null :
                                <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_white }} icon={faList} />
                            }
                            <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny, color: global.theme_white, textAlign: 'center', lineHeight: 1.2 }}>
                                {lang.account_history}
                            </p>
                        </Button>
                        <Button onClick={() => ChangeScreen(5)} style={{ display: 'flex', width: 200, height: 40, textTransform: 'none', color: index == 5 ? global.theme_white : global.theme_white, backgroundColor: index == 5 ? global.theme_light : global.theme_dark, borderRadius: 0 }}>
                            {isSmall ? null :
                                <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_white }} icon={faPaperclip} />
                            }
                            <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny, color: global.theme_white, textAlign: 'center', lineHeight: 1.2 }}>
                                {lang.reklamacie}
                            </p>
                        </Button>
                        {/*
                        <Button onClick={() => ChangeScreen(2)} style={{ display: 'flex', width: 200, height: 40, textTransform: 'none', color: index == 2 ? global.theme_white : global.theme_white, backgroundColor: index == 2 ? global.theme_light : global.theme_dark, borderRadius: 0 }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_white }} icon={faNoteSticky} />
                            {lang.account_products}
                        </Button>
                        */}
                    </div>

                    {index == 0 ?
                        // NASTAVENIA UCTU
                        <div>
                            <p style={{ fontSize: global.font_large, color: global.theme_black }}>{lang.account_settings}</p>
                            {isLoading == false ?
                                user != null ?
                                    <UserData typ={1} user={user} register={false} func={UserDataPress.bind(this)} />
                                    : null
                                : null
                            }
                        </div>
                        : null}
                    {index == 1 ?
                        // HISTORIA NAKUPOV
                        <div style={{ ...styles.Block }}>
                            <Orders user_id={user.id}></Orders>
                        </div>
                        : null}
                    {index == 2 ?
                        // ZAKUPENE PRODUKTY
                        <div>
                            <p style={{ fontSize: global.font_normal, color: global.theme_black }}>{lang.account_products}</p>

                        </div>
                        : null}
                    {index == 3 ?
                        // EDITACIA UDAJOV
                        <div>
                            <p style={{ fontSize: global.font_normal, color: global.theme_black }}>{lang.account_settings}</p>
                            {isLoading == false ?

                                <UserData typ={0} register={false} user={user} func={UserDataPress.bind(this)} />
                                : null}
                        </div>
                        : null}
                    {index == 4 ?
                        // ZMENA HESLA
                        <div>
                            <p style={{ fontSize: global.font_normal, color: global.theme_black }}>{lang.account_settings}</p>
                            {isLoading == false ?

                                <UserData typ={2} register={false} user={user} func={UserDataPress.bind(this)} />
                                : null}
                        </div>
                        : null}
                    {index == 5 ?
                        // REKLAMACIE
                        <div style={{ ...styles.Block }}>
                            <Reklamacie user_id={user.id} />
                        </div>
                        : null}
                </div>
                <div style={{ height: 20 }}></div>
                {/* FOOTER */}
                {/*isLoading == false ?
                    <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                            : null*/}

                {isBusy == true ?
                    <Loading></Loading>
                    : null}
            </div>
        </div >
    )
};

