import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sk } from './globals.js';
import { Main } from './main.js';
import { Catalog } from './catalog.js';
import { Product } from './product.js';
import { Init } from './items';
import { User } from './user.js';
import { UserRegister } from './user_register.js';
import { Downloads } from './downloads.js';
import { Blog } from './blog.js';
import { Blogs } from './blogs.js';
import { Trade } from './trade.js';
import { Gdpr } from './gdpr.js';
import { Vop } from './vop.js';
import { Cookies } from './cookies.js';
import { NewsletterRegister } from './newsletter_register.js';
import { ShoppingCard } from './shopping_card.js';
import { Checkout } from './checkout.js';
import { Reset } from './reset.js';
import { Search } from './search.js';
import { Reserve } from './reserve.js';
import { Favorites } from './favorites.js';
import { styles } from './styles.js';
import { Button } from '@mui/material';
import { Visit } from './visit.js';
import { Contact } from './contact.js';
import { Asking } from './asking.js';
import { Delivery } from './delivery.js';
import { Stock } from './stock.js';
import { AboutUs } from './about_us.js';
import { Reklamacia } from './reklamacia.js';


import { useMediaQuery } from 'react-responsive'
import { Debug, MenuLogo, MenuTop, Menu, ShoppingQuantity, GetUser, LoadCookies, SaveCookies, CookiesInfo, ProductBox, Footer, UniqueID, LoadUniqueID, SaveUniqueID, GetCost, GetPrice, ProductBuy, BannerServices, BannerReserveDate } from './items.js';
import { PresentationPlay } from './prezentacia.js';

function App() {
    const navigate = useNavigate();

    const [showCookies, setShowCookies] = useState(true);

    const [isLoading, setLoading] = useState(true);
    const [showMenu, setShowMenu] = useState(true);

    const [web, setWeb] = useState([]);

    const [shoppingCardQuantity, setShoppingCardQuantity] = useState(0);
    const [user, setUser] = useState(null);
    const [redraw, setRedraw] = useState(false);
    const [coeficient, setcoeficient] = useState(1);
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [logged, setLogged] = useState(false);
    const [callNote, setCallNote] = useState('');

    // FOOTER    
    const [subcompanies, setSubcompanies] = useState([]);
    const [blogLabel, setBlogLabel] = useState('');
    const [catalogueLabel, setCatalogueLabel] = useState('');
    const [actionLabel, setActionLabel] = useState('');
    const [newsletterSended, setNewsletterSended] = useState('');


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    useEffect(() => {
        Init();

        var cookies = LoadCookies();

        if (cookies == true) {
            setShowCookies(false);
        } else {
            setShowCookies(true);
        }

        var data = GetUser();

        db_web(data.id);

    }, [])

    const db_web = async (user_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'web', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: user_id,
                    banners_typ: 0,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                // --------------------------------------
                // WEB - základné údaje o webe
                // --------------------------------------
                setWeb(json.web);

                // --------------------------------------
                // SECTIONS
                // --------------------------------------
                var tmp = json.sections;
                tmp.sort((a, b) => {
                    if (a.priority > b.priority) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setSections(tmp);

                // --------------------------------------
                // SUBSECTIONS
                // --------------------------------------
                var tmp = json.subsections;
                tmp.sort((a, b) => {
                    if (a.priority > b.priority) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setSubsections(tmp);

                // --------------------------------------
                // KOEFICIENT PREPOCTU CIEN
                // --------------------------------------
                var tmp = json.coeficient;
                setcoeficient(parseFloat(tmp));

                // --------------------------------------
                // POCET POLOZIEK V KOSIKU AK JE PRIHLASENY
                // --------------------------------------
                if (user_id > 0) {
                    setShoppingCardQuantity(json.shopping_quantity);
                } else {
                    setShoppingCardQuantity(ShoppingQuantity());
                }

                // --------------------------------------
                // WEB - PREVADZKY - FOOTER
                // --------------------------------------
                setSubcompanies(json.subcompanies);

                // --------------------------------------
                // TEXTY
                // --------------------------------------
                setActionLabel(json.txt_action.string);
                setBlogLabel(json.txt_blog.string);
                setCatalogueLabel(json.txt_catalogue.string);
                setNewsletterSended(json.txt_newsletter_ok.string);
                setCallNote(json.txt_call_note.string);
                setLoading(false);
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const MenuTopPress = (type, id) => {
        if (type == 0) {
            // registracia
            navigate('/user_register');
        }
        if (type == 1 || type == 2) {
            // prihlásenie / odhlásenie
            reloadPage();
        }
        if (type == 3) {
            // menu
            var tmp = subsections.filter(x => x.id == id);
            console.log(tmp);
            if (tmp.length > 0) {
                var slug = tmp[0].slug;

                var tmp = sections.filter(x => x.id == tmp[0].parent);
                if (tmp.length > 0) {
                    slug = tmp[0].slug + '/' + slug;
                    DeleteScroll();
                    navigate('/catalog/' + slug);
                }
            }
        }
        if (type == 4) {
            // home
            navigate('/');
        }
    }

    const reloadPage = () => {
        window.location.reload(false);
    }

    const MenuLogoPress = (type, value) => {
        if (type == 0) {
            // KLIKNUTIE NA LOGO            
            navigate('/');
        }
    }


    const DeleteScroll = () => {
        sessionStorage.removeItem('scrollPosition')
    }

    const RouteFunc = (type, value) => {
        if (type == 0) {
            // ZOBRAZIT MENU KATEGORRI / SEKCII
            if (showMenu != value) {
                setShowMenu(value);
            }
        }
        if (type == 1) {
            // POCET KUSOV V KOSIKU
            setShoppingCardQuantity(value);
        }
        if (type == 2) {
            // PRIDAT POCET KUSOV DO KOSIKA
            console.log(shoppingCardQuantity, value);
            setShoppingCardQuantity(parseInt(shoppingCardQuantity) + value);
        }
    }

    const MenuPress = (type, id) => {
        if (type == 0) {
            //KLIKNUTIE NA KATEGÓRIU
            //ChangeSubsection(subsections, categories, id);

            var tmp = subsections.filter(x => x.id == id);
            if (tmp.length > 0) {
                var slug = tmp[0].slug;

                var tmp = sections.filter(x => x.id == tmp[0].parent);
                if (tmp.length > 0) {
                    slug = tmp[0].slug + '/' + slug;
                    DeleteScroll();
                    navigate('/catalog/' + slug);
                }
            }
        }
        if (type == 1) {
            // KLIKNUTIE NA SEKCIU
            //ChangeSection(id);
            var tmp = sections.filter(x => x.id == id);
            if (tmp.length > 0) {
                DeleteScroll();
                navigate('/catalog/' + tmp[0].slug);
            }
        }
        if (type == 2) {
            // DOMOV
            navigate('/');
        }
    }

    const CookiesInfoPress = () => {
        SaveCookies();
        setShowCookies(false);
    }

    const PresentationEnd = () => {
        navigate('/');
    }

    return (
        <div className="App" style={{ backgroundColor: global.theme_white, fontFamily: 'roboto' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />

            <Helmet>
                <title>{lang.title}</title>
                <meta name="description" content={lang.description} />
            </Helmet>

            {/* MENU */}
            {showMenu == true ?
                <div>
                    <MenuTop height={height} subsections={subsections} loged={false} mobil={web.mobil} email={'info'} name={''} surname={''} time={callNote} func={MenuTopPress.bind(this)} />
                    <MenuLogo quantity={shoppingCardQuantity} func={MenuLogoPress.bind(this)} />
                    <Menu height={height} sections={sections} subsections={subsections} func={MenuPress.bind(this)} />
                </div>
                : null}

            <Routes>
                <Route path="/" element={<Main func={RouteFunc.bind(this)} />} />
                <Route path="/card" element={<ShoppingCard func={RouteFunc.bind(this)} />} />
                <Route path="/checkout" element={<Checkout func={RouteFunc.bind(this)} />} />
                <Route path="/reset" element={<Reset func={RouteFunc.bind(this)} />} />
                <Route path="/downloads" element={<Downloads func={RouteFunc.bind(this)} />} />
                <Route path="/blog/:id" element={<Blog func={RouteFunc.bind(this)} />} />
                <Route path="/blogs" element={<Blogs func={RouteFunc.bind(this)} />} />
                <Route path="/rozvoz" element={<Trade func={RouteFunc.bind(this)} />} />
                <Route path="/gdpr" element={<Gdpr func={RouteFunc.bind(this)} />} />
                <Route path="/vop" element={<Vop func={RouteFunc.bind(this)} />} />
                <Route path="/reklamacny-poriadok" element={<Reklamacia func={RouteFunc.bind(this)} />} />
                <Route path="/reserve" element={<Reserve func={RouteFunc.bind(this)} />} />
                <Route path="/favorites" element={<Favorites func={RouteFunc.bind(this)} />} />
                <Route path="/cookies" element={<Cookies func={RouteFunc.bind(this)} />} />
                <Route path="/predajna" element={<Visit func={RouteFunc.bind(this)} />} />
                <Route path="/dorucenie" element={<Delivery func={RouteFunc.bind(this)} />} />
                <Route path="/sklad" element={<Stock func={RouteFunc.bind(this)} />} />
                <Route path="/contact" element={<Contact func={RouteFunc.bind(this)} />} />
                <Route path="/o-nas" element={<AboutUs func={RouteFunc.bind(this)} />} />
                <Route path="/search/:search" element={<Search func={RouteFunc.bind(this)} />} />
                <Route path="/newsletter/confirm/:id" element={<NewsletterRegister func={RouteFunc.bind(this)} />} />
                <Route path="/user" element={<User func={RouteFunc.bind(this)} />} />
                <Route path="/user_register" element={<UserRegister func={RouteFunc.bind(this)} />} />
                <Route path="/product/:id" element={<Product func={RouteFunc.bind(this)} />} />
                <Route path="/pytate_sa" element={<Asking func={RouteFunc.bind(this)} />} />
                <Route path="/catalog" element={<Main func={RouteFunc.bind(this)} />} />
                <Route path="/catalog/:section" element={<Catalog type={1} draw={redraw} func={RouteFunc.bind(this)} />} />
                <Route path="/catalog/:section/:subsection" element={<Catalog type={2} func={RouteFunc.bind(this)} />} />
                <Route path="/catalog/:section/:subsection/:category" element={<Catalog type={3} func={RouteFunc.bind(this)} />} />
                <Route path="/catalog/:section/:subsection/:category/:subcategory" element={<Catalog type={4} func={RouteFunc.bind(this)} />} />
                <Route path="/catalog/:section/:subsection/:category/:subcategory/:subproduct" element={<Catalog type={5} func={RouteFunc.bind(this)} />} />
                <Route path="/katalog" element={<Main func={RouteFunc.bind(this)} />} />
                <Route path="/katalog/:section" element={<Catalog type={1} draw={redraw} func={RouteFunc.bind(this)} />} />
                <Route path="/katalog/:section/:subsection" element={<Catalog type={2} func={RouteFunc.bind(this)} />} />
                <Route path="/katalog/:section/:subsection/:category" element={<Catalog type={3} func={RouteFunc.bind(this)} />} />
                <Route path="/katalog/:section/:subsection/:category/:subcategory" element={<Catalog type={4} func={RouteFunc.bind(this)} />} />
                <Route path="/katalog/:section/:subsection/:category/:subcategory/:subproduct" element={<Catalog type={5} func={RouteFunc.bind(this)} />} />
                <Route path="/presentation/:id" element={<PresentationPlay stats={false} lang={sk} func={PresentationEnd.bind(this)} />} />
                <Route path="*" element={<Main func={RouteFunc.bind(this)} />} />
            </Routes>

            {showCookies == true ?
                <CookiesInfo func={CookiesInfoPress.bind(this)} />
                : null}

            {/* FOOTER */}
            {isLoading == false ?
                <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                : null}

        </div>
    );
}

export default App;
