import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Backdrop, Paper, Dialog, Modal, Checkbox } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, Debug, GetPrice, GetCost, GetSume, Loading, GetDiscount, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome, FormError, PlusMinus, PlusMinus2, SelectInput, DialogYesNo, TextLine, UniqueID, DialogInfo } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAngleUp, faImage, faMinus, faMinusCircle, faMinusSquare, faPaperPlane, faPlus, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import './items.css';
import { Photos } from './photos.js';
import { Reklamacia } from './reklamacie.js';

export const Orders = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [coeficient, setCoeficient] = useState(0);

    // PAGINATION
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const [orders, setOrders] = useState([]);
    const [typ, setTyp] = useState(0);
    const [selected, setSelected] = useState(0);
    const [order, setOrder] = useState([]);
    const [orderList, setOrderList] = useState([]);

    // DIALOGS
    const [showReklamacia, setShowReklamacia] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');

    const boxHeight = 50;

    useEffect(() => {
        var data = GetUser();

        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        db_web(0, false);

        GoHome();

        return () => {
        };

    }, []);

    const db_web = async (start, scroll) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'user_orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user_id,
                    start: start,
                    count: global.items_max,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setOrders(json.items);
                setLoading(false);
                setCoeficient(parseFloat(json.coeficient.value));

                // pagination
                var tmp = json.count;
                tmp = Math.ceil(tmp / global.items_max);
                setCount(tmp);

                if (scroll == true) {
                    GoToStart();
                }
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const db_item_detail = async (id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'user_order_details', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id
                })
            })

            const json = await response.json();
            console.log(json);
            if (json.ok > 0) {
                setOrderList(json.item);
                setTyp(1);
                GoHome();
            }
            setLoading(false);

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "end" }); // start, center, end, nearest
            }
        }, global.goto_start_delay);
    }

    const ShowDetail = (item) => {
        setOrder(item);
        setDiscount(item.discount);
        setSelected(item.id);
        db_item_detail(item.id);
    }

    const CloseDetails = () => {
        setTyp(0);
        GoHome();
    }

    const PageChange = (event, value) => {
        // PAGINATION        
        setPage(value);
        var page = (value - 1) * global.items_max;
        db_web(page, true);
    };

    const ReklamaciaResult = (typ) => {
        setShowReklamacia(false);
    }

    return (
        typ == 0 ?

            // ZOZNAM OBJEDNAVOK

            <div id={'id_orders_start'} style={{ ...styles.BlockCenter, backgroundColor: global.theme_back, minHeight: 400 }}>

                <div style={{ ...styles.BlockMaxLarge, width: '95%' }}>
                    <div style={{ ...styles.BlockLeft, marginTop: 40, marginBottom: 40 }}>
                        <p style={{ ...styles.TextNormal }}>{lang.orders_list}</p>
                    </div>

                    {orders.map(item => (
                        <div onClick={() => ShowDetail(item)} key={item.id} style={{ ...styles.BlockRow, width: '100%', height: boxHeight, backgroundColor: global.theme_gray, marginTop: 5, marginBottom: 5, cursor: 'pointer', border: selected == item.id ? '1px solid ' + global.theme_light : '0px solid' }}>
                            <div style={{ ...styles.BlockLeft, width: 120, height: boxHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextSmall, marginLeft: 10, color: global.theme_blue }}>{String(item.id).padStart(8, '0')}</p>
                            </div>
                            {isSmall ? null :
                                < div style={{ ...styles.BlockLeft, width: 120, height: boxHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextSmall }}>{item.datum}</p>
                                </div>
                            }
                            {isSmall ? null :
                                <div style={{ ...styles.BlockLeft, width: '50%', height: boxHeight, justifyContent: 'center' }}>
                                </div>
                            }
                            <div style={{ ...styles.BlockLeft, width: 200, height: boxHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextSmall, marginLeft: 20 }}>{lang.status[item.status].toLowerCase()}</p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: 120, height: boxHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextSmall, marginRight: 10, fontWeight: 'bold' }}>{item.cost} {lang.money}</p>
                            </div>
                        </div>
                    ))}


                    {count > 1 ?
                        <div style={{ ...styles.Block, maxWidth: 900, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                            <div style={{ height: 30 }}>
                                {isLoading == true ?
                                    <CircularProgress color="inherit" size={20} />
                                    : null}
                            </div>
                            <Pagination count={count} size={'medium'} page={page} onChange={PageChange} showFirstButton showLastButton />
                        </div>
                        : null}

                    <div style={{ height: 20 }}></div>

                    {isLoading == true ?
                        <Loading></Loading>
                        : null}
                </div>

            </div >

            :
            // DETAIL OBJEDNAVKY
            <div style={{ ...styles.BlockMaxLarge, backgroundColor: global.theme_back }}>
                <div style={{ ...styles.BlockRow, backgroundColor: global.theme_light_gray, height: 50, justifyContent: 'center', alignItems: 'center', marginTop: 40, borderRadius: 4 }}>
                    <div style={{ ...styles.BlockLeft }}>
                        <p style={{ ...isSmall ? styles.TextSmall : styles.TextLarge, color: global.theme_black, textAlign: 'left', marginLeft: 5 }}>{lang.order_detail}: {isSmall ? '' : String(order.id).padStart(8, '0')} {isSmall ? '' : '(' + lang.status[order.status].toLowerCase() + ')'}</p>
                        {isSmall ?
                            <p style={{ ...isSmall ? styles.TextTiny : styles.TextLarge, color: global.theme_black, textAlign: 'left' }}>{lang.status[order.status].toLowerCase()}</p>
                            : null}
                    </div>
                    <div style={{ width: 50 }}>
                        <IconButton onClick={() => CloseDetails()} style={{ ...styles.BlockRound, width: 36, height: 36, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                            <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>

                {/* BODY */}
                <div style={{ ...styles.Block, width: '95%' }}>
                    <div style={{ ...styles.BlockRow, width: '100%', marginTop: 20, marginBottom: 20, paddingBottom: 20, ...styles.BorderBottom }}>

                        {/* ZAKLADNE UDAJE - FAKTURACNE  */}
                        <div style={{ ...styles.BlockLeft, width: '50%', marginTop: 0 }}>

                            <div style={{ ...styles.BlockRow, width: '100%', marginTop: 0 }}>
                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.basic_data}:</p>
                            </div>

                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.meno}</p>

                            {b2b == true ?
                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.fname}</p>
                                : null}

                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.fulica}</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.fpsc} {order.fmesto}</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 15 }}>{order.fstat}</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{lang.email}: {order.email}</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{lang.mobil}: {order.tel}</p>
                            {b2b == true ?
                                <div>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginTop: 10, marginBottom: 5 }}>{lang.ico}: {order.ico}</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{lang.dic}: {order.dic}</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{lang.ic_dph}: {order.icdph}</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.dph == true ? lang.dph_pay : ''}</p>
                                </div>
                                : null}
                        </div>

                        {/* FAKTURACNE UDAJE */}
                        <div style={{ ...styles.BlockLeft, width: '50%', marginTop: 0 }}>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.delivery_address}:</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.dmeno}</p>
                            {b2b == true ?
                                <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.dfirma}</p>
                                : null}
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.dulica}</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 5 }}>{order.dpsc} {order.dmesto}</p>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginBottom: 15 }}>{order.dstat}</p>

                        </div>

                    </div>

                    {/* POZNAMKA */}
                    {order.note != '' ?
                        <div style={{ ...styles.BlockRow, width: '100%', marginTop: 0, marginBottom: 20 }}>
                            <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginLeft: 10 }}>{lang.note}: {order.note}</p>
                        </div>
                        : null}

                    {/* DETAIL OBJEDNAVKY */}
                    <div style={{ ...styles.BlockRow, width: '100%', marginTop: 0, marginBottom: 10 }}>
                        <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'bold', marginBottom: 10 }}>{lang.order_products}:</p>
                    </div>

                    {/* LISTING POLOZIEK */}
                    {isSmall ? null :
                        <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 0, marginBottom: 10, borderBottom: '1px solid ' + global.theme_light_gray, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: 50 }}>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: 60 }}>
                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 5, marginBottom: 5 }}>{lang.code}</p>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: 320 }}>
                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 5, marginBottom: 5 }}>{lang.product_name}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 100 }}>
                                    <p style={{ ...styles.TextXXTiny, marginTop: 5, marginBottom: 5 }}>{lang.quantity}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 100 }}>
                                    <p style={{ ...styles.TextXXTiny, marginTop: 5, marginBottom: 5, textAlign: 'left' }}>{'bez DPH / 1ks'}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 50 }}>
                                    <p style={{ ...styles.TextXXTiny, marginTop: 5, marginBottom: 5, textAlign: 'left' }}>{lang.DPH}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 100 }}>
                                    <p style={{ ...styles.TextXXTiny, marginTop: 5, marginBottom: 5 }}>{lang.cost_without_dph}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, width: 100 }}>
                                    <p style={{ ...styles.TextXXTiny, marginTop: 5, marginBottom: 5 }}>{lang.cost_with_dph}</p>
                                </div>
                            </div>
                        </div>
                    }

                    <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 0, marginBottom: 0, borderBottom: '3px solid ' + global.theme_light_gray }}>

                        {
                            orderList.length > 0 ?
                                orderList.map(item => {
                                    return (
                                        <div key={item.id} style={{ ...styles.BlockRow, marginBottom: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: isSmall ? 40 : 50 }}>
                                                {item.image != '' ?
                                                    <img src={global.product_image + item.image} style={{ width: 40, height: 40 }}></img>
                                                    : null}
                                            </div>
                                            {isSmall ? null :
                                                <div style={{ ...styles.BlockLeft, width: 60 }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 10 }}>{item.code}</p>
                                                </div>
                                            }
                                            <div style={{ ...styles.BlockLeft, width: isSmall ? '50%' : 320 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 10 }}>{item.name}</p>
                                                {isSmall && item.item_id != 0 ?
                                                    <p style={{ ...styles.TextXXXTiny, marginTop: 5 }}>{item.cost} {lang.money} / 1ks</p>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100 }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{item.quantity} {lang.pieces}</p>
                                            </div>
                                            {isSmall ? null :
                                                <div style={{ ...styles.BlockRight, width: 100 }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{item.cost} {lang.money}</p>
                                                </div>
                                            }
                                            {isSmall ? null :
                                                <div style={{ ...styles.BlockRight, width: 50 }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 10 }}>{isDPH == true ? item.dph : lang.dph}</p>
                                                </div>
                                            }
                                            <div style={{ ...styles.BlockRight, width: 100 }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{GetPrice(GetCost(b2b == true ? 0 : 1, item.cost * item.quantity, 1.0))} {lang.money}</p>
                                                {isSmall ?
                                                    <p style={{ ...styles.TextXXXTiny, marginTop: 5, marginBottom: 5 }}>{'bez DPH'}</p>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 100 }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{GetPrice(GetCost(b2b == true ? 0 : 1, item.cost * item.quantity, 1.0) * lang.dph_coeficient)} {lang.money}</p>
                                                {isSmall ?
                                                    <p style={{ ...styles.TextXXXTiny, marginTop: 5, marginBottom: 5 }}>{'s DPH'}</p>
                                                    : null}
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>

                    {b2b == true && discount > 0 ?
                        <div style={{ ...styles.Block, width: '100%', backgroundColor: global.theme_light_red, paddingTop: 10, paddingBottom: 10 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>Poskytnutá zľava vo výške {discount}% </p>
                        </div>
                        : null}

                    {/* SUMA */}
                    <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 10, marginBottom: 10 }}>
                        <div style={{ ...styles.BlockRow }}>
                            {isSmall ? null :
                                <div style={{ ...styles.BlockLeft, width: 110 }}>
                                </div>
                            }
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '50%' : 570 }}>
                                <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 5, marginBottom: 5 }}>{lang.sum_without_dph}</p>
                                {/*isDPH == true ?
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 5, marginBottom: 5 }}>{lang.with_dph_}</p>
                                : null*/}
                                <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 5, marginBottom: 5 }}>{lang.sum_dph}</p>
                            </div>
                            {isSmall ? null :
                                <div style={{ ...styles.BlockRight, width: 100 }}></div>
                            }
                            <div style={{ ...styles.BlockRight, width: isSmall ? '50%' : 100 }}>
                                <p style={{ ...styles.TextTiny, marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>{order.cost} {lang.money}</p>
                                {/*isDPH == true ?
                                    <p style={{ ...styles.TextTiny, marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>{GetPrice(GetSume(orderList, true, b2b, isDPH, coeficient))} {lang.money}</p>
                                : null*/}
                                <p style={{ ...styles.TextTiny, marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>{order.cost_with_dph} {lang.money}</p>
                            </div>
                        </div>
                    </div>

                    {/* STAV OBJEDNAVKY */}
                    <div style={{ ...styles.BlockRow, width: '100%', marginTop: 0, marginBottom: 20, marginTop: 40 }}>
                        <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: 'normal', marginBottom: 15, fontWeight: 'bold' }}>{lang.order_status}: {lang.status[order.status].toLowerCase()}</p>
                    </div>

                </div>


                <div style={{ ...styles.Block, marginTop: 50, marginBottom: 40 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {order.status == 2 || order.status == 3 || order.status == 4 ?
                            <Button onClick={() => setShowReklamacia(true)} style={{ ...styles.ButtonOutlined, marginRight: 20 }}>
                                {lang.reklamacia}
                            </Button>
                            : null}
                        <Button onClick={() => CloseDetails()} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_dark }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_white }} icon={faXmark} />
                            {lang.close}
                        </Button>
                    </div>

                </div>

                {showReklamacia == true ?
                    <Reklamacia item={false} isSmall={isSmall} orderList={orderList} order={order} user_id={props.user_id} lang={lang} func={ReklamaciaResult.bind(this)} />
                    : null}

            </div >
    )
};


