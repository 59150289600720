import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, Debug, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome, Line } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import { textAlign } from '@mui/system';



export const Downloads = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [logged, setLogged] = useState(false);

    const [catalog1, setCatalog1] = useState(null);
    const [catalog2, setCatalog2] = useState(null);
    const [flayers, setFlayers] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setLogged(data.logged);

        GoHome();

        db_downloads();

        return () => {
        };

    }, []);


    const db_downloads = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'downloads', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({

                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setCatalog1(json.catalog1);
                setCatalog2(json.catalog2);
                setFlayers(json.flayers);
            }
            setLoading(false);

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }



    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back, paddingTop: 50, paddingBottom: 50 }}>

            <div style={{ paddingBottom: 50 }}>
                <p style={{ ...styles.TextXXLarge }}>Katalóg a letáky</p>
                <p style={{ ...styles.TextNormal, marginTop: 5 }}>na stiahnutie</p>
            </div>


            <div style={{ ...styles.Block }}>
                {catalog1 != null ?
                    <Paper elevation={3} style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? 300 : 600, paddingTop: isSmall ? 10 : 0 }}>
                        <img src={global.images + 'flayers/' + catalog1.image} style={{ width: 200 }}></img>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : 400, height: 200, backgroundColor: global.theme_white }}>
                            <p style={{ ...styles.TextLarge }}>{catalog1.label}</p>
                            <Line></Line>
                            <a href={global.downloads + catalog1.filename} target={'_blank'} style={{ ...styles.ButtonLink, marginTop: 0, textDecoration: 'none' }}>Stiahnuť</a>
                        </div>
                    </Paper>
                    : null}

                {catalog2 != null ?
                    <Paper elevation={3} style={{ ...isSmall ? styles.Block : styles.BlockRow, width: isSmall ? 300 : 600, marginTop: 40, paddingTop: isSmall ? 10 : 0 }}>
                        <img src={global.images + 'flayers/' + catalog2.image} style={{ width: 200 }}></img>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : 400, height: 200, backgroundColor: global.theme_white }}>
                            <p style={{ ...styles.TextLarge }}>{catalog2.label}</p>
                            <Line></Line>
                            <a href={global.downloads + catalog2.filename} target={'_blank'} style={{ ...styles.ButtonLink, marginTop: 0, textDecoration: 'none' }}>Stiahnuť</a>
                        </div>
                    </Paper>
                    : null}

                <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw, flexWrap: 'wrap', marginTop: 50 }}>
                    {flayers.map(item => (
                        <a key={item.id} href={global.downloads + item.filename} target={'_blank'} style={{ ...styles.Block, width: 300, margin: 10, textDecoration: 'none', color: global.theme_black }}>
                            <img src={global.images + 'flayers/' + item.image} style={{ width: 300 }}></img>
                            <div style={{ width: 300, marginTop: 5 }}>
                                <p style={{ ...styles.TextSmall, textDecoration: 'none' }}>{item.label}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>

            {
                isLoading == true ?
                    <Loading></Loading>
                    : null
            }

        </div >
    )
};
