import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';


export const Gdpr = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [web, setWeb] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setLogged(data.logged);

        GoHome();
        db_web();

        return () => {
        };

    }, []);

    const db_web = async (user_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'main_page', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: user_id,
                    banners_typ: 0,
                })
            })

            const json = await response.json();

            console.log(json.web);

            if (json.ok > 0) {
                setWeb(json.web);
                setLoading(false);
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div style={{ paddingTop: 20, paddingBottom: 30 }}>
                <h1>Ochrana osobných údajov</h1>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 20, alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: global.screen_max }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, marginTop: 0 }}><b>Internetová stránka: {global.www}</b> (ďalej iba Táto stránka, Tieto stránky a pod.)</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, marginTop: 40 }}><b>Poskytovateľ služby:</b> {web.company}, {web.street} {web.street_number}, {web.psc} {web.town}, {lang.ico}:{web.ico} (ďalej iba Táto stránka, Tieto stránky a pod.)</p>
                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Zákonné predpoklady</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>1. Poskytovateľ bude v prípade sprostredkovaných, príp. zverejnených údajov na webových stránkach postupovať s ohľadom na platné zákonné predpisy EÚ a predpisy slovenského zákona o ochrane osobných údajov.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Súhlas používateľa s použitím údajov</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>1. Aby bolo s užívateľom možné uzavrieť v prípade objednávky služby alebo tovaru kúpno-predajnú zmluvu, je užívateľ povinný poskytnúť prevádzkovateľovi nevyhnutné osobné údaje potrebné pre uzavretie tejto zmluvy.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>2. Užívateľ má právo súhlas so spracovaním a uchovaním osobných údajov kedykoľvek odvolať.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>3. Prevádzkovateľ môže osobné údaje naďalej uchovávať a spracovávať, pokiaľ existuje legálna povinnosť takéto údaje uchovávať a spracovávať.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>4. Prevádzkovateľ môže použiť osobné údaje vo forme Cookies uložených v internetovom prehliadači užívateľa na zlepšenie poskytovaných služieb alebo pri analýze výsledkov.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>5. Užívateľ má možnosť zastaviť použitie Cookies a tým pádom možnosť na zrušenie jeho odoslania na server v prípade opätovnej požiadavky servera. Viac informácií o Cookies nájdete v sekcii Cookies.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>6. Tieto internetové stránky sa menia a udržiavajú spolu s externými poskytovateľmi služieb. V niektorých prípadoch môžu dostať poskytovatelia prístup aj k otvoreným údajom. Predtým sú zaviazaní k tomu, aby získané údaje použili výhradne podľa pokynov a pre potreby úprav a po ukončení prác ich vymazali.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>7. Prevádzkovateľ kladie veľký dôraz na bezpečné používanie internetu a elektronických médií slúžiacich na prenos údajov. Vyzýva však užívateľov aby sa snažili o čo najvyššie zabezpečenie z ich strany a to použitím aktuálnej verzie internetového prehliadača, utajením bezpečnostných prvkov slúžiacich na prístup k účtu, opatrnosť pri prenose údajov prostredníctvom elektronických médií a internetu.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>8. V prípade úniku alebo krádeže osobných údajov je prevádzkovateľ povinný informovať Úrad na ochranu osobných údajov, maximálne do 72 hodín od zistenia úniku.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Vysvetlenie pojmu „osobný údaj“ a jeho použitie</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Osobné údaje sú podľa Článku 4 odsek 1 nariadenia GDPR akékoľvek informácie týkajúce sa identifikovanej alebo identifikovateľnej fyzickej osoby (ďalej len „dotknutá osoba“); identifikovateľná fyzická osoba je osoba, ktorú možno identifikovať priamo alebo nepriamo, najmä odkazom na identifikátor, ako je meno, identifikačné číslo, lokalizačné údaje, online identifikátor, alebo odkazom na jeden či viaceré prvky, ktoré sú špecifické pre fyzickú, fyziologickú, genetickú, mentálnu, ekonomickú, kultúrnu alebo sociálnu identitu tejto fyzickej osoby. Osobným údajom je akákoľvek informácia, ktorá sa vzťahuje s fyzickej osobe a prevádzkovateľ je schopný na základe tejto informácie fyzickú osobu identifikovať.</p>
                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}><b>Základné osobné a identifikačné údaje</b></p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Údaje potrebné k uzavretiu a plneniu uzavretej kúpno-predajnej zmluvy. V prípade jednorázového predaja môže byť rozsah osobných údajov zúžený na základné osobné údaje.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 40 }}><b>Príklady osobných údajov, ktoré môžu byť vyžadované v prípade konkrétnych formulárov alebo objednávok.</b></p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>Meno a priezvisko</li>
                            <li>Obchodný názov firmy/spoločnosti</li>
                            <li>IČO, DIČ</li>
                            <li>Adresa trvalého pobytu</li>
                            <li>Adresa sídla firmy/spoločnosti, miesto podnikania</li>
                            <li>Fakturačná adresa</li>
                            <li>Čísla identifikačných dokladov</li>
                            <li>Číslo účtu</li>
                            <li>Telefónne číslo</li>
                            <li>E-mail</li>
                            <li>Cookies uložené v internetovom prehliadači užívateľa</li>
                        </ul>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Právo na informácie o uchovaných a spracovaných osobných údajoch a právo na vymazanie údajov, vymazanie konta, úpravu osobných údajov</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Užívateľ má nárok na výpis svojich osobných údajov, ktoré prevádzkovateľ uchováva, ich opravu alebo vymazanie údajov v súlade so zákonom o ochrane osobných údajov. Užívateľ má právo na vymazanie svojho konta, ktoré bolo zaregistrované na internetovej stránke užívateľom alebo poskytovateľom. V prípade žiadosti o vymazanie osobných údajov, vymazanie užívateľského konta alebo úpravu uchovávaných osobných údajov môže užívateľ kontaktovať prevádzkovateľa osobne, telefonicky alebo e-mailom</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Účel spracovania a uchovania osobných údajov a dĺžka uchovania osobných údajov</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Rozsah a dĺžka spracovania a uchovávania osobných údajov závisí od účelu použitia osobných údajov a od právnych predpisov. Poskytovateľ spracováva a uchováva osobné údaje za účelom plnenia zmluvy, v prípade, že by jej plnenie bez osobných údajov užívateľa nebolo možné. Spracovanie a uchovanie údajov z dôvodu plnenia zmluvy na základe platných zákonných povinností nie je možné odmietnuť. Osobné údaje sú pre tento účel spracované a uchované v rozsahu a po dobu nutnú pre naplnenie tejto činnosti alebo po dobu, ktorá je stanovená zákonom. Osobné údaje sú po uplynutí tejto doby vymazané.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Možné dôvody uchovania a spracovania osobných údajov</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>Identifikácia zákazníka a vyúčtovanie za služby a produkty</li>
                            <li>Poskytovanie služieb elektronickej komunikácie alebo platobných transakcií</li>
                            <li>Plnenie zákonom stanovených daňových povinností</li>
                            <li>Plnenie zákonom stanovených daňových povinností. Výmena údajov medzi prevádzkovateľmi služieb elektronických komunikácií, ktoré zabezpečujú prístup k telekomunikačným sieťam, prevádzku informačných systémov a prevádzku kamerových systémov, pre zabezpečenie servisu, prevádzky a vyúčtovanie.</li>
                            <li>Adresa sídla firmy/spoločnosti, miesto podnikania</li>
                            <li>Analýza zákazníkov prostredníctvom služieb tretích strán ako napríklad Google AdWords, Facebook Ads, Remarketing, Analýza návštevnosti a demografických údajov, analýza správania užívateľa a pod.</li>
                            <li>Zaistenie dôkazov pre prípad nutnosti obhajoby prevádzkovateľa</li>
                            <li>Evidencia neplatičov</li>
                        </ul>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Osobné údaje, faktúry a iné nevyhnutné doklady spojené s nákupom služby alebo produktu budú spracované a uchované po dobu, ktorú stanovuje zákon.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Analýza webových stránok</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>Webové stránky prevádzkovateľa používajú analytické nástroje na meranie výkonnosti a návštevnosti a to hlavne nástroj Google Analytics, službu webovej analýzy Google Inc. Google využíva Cookies, ktoré sa ukladajú do pamäte užívateľa webových stránok. Tieto informácie sa spravidla prenášajú na server Google, kde sa ukladajú do pamäte. V prípade zaistenia anonymity IP na webovej stránke Google predtým skráti IP adresu užívateľa v rámci členských štátov EÚ alebo v iných zmluvných štátoch Dohody o európskom hospodárskom priestore. Tieto informácie prevádzkovateľ využíva na vyhodnotenie aktivít užívateľov na svojich webových stránkach a aby umožnil využitie ďalších služieb spojených s používaním webových stránok. Google nespája IP adresu užívateľa s jeho osobnými údajmi. Údaje sú anonymné.</li>
                            <li>Užívateľ môže ukladanie údajov cookies vo svojom počítači alebo mobilnom zariadení zrušiť alebo obmedziť správnym nastavením softvéru spravidla internetového prehliadača. V tom prípade však môže dôjsť k obmedzeniu funkcionality niektorých častí webových stránok.</li>
                            <li>Dáta užívateľa sú na serveri Google uložené po dobu 26 mesiacov. Bližšie informácie o uchovaní osobných údajov slúžiacich na analýzu návštevnosti webovej stránky nájdete na stránkach spoločnosti Google – Uchovávanie dát Google Analytics</li>
                            <li>Nástroj Google Analytics môže uchovávať demografické údaje užívateľa ako je pohlavie, vek, lokalita a pod. Údaje sú anonymné.</li>
                            <li>Nástroj Google Analytics môže byť prepojený s nástrojom Google Search Console, ktorý slúži na analýzu vyhľadávania prostredníctvom fulltextového vyhľadávača Google a spôsoby vstupu užívateľa na internetové stránky. Súčasťou tejto analýzy môže byt prenos demografických a iných osobných údajov užívateľa. Údaje sú anonymné.</li>
                            <li>Nástroj Google Analytics môže byť prepojený s nástrojom Google Search AdWords, ktorý slúži na zobrazenie internetovej reklamy (vo forme textu a grafických bannerov) a zacielenie reklamy správnemu publiku. Súčasťou tejto analýzy môže byt prenos demografických a iných osobných údajov užívateľa. Údaje sú anonymné.</li>
                        </ul>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Sociálne pluginy</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>Na webové stránky prevádzkovateľa sú napojené pluginy (aplikácie) tretích strán ako je napr. Facebook, Google Plus, YouTube, Twitter, AddThis, Pinterest, Tumblr a pod. Tieto aplikácie sú uložené a spustené na serveroch tretích strán. Prevádzkovateľ nemá vplyv na zabezpečenie ochrany osobných údajov pri používaní aplikácií tretích strán.</li>
                            <li>Webové stránky prevádzkovateľa používajú doplnky tretích strán, ktoré umožňujú užívateľom zdieľať, komentovať, hodnotiť obsah webových stránok na sociálnych sieťach alebo zaregistrovať sa prostredníctvom účtu tretej strany. V takomto prípade internetový prehliadač vytvára medzi užívateľom a treťou stranou priame spojenie pri ktorom dochádza k použitiu cookies a prenosu údajov užívateľa medzi webovými stránkami, prehliadačom užívateľa a serverom tretej strany. Dáta spravidla nie sú spájané s osobnými údajmi užívateľa. Prevádzkovateľ využíva na svojich stránkach zásadne spoľahlivé zdroje pluginov a doplnkov no nedokáže zaručiť funkčnosť ani spoľahlivosť pluginov tretích strán.</li>
                            <li>V prípade akcie užívateľa na týchto internetových stránkach prostredníctvom sociálnych pluginov môžu byť tieto akcie zobrazené na stránkach tretích strán v závislosti od nastavenia konta užívateľa (napr. Facebook Like, Google Plus, Zdieľanie na sociálnych sieťach a pod.).</li>
                        </ul>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Reklama a remarketing</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>Tieto webové stránky môžu zobrazovať reklamy zahŕňajúce reklamy tretích strán a môžu byť využité na zobrazenie reklamy na internetových stránkach tretích strán. Reklamný systém môže využiť technológiu PPC resp. PPI a remarketing. Tieto technológie sú na stránke použité kvôli tomu aby mohli užívateľovi poskytnúť najrelevantnejšiu ponuku a to aj na webových stránkach tretích strán. Prevádzkovateľ prostredníctvom personalizovanej reklamy zameranej na záujmy používateľa poskytuje zaujímavejšie zobrazovanie reklamy, ku ktorej má užívateľ osobný vzťah.</li>
                            <li>Reklamy sa na týchto internetových stránkach a na webových stránkach tretích strán zobrazujú za pomoci technológie cookies a analýzy správania užívateľa na internete.</li>
                            <li>Táto internetová stránka môže využívať reklamný systém Google AdWrods, Facebook Ads a pod..</li>
                        </ul>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Mapy a lokalizácia</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10 }}>Tieto internetové stránky môžu obsahovať mapy využívajúce službu Google Maps. Táto služba môže uchovávať osobné údaje užívateľa týkajúce sa jeho polohy.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Príspevky používateľov</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>Používatelia týchto internetových stránok môžu prispievať svojim obsahom vo forme komentárov, hodnotení a pod., či už priamo na webových stránkach alebo na stránkach firmy na sociálnych sieťach alebo na svojich vlastných stránkach na sociálnych sieťach formou zdieľania a hodnotenia.</li>
                            <li>Akýkoľvek obsah, informácie alebo iné formy údajov a komunikácie, ktoré boli na stránke uverejnené užívateľmi na tejto internetovej stránke alebo zdieľané prostredníctvom sociálnych sietí a iných aplikácií aj na webových stránkach tretích strán sa nepovažujú za dôverné a nepodliehajú ochrane obchodného tajomstva.</li>
                            <li>Prevádzkovateľ nepreberá za príspevky užívateľov, informácie a komunikáciu zodpovednosť. Zároveň výslovne zakazuje uverejňovať alebo prenášať na webovú stránku akékoľvek protiprávne, výhražné, hanlivé, obscénne materiály alebo iné materiály urážajúce ľudskú dôstojnosť alebo s akýmkoľvek diskriminačným charakterom.</li>
                        </ul>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>Práva dotknutej osoby (Zákon č. 122/2013 Z. z. o ochrane osobných údajov v znení neskorších predpisov)</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>Dotknutá osoba má právo na základe písomnej žiadosti od prevádzkovateľa vyžadovať</li>
                            <ul style={{ ...styles.TextNormal, marginTop: 5, textAlign: 'left' }}>
                                <li>a) potvrdenie, či sú alebo nie sú osobné údaje o nej spracúvané,</li>
                                <li>b) vo všeobecne zrozumiteľnej forme informácie o spracúvaní osobných údajov v informačnom systéme v rozsahu podľa § 15 ods. 1 písm. a) až e) druhý až šiesty bod; pri vydaní rozhodnutia podľa odseku 5 je dotknutá osoba oprávnená oboznámiť sa s postupom spracúvania a vyhodnocovania operácií,</li>
                                <li>c) vo všeobecne zrozumiteľnej forme presné informácie o zdroji, z ktorého získal jej osobné údaje na spracúvanie,</li>
                                <li>d) vo všeobecne zrozumiteľnej forme zoznam jej osobných údajov, ktoré sú predmetom spracúvania,</li>
                                <li>e) opravu alebo likvidáciu svojich nesprávnych, neúplných alebo neaktuálnych osobných údajov, ktoré sú predmetom spracúvania,</li>
                                <li>f) likvidáciu jej osobných údajov, ktorých účel spracúvania sa skončil; ak sú predmetom spracúvania úradné doklady obsahujúce osobné údaje, môže požiadať o ich vrátenie,</li>
                                <li>g) likvidáciu jej osobných údajov, ktoré sú predmetom spracúvania, ak došlo k porušeniu zákona,</li>
                                <li>h) blokovanie jej osobných údajov z dôvodu odvolania súhlasu pred uplynutím času jeho platnosti, ak prevádzkovateľ spracúva osobné údaje na základe súhlasu dotknutej osoby.</li>
                            </ul>

                            <li>Právo dotknutej osoby podľa odseku 1 písm. e) a f) možno obmedziť, len ak takéto obmedzenie vyplýva z osobitného zákona alebo jeho uplatnením by bola porušená ochrana dotknutej osoby, alebo by boli porušené práva a slobody iných osôb.</li>
                            <li>Dotknutá osoba na základe písomnej žiadosti má právo u prevádzkovateľa namietať voči</li>
                            <ul style={{ ...styles.TextNormal, marginTop: 5, textAlign: 'left' }}>
                                <li>a) spracúvaniu jej osobných údajov, o ktorých predpokladá, že sú alebo budú spracúvané na účely priameho marketingu bez jej súhlasu, a žiadať ich likvidáciu,</li>
                                <li>b) využívaniu osobných údajov uvedených v § 10 ods. 3 písm. d) na účely priameho marketingu v poštovom styku, alebo</li>
                                <li>c) poskytovaniu osobných údajov uvedených v § 10 ods. 3 písm. d) na účely priameho marketingu.</li>
                            </ul>
                            <li>Dotknutá osoba na základe písomnej žiadosti alebo osobne, ak vec neznesie odklad, má právo u prevádzkovateľa kedykoľvek namietať voči spracúvaniu osobných údajov v prípadoch podľa § 10 ods. 3 písm. a), e), f) alebo g) vyslovením oprávnených dôvodov alebo predložením dôkazov o neoprávnenom zasahovaní do jej práv a právom chránených záujmov, ktoré sú alebo môžu byť v konkrétnom prípade takýmto spracúvaním osobných údajov poškodené; ak tomu nebránia zákonné dôvody a preukáže sa, že námietka dotknutej osoby je oprávnená, prevádzkovateľ je povinný osobné údaje, ktorých spracúvanie dotknutá osoba namietala, bez zbytočného odkladu blokovať a zlikvidovať ihneď, ako to okolnosti dovolia.</li>
                            <li>Dotknutá osoba na základe písomnej žiadosti alebo osobne, ak vec neznesie odklad, ďalej má právo u prevádzkovateľa kedykoľvek namietať a nepodrobiť sa rozhodnutiu prevádzkovateľa, ktoré by malo pre ňu právne účinky alebo významný dosah, ak sa také rozhodnutie vydá výlučne na základe úkonov automatizovaného spracúvania jej osobných údajov. Dotknutá osoba má právo žiadať prevádzkovateľa o preskúmanie vydaného rozhodnutia metódou odlišnou od automatizovanej formy spracúvania, pričom prevádzkovateľ je povinný žiadosti dotknutej osoby vyhovieť, a to tak, že rozhodujúcu úlohu pri preskúmaní rozhodnutia bude mať oprávnená osoba; o spôsobe preskúmania a výsledku zistenia prevádzkovateľ informuje dotknutú osobu v lehote podľa § 29 ods. 3. Dotknutá osoba nemá toto právo iba v prípade, ak to ustanovuje osobitný zákon, v ktorom sú upravené opatrenia na zabezpečenie oprávnených záujmov dotknutej osoby, alebo ak v rámci predzmluvných vzťahov alebo počas existencie zmluvných vzťahov prevádzkovateľ vydal rozhodnutie, ktorým vyhovel požiadavke dotknutej osoby, alebo ak prevádzkovateľ na základe zmluvy prijal iné primerané opatrenia na zabezpečenie oprávnených záujmov dotknutej osoby.</li>
                            <li>Ak dotknutá osoba uplatní svoje právo</li>
                            <ul style={{ ...styles.TextNormal, marginTop: 5, textAlign: 'left' }}>
                                <li>a)písomne a z obsahu jej žiadosti vyplýva, že uplatňuje svoje právo, žiadosť sa považuje za podanú podľa tohto zákona; žiadosť podanú elektronickou poštou alebo faxom dotknutá osoba doručí písomne najneskôr do troch dní odo dňa jej odoslania,</li>
                                <li>b) osobne ústnou formou do zápisnice, z ktorej musí byť zrejmé, kto právo uplatnil, čoho sa domáha a kedy a kto vyhotovil zápisnicu, jeho podpis a podpis dotknutej osoby; kópiu zápisnice je prevádzkovateľ povinný odovzdať dotknutej osobe,</li>
                                <li>c) u sprostredkovateľa podľa písmena a) alebo písmena b), je ten povinný túto žiadosť alebo zápisnicu odovzdať prevádzkovateľovi bez zbytočného odkladu.</li>
                            </ul>

                            <li>Dotknutá osoba pri podozrení, že jej osobné údaje sa neoprávnene spracúvajú, môže podať úradu návrh na začatie konania o ochrane osobných údajov.</li>
                            <li>Ak dotknutá osoba nemá spôsobilosť na právne úkony v plnom rozsahu, ¹⁵) jej práva môže uplatniť zákonný zástupca. ¹⁶)</li>
                            <li>Ak dotknutá osoba nežije, jej práva, ktoré mala podľa tohto zákona, môže uplatniť blízka osoba. ¹⁷) ¹</li>
                        </ul>
                        <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 40 }}>
                            ¹⁵) § 8 Občianskeho zákonníka v znení zákona č. 509/1991 Zb.<br />
                            ¹⁶) § 26 až 30 Občianskeho zákonníka v znení neskorších predpisov.<br />
                            ¹⁷) § 116 Občianskeho zákonníka.
                        </p>

                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 40 }}>Viac informácií o ochrane osobných údajov nájdete na stránkach <a href="https://dataprotection.gov.sk/uoou/sk">Úradu na ochranu osobných údajov.</a></p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 40 }}>V prípade žiadosti o vymazanie osobných údajov nás kontaktujte e-mailom na adrese: {web.email}</p>
                    </div>
                </div>
                <Button onClick={() => GotoBack()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 50 }} variant='outlined'>{lang.back}</Button>

            </div>

        </div >
    )
};
