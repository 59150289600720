import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faBox, faCar, faLayerGroup, faMinus, faMinusCircle, faMinusSquare, faPersonWalking, faPlus, faPlusCircle, faShop, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import { style } from '@mui/system';
import GoogleMapReact from 'google-map-react';
import { layer } from '@fortawesome/fontawesome-svg-core';


export const Stock = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const lat = 48.382249;
    const lng = 18.395264;

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const img1 = require('./react/app/img_1.jpg');
    const pin = require('./react/app/pin.png');

    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        GoHome();

        return () => {
        };

    }, []);



    const GotoBack = () => {
        navigate(-1);
    }

    const AnyReactComponent = ({ text }) => (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
            <img src={pin} height={40} />
            <p style={{ ...styles.TextSmall, marginTop: 12, fontWeight: 'bold', color: global.theme_dark_red }}>Polplast</p>
        </div>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef} style={{ ...styles.Block, marginTop: 30 }}>
                <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: isSmall ? 'center' : 'left' }}>Široký sortiment skladom ihneď k dispozícii</h1>

                <div style={{ ...styles.BlockMaxLarge, justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40 }}>
                    <div style={{ ...styles.BlockLeft, backgroundColor: global.theme_gray }}>
                        <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <img src={global.images + 'app/zahradnik_round.png'} style={{ width: 60, height: 60 }}></img>
                                        <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left', color: global.theme_dark_blue, marginTop: 15, marginLeft: 10 }}>B2B veľkoobchodný zákazník</h1>
                                    </div>
                                    <div style={{ ...styles.BlockRow, marginTop: 30 }}>
                                        <div style={{ ...styles.BlockLeft, width: 80 }}>
                                            <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faShop} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0 }}>Tovar pre Vašu prevádzku</p>
                                            <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left', textAlign: 'justify' }}>Sme špecialisti na kvetináče. Kliknite a uvidíte! Nájdete u nás najširší sortiment kvetináčov na Slovensku. Veľkoobchodne zabezpečíme tovar pre kvetinárstva, záhradné centrá, domáce potreby, stavebniny, drogérie a zmiešaný tovar či predajne nábytku. Bytovým a záhradným architektom pomôžeme zrealizovať projekty, ktoré uspokoja aj skutočne náročných klientov.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ ...styles.Block, width: isSmall ? '100%' : '30%' }}>
                                <img src={global.images + 'app/stock_1.jpg'} style={{ width: '100%', height: '100%' }} />
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: undefined, padding: 20 }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 10 }}>Ponúkame:</p>
                            <ul style={{ ...style.TextSmall, textAlign: 'left' }}>
                                <li>kvetináče do interiéru a záhrad od výrobcov z Európy a vlastný import z Ázie</li>
                                <li>misy, vázy, amfory, gazony, aranžovacie nádoby pre floristov</li>
                                <li>figúry zvierat a postavičiek do záhrady</li>
                                <li>substráty, hnojivá, semená, postreky, prípravky na ošetrenie, rukavice, zakoreňovače</li>
                                <li>kahance, katedrály, vklady a tuby, diodové kahance</li>
                                <li>smútočná plastová a keramická výzdoba</li>
                                <li>syntetické kytice a kvety</li>
                                <li>bytový a záhradný textil</li>
                                <li>sviečky, aromaoleje, difuzery, vonné vosky a svietniky k výzdobe</li>
                                <li>sezónne dekorácie (jar, leto, jeseň, Vianoce)</li>
                            </ul>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: undefined, padding: 20 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 10 }}>Prečo je tovar lepšie objednať?</p>
                                <FontAwesomeIcon style={{ marginLeft: 10, marginTop: 10, width: 20, height: 20, color: global.theme_black }} icon={faShoppingCart} />
                            </div>
                            <p style={{ ...style.TextSmall, textAlign: 'left' }}>Online objednávkový systém v e-shope Vám prináša ZISK. Zaregistrujte sa a ušetrite svoj čas a peniaze. Za každú odoslanú online objednávku Vám poskytneme zľavu -2 %. K tejto úvodnej zľave sa navyše pripočítava vernostná a množstevná zľava. Výška zliav teda v Polplast-SK stále rastie!</p>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: undefined, padding: 20 }}>
                            <p style={{ ...style.TextSmall, textAlign: 'left', fontWeight: 'bold' }}>Minimálny nákup pre B2B je iba 50,00 € bez DPH. Dovoz zdarma!</p>
                        </div>

                    </div>

                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_lighter, marginTop: 50, marginBottom: 50 }}>
                        <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <div style={{ ...styles.BlockRow }}>
                                    <img src={global.images + 'app/customer_round.png'} style={{ width: 60, height: 60 }}></img>
                                    <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left', color: global.theme_dark_blue, marginTop: 15, marginLeft: 10 }}>B2C koncový zákazník</h1>
                                </div>
                                <div style={{ ...styles.BlockRow, marginTop: 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0 }}>Výhody nášho sortimentu</p>
                                        <ul style={{ ...style.TextSmall, textAlign: 'left' }}>
                                            <li>kvetináče ako aj kahance a dekorácie sú zoskupené do spoločne zladených kolekcií. Podľa nich si veľmi jednoducho zladíte vzory, odtiene a štýly vhodné pre svoje bývanie.</li>
                                            <li>sortiment nájdete podľa obrázkov v kategóriách e-shopu, alebo pod jednou strechou nášho maloobchodu!</li>
                                            <li>sortiment do záhrady je dostupný v exteriéri predajne k priamemu naloženiu do vášho auta z parkoviska</li>
                                            <li>zásobovanie maloobchodu plynie súčinne s veľkoobchodom - preto máme väčšie skladové zásoby a sortiment k dispozícii skladom</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        < div style={{ ...styles.Block, width: isSmall ? '100%' : '30%' }}>
                            <img src={global.images + 'app/stock_2.jpg'} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>




                    {isLoading == true ?
                        <Loading></Loading>
                        : null}

                </div >
            </div >
        </div >
    )
};
