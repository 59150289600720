import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faCar, faMinus, faMinusCircle, faMinusSquare, faPersonWalking, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import { style } from '@mui/system';
import GoogleMapReact from 'google-map-react';
import { layer } from '@fortawesome/fontawesome-svg-core';


export const AboutUs = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);
    const [years, setYears] = useState(null);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const lat = 48.382249;
    const lng = 18.395264;

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const img1 = require('./react/app/img_1.jpg');
    const pin = require('./react/app/pin.png');

    useEffect(() => {
        func(0, true);

        var today = new Date();
        var y = today.getFullYear() - 1994;
        setYears(y);


        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        GoHome();

        return () => {
        };

    }, []);



    const GotoBack = () => {
        navigate(-1);
    }

    const AnyReactComponent = ({ text }) => (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
            <img src={pin} height={40} />
            <p style={{ ...styles.TextSmall, marginTop: 12, fontWeight: 'bold', color: global.theme_dark_red }}>Polplast</p>
        </div>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef} style={{ ...styles.Block, marginTop: 30 }}>
                <div style={{ ...styles.BlockMaxLarge, justifyContent: 'flex-start', alignItems: 'flex-start' }}>

                    {/* SLOVENSKY */}
                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_gray }}>
                        <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left' }}>Sme tu už {years} rokov</h1>
                                <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 5, textAlign: 'left', color: global.theme_black }}>a stále rastieme</p>
                                <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>V roku 1994 sme začali s výrobou keramických kvetináčov. Mali u vás úspech. S narastajúcim záujmom o naše služby sme postupne rozšírili ponuku aj o plastové kvetináče, kahance, sviečky, textil a sezónne dekorácie. Dnes náš veľkoobchod importuje tovar z 8 krajín sveta. Sme exkluzívnym dovozcom keramiky značky Polnix a Deroma a kahancov značky Max-pol na Slovensku.</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '90%' : '30%' }}>
                            <img src={global.images + 'app/about_us_1.jpg'} style={{ width: '100%', height: '100%' }} />
                        </div>

                    </div>

                    <div style={{ ...styles.Block }}>
                        <div style={{ width: isSmall ? '90%' : '100%' }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Dlhoročným pôsobením na trhu a otvorenosťou voči vašim pripomienkam sme pochopili predstavu o dobrom dodávateľovi. Teraz vieme, že tovar treba dodať včas a za rozumnú cenu. Pravidelne prinášať atraktívne novinky. Rýchlo vyriešiť prípadné reklamácie a to všetko v priateľskej atmosfére.</p>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Dobrou správou je, že sa z roka na rok k tomuto ideálu približujeme. Zväčšili sme vozový park a rozšírili sklady. Vytvorili sme internetový obchod pre váš pohodlný a prehľadný nákup so zľavou 2% a prednostným vybavením objednávok, ktorý budete môcť využívať aj vo svojom mobilnom telefóne. Vydali sme svoj prvý e-book s radami pre floristov a aktualizujeme produktový pdf. katalóg a letáky, ktoré môžete využiť pre svojich zákazníkov! Získate s nimi väčšiu ponuku ako dovoľujú vaše priestory.</p>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Myslíme i na vašich klientov. Ich spokojnosť nám spoločne zabezpečí perspektívu a rast. Objednajte sa k nám, zavolajte, pošlite správu na sociálne siete, buďme spolu v spojení! Pretože aj pri kvetináčoch platí: raz vidieť je lepšie ako 100x počuť.</p>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 30, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Ľubomír Homola s kolektívom Polplast-sk</p>
                        </div>
                    </div>

                    {/* ANGLICKY */}
                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_lighter, marginTop: 80 }}>
                        <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left' }}>About us</h1>
                                <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Polplast-sk - the wholesale trade of flowerpots. The company is a family concern established in 1994. Our portfolio is made of ceramic and plastic pots, mainly clear glass orchid pots which we distribute.Nowadays our product range includes also grave candles, candles, ceramic and resin figures for gardens, soils, seeds, fertilisers and seasonal decorations. We cooperate with more than 1240 flowershops and garden centers.</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '90%' : '30%' }}>
                            <img src={global.images + 'app/about_us_2.jpg'} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>

                    <div style={{ ...styles.Block }}>
                        <div style={{ width: isSmall ? '90%' : '100%' }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>We offer to our clients the complete service: goods delivery, higher maturity dates, system of discounts, advertisement and individual sales support.</p>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Thank you for your interest and we look forward to our cooperation.Welcome and enjoy your visit on our website! Stay in contact with us: contact us or manage your time to visit us.</p>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 30, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Lubomir Homola and Polplast-sk team</p>
                        </div>
                    </div>

                    {/* NEMECKY */}
                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_light_red, marginTop: 80 }}>
                        <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left' }}>Uber uns</h1>
                                <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Polplast-sk - the wholesale trade of flowerpots. The company is a family concern established in 1994. Our portfolio is made of ceramic and plastic pots, mainly clear glass orchid pots which we distribute.Nowadays our product range includes also grave candles, candles, ceramic and resin figures for gardens, soils, seeds, fertilisers and seasonal decorations. We cooperate with more than 1240 flowershops and garden centers.</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '90%' : '30%' }}>
                            <img src={global.images + 'app/about_us_3.jpg'} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>

                    <div style={{ ...styles.Block }}>
                        <div style={{ width: isSmall ? '90%' : '100%' }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>olplast-sk - Grosshandel mit Blumentopfen. Wir sind ein Familienbetrieb. Seit Jahre 1994 verkaufen wir Blumentopfe. Von unserem Angebot schopfen Blumentopfe 80%. Ausser Auswahl von keramischen, plastik und glass Blumentopfe fur Orchideen bieten wir unseren Kunden Grubenlampen, Kerzen, Zwergen aus Harz und Keramik, Blumenerde, Dunger und Saisongeschenkeware.</p>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 20, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Wir bieten unseren Kunden kompletten Service und individuelle Fuhrsorge. Wir bedanken uns herzlich fur Ihre Interesse und zugleich laden wir Euch zu einem freundlichen Besuch.</p>
                            <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 30, textAlign: 'left', color: global.theme_black, textAlign: 'justify', marginBottom: 40 }}>Lubomir Homola und Polplast-sk team</p>
                        </div>
                    </div>

                    {isLoading == true ?
                        <Loading></Loading>
                        : null}

                </div >
            </div >
        </div >
    )
};
