import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Backdrop, Paper, Dialog, Modal, Checkbox, Chip } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, Debug, GetPrice, GetCost, GetSume, Loading, GetDiscount, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome, FormError, PlusMinus, PlusMinus2, SelectInput, DialogYesNo, TextLine, UniqueID, DialogInfo, GetPages, GetDatum2 } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAngleUp, faImage, faMinus, faMinusCircle, faMinusSquare, faPaperPlane, faPlus, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import './items.css';
import { Photos } from './photos.js';

export const Reklamacie = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);
    const [discount, setDiscount] = useState(0);

    // PAGINATION
    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);

    const [orders, setOrders] = useState([]);
    const [typ, setTyp] = useState(0);
    const [selected, setSelected] = useState(0);
    const [items, setItems] = useState(false);

    // DIALOGS
    const [showReklamacia, setShowReklamacia] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    var running = false;

    const rowHeight = 60;
    const maxItems = global.items_max;

    useEffect(() => {
        if (running == false) {
            var data = GetUser();

            setDPH(data.dph);
            setB2B(data.b2b);
            setLogged(data.logged);
            setUser(data.user);

            db_get(0, false);

            GoHome();

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async (start, scroll) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'reklamacie', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user_id,
                    filter: false,
                    start: start * maxItems,
                    length: maxItems,
                })
            })

            const json = await response.json();
            Debug(json);
            setLoading(false);
            if (json.ok > 0) {
                setItems(json.items);
                setPage(start);
                setPages(GetPages(json.count, maxItems));
                if (scroll == true) {
                    GoToStart();
                }
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_reklamacie_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "end" }); // start, center, end, nearest
            }
        }, global.goto_start_delay);
    }


    const ChangePage = (value) => {
        db_get(value - 1, true);
    }

    const SelectItem = (item) => {
        setSelected(item);
        setShowReklamacia(true);
    }

    return (
        // ZOZNAM REKLAMACII
        <div id={'id_reklamacie_start'} style={{ ...styles.BlockCenter, backgroundColor: global.theme_back, minHeight: 400 }}>

            <div style={{ ...styles.BlockMaxLarge, width: '95%' }}>
                <div style={{ ...styles.BlockLeft, marginTop: 40, marginBottom: 10 }}>
                    <p style={{ ...styles.TextNormal }}>{lang.reklamacie_list}</p>
                </div>
                {items != false ? items.map((item, index) => (
                    <Paper
                        key={item.id}
                        elevation={2}
                        style={{ ...styles.Block, minHeight: rowHeight, marginTop: 5, marginBottom: 5, cursor: 'pointer' }}
                        onClick={() => SelectItem(item)}
                    >
                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? 120 : 100, height: rowHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny }}>{GetDatum2(item.created)}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '60%' : '75%', height: rowHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.reklamacia_title}: <b>{String(item.order_id).padStart(8, '0')}</b></p>
                            </div>
                            <div style={{ ...styles.Block, width: isSmall ? 140 : 150, height: rowHeight, justifyContent: 'center' }}>
                                <Chip size={isSmall ? 'small' : 'medium'} style={{ backgroundColor: global.reklamacia_colors[item.status] }} label={<p style={{ ...isSmall ? styles.TextXTiny : styles.TextTiny, color: global.reklamacia_font_colors[item.status] }}>{lang.reklamacia_statuses[item.status]}</p>}></Chip>
                            </div>
                        </div>
                    </Paper>
                )) :
                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                        <p style={{ ...styles.TextXSmall }}>{lang.items_none}</p>
                    </div>
                }


                {pages > 1 ?
                    <div style={{ ...styles.Block, marginTop: 40, marginBottom: 10 }}>
                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                    </div>
                    :
                    <div style={{ ...styles.Block, minHeight: 40 }}></div>
                }

            </div>

            {showReklamacia == true ?
                <ReklamaciaPreview item={selected} isSmall={isSmall} user_id={props.user_id} lang={lang} func={() => setShowReklamacia(false)} />
                : null}

        </div >
    )
};



export const Reklamacia = (props) => {
    /*

        VYTVORENIE REKLAMACIE

    */
    const isSmall = props.isSmall;
    const radius = props.isSmall ? 0 : global.radius;

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // VALUES
    const [order, setOrder] = useState(false);
    const [products, setProducts] = useState(false);
    const [index, setIndex] = useState(0);
    const [text, setText] = useState('');
    const [reasonID, setReasonID] = useState(0);
    const [requestID, setRequestID] = useState(0);
    const [fakturaNumber, setFakturaNumber] = useState('');
    const [photos, setPhotos] = useState([]);
    const [showAddPhoto, setShowAddPhoto] = useState(false);

    // ODOSLAT ?
    const [showSend, setShowSend] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    const photoSize = props.isSmall ? 120 : 140;

    const buttonHeight = 130;
    const rowHeight = 60;

    let { func } = props;
    let lang = props.lang;

    var running = false;
    useEffect(() => {

        if (running == false) {

            if (props.item == false) {
                var tmp = [...props.orderList];
                tmp.forEach(item => {
                    item.pieces = 0;
                    item.selected = false;
                });

                setProducts(tmp);
                setOrder(props.order);
            }

            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'customer_get_gps', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: props.token,
                    user_id: props.user.id,
                    organization_id: props.organization.id,
                    // -------------------------------------

                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    setShowOK(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false);
    }

    const ChangeSelected = (item) => {
        if (item.selected == true) {
            item.selected = false;
        } else {
            item.selected = true;
        }

        setRedraw(!redraw);
    }

    const Prev = () => {
        setIndex(0);
        GoToStart();
    }

    const Next = () => {
        setIndex(1);
        GoToStart();
    }

    const PlusMinusResult = (value, item) => {
        item.pieces = value;
        if (value > 0) {
            item.selected = true;
        } else {
            item.selected = false;
        }
        setRedraw(!redraw);
    }

    const Process = () => {

        var product_list = products.filter(x => x.selected == true || x.pieces > 0);
        if (product_list.length == 0) {
            product_list = 0;
        }

        var data = {
            id: props.item == false ? 0 : props.item.id,
            enabled: true,
            status: 0,
            order_id: props.order.id,
            client_id: props.user_id,
            products: product_list == 0 ? 0 : JSON.stringify(product_list),
            reason_id: reasonID,
            solve_id: 0,
            faktura_cislo: fakturaNumber,
            text: text,
            photos: photos.length > 0 ? JSON.stringify(photos) : 0,
            request_id: requestID,
            deny_reason: '',
        }

        Debug(data);
        db_update(data);
    }

    const SendResult = (typ) => {
        setShowSend(false);
        if (typ == true) {
            Process();
        }
    }

    const AddPhoto = () => {
        setShowAddPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setShowAddPhoto(false);
        console.log(path);
        console.log(file);
        if (typ == true) {
            let data = {
                id: UniqueID(),
                path: path,
                file: file
            }
            let tmp = photos;
            tmp.push(data);
            setPhotos(tmp);
            setRedraw(!redraw);
        }
    }

    const DeletePhoto = (item) => {
        var tmp = photos.filter(x => x.id != item.id);
        setPhotos(tmp);
    }

    const GoToStart = () => {
        setTimeout(() => {
            var element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "nearest" }); // start, center, end, nearest
            }
        }, global.goto_start_delay);
    }

    return (
        <Dialog
            open={true}
            PaperProps={{
                sx: { borderRadius: props.isSmall ? 0 : '10px', margin: 0, minHeight: dialogHeight, minWidth: dialogWidth },
                elevation: 0
            }}
        >
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, maxHeight: dialogHeight, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.window_bar_color }}>
                    <div style={{ ...styles.Block, width: dialogWidth - 60, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{lang.reklamacia_title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_back, borderBottomLeftRadius: radius, borderBottomRightRadius: radius, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                    <div id={'id_start'} style={{ ...styles.Block }}></div>
                    <div style={{ ...styles.Block, marginTop: 10, marginBottom: 10 }}>
                        {index == 0 ?
                            <p style={{ ...styles.TextXSmall, textAlign: 'center' }}><b>{lang.reklamacia_create_text}: {order != false ? String(order.id).padStart(8, '0') : ''}</b></p>
                            :
                            <p style={{ ...styles.TextXSmall, textAlign: 'center' }}><b>{lang.reklamacia_page_2}</b></p>
                        }
                    </div>
                    {index == 0 ?
                        <div style={{ ...styles.BlockCenter }}>
                            <div style={{ ...styles.Block, marginTop: 20, marginBottom: 10 }}>
                                <p style={{ ...styles.TextXSmall, textAlign: 'center' }}>{lang.reklamacia_select}</p>
                            </div>

                            {products != false ? products.map((item, index) => (
                                item.item_id > 0 ?
                                    props.isSmall ?
                                        /*
                                        *
                                        * =================================================================================================================
                                        * SMALL SCREEN
                                        * =================================================================================================================
                                        *
                                        */

                                        <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '98%', marginTop: 5, marginBottom: 5, minHeight: rowHeight, backgroundColor: item.selected == true ? global.theme_lighteen_red : global.theme_white, borderRadius: 10 }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.Block, width: 40, height: rowHeight, height: rowHeight + 10 }}>
                                                    <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                                </div>
                                                <div onClick={() => ChangeSelected(item)} style={{ ...styles.BlockLeft, width: rowHeight, height: rowHeight + 10, justifyContent: 'center', cursor: 'pointer' }}>
                                                    {item.image != '' ?
                                                        <img src={global.product_image + item.image} style={{ width: rowHeight - 10, height: rowHeight - 10, borderRadius: 5 }}></img>
                                                        : null}
                                                </div>
                                                <div onClick={() => ChangeSelected(item)} style={{ ...styles.BlockLeft, height: rowHeight + 10, width: '75%', justifyContent: 'center', cursor: 'pointer' }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{item.code}</p>
                                                    <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} marginLeft={5} color={global.theme_black} />
                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.reklamacia_quantity_}: {item.quantity} {lang.pieces}</p>
                                                </div>
                                            </div>

                                            <div style={{ ...styles.Block, justifyContent: 'center', cursor: 'pointer', marginTop: 5, marginBottom: 10 }}>
                                                <p style={{ ...styles.TextTiny, marginBottom: 2 }}>{lang.reklamacia_quantity}</p>
                                                <PlusMinus2 quantity={item.pieces > 0 ? item.pieces : 0} min={0} max={item.quantity} item={item} func={PlusMinusResult.bind(this)} />
                                            </div>
                                        </Paper>
                                        :
                                        /*
                                        *
                                        * =================================================================================================================
                                        * STANDART SCREEN
                                        * =================================================================================================================
                                        *
                                        */

                                        <Paper elevation={2} key={item.id} style={{ ...styles.BlockRow, width: '98%', marginTop: 5, marginBottom: 5, minHeight: rowHeight, backgroundColor: item.selected == true ? global.theme_lighteen_red : global.theme_white, borderRadius: 10 }}>
                                            <div style={{ ...styles.Block, width: 40, height: rowHeight, height: rowHeight }}>
                                                <Checkbox checked={item.selected == true ? true : false} size={'small'} onChange={() => ChangeSelected(item)} />
                                            </div>
                                            <div onClick={() => ChangeSelected(item)} style={{ ...styles.BlockLeft, width: rowHeight, height: rowHeight, justifyContent: 'center', cursor: 'pointer' }}>
                                                {item.image != '' ?
                                                    <img src={global.product_image + item.image} style={{ width: rowHeight - 8, height: rowHeight - 8, borderRadius: 5 }}></img>
                                                    : null}
                                            </div>
                                            <div onClick={() => ChangeSelected(item)} style={{ ...styles.Block, width: 80, height: rowHeight, justifyContent: 'center', cursor: 'pointer' }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{item.code}</p>
                                            </div>
                                            <div onClick={() => ChangeSelected(item)} style={{ ...styles.BlockLeft, width: '60%', height: rowHeight, justifyContent: 'center', cursor: 'pointer' }}>
                                                <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} marginLeft={5} color={global.theme_black} />
                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.reklamacia_quantity_}: {item.quantity} {lang.pieces}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 200, height: rowHeight, justifyContent: 'center', cursor: 'pointer' }}>
                                                <PlusMinus2 quantity={item.pieces > 0 ? item.pieces : 0} min={0} max={item.quantity} item={item} func={PlusMinusResult.bind(this)} />
                                            </div>
                                        </Paper>
                                    : null
                            )) : null}

                            <div style={{ ...styles.Block, minHeight: 100 }}></div>
                        </div>
                        : null}


                    {index == 1 ?
                        <div style={{ ...styles.BlockCenter }}>
                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, marginTop: 30 }}>
                                <div style={{ ...styles.Block, width: props.isSmall ? '100%' : '50%' }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600', marginBottom: 2 }}>{lang.reklamacia_reason}</p>
                                    <SelectInput default={lang.choose} enabled={true} lang={lang} value={reasonID} items={lang.reklamacia_reasons} field={'name'} label={lang.mobil} radius={global.radius} width={'90%'} func={(txt) => setReasonID(txt)} />
                                </div>

                                <div style={{ ...styles.Block, width: props.isSmall ? '100%' : '50%', marginTop: props.isSmall ? 30 : 0 }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600', marginBottom: 2 }}>{lang.reklamacia_faktúra}</p>
                                    <TextField
                                        value={fakturaNumber}
                                        onInput={e => setFakturaNumber(e.target.value)}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        style={{ backgroundColor: global.theme_white, width: '90%' }}
                                        //placeholder={lang.reklamacia_text_}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "#000000",
                                            },
                                            fieldset: { borderWidth: props.enabled == false ? 0 : 1, borderColor: error != '' ? global.theme_red : global.theme_gray, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                                        }}
                                        type={'text'}
                                        variant="outlined"
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div style={{ ...styles.Block, width: '95%', marginTop: 30 }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600', marginBottom: 2 }}>{lang.reklamacia_text}</p>
                                <TextField
                                    value={text}
                                    onInput={e => setText(e.target.value)}
                                    size="small"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                    placeholder={lang.reklamacia_text_}
                                    multiline={true}
                                    rows={5}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#000000",
                                        },
                                        fieldset: { borderWidth: props.enabled == false ? 0 : 1, borderColor: error != '' ? global.theme_red : global.theme_gray, borderLeft: props.require == true ? '3px solid #FF0000' : undefined }
                                    }}
                                    type={'text'}
                                    variant="outlined"
                                    disabled={false}
                                />
                            </div>

                            <div style={{ ...styles.Block, width: props.isSmall ? '100%' : '50%', marginTop: 30 }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600', marginBottom: 2 }}>{lang.reklamacia_request}</p>
                                <SelectInput default={lang.choose} enabled={true} lang={lang} value={requestID} items={lang.reklamacia_requests} field={'name'} label={lang.mobil} radius={global.radius} width={'90%'} func={(txt) => setRequestID(txt)} />
                            </div>

                            <div style={{ ...styles.Block, marginTop: 30 }}>
                                <p style={{ ...styles.TextXSmall }}><b>{lang.reklamacia_photos}</b></p>
                                <p style={{ ...styles.TextTiny, }}>{lang.reklamacia_photos_}</p>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 10 }}>
                                <div style={{ ...styles.Block, justifyContent: 'center', alignItems: photos.length == 0 ? 'center' : 'center', minHeight: photoSize + 50 }}>
                                    <div style={{ ...styles.BlockCenter, width: '98%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        {photos.map((item, index) => (
                                            <Paper key={item.id} elevation={2} style={{ ...styles.Block, width: photoSize, height: photoSize + 40, marginRight: 10, backgroundColor: global.theme_white, borderRadius: radius, marginBottom: 10 }}>
                                                <img src={global.testing == true ? 'https://polplast.datadream-test.sk/public/uploads/reklamacie/' + item.file : 'https://www.polplast.sk/public/uploads/reklamacie/' + item.file} style={{ width: '100%', height: '100%', maxWidth: photoSize - 10, maxHeight: photoSize - 10, objectFit: 'contain' }}></img>
                                                <div style={{ ...styles.Block, height: 30 }}>
                                                    <Button onClick={() => DeletePhoto(item)} style={{ ...styles.ButtonLink, width: photoSize, marginTop: 10 }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faXmark} />
                                                        <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.delete}</p>
                                                    </Button>
                                                </div>
                                            </Paper>
                                        ))}
                                        {photos.length < 5 ?
                                            <div style={{ ...styles.Block, width: photoSize, height: photoSize + 20 }}>
                                                <Button onClick={() => AddPhoto()} style={{ backgroundColor: global.theme_blue, flexDirection: 'column', width: photoSize, height: 80 }}>
                                                    <FontAwesomeIcon style={{ height: 20, color: global.theme_white, marginRight: 10 }} icon={faImage} />
                                                    <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 5, textTransform: 'none' }}>{lang.photo_add}</p>
                                                </Button>
                                                <div style={{ ...styles.Block, height: 30 }}></div>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            <div style={{ ...styles.Block, minHeight: 150 }}></div>
                        </div>
                        : null}


                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.Block, height: 50 }}>
                        {reasonID == 0 ?
                            index == 1 ?
                                <Chip style={{ backgroundColor: global.theme_dark_red }} label={<p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.reklamacia_reason_missing}</p>} ></Chip>
                                :
                                <FormError small margin={0} error={error} theme={props.theme} />
                            :
                            <FormError small margin={0} error={error} theme={props.theme} />
                        }
                    </div>
                    {props.isSmall == true ?
                        <div style={{ ...styles.Block, height: buttonHeight - 50 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, width: '50%' }}>
                                    {index == 1 ?
                                        <Button onClick={() => Prev()} style={{ ...styles.ButtonOutlined }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                                            {lang.back}
                                        </Button>
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: '50%' }}>
                                    {index == 0 ?
                                        <Button onClick={() => Next()} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_darker_green }}>
                                            {lang.continue}
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                        </Button>
                                        :
                                        <Button onClick={() => setShowSend(true)} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_darker_red }}>
                                            {lang.reklamacia_send}
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faPaperPlane} />
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.Block, height: buttonHeight - 50 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    {index == 1 ?
                                        <Button onClick={() => Prev()} style={{ ...styles.ButtonOutlined }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                                            {lang.back}
                                        </Button>
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_dark }}>{lang.close}</Button>
                                </div>
                                <div style={{ ...styles.Block, width: '33%' }}>
                                    {index == 0 ?
                                        <Button onClick={() => Next()} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_darker_green }}>
                                            {lang.continue}
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                        </Button>
                                        :
                                        reasonID > 0 ?
                                            <Button onClick={() => setShowSend(true)} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_darker_red }}>
                                                {lang.reklamacia_send}
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faPaperPlane} />
                                            </Button>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Paper>

            {
                showAddPhoto == true ?
                    <Photos lang={lang} typ={0} max_size={1600} full title={lang.photo_choose} sub_title={lang.booking_reklamacia} radius={radius} offset={props.offset} theme={props.theme} func={PhotoResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <DialogInfo title={lang.reklamacia} text={lang.reklamacia_sended} sub_text={lang.reklamacia_sended_text} button={lang.close} radius={radius} func={() => func(true)} />
                    : null
            }

            {
                showSend == true ?
                    <DialogYesNo radius={radius} title={lang.reklamacia_send_ask} text={lang.reklamacia_send_ask_text} sub_text={''} warning_text={''} icon={faPaperPlane} yes={lang.reklamacia_send} no={lang.no} yes_color={global.theme_darker_red} no_color={global.theme_dark} func={SendResult.bind(this)} />
                    : null
            }

        </Dialog >
    );
}



export const ReklamaciaPreview = (props) => {
    /*

        NAHLAD REKLAMACIE

    */
    const isSmall = props.isSmall;
    const radius = props.isSmall ? 0 : global.radius;

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // VALUES
    const [reklamacia, setReklamacia] = useState(false);
    const [products, setProducts] = useState(false);
    const [photos, setPhotos] = useState(false);
    const [request, setRequest] = useState('');
    const [reason, setReason] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    const photoSize = props.isSmall ? 160 : 180;

    const buttonHeight = 130;
    const rowHeight = 60;

    let { func } = props;
    let lang = props.lang;

    var running = false;
    useEffect(() => {

        if (running == false) {
            db_get();
            running = true;
        }

        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item.id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setReklamacia(json.item);
                if (json.item.products != 0) {
                    setProducts(JSON.parse(json.item.products));
                }
                if (json.item.photos != 0) {
                    setPhotos(JSON.parse(json.item.photos));
                }
                if (json.item.reason_id > 0) {
                    let tmp = lang.reklamacia_reasons.find(x => x.id == json.item.reason_id);
                    if (tmp != undefined) {
                        setReason(tmp.name);
                    }
                }
                if (json.item.request_id > 0) {
                    let tmp = lang.reklamacia_requests.find(x => x.id == json.item.request_id);
                    if (tmp != undefined) {
                        setRequest(tmp.name);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    setShowOK(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Close = () => {
        func(false);
    }
    const GoToStart = () => {
        setTimeout(() => {
            var element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth', block: "nearest" }); // start, center, end, nearest
            }
        }, global.goto_start_delay);
    }

    return (
        <Dialog
            open={true}
            //fullScreen={fullScreen}
            //sx={{ height: dialogHeight }}
            PaperProps={{
                sx: { borderRadius: props.isSmall ? 0 : '10px', margin: 0, minHeight: dialogHeight, minWidth: dialogWidth },
                elevation: 0
            }}
        >
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, maxHeight: dialogHeight, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.window_bar_color }}>
                    <div style={{ ...styles.Block, width: dialogWidth - 60, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{lang.reklamacia_title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_back, borderBottomLeftRadius: radius, borderBottomRightRadius: radius, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                    <div id={'id_start'} style={{ ...styles.Block }}></div>
                    <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 20, backgroundColor: global.theme_gray }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'center', marginBottom: 20 }}><b>{lang.reklamacia_title}: {reklamacia != false ? String(reklamacia.order_id).padStart(8, '0') : ''}</b></p>

                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600' }}>{lang.reklamacia_reason}</p>
                            <p style={{ ...styles.TextXSmall }}>{reason}</p>
                        </div>
                        <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 10 }}></div>
                        <div style={{ ...styles.Block, marginTop: 10 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600' }}>{lang.reklamacia_faktúra}</p>
                            <p style={{ ...styles.TextXSmall }}>{reklamacia != false ? reklamacia.faktura_cislo == '' ? lang.none_entered : reklamacia.faktura_cislo : ''}</p>
                        </div>
                        <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 10 }}></div>
                        <div style={{ ...styles.Block, marginTop: 20, width: '96%' }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600' }}>{lang.reklamacia_text}</p>
                            <p style={{ ...styles.TextXSmall }}>{reklamacia != false ? reklamacia.text == '' ? lang.none_entered : reklamacia.text : ''}</p>
                        </div>
                        {reklamacia != false ? reklamacia.status < 2 ?
                            <>
                                <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 10 }}></div>
                                <div style={{ ...styles.Block, marginTop: 10 }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, fontWeight: '600' }}>{lang.reklamacia_request}</p>
                                    <p style={{ ...styles.TextXSmall }}>{request == '' ? lang.none_entered : request}</p>
                                </div>
                            </>
                            : null : null}

                        {reklamacia != false ? reklamacia.deny_reason != '' && reklamacia.status == 3 ?
                            <>
                                <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 10 }}></div>
                                <div style={{ ...styles.Block, marginTop: 10, width: '96%' }}>
                                    <p style={{ ...styles.TextXSmall, color: global.theme_dark_red, fontWeight: '600' }}>{lang.reklamacia_deny_reason}</p>
                                    <p style={{ ...styles.TextXSmall }}>{reklamacia.deny_reason}</p>
                                </div>
                            </>
                            : null : null}
                    </div>

                    <div style={{ ...styles.BlockCenter, marginTop: 20 }}>
                        {products != false ?
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, textAlign: 'center' }}>{lang.reklamacia_products}</p>
                            : null}
                        {products != false ? products.map((item, index) => (
                            item.item_id > 0 ?
                                props.isSmall ?
                                    /*
                                    *
                                    * =================================================================================================================
                                    * SMALL SCREEN
                                    * =================================================================================================================
                                    *
                                    */

                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '98%', marginTop: 5, marginBottom: 5, minHeight: rowHeight, backgroundColor: global.theme_white, borderRadius: 10 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: rowHeight + 10, justifyContent: 'center' }}>
                                                {item.image != '' ?
                                                    <img src={global.product_image + item.image} style={{ width: rowHeight - 10, height: rowHeight - 10, borderRadius: 5 }}></img>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockLeft, height: rowHeight + 10, width: '75%', justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{item.code}</p>
                                                <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} marginLeft={5} color={global.theme_black} />
                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.reklamacia_quantity_}: {item.quantity} {lang.pieces}</p>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRight, marginRight: 10, marginBottom: 10 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.reklamacia_quantity}: <b>{item.pieces} {lang.pieces}</b></p>
                                        </div>
                                    </Paper>
                                    :
                                    /*
                                    *
                                    * =================================================================================================================
                                    * STANDART SCREEN
                                    * =================================================================================================================
                                    *
                                    */

                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', marginTop: 5, marginBottom: 5, minHeight: rowHeight, backgroundColor: global.theme_white, borderRadius: 10 }}>
                                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                                            <div style={{ ...styles.BlockLeft, width: rowHeight, height: rowHeight, justifyContent: 'center' }}>
                                                {item.image != '' ?
                                                    <img src={global.product_image + item.image} style={{ width: rowHeight - 8, height: rowHeight - 8, borderRadius: 5 }}></img>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.Block, width: 80, height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{item.code}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '62%', height: rowHeight, justifyContent: 'center' }}>
                                                <TextLine text={item.name} fontSize={global.font_tiny} fontWeight={'600'} marginLeft={5} color={global.theme_black} />
                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.reklamacia_quantity_}: {item.quantity} {lang.pieces}</p>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 150, height: rowHeight, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{item.pieces} {lang.pieces}</p>
                                            </div>
                                        </div>

                                    </Paper>
                                : null
                        )) : null}

                        {photos != false ?
                            <div style={{ ...styles.BlockCenter }}>
                                <div style={{ ...styles.Block, marginTop: 30 }}>
                                    <p style={{ ...styles.TextXSmall }}><b>{lang.reklamacia_photos}</b></p>
                                </div>
                                <div style={{ ...styles.Block, marginTop: 10 }}>
                                    <div style={{ ...styles.Block, justifyContent: 'center', alignItems: photos.length == 0 ? 'center' : 'center', minHeight: photoSize + 50 }}>
                                        <div style={{ ...styles.BlockCenter, width: '98%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                                            {photos != false ? photos.map((item, index) => (
                                                <Paper key={item.id} elevation={2} style={{ ...styles.Block, width: photoSize, height: photoSize + 40, marginRight: 10, backgroundColor: global.theme_white, borderRadius: radius, marginBottom: 10 }}>
                                                    <img src={global.testing == true ? 'https://polplast.datadream-test.sk/public/uploads/reklamacie/' + item.file : 'https://www.polplast.sk/public/uploads/reklamacie/' + item.file} style={{ width: '100%', height: '100%', maxWidth: photoSize - 10, maxHeight: photoSize - 10, objectFit: 'contain' }}></img>
                                                </Paper>
                                            )) : null}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, minHeight: 150 }}></div>
                            </div>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <p style={{ ...styles.TextXSmall }}>{lang.reklamacia_status}: <b>{reklamacia != false ? lang.reklamacia_statuses[reklamacia.status] : ''}</b></p>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight - 50 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block }}>
                                <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_dark }}>
                                    {lang.close}
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>
            </Paper>

        </Dialog >
    );
}


