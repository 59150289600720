import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, Footer, Loading, DivideProducts, DivideText, GalleryView, PlusMinus, LoadUser, Debug, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronCircleRight, faCircle, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis'
import { styles } from './styles.js';

export const Blog = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(true);

    const [web, setWeb] = useState([]);
    const [logged, setLogged] = useState(false);

    const [blog, setBlog] = useState(null);
    const [blogs, setBlogs] = useState(null);
    const [blogPrev, setBlogPrev] = useState(null);
    const [blogNext, setBlogNext] = useState(null);
    const [items, setItems] = useState(null);
    const [products, setProducts] = useState([]);

    const textWidth = '70%';

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setLogged(data.logged);

        GoHome();
        db_blog(params.id);

        return () => {
        };

    }, []);

    const db_blog = async (id) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'blog', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    blog_id: id
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setBlog(json.blog);
                setItems(json.items);
                setProducts(json.products);

                var tmp = json.blogs;
                var index = json.index; // index aktualneho blogu

                var prev = null;
                var next = null;

                if (index == 0) {
                    var next = tmp.filter(x => x.index == 1);
                } else if (index > 0 && index < tmp.length - 1) {
                    var prev = tmp.filter(x => x.index == index - 1);
                    var next = tmp.filter(x => x.index == index + 1);
                } else {
                    var prev = tmp.filter(x => x.index == index - 1);
                }


                setBlogPrev(prev);
                setBlogNext(next);

                setBlogs(tmp);

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }

    const NextBlog = (id) => {
        GoHome();
        db_blog(id);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', backgroundColor: global.theme_white }}>

            {/* TITLE */}
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isSmall ? 320 : '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>

                {/* BODY */}
                {isBusy == false ?
                    <div style={{ ...styles.Block, paddingBottom: 0 }}>

                        {/* OBSAH BLOGU */}
                        <div style={{ ...styles.Block, ...styles.BorderTopBold, marginTop: 40 }}>
                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 40, maxWidth: global.screen_max }}>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                                    <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, paddingRight: isSmall ? 0 : 20 }}>
                                        {isSmall ?
                                            <div style={{ ...styles.Block, width: '90%' }}>
                                                <img src={global.blog_image + blog.image} style={{ width: '100%', borderRadius: 20 }}></img>
                                            </div>
                                            : null
                                        }
                                        <h1 style={{ ...styles.TextXXLarge, textAlign: 'left', color: global.theme_black, fontWeight: 'bold', marginBottom: 20, marginTop: isSmall ? 20 : 0 }}>{blog.title}</h1>
                                        {/*<p style={{ ...styles.TextBlog, textAlign: 'justify', marginTop: 30, color: global.theme_black, textIndent: global.tabsize }}>{blog.description}</p>*/}
                                        {DivideText(blog.description).map((txt, index) => (
                                            <p key={index} style={{ ...styles.TextBlog, textIndent: 0, textAlign: 'justify', color: global.theme_black, marginBottom: txt == '' ? global.font_normal : 0 }}>{txt}</p>
                                        ))}
                                    </div>
                                    {isSmall ? null :
                                        <div style={{ ...styles.Block, width: 320 }}>
                                            <img src={global.blog_image + blog.image} style={{ width: 320, height: 320, borderRadius: 20 }}></img>
                                        </div>
                                    }
                                </div>

                                <div style={{ ...styles.Block }}>
                                    {items.map(item => (
                                        item.typ == 0 ?
                                            //  TITLE & TEXT
                                            <div key={item.id} style={{ ...styles.BlockLeft, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down), width: isSmall ? '95%' : textWidth }}>
                                                <p style={{ ...styles.TextXLarge, textAlign: 'left', color: item.color_1, fontWeight: item.bool == true ? 'bold' : 'normal' }}>{item.title}</p>
                                                <div style={{ marginTop: 30 }}>
                                                    {DivideText(item.text).map((txt, index) => (
                                                        <p key={index} style={{ ...styles.TextBlog, textAlign: item.justify == true ? 'justify' : 'left', textIndent: item.tab == true ? global.tabsize : 0, color: item.color_2, marginBottom: txt == '' ? global.font_normal : 0, textAlign: 'justify' }}>{txt}</p>
                                                    ))}
                                                </div>
                                            </div>
                                            :
                                            item.typ == 1 ?
                                                //  TEXT
                                                <div key={item.id} style={{ ...styles.BlockLeft, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down), width: isSmall ? '95%' : '70%' }}>
                                                    {DivideText(item.text).map((txt, index) => (
                                                        <p key={index} style={{ ...styles.TextBlog, textAlign: item.justify == true ? 'justify' : 'left', textIndent: item.tab == true ? global.tabsize : 0, color: item.color_1, marginBottom: txt == '' ? global.font_normal : 0, textAlign: 'justify' }}>{txt}</p>
                                                    ))}
                                                </div>
                                                : item.typ == 2 ?
                                                    //  TEXT + IMAGE
                                                    item.value == 0 ?
                                                        <div key={item.id} style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down) }}>
                                                            <div style={{ ...styles.BlockLeft, width: isSmall ? '90%' : '30%' }}>
                                                                <img src={global.blog_image + item.string} style={{ width: '100%', borderRadius: 20 }}></img>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: isSmall ? '95%' : '70%', paddingLeft: isSmall ? 0 : 20 }}>
                                                                {DivideText(item.text).map((txt, index) => (
                                                                    <p key={index} style={{ ...styles.TextBlog, textAlign: item.justify == true ? 'justify' : 'left', textIndent: item.tab == true ? global.tabsize : 0, color: item.color_1, marginBottom: txt == '' ? global.font_normal : 0 }}>{txt}</p>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div key={item.id} style={{ ...styles.BlockRow, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down) }}>
                                                            <div style={{ ...styles.BlockLeft, width: isSmall ? '95%' : '70%', paddingRight: isSmall ? 0 : 20 }}>
                                                                {DivideText(item.text).map((txt, index) => (
                                                                    <p key={index} style={{ ...styles.TextBlog, textAlign: item.justify == true ? 'justify' : 'left', textIndent: item.tab == true ? global.tabsize : 0, color: item.color_1, marginBottom: txt == '' ? global.font_normal : 0 }}>{txt}</p>
                                                                ))}
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: isSmall ? '90%' : '30%' }}>
                                                                <img src={global.blog_image + item.string} style={{ width: '100%', borderRadius: 20 }}></img>
                                                            </div>
                                                        </div>
                                                    : item.typ == 3 ?
                                                        //  IMAGE
                                                        <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down) }}>
                                                            <div style={{ ...styles.Block, width: '90%', height: props.height }}>
                                                                <div style={{ ...styles.Block }}>
                                                                    <img src={global.blog_image + item.string} style={{ maxWidth: '100%', borderRadius: 20, maxHeight: 400 }}></img>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : item.typ == 4 ?
                                                            //  BANNER - TEXT
                                                            <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down) }}>
                                                                <div style={{ ...styles.Block, width: '100%', backgroundColor: item.color_2, paddingTop: 50, paddingBottom: 50 }}>
                                                                    {DivideText(item.string).map((txt, index) => (
                                                                        <p key={index} style={{ ...styles.TextLarge, textAlign: isSmall ? 'center' : 'left', color: item.color_1, textIndent: global.tabsize, marginBottom: txt == '' ? global.font_normal : 0, fontWeight: item.bool == true ? 'bold' : 'normal', letterSpacing: 2 }}>{txt}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            : item.typ == 5 ?
                                                                // LINK - BUTTON
                                                                <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down) }}>
                                                                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: undefined }}>
                                                                        <div style={{ marginTop: 10 }}>
                                                                            <p style={{ ...styles.TextBlog, color: item.bgcolor, marginRight: isSmall ? 0 : 20, marginBottom: isSmall ? 10 : 0 }}>{item.text}</p>
                                                                        </div>
                                                                        <a href={item.string} target="_blank" style={{ ...styles.Block, width: undefined, flexDirection: 'row', height: 40, backgroundColor: item.color_2, textDecoration: 'none', borderRadius: item.bool == true ? 20 : 0 }}>
                                                                            <p style={{ ...styles.TextTiny, color: item.color_1, marginLeft: 40, textDecoration: 'none' }}>{item.title}</p>
                                                                            <FontAwesomeIcon style={{ width: 18, height: 18, color: item.color_1, marginLeft: 15, marginRight: 40, marginTop: 0 }} icon={faChevronCircleRight} />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                : item.typ == 6 ?
                                                                    // SPACER
                                                                    <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down), width: isSmall ? '100%' : textWidth }}>
                                                                        <div style={{ width: '100%', height: item.bool == true ? 3 : 1, backgroundColor: item.color_1 }}></div>
                                                                    </div>
                                                                    : item.typ == 7 ?
                                                                        // LIST
                                                                        <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down), width: isSmall ? '100%' : textWidth }}>
                                                                            {DivideText(item.text).map((txt, index) => (
                                                                                <div style={{ ...styles.BlockRow }}>
                                                                                    <FontAwesomeIcon style={{ width: txt == '' ? 0 : 6, height: txt == '' ? 0 : 6, color: txt == '' ? '#00000000' : item.color_1, marginRight: 10, marginTop: 9 }} icon={faCircle} />
                                                                                    <p key={index} style={{ ...styles.TextBlog, textAlign: 'left', color: item.color_1, textIndent: global.tabsize, marginBottom: txt == '' ? global.font_normal : 0 }}>{txt}</p>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        : item.typ == 8 ?
                                                                            // VIDEO
                                                                            <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down), width: isSmall ? '95%' : textWidth }}>
                                                                                {item.string != '' ?
                                                                                    <p style={{ ...styles.TextXXLarge, textAlign: 'left', marginTop: 50, color: item.color_1, fontWeight: item.bool == true ? 'bold' : 'normal' }}>{item.string}</p>
                                                                                    : null}
                                                                                <div style={{ marginTop: 20 }} dangerouslySetInnerHTML={{ __html: item.text }} />
                                                                            </div>
                                                                            : item.typ == 9 ?
                                                                                // PRODUKTY
                                                                                <div key={item.id} style={{ ...styles.Block, marginTop: parseInt(item.margin_up), marginBottom: parseInt(item.margin_down), width: '100%', backgroundColor: item.color_2, paddingTop: 20, paddingBottom: 20 }}>
                                                                                    <div style={{ ...styles.Block, width: isSmall ? '100%' : textWidth }}>
                                                                                        {item.title != '' ?
                                                                                            <p style={{ ...styles.TextXXLarge, textAlign: 'center', marginTop: 0, marginBottom: 20, color: item.color_1, fontWeight: item.bool == true ? 'bold' : 'normal' }}>{item.title}</p>
                                                                                            : null}

                                                                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                                                                                            {products.filter(x => x.item_id == item.id).map((product) => (

                                                                                                <Paper key={product.id} elevation={3} style={{ ...styles.Block, width: 150, height: 250, margin: 5 }}>
                                                                                                    <a href={global.web_product + product.tid} target="_blank" style={{ ...styles.BlockLeft, justifyContent: 'center', alignItems: 'center', width: 150, height: 150, cursor: 'pointer' }}>
                                                                                                        <img src={global.product_image + product.id + '/th_' + product.image} style={{ maxWidth: 140, maxHeight: 140 }} />
                                                                                                    </a>
                                                                                                    <div style={{ ...styles.BlockCenter, width: undefined, paddingLeft: 10, paddingRight: 10, height: 90 }}>
                                                                                                        <p style={{ ...styles.TextSmall, textAlign: 'center', fontWeight: 'normal', color: global.theme_medium_gray }}>{product.title}</p>
                                                                                                    </div>
                                                                                                </Paper>

                                                                                            ))}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                : null
                                    ))}
                                </div>

                                <div style={{ ...styles.BlockLeft, ...styles.BorderTop, marginTop: 40 }}>
                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{blog.author}</p>
                                    <p style={{ ...styles.TextTiny }}>{blog.datum}</p>
                                </div>

                            </div>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 60, backgroundColor: global.theme_gray }}>
                            <div style={{ marginBottom: 40, marginTop: 40 }}>
                                <p style={{ ...styles.TextXXLarge }}>{'Ďalšie príspevky'}</p>
                            </div>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: undefined }}>
                                {blogPrev != null ?
                                    <div onClick={() => NextBlog(blogPrev[0].tid)} style={{ ...styles.Block, width: 300, margin: 5, marginBottom: 80, cursor: 'pointer' }}>
                                        <img src={global.blog_image + blogPrev[0].image} style={{ width: 300, height: 300, borderRadius: 20 }}></img>
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.BlockLeft, width: 280 }}>
                                                <p style={{ ...styles.TextXXTiny, fontWeight: 'normal', marginTop: 5, color: global.theme_medium_gray }}>{blogPrev[0].datum}</p>
                                                <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 15 }}>{blogPrev[0].title}</p>
                                                <div style={{ margin: 0, fontSize: global.font_normal, color: global.theme_dark_gray, fontWeight: 'normal', textAlign: 'left', lineHeight: 1.2, marginTop: 15 }}>
                                                    <LinesEllipsis
                                                        text={blogPrev[0].description}
                                                        maxLine={3}
                                                        ellipsis='...'
                                                        trimRight
                                                        basedOn='letters'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                                {blogNext != null ?
                                    <div onClick={() => NextBlog(blogNext[0].tid)} style={{ ...styles.Block, width: 300, margin: 5, marginBottom: 80, cursor: 'pointer' }}>
                                        <img src={global.blog_image + blogNext[0].image} style={{ width: 300, height: 300, borderRadius: 20 }}></img>
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.BlockLeft, width: 280 }}>
                                                <p style={{ ...styles.TextXXTiny, fontWeight: 'normal', marginTop: 5, color: global.theme_medium_gray }}>{blogNext[0].datum}</p>
                                                <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 15 }}>{blogNext[0].title}</p>
                                                <div style={{ margin: 0, fontSize: global.font_normal, color: global.theme_dark_gray, fontWeight: 'normal', textAlign: 'left', lineHeight: 1.2, marginTop: 15 }}>
                                                    <LinesEllipsis
                                                        text={blogNext[0].description}
                                                        maxLine={3}
                                                        ellipsis='...'
                                                        trimRight
                                                        basedOn='letters'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>

                    </div>
                    :
                    <div style={{ ...styles.BlockCenter, height: 700 }}>
                    </div>
                }

                {isBusy == true ?
                    <Loading></Loading>
                    : null}

            </div>

        </div >

    )
};

