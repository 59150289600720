import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './globals.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faShoppingCart, faSearch, faAngleDoubleLeft, faChevronLeft, faAngleDoubleRight, faChevronRight, faBars, faCross, faTimesCircle, faList, faAngleDown, faAnglesUp, faAngleLeft, faGear, faCaretUp, faArrowRight, faAngleRight, faPerson, faUser, faPenToSquare, faClose, faRightFromBracket, faEdit, faArrowRightToBracket, faHome, faPaperPlane, faPhone, faCheck, faXmark, faHeart, faCircle, faWarning, faImage } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faMobile, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { DialogActions, Drawer, Slide } from '@mui/material';
//import Slider from "react-slick";
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton, { getLoadingButtonUtilityClass } from '@mui/lab/LoadingButton';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import './items.css';

export const Init = () => {
    localStorage.removeItem('settings');
}


export const TodayDate = () => {
    var today = new Date();

    var d = String(today.getDate()).padStart(2, '0');
    var m = String(today.getMonth() + 1).padStart(2, '0');
    var y = String(today.getFullYear());
    today = y + '-' + m + '-' + d;

    return today;
}

// ********************************************************
export const LoadLogin = () => {
    var data = localStorage.getItem('login');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return { logged: false, date: null };;
    }
}

export const SaveLogin = (data) => {
    localStorage.setItem('login', JSON.stringify(data));
}

// ********************************************************
export const SaveUniqueID = (data) => {
    localStorage.setItem('uniqueID', data);
}

export const LoadUniqueID = () => {
    var data = localStorage.getItem('uniqueID');
    if (data != null) {
        return (data);
    } else {
        return null;
    }
}

// ********************************************************
export const LoadUser = () => {
    var data = localStorage.getItem('user');
    if (data != null) {
        return JSON.parse(data);
    } else {
        return null;
    }
}

export const SaveUser = (data) => {
    localStorage.setItem('user', JSON.stringify(data));
}

export const LoadSettings = () => {
    var data = localStorage.getItem('settings');
    if (data != null) {
        var result = JSON.parse(data);
        return result
    } else {
        var tmp = {
            show: global.show_1,
            display: 0,
            stock: false,
            sort: 0
        }
        return tmp;
    }
}

export const SaveSettings = (show, display, stock, sort) => {

    var data = { show: show, display: display, stock: stock, sort: sort };
    localStorage.setItem('settings', JSON.stringify(data));
}

export const LoadCookies = () => {
    var data = localStorage.getItem('web_cookies');
    if (data != null) {
        return true;
    } else {
        return false;
    }
}

export const SaveCookies = (data) => {
    localStorage.setItem('web_cookies', true);
}

export const Debug = (label, data) => {
    if (global.debugging == true) {
        var dt = data == undefined ? '' : data;
        if (dt == '') {
            console.log(label);
        } else {
            console.log(label, dt);
        }
    }
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (true)
    }
    return (false)
};

export const Menu = (props) => {
    /*
            MENU - na lavej strane

            USAGE:
            <Menu func={MenuPress.bind(this)} />

            const MenuPress = (value) => {
                Debug(value)
            }

    */

    const [showCategories, setShowCategories] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const buttonsHeight = 35;

    const logo = require('./react/app/logo.png');
    const vianoce = require('./react/app/vianoce.png');
    const atelier = require('./react/app/atelier.png');

    const navigate = useNavigate();
    const menuButtonWidth = 120


    var lang = sk;
    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type, id) => {
        func(type, id);
    }

    const OpenClose = () => {
        setShowCategories(!showCategories);
    }

    const ShowCategory = (id) => {
        Press(0, id);
        setShowCategories(false);
        setOpen(false);
    }

    const ShowSubsections = (id) => {
        if (id == global.menu_action) {
            // akcia
            ShowCategory(global.menu_subaction);
        } else {
            Press(1, id);
            setShowCategories(false);
        }
    }

    const OpenMenu = () => {
        setOpen(true);
    }

    const CloseMenu = () => {
        setOpen(false);
    }

    const Home = () => {
        Press(2, 0);
        setOpen(false);
    }

    const Blog = () => {
        navigate('/blogs');
        setOpen(false);
    }

    const Downloads = () => {
        navigate('/downloads');
        setOpen(false);
    }

    const Contact = () => {
        navigate('/contact');
        setOpen(false);
    }

    return (
        isSmall || isMedium ?
            <div style={{ ...styles.BlockLeft, paddingBottom: 10, backgroundColor: global.theme_white }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.Block, width: 50 }}>
                        <IconButton onClick={() => OpenMenu()} style={{ height: 40, width: 40 }}>
                            <FontAwesomeIcon style={{ color: global.theme_black }} icon={faBars} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.Block }}>
                        <img onClick={() => Home()} src={logo} height={50} style={{ cursor: 'pointer' }} />
                    </div>
                    <div style={{ ...styles.Block, width: 50 }}></div>
                </div>
                <Drawer
                    anchor={'left'}
                    open={isOpen}
                    onClose={CloseMenu}
                >
                    <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_menu, width: 280, height: props.height, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                        <div style={{ ...styles.BlockRow, backgroundColor: global.theme_white, height: 60, marginTop: 0 }}>
                            <div style={{ ...styles.Block, height: 60, width: 230 }}>
                                <img src={logo} height={40} style={{}} />
                            </div>
                            <div style={{ ...styles.Block, height: 80, width: 50, justifyContent: 'flex-start', alignContent: 'flex-end' }}>
                                <IconButton onClick={() => CloseMenu()} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, marginTop: 10 }}>
                                    <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                                </IconButton>
                            </div>
                        </div>




                        <Button onClick={() => Home()} style={{ ...styles.Button, width: 200, marginTop: 0 }}>
                            <p style={{ ...styles.TextNormal, color: props.index == 0 ? global.theme_light : global.theme_white }}>{lang.home}</p>
                        </Button>

                        {props.subsections.map(item => {
                            return (
                                <Button key={item.id} onClick={() => ShowCategory(item.id)} style={{ ...styles.BlockLeft, height: buttonsHeight, marginTop: 5, textTransform: 'none' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div style={{ height: buttonsHeight }}>
                                            <img src={global.images + 'catalog/' + item.image + '?id=menu-' + item.id} height={buttonsHeight} width={buttonsHeight} />
                                        </div>
                                        <div style={{ ...styles.Block, height: buttonsHeight, paddingLeft: 10 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white, textAlign: 'left', fontWeight: 'normal' }}>{item.label}</p>
                                        </div>
                                    </div>
                                </Button>
                            )
                        }
                        )}

                        <div style={{ ...styles.Block, backgroundColor: global.theme_menu, paddingBottom: 20 }}>
                            <Button onClick={() => Blog()} style={{ ...styles.Button, width: 200, marginTop: 0, textTransform: 'none' }}>
                                <p style={{ ...styles.TextSmall, color: props.index == 0 ? global.theme_light : global.theme_white }}>{lang.blog}</p>
                            </Button>

                            <Button onClick={() => Downloads()} style={{ ...styles.Button, width: 200, marginTop: 0, textTransform: 'none' }}>
                                <p style={{ ...styles.TextSmall, color: props.index == 0 ? global.theme_light : global.theme_white }}>{lang.catalogue}</p>
                            </Button>

                            <Button onClick={() => Contact()} style={{ ...styles.Button, width: 200, marginTop: 0, textTransform: 'none' }}>
                                <p style={{ ...styles.TextSmall, color: props.index == 0 ? global.theme_light : global.theme_white }}>{lang.contact}</p>
                            </Button>
                        </div>



                    </div>
                </Drawer>
            </div>
            :
            <div>
                <div style={{ display: 'flex', height: 70, flexDirection: 'row', backgroundColor: global.theme_menu, alignItems: 'center', paddingLeft: global.menu_padding, paddingRight: global.menu_padding }}>
                    <Button onClick={() => OpenClose()} style={{ borderRadius: 0, flexDirection: 'column', height: '100%', width: menuButtonWidth + 40, margin: 0, padding: 0, fontSize: global.font_main_menu, color: global.theme_black, textTransform: 'none', backgroundColor: global.theme_menu_categories, borderTopRightRadius: 20, borderBottomRightRadius: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: 50, alignItems: 'flex-end' }}>
                            <FontAwesomeIcon style={{ marginRight: 10, width: 15, height: 15, marginBottom: 6 }} icon={faBars} />
                            <p style={{ margin: 0 }}>{showCategories == true ? lang.hide_categories : lang.show_categories}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', height: 20, alignItems: 'flex-end' }}>
                            {showCategories == true ?
                                <FontAwesomeIcon style={{ width: 36, height: 36, paddingTop: 0, color: global.theme_white }} icon={faCaretUp} />
                                : null}
                        </div>
                    </Button>
                    <Button onClick={() => Press(2, 0)} style={{ borderRadius: 0, flexDirection: 'column', height: '100%', width: menuButtonWidth, margin: 0, padding: 0, fontSize: global.font_main_menu, color: global.theme_white, textTransform: 'none', backgroundColor: global.theme_menu }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FontAwesomeIcon style={{ marginRight: 10, marginTop: 5, width: 15, height: 15, marginBottom: 6 }} icon={faHome} />
                            <p style={{ margin: 0 }}>{lang.home}</p>
                        </div>
                    </Button>
                    {props.sections.map(item => {
                        return (
                            <Button key={item.id} onClick={() => ShowSubsections(item.id)} style={{ height: '100%', width: menuButtonWidth, fontSize: global.font_main_menu, color: item.id == 1005 ? global.theme_yellow : global.theme_white, textTransform: 'none', backgroundColor: global.theme_menu, lineHeight: 1.2 }}>
                                {item.id == 1005 ?
                                    <img src={vianoce} style={{ width: 40, height: 45 }}></img>
                                    : null}
                                {item.id == 1006 ?
                                    <img src={atelier} style={{ width: undefined, height: 36, marginRight: 5 }}></img>
                                    : null}
                                {item.label}
                            </Button>
                        )
                    }
                    )}
                </div>
                {
                    showCategories == true ?
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor: global.theme_back, alignItems: 'center', paddingLeft: global.menu_padding, paddingRight: global.menu_padding }}>
                            {props.subsections.map(item => {
                                return (
                                    <Button key={item.id} onClick={() => ShowCategory(item.id)} style={{ height: 160, borderRadius: 0, maxWidth: global.menu_button_width, fontSize: global.font_tiny, color: global.theme_white, textTransform: 'none', borderRight: '1px solid ' + global.theme_gray, backgroundColor: global.theme_white }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <div style={{ height: 90 }}>
                                                <img src={global.images + 'catalog/' + item.image + '?id=menu-' + item.id} height={80} width={80} />
                                            </div>
                                            <div style={{ height: 40 }}>
                                                <p style={{ margin: 0, fontSize: global.font_tiny, fontWeight: 'normal', color: global.theme_dark }}>{item.label}</p>
                                            </div>
                                        </div>
                                    </Button>
                                )
                            }
                            )}
                        </div>
                        : null
                }
            </div>
    )

};

export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop name={} surname={} language={language} func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {
                Debug(value)
            }

    */
    const [isBusy, setBusy] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showForgot, setShowForgot] = useState(false);
    const [username, setUsername] = useState(global.testing == true ? 'info@vgvstudio.sk' : '');
    const [password, setPassword] = useState(global.testing == true ? '12345678' : '');
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [codeErr, setCodeErr] = useState('');
    const [verifyCode, setVerifyCode] = useState('');
    const [showCode, setShowCode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [clientID, setClientID] = useState(0);
    const [passwordErr, setPasswordErr] = useState('');
    const [isChanged, setChanged] = useState(false);
    const [user, setUser] = useState([]);
    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();

    const TYP_REGISTER = 0;
    const TYP_LOGIN = 1;
    const TYP_LOGOUT = 2;

    const logo = require('./react/app/logo.png');

    const menuHeight = 40;
    const buttonsHeight = 40;

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        var data = LoadLogin();
        if (data != null) {
            if (data.logged == true) {
                setLogged(true);
                var user = LoadUser();
                setUser(user);
                if (user.trieda == 0) {
                    setB2B(true);
                } else {
                    setB2B(false);
                }
            }
        }
        return () => {
        };

    }, []);

    const Press = (type, value) => {
        func(type, value);
    }

    const db_user_login = async (data) => {
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'login', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: username,
                        password: password,
                        language: props.language
                    })
                })

                const json = await response.json();

                setBusy(false);
                if (json.ok > 0) {
                    var today = new Date();
                    var data = { logged: true, date: today };
                    SaveLogin(data);
                    SaveUser(json.user);
                    setShowLogin(false);
                    setLogged(true);
                    setUser(json.user);
                    navigate('/');
                    Press(TYP_LOGIN, 0);

                } else {
                    setError(lang.login_error);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_recover = async () => {
        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'recover', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                    })
                })

                const json = await response.json();

                setBusy(false);
                if (json.ok > 0) {
                    setVerifyCode(json.code);
                    setClientID(json.id);
                    setEmailError('');
                    setCodeErr('');
                    setShowForgot(false);
                    setShowCode(true);
                } else {
                    setEmailError(lang.forgot_email_no_exists);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const db_password_new = async () => {

        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'password_new', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        client_id: parseInt(clientID),
                        password: password
                    })
                })

                const json = await response.json();
                setBusy(false);
                Debug(json);
                if (json.ok > 0) {
                    setPasswordErr('');
                    setChanged(true);
                } else {
                    setPasswordErr(lang.password_error);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };


    const LoginProccess = () => {
        setError('');
        db_user_login();
    }

    const Logout = () => {
        setShowLogout(true);
    }

    const LogoutClose = () => {
        setShowLogout(false);
    }

    const LogoutProccess = () => {
        var today = new Date();
        var data = { logged: false, date: today };
        SaveLogin(data);
        SaveUser([]);
        setLogged(false);
        setShowLogout(false);
        setB2B(false);
        navigate('/');
        Press(TYP_LOGOUT, 0)

    }

    const ForgotPassword = () => {
        setShowLogin(false);
        setShowForgot(true);
        setChanged(false);
        setEmailError('');
        setVerifyCode('');
        setCode('');
        setEmail('');
    }


    const ForgotPasswordClose = () => {
        setShowForgot(false);
    }


    const ForgotPasswordSend = () => {
        if (ValidateEmail(email.trim()) == true) {
            setEmailError('');
            db_recover();
        } else {
            setEmailError(lang.email_error);
        }
    }

    const CloseCode = () => {
        setShowCode(false);
    }

    const VerifyCode = () => {
        if (verifyCode != code) {
            setCodeErr(lang.verification_code_error);
        } else {
            // KOD JE OK
            setShowCode(false);
            setChanged(false);
            setShowPassword(true);
        }
    }

    const ClosePassword = () => {
        setShowPassword(false);
    }

    const SaveNewCode = () => {
        setChanged(false);
        if (password.trim() != '') {
            if (password.trim().length >= 8) {
                setPasswordErr('');
                db_password_new();
            } else {
                setPasswordErr(lang.password_min_len);
            }
        } else {
            setPasswordErr(lang.required);
        }
    }

    const User = () => {
        navigate('/user');
    }

    const Favorites = () => {
        navigate('/favorites');
    }

    return (
        <div style={{ ...styles.BlockRow, height: menuHeight, backgroundColor: global.theme_menu_top, alignItems: 'center', justifyContent: 'center' }}>

            {isSmall || isMedium ? null
                :
                <div style={{ ...styles.BlockRow, width: '33%', height: menuHeight, paddingLeft: global.menu_padding }}>
                    <div style={{ ...styles.BlockLeft, width: 100 }}>
                        <Button onClick={() => navigate('/contact')} style={{ textTransform: 'none', color: global.theme_dark_gray, fontSize: global.font_tiny, fontWeight: 'normal', borderRadius: 0, height: menuHeight, width: 100, borderLeft: '1px solid ' + global.theme_light_gray, borderRight: '1px solid ' + global.theme_light_gray }}>{lang.contact}</Button>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: 100 }}>
                        <Button onClick={() => navigate('/blogs')} style={{ textTransform: 'none', color: global.theme_dark_gray, fontSize: global.font_tiny, fontWeight: 'normal', borderRadius: 0, height: menuHeight, width: 100, borderRight: '1px solid ' + global.theme_light_gray }}>{lang.blog}</Button>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: 100 }}>
                        <Button onClick={() => navigate('/downloads')} style={{ textTransform: 'none', color: global.theme_dark_gray, fontSize: global.font_tiny, fontWeight: 'normal', borderRadius: 0, height: menuHeight, width: 100, borderRight: '1px solid ' + global.theme_light_gray }}>{lang.catalogue}</Button>
                    </div>
                </div>
            }
            {
                isSmall || isMedium ? null :
                    <div style={{ ...styles.Block, width: '33%', height: menuHeight, alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', height: menuHeight, alignItems: 'center' }}>
                            <p style={{ ...styles.TextSmall, color: global.theme_medium_gray }}>{lang.call_us}:</p>
                            <p style={{ ...styles.TextSmall, color: global.theme_black, marginLeft: 5, fontWeight: 'bold' }}>{props.mobil}</p>
                            <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10, fontWeight: 'normal' }}>({props.time})</p>
                        </div>
                    </div>
            }
            <div style={{ display: 'flex', width: isSmall || isMedium ? '100%' : '33%', justifyContent: isSmall || isMedium ? 'flex-end' : 'flex-end', paddingRight: isSmall || isMedium ? 0 : global.menu_padding }}>
                {logged == true ?
                    <IconButton onClick={() => Favorites()} style={{ width: 30, height: 30, borderRadius: '50%', marginTop: 5, marginRight: 20 }}>
                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_favorite }} icon={faHeart} />
                    </IconButton>
                    : null}
                {b2b == true ?
                    <p style={{ margin: 0, marginTop: 12, marginRight: 20, fontWeight: 'bold', fontSize: global.font_tiny }}>{isSmall || isMedium ? 'B2B' : lang.b2b_prices}</p>
                    : null}
                {logged == false ?
                    <div style={{ display: 'flex' }}>
                        <Button onClick={() => Press(TYP_REGISTER, 0)} style={{ ...styles.ButtonEmpty, color: global.theme_black, width: 120, marginRight: 20, fontWeight: 'bold', fontsize: global.font_tiny }} variant="text"><FontAwesomeIcon style={{ marginRight: 5 }} icon={faPenToSquare} />{lang.register_button}</Button>
                        <Button onClick={() => setShowLogin(true)} style={{ ...styles.ButtonEmpty, color: global.theme_black, width: 120, fontWeight: 'bold', fontsize: global.font_tiny }} variant="text"><FontAwesomeIcon style={{ marginRight: 5 }} icon={faUser} />{lang.login_button}</Button>
                    </div>
                    :
                    <div>
                        <Button onClick={() => User()} style={{ textTransform: 'none', color: global.theme_black, fontSize: global.font_tiny, fontWeight: 'normal', borderRadius: 0, height: 40 }}>{user.meno}</Button>
                        <IconButton onClick={() => Logout()} style={{ width: 35, height: 35, borderRadius: 0 }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_black }} icon={faRightFromBracket} />
                        </IconButton>
                    </div>
                }
            </div>

            {/* =============================================================================
            PRIHLASIT SA - DIALOG
            ============================================================================= */}
            <Dialog open={showLogin} maxWidth={'md'}>
                <div style={{ backgroundColor: global.theme_gray, width: 300, padding: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 50 }}></div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <p style={{ fontSize: global.font_large, margin: 0, marginTop: 5 }}>{lang.login}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => setShowLogin(false)} style={{ width: 35, height: 35, borderRadius: '50%', backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: 25, marginBottom: 25 }}>
                        <img src={logo} width={200} />
                    </div>
                    <div style={{ padding: 0 }}>
                        <div style={{ padding: 10 }} >
                            <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={lang.username} variant="outlined" value={username} onInput={e => setUsername(e.target.value)} />
                        </div>
                        <div style={{ padding: 10 }} >
                            <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={lang.password} variant="outlined" type="password" value={password} onInput={e => setPassword(e.target.value)} />
                        </div>
                    </div>
                    {error != '' ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Alert severity="error">{error}</Alert>
                        </div>
                        : null}
                    {isBusy == true ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                            <CircularProgress />
                        </div>
                        : null}
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                        <Button onClick={() => LoginProccess()} style={{ backgroundColor: global.theme_green, width: 240 }} variant="contained">{lang.login}</Button>
                        <Button onClick={() => ForgotPassword()} style={{ marginTop: 12, color: global.theme_medium, textDecorationLine: 'underline', textTransform: 'none' }} variant="text">{lang.lost_password_button}</Button>
                    </div>
                </div>
            </Dialog>

            {/* =============================================================================
            ODHLASIT SA - DIALOG
            ============================================================================= */}
            <Dialog open={showLogout} >
                <div style={{ backgroundColor: global.theme_gray, width: 300, padding: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 50 }}></div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <p style={{ fontSize: global.font_large, margin: 0, marginTop: 5 }}>{lang.logout_question}?</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => LogoutClose()} style={{ width: 35, height: 35, borderRadius: '50%', backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', padding: 20, flexDirection: 'row', justifyItems: 'center', paddingBottom: 30 }}>
                    <div style={{ display: 'flex', width: '50%', padding: 5, alignContent: 'center' }}>
                        <Button onClick={() => LogoutProccess()} style={{ marginTop: 20, backgroundColor: global.theme_dark_red, width: '100%', height: 40 }} variant="contained">{lang.logout}</Button>
                    </div>
                    <div style={{ width: '50%', padding: 5 }}>
                        <Button onClick={() => LogoutClose()} style={{ marginTop: 20, backgroundColor: global.theme_black, width: '100%', height: 40 }} variant="contained">{lang.cancel}</Button>
                    </div>
                </div>
            </Dialog>

            {/* =============================================================================
                ZABUDNUTE HESLO
                ============================================================================= */}
            <Dialog open={showForgot} maxWidth={'md'}>
                <div style={{ backgroundColor: global.theme_gray, width: 300, padding: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 50 }}></div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <p style={{ fontSize: global.font_large, margin: 0, marginTop: 5 }}>{lang.lost_password}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => ForgotPasswordClose()} style={{ width: 35, height: 35, borderRadius: '50%', backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', padding: 20, width: 280, flexDirection: 'column', justifyItems: 'center', paddingBottom: 30 }}>
                    <div style={{ width: '100%' }}>
                        <p style={{ textAlign: 'center' }}>{lang.forgot_password_email1}</p>
                    </div>
                    <TextField style={{ marginTop: 10, backgroundColor: global.theme_white, width: '100%' }} label={lang.email} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                    {isBusy == true ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                            <CircularProgress />
                        </div>
                        : null}
                    {emailError != '' ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                            <Alert severity="error">{emailError}</Alert>
                        </div>

                        : null}
                    <Button onClick={() => ForgotPasswordSend()} style={{ marginTop: 20, backgroundColor: global.theme_green, width: '100%', height: 40 }} variant="contained">{lang.send}</Button>
                </div>
            </Dialog>



            {/* =============================================================================
                VLOZENIE KODU Z EMAILU
                ============================================================================= */}
            <Dialog open={showCode} maxWidth={'md'}>
                <div style={{ backgroundColor: global.theme_gray, width: 300, padding: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 50 }}></div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <p style={{ fontSize: global.font_large, margin: 0, marginTop: 5 }}>{lang.verification_code}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => CloseCode()} style={{ width: 35, height: 35, borderRadius: '50%', backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', padding: 0, flexDirection: 'column', justifyItems: 'center', alignItems: 'center' }}>
                    <div style={{ width: 260, marginBottom: 10, marginTop: 10, }}>
                        <p style={{ textAlign: 'center' }}>{lang.verification_code_enter}.</p>
                    </div>

                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                        <TextField style={{ marginTop: 10, backgroundColor: global.theme_white, width: 200 }} label={lang.verification_code} variant="outlined" value={code} onInput={e => setCode(e.target.value)} />
                    </div>

                    {codeErr != '' ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                            <Alert severity="error">{codeErr}</Alert>
                        </div>

                        : null}
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', padding: 20 }}>
                        <Button onClick={() => VerifyCode()} style={{ backgroundColor: global.theme_green, width: 200, height: 40 }} variant="contained">{lang.verify}</Button>
                    </div>
                </div>
            </Dialog>



            {/* =============================================================================
                VLOZENIE NOVEHO HESLA
                ============================================================================= */}
            <Dialog open={showPassword} maxWidth={'md'}>
                <div style={{ backgroundColor: global.theme_gray, width: 300, padding: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 50 }}></div>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            <p style={{ fontSize: global.font_large, margin: 0, marginTop: 5 }}>{lang.password_change}</p>
                        </div>
                        <div style={{ width: 50 }}>
                            <IconButton onClick={() => ClosePassword()} style={{ width: 35, height: 35, borderRadius: '50%', backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                {isChanged == false ?
                    <div style={{ display: 'flex', padding: 10, width: 280, flexDirection: 'column', justifyItems: 'center', paddingBottom: 0 }}>
                        <div style={{ width: '100%' }}>
                            <p style={{ textAlign: 'center' }}>{lang.password_new}</p>
                        </div>

                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <TextField style={{ marginTop: 10, backgroundColor: global.theme_white, width: 200 }} label={lang.password} variant="outlined" value={password} onInput={e => setPassword(e.target.value)} />
                        </div>

                        {passwordErr != '' ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                                <Alert severity="error">{passwordErr}</Alert>
                            </div>

                            : null}
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', padding: 20 }}>
                            <Button onClick={() => SaveNewCode()} style={{ backgroundColor: global.theme_green, width: 200, height: 40 }} variant="contained">{lang.save}</Button>
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', padding: 10, flexDirection: 'column', justifyItems: 'center', paddingBottom: 0 }}>
                        <div style={{ width: '100%' }}>
                            <p style={{ textAlign: 'center' }}>{lang.password_ok}.</p>
                        </div>

                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', padding: 20 }}>
                            <Button onClick={() => ClosePassword()} style={{ backgroundColor: global.theme_green, width: 200, height: 40 }} variant="contained">{lang.close}</Button>
                        </div>
                    </div>
                }
            </Dialog>



            {/* =============================================================================
                LOADING
                ============================================================================= */}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBusy} onClick={null}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div >
    )

};


export const MenuBottom = (props) => {
    /*
            USAGE:
            <MenuBottom func={MenuBottomPress.bind(this)} />

            const MenuBottomPress = (value) => {
                Debug(value)
            }

    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    return (
        <div style={{ paddingTop: 20, paddingBottom: 20, background: global.theme_black }}>
            <div style={{ display: 'flex', flexDirection: isLarge ? 'row' : 'column', padding: 5, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                    <Link style={{ fontSize: 13, color: global.theme_white, textDecoration: 'none', color: global.theme_light_gray }} to={'/gdpr'}>{lang.gdpr}</Link>
                </div>
                <div style={{ fontSize: 13, padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                    <Link style={{ color: global.theme_white, textDecoration: 'none', color: global.theme_light_gray }} to={'/rules'}>{lang.sell_docs}</Link>
                </div>
                <div style={{ fontSize: 13, padding: 5, paddingLeft: 10, paddingRight: 10 }}>
                    <Link style={{ color: global.theme_white, textDecoration: 'none', color: global.theme_light_gray }} to={'/cookies'}>{lang.cookies_rules}</Link>
                </div>
            </div >
        </div>
    )
}


export const MenuLogo = (props) => {
    /*
            

            USAGE:
            <MenuLogo func={MenuLogoPress.bind(this)} />

            const MenuLogoPress = (value) => {
                Debug(value)
            }

    */
    const [search, setSearch] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [err, setErr] = React.useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    const navigate = useNavigate();
    const location = useLocation();

    const menuHeight = 120;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type, value) => {
        func(type, value);
    }

    const logo = require('./react/app/logo.png');

    const db_search = async (txt) => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'search2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    //language: props.language,
                    search: txt
                })
            })

            const json = await response.json();
            console.log(json);

            if (json.ok > 0) {
                setProducts(json.products);
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }

    }

    const Search = (txt_) => {
        var txt = txt_.trim();
        setSearch(txt);
        if (txt.length > 2) {
            var search = txt.trim();
            search = search.replaceAll('/', '');
            search = search.replaceAll('+', '');
            search = search.replaceAll('#', '');
            //search = search.replaceAll(' ', '+');

            setErr('');
            db_search(search);
        }
    }

    const SearchProduct = (value) => {
        // zobrazenie vybratého produktu
        var url_current = location.pathname;
        var url_new = '/product/' + value.tid;
        var url = url_current.split('/');
        if (value.tid == undefined) {
            // jedna sa o hladany výraz
            if (search.length > 2) {
                navigate('/search/' + search);
                window.location.reload();
            } else {

            }
        } else {
            // bol označený konkrétny produkt
            if (url.length > 1) {
                // ZISTENIE CI JE POZTREBNE ZNOVUNACITAT OBRAZOVKU        
                if (url[1] != 'product') {
                    navigate(url_new);
                } else {
                    navigate(url_new);
                    window.location.reload();
                }
            } else {
                navigate(url_new);
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: isSmall || isMedium ? 'column' : 'row', width: '100%', height: isSmall || isMedium ? undefined : menuHeight, backgroundColor: global.theme_white, alignItems: 'center', paddingTop: isSmall || isMedium ? 0 : 0, paddingBottom: isSmall || isMedium ? 10 : 0 }}>
            {isSmall || isMedium ? null :
                <div style={{ display: 'flex', width: isSmall || isMedium ? '95%' : 380, justifyContent: isSmall || isMedium ? 'center' : 'flex-start', paddingLeft: isSmall || isMedium ? 0 : global.menu_padding }}>
                    <img onClick={() => Press(0, 0)} src={logo} height={65} style={{ cursor: 'pointer' }} />
                </div>
            }
            {/* SEARCH - HLADANIE PRODUKTU */}
            <div style={{ display: 'flex', flexDirection: 'row', width: isSmall || isMedium ? '95%' : '80%', justifyContent: 'center', alignItems: 'center', paddingLeft: isSmall || isMedium ? 0 : 20, paddingRight: isSmall || isMedium ? 0 : 20, height: isSmall || isMedium ? undefined : 100 }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'flex-start' }}>
                    {isSmall || isMedium ? null :
                        <FontAwesomeIcon style={{ marginRight: 10, width: 15, height: 15, color: global.theme_black, marginTop: 17 }} icon={faSearch} />
                    }
                    <Autocomplete
                        freeSolo
                        disableClearable
                        style={{ width: '100%', maxWidth: 600, backgroundColor: global.theme_gray }}
                        options={products}
                        getOptionLabel={(option) => option.title ? option.title : ""}
                        loading={loading}
                        onChange={(event, newValue) => { SearchProduct(newValue); }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                // Prevent's default 'Enter' behavior.
                                if (search.length < 3) {
                                    event.defaultMuiPrevented = true;
                                    setErr(lang.search_error);
                                } else {
                                    setErr('');
                                    SearchProduct(search);
                                }

                            }
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <div style={{ ...styles.Block, width: 50, marginRight: 10 }}>
                                    <img
                                        loading="lazy"
                                        style={{ maxWidth: 50, maxHeight: 50 }}
                                        src={global.product_image + option.path + '/th_' + option.image}
                                        alt=""
                                    />
                                </div>
                                {option.title}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={err == '' ? lang.search : err}
                                onInput={e => Search(e.target.value)}
                                error={err == '' ? false : true}
                                size={isSmall || isMedium ? "small" : "medium"}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
                {isSmall || isMedium ?
                    <div style={{ ...styles.Block, width: 50 }}>
                        <Button onClick={() => navigate('/card')} style={{ display: 'flex', width: '100%', padding: 0, height: 50, justifyContent: 'flex-end', textTransform: 'none' }}>
                            <div style={{ display: 'flex', height: 40, width: 40, justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_gray, border: '1px solid ' + global.theme_light_gray, borderRadius: 8 }}>
                                <Badge badgeContent={props.quantity} color="success">
                                    <FontAwesomeIcon style={{ marginRight: 0, width: 30, height: 30, color: global.theme_black }} icon={faShoppingCart} />
                                </Badge>
                            </div>
                        </Button>
                    </div>
                    : null}
            </div>

            {/* NAKUPNY KOSIK */}
            {isSmall || isMedium ? null :
                <div style={{ display: 'flex', width: isSmall || isMedium ? '95%' : 330, justifyContent: 'flex-end', paddingRight: isSmall || isMedium ? 0 : global.menu_padding }}>
                    <Button onClick={() => navigate('/card')} style={{ display: 'flex', width: '100%', padding: 0, height: 50, justifyContent: 'flex-end', textTransform: 'none' }}>
                        <div style={{ display: 'flex', height: 50, width: 50, justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_gray, border: '1px solid ' + global.theme_light_gray, borderRadius: 8 }}>
                            <Badge badgeContent={props.quantity} color="success">
                                <FontAwesomeIcon style={{ marginRight: 0, width: 30, height: 30, color: global.theme_black }} icon={faShoppingCart} />
                            </Badge>
                        </div>

                        <div style={{ display: 'flex', height: 50, justifyContent: 'center', alignItems: 'center', paddingLeft: 10 }}>
                            <p style={{ marginRight: 0, fontSize: global.font_small, fontWeight: 'normal', color: global.theme_black }}>{lang.shopping_card}</p>
                        </div>
                    </Button>
                </div>
            }
        </div>
    )

};


export const Loading = () => {
    /*
        USAGE: 
                <Loading />
    */


    const logo = require('./react/app/logo_flower_small.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <div style={{ ...styles.Block, width: 100, height: 100, backgroundColor: global.theme_white, borderRadius: 50 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_dark_green }} size={70} />
            </div>
        </Backdrop>
    );
}


export const CategoriesMenu = (props) => {
    /*
            MENU - na lavej strane - kategórie

            USAGE:
            <CategoriesMenu func={CategoriesMenuPress.bind(this)} />

            const CategoriesMenuPress = (value) => {
                Debug(value)
            }

    */

    const [showCategories, setShowCategories] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/app/logo.png');

    var lang = sk;
    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type, id) => {
        func(type, id);
    }

    return (
        <div>
            <div style={{ ...styles.Block, width: 260, backgroundColor: global.theme_categorymenu, alignItems: 'flex-start' }}>
                {props.categories.map(item => {
                    return (
                        <div key={item.id} >
                            <Button onClick={() => Press(0, item.id)} style={{ display: 'flex', padding: 0, justifyContent: 'flex-start', height: 55, width: 260, fontSize: global.font_categorymenu, color: '#444444', textTransform: 'none', backgroundColor: props.categoryID == item.id ? global.theme_gray : global.theme_categorymenu, fontWeight: 'bold', borderTop: '1px solid ' + global.theme_gray, borderRadius: 0, textAlign: 'left' }}>
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', marginLeft: 5 }}>
                                    {item.label}
                                </div>
                                <div style={{ display: 'flex', width: 15, justifyContent: 'flex-end', marginRight: 5 }}>
                                    <FontAwesomeIcon style={{ width: 12, height: 12, marginBottom: 6, color: global.theme_medium_gray }} icon={props.categoryID != item.id ? faAngleRight : faAngleDown} />
                                </div>
                            </Button>
                            {props.categoryID == item.id ?
                                props.subcategories.map(data => {
                                    return (
                                        <div key={data.id}>
                                            <Button onClick={() => Press(1, data.id)} style={{ display: 'flex', padding: 0, marginLeft: 0, justifyContent: 'flex-start', height: 40, width: 260, fontSize: global.font_categorymenu, textTransform: 'none', backgroundColor: props.subCategoryID == data.id ? global.theme_menu_background_selected : global.theme_categorymenu, fontWeight: 'normal', borderTop: '1px solid ' + global.theme_gray, borderRadius: 0, textAlign: 'left' }}>
                                                <FontAwesomeIcon style={{ width: 4, height: 4, marginLeft: 5, color: global.theme_light_gray }} icon={faCircle} />
                                                <p style={{ ...styles.TextTiny, marginLeft: 5, lineHeight: 1, color: props.subCategoryID == data.id ? global.theme_menu_selected : '#444444' }}>
                                                    {data.label}
                                                </p>
                                            </Button>
                                            {props.subCategoryID == data.id ?
                                                props.subProducts.map(list => {
                                                    return (
                                                        <div key={list.id} >
                                                            <Button onClick={() => Press(2, list.id)} style={{ display: 'flex', padding: 0, marginLeft: 0, justifyContent: 'flex-start', height: 40, width: 260, fontSize: global.font_categorymenu, textTransform: 'none', backgroundColor: props.subCategoryID == list.id ? global.theme_menu_background_selected : global.theme_categorymenu, fontWeight: 'normal', borderTop: '1px solid ' + global.theme_gray, borderRadius: 0, textAlign: 'left' }}>
                                                                <p style={{ ...styles.TextTiny, marginLeft: 20, lineHeight: 1, color: props.subProductID == list.id ? global.theme_menu_selected : '#444444', }}>
                                                                    {list.label}
                                                                </p>
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                                )
                                                : null}
                                        </div>
                                    )
                                }
                                )
                                : null
                            }
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )

};


export const FilterBar = (props) => {
    /*

            USAGE:
            <FilterBar func={FilterBarPress.bind(this)} />

            const FilterBarPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [show, setShow] = useState(global.show_1);
    const [display, setDisplay] = useState(0);
    const [stock, setStock] = useState(false);
    const [buttonID, setButtonID] = useState(0);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        if (props.show != undefined) {
            setShow(props.show);
        }
        if (props.display != undefined) {
            setDisplay(props.display);
        }
        if (props.stock != undefined) {
            setStock(props.stock);
        }
        if (props.buttonID != undefined) {
            setButtonID(props.buttonID);
        }

        return () => {
        };

    }, []);

    const Press = (type, id) => {
        func(type, id);
    }

    const ChangeShow = (event) => {
        setShow(event.target.value);
        Press(0, event.target.value);
    };

    const ChangeDisplay = (event) => {
        setDisplay(event.target.value);
        Press(1, event.target.value);
    };

    const ChangeStock = (value) => {
        setStock(value);
        Press(2, value);
    }

    const button = (value) => {
        setButtonID(value);
        Press(3, value);
    }

    return (
        isSmall ?
            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 20 }}>
                <FormControlLabel
                    style={{ textAlign: 'left', marginLeft: 0 }}
                    label={<p style={{ ...styles.TextTiny }}>{lang.show_on_stack}</p>}
                    control={<Checkbox checked={stock} onChange={() => ChangeStock(!stock)} />}
                />
                <div style={{ ...styles.BlockRowRaw, paddingTop: 10, paddingBottom: 10 }}>
                    <Button onClick={() => button(0)} style={{ ...styles.ButtonSelect, backgroundColor: buttonID == 0 ? global.theme_light_blue : global.theme_gray, width: 95, marginLeft: 0, marginTop: 6 }}>{'Najnovšie'}</Button>
                    <Button onClick={() => button(1)} style={{ ...styles.ButtonSelect, backgroundColor: buttonID == 1 ? global.theme_light_blue : global.theme_gray, width: 95, marginLeft: 5, marginTop: 6 }}>{'Najdrahšie'}</Button>
                    <Button onClick={() => button(2)} style={{ ...styles.ButtonSelect, backgroundColor: buttonID == 2 ? global.theme_light_blue : global.theme_gray, width: 95, marginLeft: 5, marginTop: 6 }}>{'Najlacnejšie'}</Button>
                </div>
            </div>
            :
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                <div style={{ ...styles.BlockRow, height: 70, justifyContent: 'flex-end' }}>
                    <div style={{ ...styles.BlockRow, width: '50%', height: 70 }}>
                        <FormControlLabel
                            style={{ textAlign: 'left', marginLeft: 10 }}
                            label={<p style={{ ...styles.TextTiny }}>{lang.show_on_stack}</p>}
                            control={<Checkbox checked={stock} onChange={() => ChangeStock(!stock)} />}
                        />
                    </div>
                    <div style={{ ...styles.BlockRow, width: '50%', height: 40, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <InputLabel style={{ marginRight: 10 }} id="order-select">{lang.show}:</InputLabel>
                        <Select
                            id="order-select"
                            style={{ textAlign: 'left', color: global.theme_black, width: 100 }}
                            value={show}
                            onChange={ChangeShow}
                            size={'small'}
                        >
                            <MenuItem value={global.show_1}>{global.show_1}</MenuItem>
                            <MenuItem value={global.show_2}>{global.show_2}</MenuItem>
                            <MenuItem value={global.show_3}>{global.show_3}</MenuItem>
                        </Select>
                        <Select
                            id="order-select"
                            style={{ textAlign: 'left', color: global.theme_black, width: 150, marginLeft: 20 }}
                            value={display}
                            onChange={ChangeDisplay}
                            size={'small'}
                        >
                            <MenuItem value={0}>{lang.display_grid}</MenuItem>
                            <MenuItem value={1}>{lang.display_line}</MenuItem>
                        </Select>
                    </div>
                </div>
                <div style={{ ...styles.BlockRowRaw }}>
                    <Button onClick={() => button(0)} style={{ ...styles.ButtonSelect, backgroundColor: buttonID == 0 ? global.theme_light_blue : global.theme_gray, width: 150, marginLeft: 10, marginTop: 6 }}>{'Najnovšie'}</Button>
                    <Button onClick={() => button(1)} style={{ ...styles.ButtonSelect, backgroundColor: buttonID == 1 ? global.theme_light_blue : global.theme_gray, width: 150, marginLeft: 20, marginTop: 6 }}>{'Najdrahšie'}</Button>
                    <Button onClick={() => button(2)} style={{ ...styles.ButtonSelect, backgroundColor: buttonID == 2 ? global.theme_light_blue : global.theme_gray, width: 150, marginLeft: 20, marginTop: 6 }}>{'Najlacnejšie'}</Button>
                </div>
            </div>
    )

};


export const PlusMinus = (props) => {
    /*

            USAGE:
            <PlusMinus quantity={quantity} func={PlusMinusPress.bind(this)} />

            const PlusMinusPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [quantity, setQuantity] = useState(1);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        setQuantity(props.quantity);

        return () => {
        };

    }, []);

    const Press = (id) => {
        func(id, props.id, props.item);
    }

    const ChangeQuantity = (id) => {
        var q = parseInt(quantity);
        q = q + id;
        if (q < 1) {
            q = 1;
        }
        setQuantity(q);
        Press(q);
    }

    const EnterQuantity = (text) => {
        if (/^\d+$/.test(text)) {
            setQuantity(text);
        }
        if (text.trim() == '') {
            //setQuantity(text);
            text = '1';
        }
        if (parseInt(text) < 1) {
            text = '1';
            setQuantity(text);
        }
        Press(parseInt(text));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: 175, justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: 4 }}>
                <IconButton onClick={() => ChangeQuantity(-1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_white }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faMinus} />
                </IconButton>
                <div style={{ display: 'flex', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        style={{ fontSize: global.fone_normal, margin: 0, width: 90, border: '0px', textAlign: 'center' }}
                        variant="outlined"
                        label={''}
                        value={quantity}
                        size={'small'}
                        onInput={e => EnterQuantity(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{lang.pieces}</InputAdornment>,
                        }}
                    />
                </div>
                <IconButton onClick={() => ChangeQuantity(1)} style={{ margin: 0, width: 35, height: 35, backgroundColor: global.theme_white }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faPlus} />
                </IconButton>
            </div>
        </div>
    )

};

export const PlusMinus2 = (props) => {
    /*

            USAGE:
            <PlusMinus2 quantity={quantity} item={item} min={0} max={10} func={PlusMinusPress.bind(this)} />

            const PlusMinusPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [quantity, setQuantity] = useState(1);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        setQuantity(props.quantity);

        return () => {
        };

    }, []);

    const Press = (id) => {
        func(id, props.item);
    }

    const ChangeQuantity = (id) => {
        var q = parseInt(quantity);
        q = q + id;
        if (q < props.min) {
            q = props.min;
        }
        if (q > props.max) {
            q = props.max;
        }
        setQuantity(q);
        Press(q);
    }

    const EnterQuantity = (text) => {
        if (/^\d+$/.test(text)) {
            setQuantity(text);
        }
        if (text.trim() == '') {
            //setQuantity(text);
            text = '1';
        }
        if (parseInt(text) < props.min) {
            text = String(props.min);
            setQuantity(text);
        }
        if (parseInt(text) > props.max) {
            text = String(props.max);
            setQuantity(text);
        }
        Press(parseInt(text));
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: 175, justifyContent: 'flex-end' }}>
            <div style={{ display: 'flex', flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', borderRadius: 4 }}>
                <IconButton onClick={() => ChangeQuantity(-1)} style={{ margin: 0, width: 36, height: 36, backgroundColor: '#00000000' }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faMinus} />
                </IconButton>
                <div style={{ display: 'flex', height: 40, justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        style={{ fontSize: global.fone_normal, margin: 0, width: 90, border: '0px', textAlign: 'center' }}
                        variant="outlined"
                        label={''}
                        value={quantity}
                        size={'small'}
                        onInput={e => EnterQuantity(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{lang.pieces}</InputAdornment>,
                        }}
                    />
                </div>
                <IconButton onClick={() => ChangeQuantity(1)} style={{ margin: 0, width: 36, height: 36, backgroundColor: '#00000000' }}>
                    <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 0, color: global.theme_light }} icon={faPlus} />
                </IconButton>
            </div>
        </div>
    )

};

export const GalleryView = (props) => {
    /*

            USAGE:
            <GalleryView gallery={gallery} func={GalleryViewPress.bind(this)} />

            const GalleryViewPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [image, setImage] = useState('');
    const [gallery, setGallery] = useState([]);
    const [index, setIndex] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    let { func } = props;

    useEffect(() => {

        setImage(props.image);
        setGallery(props.gallery);
        if (props.gallery.length > 1) {
            // hladanie aktuálneho obrázku
            var n = 0;
            props.gallery.forEach(element => {
                if (element == props.image) {
                    setIndex(n);
                }
                n++;
            });
        }

        return () => {
        };

    }, []);

    const Press = (id) => {
        func(id);
    }

    const ChangeImage = (id) => {
        var n = index;
        n = n + id;
        if (n < 0) {
            n = gallery.length - 1;
        }
        if (n > gallery.length - 1) {
            n = 0;
        }
        setImage(gallery[n]);
        setIndex(n);
    }

    return (
        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(true)}>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 10, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ position: 'absolute', top: 5, right: 5 }}>
                    <IconButton onClick={() => Press(true)} style={{ height: 40, width: 40, backgroundColor: global.theme_black, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faClose} />
                    </IconButton>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {gallery.length > 1 ?
                        <IconButton onClick={() => ChangeImage(-1)} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_lighter }} icon={faAngleLeft} />
                        </IconButton>
                        : null}
                    {image != '' ?
                        <img src={global.product_image + props.product.path + '/' + image + '?id=' + props.product.tid} style={{ maxHeight: 600, maxWidth: 600, objectFit: 'contain' }} />
                        : null}
                    {gallery.length > 1 ?
                        <IconButton onClick={() => ChangeImage(1)} style={{ height: 40, width: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_lighter }} icon={faAngleRight} />
                        </IconButton>
                        : null}
                </div>
            </div>
        </Dialog>
    )

};


export const UserData = (props) => {
    /*

            USAGE:
            <UserData user={user} func={UserDataPress.bind(this)} />

            const UserDataPress = (value) => {
                Debug(value)
            }

    */


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [isBusy, setBusy] = useState(false);

    const [user, setUser] = useState([]);
    const [error, setError] = useState('');

    const [clientID, setClientID] = useState(0);
    const [company, setCompany] = useState('');
    const [errCompany, setErrCompany] = useState(false);
    const [cName, setCName] = useState('');
    const [errcName, setErrCName] = useState(false);
    const [cStreet, setCStreet] = useState('');
    const [errcStreet, setErrCStreet] = useState(false);
    const [cStreetNumber, setCStreetNumber] = useState('');
    const [cPSC, setCPSC] = useState('');
    const [errcPSC, setErrCPSC] = useState(false);
    const [cTown, setCTown] = useState('');
    const [errcTown, setErrCTown] = useState(false);
    const [cState, setCState] = useState('');
    const [errcState, setErrCState] = useState(false);
    const [ico, setICO] = useState('');
    const [errico, setErrICO] = useState(false);
    const [dic, setDIC] = useState('');
    const [errdic, setErrDIC] = useState(false);
    const [icdph, setICDPH] = useState('');
    const [mobil, setMobil] = useState('');
    const [errMobil, setErrMobil] = useState(false);
    const [email, setEmail] = useState('');
    const [errEmail, setErrEmail] = useState(false);
    const [errEmailText, setErrEmailText] = useState('');

    const [dCompany, setDCompany] = useState('');
    const [dName, setDName] = useState('');
    const [errdName, setErrDName] = useState(false);
    const [dStreet, setDStreet] = useState('');
    const [dStreetNumber, setDStreetNumber] = useState('');
    const [errdStreet, setErrDStreet] = useState(false);
    const [dPSC, setDPSC] = useState('');
    const [errdPSC, setErrDPSC] = useState(false);
    const [dTown, setDTown] = useState('');
    const [errdTown, setErrDTown] = useState(false);
    const [dState, setDState] = useState('');
    const [errdState, setErrDState] = useState(false);
    const [delivery, setDelivery] = useState(true);
    const [b2b, setB2B] = useState(false);

    const [registerTyp, setRegisterTyp] = useState(-1);

    const [password, setPassword] = useState('');
    const [errPassword, setErrPassword] = useState(false);
    const [password2, setPassword2] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [passwordOK, setPasswordOK] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var lang = sk;
    let { func } = props;

    useEffect(() => {
        if (props.user != null) {
            setClientID(props.user.id);
            setCName(props.user.meno);
            setCompany(props.user.fname);
            setCStreet(props.user.fulica);
            setCPSC(props.user.fpsc);
            setCTown(props.user.fmesto);
            setCState(props.user.fstat);
            setICO(props.user.ico);
            setDIC(props.user.dic);
            setICDPH(props.user.icdph);
            setEmail(props.user.email);
            setMobil(props.user.tel);
            setDCompany(props.user.dfirma);
            setDName(props.user.dmeno);
            setDStreet(props.user.dulica);
            setDPSC(props.user.dpsc);
            setDTown(props.user.dmesto);
            setDState(props.user.dstat);
            setDelivery(props.user.dadresa == 0 ? false : true);
            setUser(props.user);
            setB2B(props.user.trieda == 1 ? false : true);
        }

        return () => {
        };

    }, []);

    const Press = (id) => {
        func(id);
    }

    const db_update = async () => {

        if (isBusy == false && clientID > 0) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'user_update', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        client_id: parseInt(clientID),
                        fname: company,
                        meno: cName,
                        fulica: cStreet,
                        fmesto: cTown,
                        fpsc: cPSC,
                        fstat: cState,
                        ico: ico,
                        icdph: icdph,
                        dic: dic,
                        email: email,
                        tel: mobil,
                        dmeno: dName,
                        dfirma: dCompany,
                        dulica: dStreet,
                        dmesto: dTown,
                        dpsc: dPSC,
                        dstat: dState,
                        dadresa: delivery,
                        trieda: b2b == true ? 0 : 1,
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    SaveUser(json.user);
                    Press(2);
                } else {
                    setError(lang.db_error);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_register = async () => {

        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'user_register', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        b2b: b2b,
                        username: email,
                        password: password,
                        fname: company,
                        meno: cName,
                        fulica: cStreet,
                        fmesto: cTown,
                        fpsc: cPSC,
                        fstat: cState,
                        ico: ico,
                        icdph: icdph,
                        dic: dic,
                        email: email,
                        tel: mobil,
                        dmeno: dName,
                        dfirma: dCompany,
                        dulica: dStreet,
                        dmesto: dTown,
                        dpsc: dPSC,
                        dstat: dState,
                        dadresa: delivery,
                    })
                })

                const json = await response.json();
                setBusy(false);
                console.log(json);
                if (json.ok > 0) {
                    if (json.user != null) {
                        var today = new Date();
                        var data = { logged: true, date: today };
                        SaveLogin(data);
                        SaveUser(json.user);
                    }

                    Press(1);
                } else {
                    setError(lang.forgot_email_error);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_password_new = async () => {

        if (isBusy == false) {
            setBusy(true);
            try {
                const response = await fetch(
                    global.db_url + 'password_new', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        client_id: parseInt(clientID),
                        password: password
                    })
                })

                const json = await response.json();
                setBusy(false);
                Debug(json);
                if (json.ok > 0) {
                    setPasswordErr('');
                    setPasswordOK(true);
                } else {
                    setPasswordErr(lang.password_error);
                }

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    };

    const db_check_email = async () => {
        try {
            const response = await fetch(
                global.db_url + 'check_email', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.error == false) {
                    setErrEmailText('');
                    setErrEmail(false);
                } else {
                    setErrEmailText(lang.password_exists);
                    setErrEmail(true);
                }
            }
        } catch (error) {
            Debug(error);

        }
    }

    const ChangeDelivery = (event) => {
        setDelivery(event.target.checked);
    }

    const Save = () => {
        db_update();
    }

    const Register = () => {
        db_register();
    }

    const Check = (id) => {
        // registrovanie zakaznika
        var err = '';

        setErrCName(false);
        setErrCPSC(false);
        setErrCState(false);
        setErrCStreet(false);
        setErrCTown(false);
        setErrCompany(false);
        setErrDIC(false);
        setErrEmail(false);
        setErrICO(false);
        setErrMobil(false);
        setErrDName(false);
        setErrDPSC(false);
        setErrDState(false);
        setErrDStreet(false);
        setErrDTown(false);
        setError('');
        if (cName.trim() == '') {
            err = lang.required_data;
            setErrCName(true);
        }
        if (cStreet.trim() == '') {
            err = lang.required_data;
            setErrCStreet(true);
        }
        if (cPSC.trim() == '') {
            err = lang.required_data;
            setErrCPSC(true);
        }
        if (cTown.trim() == '') {
            err = lang.required_data;
            setErrCTown(true);
        }
        if (cState.trim() == '') {
            err = lang.required_data;
            setErrCState(true);
        }
        if (b2b == true) {
            if (ico.trim() == '') {
                err = lang.required_data;
                setErrICO(true);
            }
            if (dic.trim() == '') {
                err = lang.required_data;
                setErrDIC(true);
            }
        }
        if (mobil.trim() == '') {
            err = lang.required_data;
            setErrMobil(true);
        }

        // DELIVERY ADRESS
        if (delivery == false) {
            if (dName.trim() == '') {
                err = lang.required_data;
                setErrDName(true);
            }
            if (dStreet.trim() == '') {
                err = lang.required_data;
                setErrDStreet(true);
            }
            if (dPSC.trim() == '') {
                err = lang.required_data;
                setErrDPSC(true);
            }
            if (dTown.trim() == '') {
                err = lang.required_data;
                setErrDTown(true);
            }
            if (dName.trim() == '') {
                err = lang.required_data;
                setErrDName(true);
            }
            if (dState.trim() == '') {
                err = lang.required_data;
                setErrDState(true);
            }
        }

        if (id == 0) {
            setErrPassword(false);
            if (password.trim().length < 8) {
                setErrPassword(true);
                err = lang.password_min_len;
            }
        }
        if (email.trim() == '') {
            err = lang.required_data;
            setErrEmail(true);
        } else {
            if (ValidateEmail(email.trim()) == false) {
                setErrEmail(true);
                err = lang.email_error;
            }
        }
        if (err != '') {
            setError(err);
        } else {
            if (id == 0) {
                Register();
            } else {
                Save();
            }
        }
    }

    const SavePassword = () => {
        setPasswordErr('');
        setPasswordOK(false);
        if (password.trim().length < 8) {
            setPasswordErr(lang.password_min_len);
        } else {
            if (password != password2) {
                setPasswordErr(lang.passwords_not_equal);
            } else {
                db_password_new();
            }
        }
    }

    const CheckEmail = () => {
        if (email.trim() != '') {
            db_check_email();
        }
    }

    const ChangeTyp = (typ) => {
        setRegisterTyp(typ);
        if (typ == 0) {
            setB2B(false);
        } else {
            setB2B(true);
        }
    }

    return (
        props.typ == 0 ?
            /*
            ************************************************************************
                ZAKLADNE UDAJE - UPRAVA
            ************************************************************************
            */
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: isSmall ? '100%' : 450 }} >
                {props.register == true ?
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {/* PRIHLASOVACIE UDAJE */}
                        <Paper elevation={3} style={{ ...styles.Block, borderRadius: global.radius, marginBottom: 20, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.Block, width: '95%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }} >
                                    <p style={{ margin: 0, marginTop: 20, marginBottom: 20, fontSize: global.font_large, color: global.theme_black }}>{lang.login_data}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 20 }}>
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.email} (*)</p>
                                    <TextField error={errEmail} onBlur={() => CheckEmail()} style={{ marginTop: 0, backgroundColor: global.theme_white, width: '100%' }} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_dark_red, margin: 3 }}>{errEmailText}</p>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 10 }}>
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.password} ({lang.password_min_len.toLowerCase()}) *</p>
                                    <TextField error={errPassword} type='password' style={{ marginBottom: 30, backgroundColor: global.theme_white, width: '100%' }} variant="outlined" value={password} onInput={e => setPassword(e.target.value)} />
                                </div>
                            </div>
                        </Paper>
                    </div>
                    : null}

                <Paper elevation={3} style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10, borderRadius: global.radius, marginBottom: 20, backgroundColor: global.theme_gray }}>
                    <div style={{ ...styles.Block, width: '95%' }}>

                        {/* VYBER TYPU REGISTRACIE */}
                        {props.register == true ?
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }} >
                                <p style={{ ...styles.TextNormal, marginBottom: 10 }}>{lang.register_type}</p>
                                <Button onClick={() => ChangeTyp(0)} style={{ ...styles.ButtonDark, backgroundColor: registerTyp == 0 ? global.theme_blue : global.theme_medium_gray, width: 250, margin: 10 }}>{lang.register_b2c}</Button>
                                <Button onClick={() => ChangeTyp(1)} style={{ ...styles.ButtonDark, backgroundColor: registerTyp == 1 ? global.theme_blue : global.theme_medium_gray, width: 250, margin: 10 }}>{lang.register_b2b}</Button>
                            </div>
                            : null}

                        {(props.register == true && registerTyp >= 0) || (props.register == false) ?
                            <div style={{ ...styles.BlockLeft }}>
                                {/* FIREMNE UDAJE */}
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }} >
                                    <p style={{ margin: 0, marginTop: 20, fontSize: global.font_large, color: global.theme_black }}>{lang.basic_data} / {lang.facture_data}</p>
                                    <p style={{ margin: 0, marginBottom: 20, fontSize: global.font_tiny, color: global.theme_light }}>({lang.required_data})</p>
                                </div>

                                {props.register == true ? null :
                                    < FormControlLabel
                                        style={{ textAlign: 'left', marginTop: 10, marginBottom: 10 }}
                                        label={lang.b2b_user}
                                        control={<Checkbox checked={b2b} onChange={() => setB2B(!b2b)} />}
                                    />
                                }

                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.name + ' a ' + lang.surname.toLocaleLowerCase()} (*)</p>
                                <TextField error={errcName} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={cName} onInput={e => setCName(e.target.value)} />
                                {props.b2b == true || b2b == true ?
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.company}</p>
                                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={company} onInput={e => setCompany(e.target.value)} />
                                    </div>
                                    : null}
                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.street} (*)</p>
                                <TextField error={errcStreet} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={cStreet} onInput={e => setCStreet(e.target.value)} />
                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '25%', marginRight: 10 }}>
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.psc} (*)</p>
                                        <TextField error={errcPSC} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={cPSC} onInput={e => setCPSC(e.target.value)} />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '75%' }}>
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.town} (*)</p>
                                        <TextField error={errcTown} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={cTown} onInput={e => setCTown(e.target.value)} />
                                    </div>
                                </div>
                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.state} (*)</p>
                                <TextField error={errcState} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={cState} onInput={e => setCState(e.target.value)} />

                                {b2b == true ?
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3, marginTop: 20 }}>{lang.ico} (*)</p>
                                        <TextField error={errico} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={ico} onInput={e => setICO(e.target.value)} />
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3, marginTop: 0 }}>{lang.dic} (*)</p>
                                        <TextField error={errdic} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dic} onInput={e => setDIC(e.target.value)} />
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3, marginTop: 0 }}>{lang.ic_dph}</p>
                                        <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={icdph} onInput={e => setICDPH(e.target.value)} />
                                    </div>
                                    : null}

                                {props.register == false ?
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3, marginTop: 20 }}>{lang.email} (*)</p>
                                        <TextField error={errEmail} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                                    </div>
                                    : null}
                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.mobil} (*)</p>
                                <TextField error={errMobil} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} />
                            </div>
                            : null}
                    </div>
                </Paper>

                {(props.register == true && registerTyp >= 0) || (props.register == false) ?
                    <Paper elevation={3} style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10, borderRadius: global.radius, marginBottom: 20, backgroundColor: global.theme_gray }}>
                        <div style={{ ...styles.Block, width: '95%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 0 }} >
                                <p style={{ fontSize: global.font_large, color: global.theme_black }}>{lang.delivery_address}</p>
                            </div>
                            <FormControlLabel
                                style={{ textAlign: 'left' }}
                                label={lang.delivery_address_same}
                                control={<Checkbox checked={delivery} onChange={ChangeDelivery} />}
                            />

                            {delivery == false ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 20 }} >
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.name + ' a ' + lang.surname.toLowerCase()}</p>
                                    <TextField error={errdName} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dName} onInput={e => setDName(e.target.value)} />
                                    {props.b2b == true ?
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                            <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.company}</p>
                                            <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dCompany} onInput={e => setDCompany(e.target.value)} />
                                        </div>
                                        : null}
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.street}</p>
                                    <TextField error={errdStreet} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dStreet} onInput={e => setDStreet(e.target.value)} />
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '25%', marginRight: 10 }}>
                                            <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.psc}</p>
                                            <TextField error={errdPSC} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={dPSC} onInput={e => setDPSC(e.target.value)} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '75%' }}>
                                            <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.town}</p>
                                            <TextField error={errdTown} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={dTown} onInput={e => setDTown(e.target.value)} />
                                        </div>
                                    </div>
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.state}</p>
                                    <TextField error={errdState} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dState} onInput={e => setDState(e.target.value)} />
                                </div>
                                : null}
                        </div>
                    </Paper>
                    : null}

                {(props.register == true && registerTyp >= 0) || (props.register == false) ?
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 40, marginBottom: 50 }}>
                        {error != '' ?
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                <Alert severity="error">{error}</Alert>
                            </div>
                            : null}
                        {props.register == true ?
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Button onClick={() => Press(0)} style={{ borderRadius: 0, flexDirection: 'column', height: 36, width: 150, margin: 0, marginRight: 20, padding: 0, fontSize: global.font_small, color: global.theme_white, textTransform: 'none', fontWeight: 'normal', backgroundColor: global.theme_dark }}>
                                    {lang.back}
                                </Button>
                                <Button onClick={() => Check(0)} style={{ borderRadius: 0, flexDirection: 'column', height: 36, width: 150, margin: 0, padding: 0, fontSize: global.font_small, color: global.theme_white, textTransform: 'none', fontWeight: 'normal', backgroundColor: global.theme_dark_green }}>
                                    {isBusy == true ?
                                        <CircularProgress style={{ width: 20, height: 20, color: global.theme_white }}></CircularProgress>
                                        : lang.register_btn}
                                </Button>
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Button onClick={() => Press(0)} style={{ borderRadius: 0, flexDirection: 'column', height: 36, width: 150, margin: 0, marginRight: 20, padding: 0, fontSize: global.font_small, color: global.theme_white, textTransform: 'none', fontWeight: 'normal', backgroundColor: global.theme_dark }}>
                                    {lang.back}
                                </Button>
                                <Button onClick={() => Check(1)} style={{ borderRadius: 0, flexDirection: 'column', height: 36, width: 150, margin: 0, padding: 0, fontSize: global.font_small, color: global.theme_white, textTransform: 'none', fontWeight: 'normal', backgroundColor: global.theme_dark_green }}>
                                    {isBusy == true ?
                                        <CircularProgress style={{ width: 20, height: 20, color: global.theme_white }}></CircularProgress>
                                        : lang.save_changes}
                                </Button>
                            </div>
                        }

                    </div>
                    : null}

            </div>
            :
            props.typ == 1 ?
                /*
                ************************************************************************
                    ZOBRAZENIE UDAJOV
                ************************************************************************
                */
                <div style={{ marginBottom: 20 }}>
                    <p style={{ margin: 0, marginBottom: 10, fontSize: global.font_normal, fontWeight: 'bold' }}>{lang.facture_data}:</p>
                    <p style={{ margin: 1, fontSize: global.font_small }}>{user.fname}</p>
                    <p style={{ margin: 1, fontSize: global.font_small }}>{user.meno}</p>
                    <p style={{ margin: 1, fontSize: global.font_small }}>{user.fulica}</p>
                    <p style={{ margin: 1, fontSize: global.font_small }}>{user.fpsc} {user.fmesto}</p>
                    <p style={{ margin: 1, fontSize: global.font_small }}>{user.fstat}</p>
                    {props.user.trieda == 0 ?
                        <div>
                            <p style={{ margin: 1, fontSize: global.font_small, marginTop: 20 }}>{lang.ico}: {user.ico}</p>
                            <p style={{ margin: 1, fontSize: global.font_small }}>{lang.dic}: {user.dic}</p>
                            <p style={{ margin: 1, fontSize: global.font_small }}>{lang.ic_dph}: {user.icdph}</p>
                        </div>
                        : null}
                    <p style={{ margin: 1, fontSize: global.font_small, marginTop: 20 }}>{lang.email.toLowerCase()}: {user.email}</p>
                    <p style={{ margin: 1, fontSize: global.font_small }}>{lang.mobil.toLowerCase()}: {user.tel}</p>
                    {user.discount > 0 ?
                        <div style={{ marginTop: 20, paddingTop: 5, paddingBottom: 5, backgroundColor: global.theme_light_red }}>
                            <p style={{ margin: 1, fontSize: global.font_small }}>{lang.user_discount.toLowerCase()}: {user.discount}%</p>
                        </div>
                        : null}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                        <Button onClick={() => Press(1)} style={{ textTransform: 'none', color: global.theme_black, fontSize: global.font_tiny, backgroundColor: global.theme_lighter, width: 150, marginRight: 10 }} variant="text">{lang.edit}</Button>
                        <Button onClick={() => Press(3)} style={{ textTransform: 'none', color: global.theme_black, fontSize: global.font_tiny, backgroundColor: global.theme_lighter, width: 150 }} variant="text">{lang.password_change}</Button>
                    </div>
                    {user.dadresa == true ?
                        <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', borderRadius: 5, backgroundColor: global.theme_white, width: 320, marginTop: 40, paddingTop: 10, paddingBottom: 10 }} >
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', paddingLeft: 10 }}>
                                    <p style={{ margin: 0, marginBottom: 10, marginTop: 0, fontSize: global.font_normal, fontWeight: 'bold' }}>{lang.delivery_address}:</p>
                                </div>
                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => Press(1)} style={{ width: 20, height: 20, borderRadius: 0 }}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_blue }} icon={faEdit} />
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 10 }}>
                                < p style={{ margin: 1, fontSize: global.font_tiny }}>{lang.delivery_address_same}</p>
                            </div>
                        </Paper>
                        :
                        <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', borderRadius: 5, backgroundColor: global.theme_white, width: 320, marginTop: 40, paddingTop: 10, paddingBottom: 10 }} >
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', paddingLeft: 10 }}>
                                    <p style={{ margin: 0, marginBottom: 10, marginTop: 0, fontSize: global.font_normal, fontWeight: 'bold' }}>{lang.delivery_address}:</p>
                                </div>
                                <div style={{ width: 50 }}>
                                    <IconButton onClick={() => Press(1)} style={{ width: 20, height: 20, borderRadius: 0 }}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_blue }} icon={faEdit} />
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 10 }}>
                                < p style={{ margin: 1, fontSize: global.font_tiny }}>{user.dfirma}</p>
                                <p style={{ margin: 1, fontSize: global.font_tiny }}>{user.dmeno}</p>
                                <p style={{ margin: 1, fontSize: global.font_tiny }}>{user.dulica}</p>
                                <p style={{ margin: 1, fontSize: global.font_tiny }}>{user.dpsc} {user.dmesto}</p>
                                <p style={{ margin: 1, fontSize: global.font_tiny }}>{user.dstat}</p>
                            </div>
                        </Paper>
                    }
                </div>
                : props.typ == 2 ?
                    /*
                    ************************************************************************
                        ZMENA HESLA
                    ************************************************************************
                    */
                    <div>
                        <div style={{ width: '100%' }}>
                            <p style={{ textAlign: 'center' }}>{lang.password_new}</p>
                        </div>

                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <TextField type='password' style={{ marginTop: 10, backgroundColor: global.theme_white, width: 200 }} label={lang.password} variant="outlined" value={password} onInput={e => setPassword(e.target.value)} />
                        </div>
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            <TextField type='password' style={{ marginTop: 10, backgroundColor: global.theme_white, width: 200 }} label={lang.password_new_again} variant="outlined" value={password2} onInput={e => setPassword2(e.target.value)} />
                        </div>

                        {passwordErr != '' ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                                <Alert severity="error">{passwordErr}</Alert>
                            </div>
                            : null}
                        {passwordOK == true ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
                                <Alert severity='success'>{lang.password_ok}</Alert>
                            </div>
                            : null}
                        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', padding: 20 }}>
                            <Button onClick={() => SavePassword()} style={{ backgroundColor: global.theme_green, width: 200, height: 40 }} variant="contained">{lang.save}</Button>
                        </div>
                    </div>
                    : null
    )

};

export const BannerImage = (props) => {
    /*
        <BannerImage banner={banner} />
    */
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();

    var lang = sk;

    const Press = () => {
        if (props.banner.link != '') {
            navigate(props.banner.link);
        }
    }
    return (
        <div style={{ backgroundColor: global.theme_white, padding: 0, display: 'flex', flexDirection: 'row' }}>
            {isSmall == true ?
                <img onClick={() => Press()} src={global.images + 'banners/' + props.banner.image_small} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                :
                <img onClick={() => Press()} src={global.images + 'banners/' + props.banner.image} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
            }
        </div>
    )
}

export const BannerServices = (props) => {
    /*
        <BannerServices />
    */

    const [years, setYears] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const widthBox = 280;
    const leftMargin = 25;
    const iconSize = 60;

    const img_icon_1 = require('./react/app/icon_shop.png');
    const img_icon_2 = require('./react/app/icon_car.png');
    const img_icon_3 = require('./react/app/icon_packets.png');
    const img_icon_4 = require('./react/app/icon_flower.png');

    const navigate = useNavigate();

    var lang = sk;

    useEffect(() => {
        var today = new Date();
        var y = today.getFullYear() - 1994;
        setYears(y);

        return () => {
        };

    }, []);

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_white }}>
            <div style={{ ...isSmall || isMedium ? styles.Block : styles.BlockRow, justifyContent: 'center', alignItems: 'center', marginTop: 40, marginBottom: 40 }}>

                <div onClick={() => navigate('/predajna')} style={{ ...styles.BlockLeft, width: isSmall || isMedium ? '100%' : widthBox, borderLeft: isSmall || isMedium ? '0px' : '1px solid ' + global.theme_light_gray, borderRight: isSmall || isMedium ? '0px' : '1px solid ' + global.theme_light_gray, cursor: 'pointer' }}>
                    <div style={{ ...isSmall || isMedium ? styles.Block : styles.BlockLeft, marginLeft: isSmall || isMedium ? 0 : leftMargin }}>
                        <img src={img_icon_1} style={{ width: iconSize, height: iconSize }}></img>
                        <p style={{ ...styles.TextLarge, color: global.theme_dark_gray, marginTop: 10 }}>{lang.service1}</p>
                        <p style={{ ...styles.TextSmall, color: global.theme_medium_gray, marginTop: 5 }}>{lang.service1_text}</p>
                    </div>
                </div>

                <div onClick={() => navigate('/dorucenie')} style={{ ...styles.BlockLeft, width: isSmall || isMedium ? '100%' : widthBox, borderRight: isSmall || isMedium ? '0px' : '1px solid ' + global.theme_light_gray, cursor: 'pointer', marginTop: isSmall || isMedium ? 40 : 0 }}>
                    <div style={{ ...isSmall || isMedium ? styles.Block : styles.BlockLeft, marginLeft: isSmall || isMedium ? 0 : leftMargin }}>
                        <img src={img_icon_2} style={{ width: iconSize, height: iconSize }}></img>
                        <p style={{ ...styles.TextLarge, color: global.theme_dark_gray, marginTop: 10 }}>{lang.service2}</p>
                        <p style={{ ...styles.TextSmall, color: global.theme_medium_gray, marginTop: 5 }}>{lang.service2_text}</p>
                    </div>
                </div>

                <div onClick={() => navigate('/sklad')} style={{ ...styles.BlockLeft, width: isSmall || isMedium ? '100%' : widthBox, borderRight: isSmall || isMedium ? '0px' : '1px solid ' + global.theme_light_gray, cursor: 'pointer', marginTop: isSmall || isMedium ? 40 : 0 }}>
                    <div style={{ ...isSmall || isMedium ? styles.Block : styles.BlockLeft, marginLeft: isSmall || isMedium ? 0 : leftMargin }}>
                        <img src={img_icon_3} style={{ width: iconSize, height: iconSize }}></img>
                        <p style={{ ...styles.TextLarge, color: global.theme_dark_gray, marginTop: 10 }}>{lang.service3}</p>
                        <p style={{ ...styles.TextSmall, color: global.theme_medium_gray, marginTop: 5 }}>{lang.service3_text}</p>
                    </div>
                </div>

                <div onClick={() => navigate('/o-nas')} style={{ ...styles.BlockLeft, width: isSmall || isMedium ? '100%' : widthBox, borderRight: isSmall || isMedium ? '0px' : '1px solid ' + global.theme_light_gray, cursor: 'pointer', marginTop: isSmall || isMedium ? 40 : 0 }}>
                    <div style={{ ...isSmall || isMedium ? styles.Block : styles.BlockLeft, marginLeft: isSmall || isMedium ? 0 : leftMargin }}>
                        <img src={img_icon_4} style={{ width: iconSize, height: iconSize }}></img>
                        <p style={{ ...styles.TextLarge, color: global.theme_dark_gray, marginTop: 10 }}>{'Sme tu už ' + years + ' rokov'}</p>
                        <p style={{ ...styles.TextSmall, color: global.theme_medium_gray, marginTop: 5 }}>{lang.service4_text}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const BannerReserveDate = (props) => {
    /*
        <BannerReserveDate reserve1={reserve1} reserve2={reserve2} />
    */
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const widthBox = 280;
    const leftMargin = 25;
    const iconSize = 60;

    const back = require('./react/app/reserve_date.jpg');
    const img_text = require('./react/app/reserve.png');

    const navigate = useNavigate();

    var lang = sk;

    return (
        isSmall ?
            <div style={{ ...styles.Block, paddingTop: 40, paddingBottom: 40 }}>
                <div style={{ width: '95%' }}>
                    <p style={{ ...styles.TextXLarge, textAlign: 'center', marginTop: 20, color: global.theme_black }}>{'Objednajte sa k nám'}</p>
                    <p style={{ ...styles.TextNormal, textAlign: 'center', marginTop: 20, color: global.theme_black }}>{props.reserve1}</p>
                    <p style={{ ...styles.TextNormal, textAlign: 'center', marginTop: 10, color: global.theme_black }}>{props.reserve2}</p>
                    <Button onClick={() => { navigate('/reserve') }} variant="contained" style={{ ...styles.ButtonGreen, marginTop: 20 }}>{lang.reserve_termin}</Button>
                </div>
            </div >
            :
            <div style={{ ...styles.Block, height: 'auto', position: 'relative' }}>
                {isSmall ? null :
                    <img src={back} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                }
                <div style={{ ...styles.BlockRow, justifyContent: 'center', alignItems: 'center', position: 'absolute', width: '100%' }}>

                    <div style={{ ...styles.BlockLeft, width: '10%' }}></div>

                    <div style={{ ...styles.BlockLeft, width: '60%' }}>
                        <img src={img_text} style={{ width: '35%', height: '35%', minHeight: '35%' }} />
                        <p style={{ ...styles.TextLarge, textAlign: 'left', marginTop: 20, color: global.theme_black }}>{props.reserve1}</p>
                        <p style={{ ...styles.TextLarge, textAlign: 'left', marginTop: 10, color: global.theme_black }}>{props.reserve2}</p>

                        <div style={{ ...styles.BlockRight, marginTop: 30 }}>
                            {isLarge == true ?
                                <Button onClick={() => { navigate('/reserve') }} variant="contained" style={{ ...styles.ButtonGreen }}>{lang.reserve_termin}</Button>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '30%' }}></div>

                </div>
            </div >

    )
}


export const BannerSlider = (props) => {
    /*
            <BannerSlider />
            */

    //BANNER: 2560 x 800 px
    //BANNER SMALL: 850 x 500 px

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const sliderSettings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 750,
    }

    const Slide = (props) => {
        const navigate = useNavigate();

        const BannerClick = (link) => {
            // kliknutie na banner
            if (link.trim() != '') {
                navigate(link);
            }
        }
        return (
            <div key={props.item.id} style={{ width: '100%', height: 'auto', position: 'relative' }}>
                {isSmall == true ?
                    <a onClick={() => BannerClick(props.item.link)} style={{ width: '100%', height: 'auto', cursor: props.item.link != '' ? 'pointer' : 'default' }}>
                        <img src={global.images + '/banners/' + props.item.image_small} loading="lazy" style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                    </a>
                    :
                    <a onClick={() => BannerClick(props.item.link)} style={{ width: '100%', height: 'auto', cursor: props.item.link != '' ? 'pointer' : 'default' }}>
                        <img src={global.images + '/banners/' + props.item.image} loading="lazy" style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        {props.item.text_position == 0 ?
                            // LEFT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: 0, top: 0, width: '40%', textAlign: 'left', paddingLeft: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 1 ?
                            // CENTER ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: '25%', top: 0, width: '40%', textAlign: 'center', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 2 ?
                            // RIGHT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', right: 0, top: 0, width: '40%', textAlign: 'right', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                    </a>
                }
            </div>)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 40 }}>
            {isSmall ?
                <Slider  {...sliderSettings}>
                    {props.banners.map(item => (
                        item.image_small == null ? null :
                            <Slide key={item.id} item={item} />
                    ))}
                </Slider>
                :
                <Slider  {...sliderSettings}>
                    {props.banners.map(item => (
                        item.image == null ? null :
                            <Slide key={item.id} item={item} />
                    ))}
                </Slider>
            }
        </div>
    )
}

export const ProductBox = (props) => {
    /*
            <Product typ={0} item={item} func={ProductPress.bind(this)} />

        const ProductPress = (item) => {
                SelectProduct(item.id)
            }
            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [over, setOver] = useState(false);

    const boxWidth = 260;
    const boxHeight = 470;

    const slideWidth = 225
    const slideHeight = 240
    let { func } = props;

    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (type) => {
        func(props.item, type);
    }

    const StyledBadge = styled(Badge)({
        "& .MuiBadge-badge": {
            color: global.theme_white,
            backgroundColor: global.theme_product_new,
            top: 20,
            right: 60,
            width: 100,
            height: 20
        }
    });


    return (
        props.typ == 0 ?
            // GRID ZOBRAZENIE            
            <div style={{ width: boxWidth, height: boxHeight, marginRight: 5, marginLeft: 5, marginBottom: 10, textTransform: 'none' }} onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
                <div style={{ width: boxWidth, height: boxHeight, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ position: 'relative', width: boxWidth, height: boxWidth, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>
                        <div style={{ ...styles.BlockLeft, width: undefined, position: 'absolute' }}>

                            {/* VYHODNY NAKUP */}
                            {props.item.action == true ?
                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.theme_blue, borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.theme_white }}>{'Výhodný nákup'}</p>
                                </div>
                                : null}

                            {/* NOVINKA */}
                            {props.item.new == true ?
                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.theme_green, borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.theme_white }}>{lang.product_new}</p>
                                </div>
                                : null}

                            {/* FLAG-TEXT */}
                            {props.item.flag_text != '' ?
                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.theme_dark_red, borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.theme_white }}>{props.item.flag_text}</p>
                                </div>
                                : null}

                            {/* ZNAČKY */}
                            {props.item.flag > 0 ?
                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.flags_color[props.item.flag], borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.flags_text_color[props.item.flag] }}>{global.flags[props.item.flag]}</p>
                                </div>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, height: boxWidth }}>
                            <img onClick={() => Press(0)} src={global.product_image + props.item.path + '/th_' + props.item.image} style={{ maxWidth: boxWidth - 20, maxHeight: boxWidth - 20, cursor: 'pointer' }} />
                        </div>
                    </div>
                    <div style={{ width: boxWidth, height: boxHeight - boxWidth, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', borderLeft: '1px solid ' + global.theme_light_gray, borderRight: '1px solid ' + global.theme_light_gray, borderBottom: '1px solid ' + global.theme_light_gray, backgroundColor: global.theme_back }}>
                        <div style={{ padding: 10, width: boxWidth - 20, height: '100%' }}>
                            <div style={{ height: '35%' }}>
                                <p style={{ textAlign: 'left', margin: 0, marginLeft: 0, fontSize: global.font_products_grid, color: over == true ? global.theme_box_label_over : global.theme_box_label, fontWeight: 'bold', lineHeight: 1.2 }}>{props.item.title}</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: '25%' }}>
                                <div style={{ ...styles.BlockRow, width: '50%' }}>
                                    {props.item.count > 0 ?
                                        <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_dark_green }} icon={faCheck} />
                                        :
                                        <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_dark_red }} icon={faXmark} />
                                    }
                                    <p style={{ textAlign: 'left', margin: 0, marginLeft: 0, fontSize: global.font_tiny, color: props.item.count > 0 ? global.theme_dark_green : global.theme_dark_red, fontWeight: 'normal', lineHeight: 1.2 }}>{props.item.count > 0 ? lang.stock : lang.no_stock}</p>
                                </div>
                                <div style={{ ...styles.BlockRight, justifyContent: 'flex-start', width: '50%' }}>
                                </div>
                            </div>
                            <div style={{ ...styles.BlockLeft, height: '20%' }}>
                                <div style={{ textAlign: 'left', width: '50%', paddingTop: 2 }}>
                                    <p style={{ margin: 0, marginLeft: 0, fontSize: global.font_tiny, color: global.theme_light, fontWeight: 'normal', lineHeight: 0.8 }}>{props.item.more_prices == true ? lang.price_starts : lang.price}:</p>
                                    <p style={{ margin: 0, marginLeft: 0, fontSize: global.font_prices, color: global.theme_dark, fontWeight: 'bold' }}>{props.price} {lang.money}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '20%' }}>
                                <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                    <IconButton onClick={() => Press(2)} style={{ width: 30, height: 30, borderRadius: '50%', marginTop: 5 }}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16, color: props.item.favorite == true ? global.theme_favorite : over == true ? global.theme_lighter : global.theme_light_gray }} icon={faHeart} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '50%' }}>
                                    <Button onClick={() => Press(1)} variant='contained' style={{ margin: 0, width: '100%', height: 34, color: global.theme_white, backgroundColor: global.theme_button_buy, fontWeight: 'normal', textTransform: 'none' }}>{lang.buy_button}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            :
            props.typ == 1 ?
                // RIADKOVE ZOBRAZENIE
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 100, marginBottom: 10, border: '1px solid ' + global.theme_light_gray, backgroundColor: global.theme_white }}>
                    <div style={{ ...styles.BlockLeft, width: 40, height: 100 }}>
                        <IconButton onClick={() => Press(2)} style={{ width: 30, height: 30, borderRadius: '50%', marginTop: 5 }}>
                            <FontAwesomeIcon style={{ width: 16, height: 16, color: props.item.favorite == true ? global.theme_favorite : over == true ? global.theme_lighter : global.theme_light_gray }} icon={faHeart} />
                        </IconButton>
                    </div>
                    <div style={{ width: 100, height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ ...styles.Block, width: 100, height: 100 }}>
                            <img onClick={() => Press(0)} src={global.product_image + props.item.path + '/th_' + props.item.image} style={{ maxWidth: 90, maxHeight: 90, cursor: 'pointer' }} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, alignItems: 'center' }}>

                        <div style={{ ...styles.BlockLeft, width: '45%' }}>
                            {props.item.new == true ?
                                <div style={{ ...styles.Block, width: 120, height: 26, borderRadius: 13, backgroundColor: global.theme_dark_green, marginBottom: 10 }}>
                                    <p style={{ ...styles.TextXXTiny, color: global.theme_white }}>{lang.product_new}</p>
                                </div>
                                :
                                null}

                            <p style={{ ...styles.TextNormal }}>{props.item.title.trim()}</p>
                        </div>

                        <div style={{ ...styles.BlockRow, width: '20%' }}>
                            {props.item.count > 0 ?
                                <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_dark_green }} icon={faCheck} />
                                :
                                <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_dark_red }} icon={faXmark} />
                            }
                            <p style={{ textAlign: 'left', margin: 0, marginLeft: 0, fontSize: global.font_tiny, color: props.item.count > 0 ? global.theme_dark_green : global.theme_dark_red, fontWeight: 'normal', lineHeight: 1.2 }}>{props.item.count > 0 ? lang.stock : lang.no_stock}</p>
                        </div>

                        <div style={{ ...styles.BlockLeft, width: 150 }}>
                            <div style={{ textAlign: 'left', paddingTop: 2 }}>
                                <p style={{ margin: 0, marginLeft: 0, fontSize: global.font_tiny, color: global.theme_light, fontWeight: 'normal', lineHeight: 0.8 }}>{props.item.more_prices == true ? lang.price_starts : lang.price}:</p>
                                <p style={{ margin: 0, marginLeft: 0, fontSize: global.font_prices, color: global.theme_dark, fontWeight: 'bold', marginTop: 10 }}>{props.price} {lang.money}</p>
                            </div>
                        </div>

                        <div style={{ ...styles.BlockRight, width: 150 }}>
                            <Button onClick={() => Press(1)} variant='contained' style={{ margin: 0, width: '100%', height: 34, color: global.theme_white, backgroundColor: global.theme_button_buy, fontWeight: 'normal', textTransform: 'none' }}>{lang.buy_button}</Button>
                        </div>

                    </div>
                </div>
                :
                props.typ == 2 ?
                    // PRODUKTY V SLIDERS
                    <div style={{ width: slideWidth, height: 340, marginRight: 5, marginLeft: 5, marginBottom: 10, textTransform: 'none' }}>
                        <div style={{ width: slideWidth, height: 340, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: slideWidth, height: 220, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_white, border: '1px solid ' + global.theme_light_gray }}>
                                <img onClick={() => Press(0)} src={global.product_image + props.item.path + '/th_' + props.item.image} style={{ maxWidth: slideWidth - 30, maxHeight: 190, cursor: 'pointer' }} />
                            </div>
                            <div style={{ width: slideWidth, height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', borderLeft: '1px solid ' + global.theme_light_gray, borderRight: '1px solid ' + global.theme_light_gray, borderBottom: '1px solid ' + global.theme_light_gray, backgroundColor: global.theme_back }}>
                                <div style={{ padding: 10, width: slideWidth - 20 }}>
                                    <div style={{ height: 45 }}>
                                        <p style={{ textAlign: 'left', margin: 0, marginLeft: 0, fontSize: global.font_products_grid, color: global.theme_dark, fontWeight: 'normal', lineHeight: 1.2 }}>{props.item.title.trim()}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <div style={{ textAlign: 'left', width: '45%', paddingTop: 2 }}>
                                            <p style={{ margin: 0, marginLeft: 0, fontSize: global.font_xxtiny, color: global.theme_light, fontWeight: 'normal', lineHeight: 0.8 }}>{props.item.more_prices == true ? lang.price_starts : lang.price}:</p>
                                            <p style={{ margin: 0, marginLeft: 0, fontSize: global.font_small, color: global.theme_dark, fontWeight: 'bold' }}>{props.price} {lang.money}</p>
                                        </div>

                                        <div style={{ textAlign: 'right', width: '55%' }}>
                                            <Button onClick={() => Press(1)} variant='contained' style={{ margin: 0, width: 110, height: 34, color: global.theme_white, backgroundColor: global.theme_button_buy, fontWeight: 'normal', textTransform: 'none' }}>{lang.buy_button}</Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
    )
}

export const Footer = (props) => {
    /*
            <Footer language={language} />
            */
    const [isBusy, setBusy] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [emailSended, setEmailSended] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = sk;

    const navigate = useNavigate();

    const logo = require('./react/app/logo_white.png');
    const facebook = require('./react/app/facebook.png');
    const instagram = require('./react/app/instagram.png');
    const youtube = require('./react/app/youtube.png');

    const iconSize = 30;

    const db_newsletter = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'newsletter_register', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                if (json.error == false) {
                    setEmailSended(true);
                } else {
                    setEmailErr(lang.newsletter_email_error);
                }
            } else {
                setEmailErr(lang.db_error);
            }

        } catch (error) {
            setBusy(false);
            Debug(error);
        }
    }

    var now = new Date();
    var year = now.getFullYear();

    const NewsletterRegister = () => {
        setEmailErr('');
        if (ValidateEmail(email.trim()) == true) {
            db_newsletter();
        } else {
            setEmailErr(lang.email_error);
        }
    }

    const Goto = (link) => {

    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', paddingTop: 30, backgroundColor: global.theme_footer }}>

            <div style={{ display: 'flex', flexDirection: isSmall || isMedium ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>

                {/* LAVA STRANA */}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: isSmall || isMedium ? '95%' : '66%', paddingLeft: isSmall || isMedium ? 0 : global.menu_padding, paddingRight: isSmall || isMedium ? 0 : global.menu_padding }}>
                    <div style={{ display: 'flex', flexDirection: isSmall || isMedium ? 'column' : 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: isSmall || isMedium ? '100%' : '50%', padding: isSmall || isMedium ? 0 : 10 }}>
                            <img src={logo} height={60} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: isSmall || isMedium ? '100%' : '50%', padding: isSmall || isMedium ? 0 : 10, marginTop: isSmall || isMedium ? 30 : 0 }}>
                            <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_small, textAlign: 'left', fontWeight: 'bold' }}>{lang.contact_us}:</p>
                            <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_tiny, textAlign: 'left', fontWeight: 'normal', marginTop: 5 }}>
                                <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_footer_text, marginRight: 10 }} icon={faPhone} />
                                {props.web.mobil}
                            </p>
                            <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_tiny, textAlign: 'left', fontWeight: 'normal' }}>
                                <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_footer_text, marginTop: 5, marginRight: 10 }} icon={faEnvelope} />
                                {props.web.email}
                            </p>
                        </div>
                    </div>
                    {isSmall || isMedium ?
                        // SMALl
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 40 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', padding: 0 }}>
                                <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_small, textAlign: 'left', fontWeight: 'bold' }}>{props.subcompanies[0].label}</p>
                                <div style={{}}>
                                    <p style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{props.subcompanies[0].note}</p>
                                </div>
                                <p style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{lang.hours}: ({props.subcompanies[0].label})</p>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[1]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_1}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[2]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_2}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[3]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_3}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[4]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_4}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[5]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_5}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[6]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_6}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[0]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_7}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', padding: 0, marginTop: 40 }}>
                                <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_small, textAlign: 'left', fontWeight: 'bold' }}>{props.subcompanies[1].label}</p>
                                <div style={{}}>
                                    <p style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{props.subcompanies[1].note}</p>
                                </div>
                                <p style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{lang.hours}: ({props.subcompanies[1].label})</p>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[1]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_1}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[2]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_2}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[3]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_3}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[4]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_4}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[5]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_5}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[6]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_6}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[0]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_7}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        // LARGE
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%', padding: isSmall || isMedium ? 0 : 10 }}>
                                <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_small, textAlign: 'left', fontWeight: 'bold' }}>{props.subcompanies[0].label}</p>
                                <div style={{}}>
                                    <p style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{props.subcompanies[0].note}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%', padding: 10 }}>
                                <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_small, textAlign: 'left', fontWeight: 'bold' }}>{props.subcompanies[1].label}</p>
                                <div style={{}}>
                                    <p style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{props.subcompanies[1].note}</p>
                                </div>
                            </div>
                        </div>
                    }
                    {isSmall || isMedium ?
                        // SMALL
                        null
                        :
                        // LARGE
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%', padding: isSmall || isMedium ? 0 : 10 }}>
                                <p style={{ margin: 0, marginTop: 0, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{lang.hours}: ({props.subcompanies[0].label})</p>

                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[1]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_1}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[2]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_2}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[3]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_3}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[4]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_4}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[5]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_5}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[6]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_6}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[0]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[0].hours_7}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%', padding: isSmall || isMedium ? 0 : 10 }}>
                                <p style={{ margin: 0, marginTop: 0, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>{lang.hours}: ({props.subcompanies[1].label})</p>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[1]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_1}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[2]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_2}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[3]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_3}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[4]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_4}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[5]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_5}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[6]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_6}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{lang.days[0]}:</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: 230 }}>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_footer_text }}>{props.subcompanies[1].hours_7}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {/* PRAVA STRANA */}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: isSmall || isMedium ? '95%' : '34%', paddingLeft: isSmall || isMedium ? 0 : global.menu_padding, paddingRight: isSmall || isMedium ? 0 : global.menu_padding, marginTop: isSmall || isMedium ? 40 : 0 }}>
                    {/* NEWSLETTER */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal' }}>{lang.newsletter_receive}</p>
                        {emailSended == false ?
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <TextField size='small' error={emailErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' placeholder={lang.email_yours} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                                    {emailErr != '' ?
                                        <p style={{ margin: 0, marginTop: 2, fontSize: global.font_tiny, textAlign: 'left', color: global.theme_red }}>{emailErr}</p>
                                        : null}
                                </div>
                                <Button fontSize={global.font_small} style={{ textTransform: 'none', color: global.theme_white, backgroundColor: global.theme_dark_gray, height: 41, marginTop: 4, width: 120, borderRadius: 0, borderTopRightRadius: global.radius, borderBottomRightRadius: global.radius }} size={'small'} onClick={() => NewsletterRegister()} >
                                    <FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_white, marginRight: 5 }} icon={faPaperPlane} />
                                    {lang.subscribe}
                                </Button>
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <p style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_tiny, textAlign: 'left' }}>
                                    {props.newsletterSended}
                                </p>
                            </div>
                        }
                    </div>
                    {/* INFORMAČNÉ LINKY */}
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: isLarge ? 20 : 40 }}>
                        <p style={{ margin: 0, color: global.theme_footer_text_high, fontSize: global.font_small, textAlign: 'left', fontWeight: 'bold' }}>{lang.info}:</p>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Link style={{ margin: 0, marginTop: 10, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/gdpr'}>{lang.gdpr}</Link>
                            <Link style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/vop'}>{lang.sell_docs}</Link>
                            <Link style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/reklamacny-poriadok'}>{lang.reklamacny_poriadok}</Link>
                            <div onClick={() => setShowVideo(true)} style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none', cursor: 'pointer' }} >{lang.reklamacny_poriadok_video}</div>
                            <Link style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/'}>{lang.delivery_payment}</Link>
                            <Link style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/cookies'}>{lang.cookies_rules}</Link>
                            <Link style={{ margin: 0, marginTop: 20, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/contact'}>{lang.contact}</Link>
                            <Link style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/rozvoz'}>{lang.trades}</Link>
                            <Link style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/catalog/akcia/akcie-a-zlavy'}>{props.actionLabel}</Link>
                            {/*
                            <Link style={{ margin: 0, marginTop: 5, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/blog'}>{props.blogLabel}</Link>
                            */}
                            <Link style={{ margin: 0, marginTop: 8, color: global.theme_footer_text, fontSize: global.font_small, textAlign: 'left', fontWeight: 'normal', textDecoration: 'none' }} to={'/downloads'}>{props.catalogueLabel}</Link>
                        </div>
                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <a href={props.web.facebook} target="_blank" style={{ width: iconSize, height: iconSize, borderRadius: '50%', marginRight: 30 }}>
                                <img src={facebook} style={{ width: iconSize, height: iconSize }} />
                            </a>
                            <a href={props.web.instagram} target="_blank" style={{ width: iconSize, height: iconSize, borderRadius: '50%', marginRight: 30 }}>
                                <img src={instagram} style={{ width: iconSize, height: iconSize }} />
                            </a>
                            <a href={props.web.youtube} target="_blank" style={{ width: iconSize, height: iconSize, borderRadius: '50%', marginRight: 0 }}>
                                <img src={youtube} style={{ width: iconSize, height: iconSize }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* BOTTOM */}
            <div style={{ width: '100%', marginTop: 20, backgroundColor: global.theme_black }}>
                <div style={{ width: '100%', marginTop: 20, paddingTop: 20, paddingBottom: 50 }}>
                    <a href='https://datadream.sk' style={{ margin: 0, color: global.theme_medium_gray, fontSize: global.font_tiny, textDecoration: 'none' }}>© {lang.copyright} {year} - {global.creator}</a>
                </div>
            </div>

            {showVideo == true ?
                <DialogVideo file={global.reklamacie_video} radius={props.radius} lang={props.lang} title={lang.reklamacny_poriadok_video} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={() => setShowVideo(false)} />
                : null}
        </div>
    );
}


export const UniqueID = () => {
    let s4 = () => { return Math.floor((1 + Math.random() * 0x10000)).toString(16).substring(1); }
    return (Date.now() + '-' + s4() + s4() + s4() + '-' + s4() + s4() + s4());
}

export const ShoppingAdd = (item) => {
    // pridanie produktu do košíka
    var status = LoadLogin();
    if (status.logged == true) {

    } else {
        var data = localStorage.getItem('shopping_card');
        if (data == null) {
            data = [];
        } else {
            data = JSON.parse(data);
        }
        var tmp = data.filter(x => x.id == item.id);
        if (tmp.length > 0) {
            // produkt sa uz nachádza v kosiku
            ShoppingChangeQuantity(item.id, parseInt(tmp[0].quantity) + parseInt(item.quantity));
        } else {
            data.push(item);
            localStorage.setItem('shopping_card', JSON.stringify(data));
        }
    }
}

export const ShoppingList = (item) => {
    // zoznam produktov v košíku
    // RAW DATA - musí sa volať DB
    var data = localStorage.getItem('shopping_card');
    if (data != null) {
        data = JSON.parse(data);
    }

    return data;
}

export const ShoppingDeleteAll = () => {
    // vymazať obsah košíka
    localStorage.removeItem('shopping_card');
}

export const ShoppingDeleteID = (id) => {
    // vymazanie polozky z košíka
    // RAW DATA - musí sa volať DB
    var data = localStorage.getItem('shopping_card');
    if (data != null) {
        data = JSON.parse(data);
        data = data.filter(x => x.id != id);
        localStorage.setItem('shopping_card', JSON.stringify(data));
    }

    return data;
}

export const ShoppingChangeQuantity = (id, quantity) => {
    // zmena poctu kusov v danom zázname
    var data = localStorage.getItem('shopping_card');
    if (data != null) {
        data = JSON.parse(data);
        data.forEach(item => {
            if (item.id == id) {
                item.quantity = quantity;
            }
        });
        localStorage.setItem('shopping_card', JSON.stringify(data));
    }
    return data;
}

export const ShoppingQuantity = () => {
    // vráti počet kusov v košíku
    var quantity = 0;
    /*
    var data = localStorage.getItem('shopping_card');
    if (data != null) {
        data = JSON.parse(data);
        data.forEach(item => {
            quantity += item.quantity

        });
    }
    */
    return quantity;
}


export const GetPriceRound = (price_) => {
    // VRATI zaokrúhlenú cenu
    var tmp = parseFloat(price_).toFixed(2);
    return tmp;
}

export const GetPrice = (price_) => {
    // VRATI CENU NAFORMATOVANU 0,00
    var tmp = GetPriceRound(price_).toString();;
    return tmp.replace('.', ',');
}

export const GetCost = (typ, cost, coeficient) => {
    // VYPOCET CENY podla toho ci je zákazník B2B
    if (typ == 0) {
        // B2B
        return cost;
    }
    if (typ == 1) {
        // KONCOVY ZAKAZNIK
        return (cost * coeficient);
    }
}

export function GetDiscount(price) {
    var sum = price;
    var percent = 0;

    if (price > 50 && price <= 350) {
        percent = 2;
        sum = price * (1.00 - (percent / 100));
    }
    if (price > 350 && price <= 550) {
        percent = 5;
        sum = price * (1.00 - (percent / 100));
    }
    if (price > 550 && price <= 670) {
        percent = 6;
        sum = price * (1.00 - (percent / 100));
    }
    if (price > 670) {
        percent = 7;
        sum = price * (1.00 - (percent / 100));
    }

    return [sum, percent];
}

export function GetDiscountPayment(price) {
    var sum = price;
    var percent = 0;

    if (price > 50 && price <= 250) {
        percent = 3;
        sum = price * (1.00 - (percent / 100));
    }
    if (price > 250 && price <= 350) {
        percent = 4;
        sum = price * (1.00 - (percent / 100));
    }
    if (price > 350 && price <= 550) {
        percent = 2;
        sum = price * (1.00 - (percent / 100));
    }
    if (price > 550 && price <= 670) {
        percent = 2;
        sum = price * (1.00 - (percent / 100));
    }
    if (price > 670) {
        percent = 2;
        sum = price * (1.00 - (percent / 100));
    }

    return [sum, percent];
}

export const GetSume = (list, dph, b2b, isDPH, coeficient, discount) => {
    // suma košíka bez DPH - list
    // dph - true = vrati cenu s dph
    // b2b - jedná sa o b2b zákazníka
    // isDPH - je platcom DPH?

    var sum = 0.00;
    if (list.length > 0) {
        list.forEach(item => {
            var cost = parseFloat(item.cost) * parseFloat(item.quantity);

            if (dph == true) {
                // priradenie DPH
                if (isDPH == true) {
                    cost = parseFloat(item.quantity) * item.cost_item_dph;
                } else {
                    cost = parseFloat(item.quantity) * item.cost_dph;
                }
            }

            if (b2b == false) {
                cost = cost * coeficient;
            }
            cost = parseFloat(cost.toFixed(2));
            sum = sum + cost;
        });
    }

    if (b2b == true && discount == 0) {
        // zlava s celkovej sumy
        sum = GetDiscount(sum);
    } else if (b2b == true && discount > 0 && sum > 50) {
        sum = sum * (1.00 - (discount / 100));
    }

    return parseFloat(sum);
}

export const ProductBuy = (props) => {
    /*
            USAGE:
            <ProductBuy logged={logged} b2b={b2b} idDph={isDPH} coeficient={coeficient} item={item} func={ProductBuyPress.bind(this)} />

            const ProductBuyPress = (value) => {
            }


            */

    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [items, setItems] = useState([]);
    const [variation, setVariation] = useState(null);
    const [status, setStatus] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 500;
    const boxHeight = 300;
    const labelHeight = 70;

    const navigate = useNavigate();

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        db_product();
        return () => {
        };

    }, []);

    const db_product = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_variation', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                //setItems(json.items);

                var tmp = json.items;

                if (tmp.length > 1) {
                    tmp.sort((a, b) => {
                        if (a.description > b.description) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (json.items.length == 1) {
                    setVariation(json.items[0]);
                }
                setItems(tmp);
            }

            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const db_shopping_card_add = async (product_id, item_id, quantity) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'shopping_card_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    product_id: product_id,
                    item_id: item_id,
                    quantity: quantity
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setStatus(1);
                const timer = setTimeout(() => {
                    Press(true, quantity);
                }, 1000);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const Press = (type, quantity) => {
        func(type, quantity);
    }

    const ChangeItem = (event) => {
        var value = event.target.value;
        var tmp = items.filter(x => x.id == value);
        if (tmp.length > 0) {
            setVariation(tmp[0]);
        }
        setIndex(value);
    };

    const PlusMinusPress = (value, id) => {
        setQuantity(value);
    }

    const GotoProduct = () => {
        navigate('/product/' + props.item.tid);
    }

    const Buy = () => {
        if (variation != null) {
            // KUPIT PRODUCT
            if (props.logged == true) {
                db_shopping_card_add(variation.product_id, variation.id, quantity);
            } else {
                var data = {
                    id: parseInt(variation.id),
                    item_id: parseInt(variation.id),
                    product_id: parseInt(variation.product_id),
                    quantity: parseInt(quantity),
                };
                ShoppingAdd(data);
                setStatus(1);
                const timer = setTimeout(() => {
                    Press(true, quantity);
                }, 1000);
            }
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(false)}>
            {status == 0 ?
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', minWidth: 260, width: isSmall ? '100%' : boxWidth, height: isSmall ? undefined : boxHeight }}>
                    {/* HLAVICKA - NAZOV */}
                    <div style={{ ...styles.BlockLeft, flexDirection: 'row', width: '100%', height: labelHeight, backgroundColor: global.theme_gray }}>
                        <div style={{ ...styles.BlockLeft, width: '100%', height: labelHeight, justifyContent: 'center', alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextNormal, color: global.theme_black, marginLeft: 10 }}>{props.item.title}</p>
                        </div>
                        <div style={{ ...styles.BlockRight, width: 50, height: labelHeight, justifyContent: 'center', alignItems: 'flex-end' }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10, backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>

                    {/* VARIACIE */}
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow, width: '100%', height: '100%', backgroundColor: global.theme_white, paddingTop: isSmall ? 10 : 0, paddingBottom: isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.Block, width: 140, height: '100%' }}>
                            <img src={global.product_image + props.item.id + '/th_' + props.item.image} style={{ maxWidth: 100, maxHeight: 100 }}></img>
                        </div>
                        <div style={{ ...styles.Block, width: '100%', height: '100%' }}>
                            {items.length > 1 ?
                                <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : 300 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: 'bold', color: global.theme_black, marginBottom: 5 }}>{lang.choose_variant}</p>

                                    <Select
                                        style={{ textAlign: 'left', color: global.theme_black, width: isSmall ? '90%' : '100%' }}
                                        value={index}
                                        onChange={ChangeItem}
                                        size={'small'}
                                    >
                                        {
                                            items.map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny }}>{item.description}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRight }}>
                                                                <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny }}>({GetPrice(GetCost(props.b2b == true ? 0 : 1, props.b2b == true ? item.cost : item.cost_dph, props.coeficient))} {lang.money} {props.b2b == true ? lang.dph_false : ''})</p>
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                )
                                            })}

                                    </Select>
                                </div>
                                :
                                isBusy == true ?
                                    <CircularProgress color="inherit" size={20} />
                                    :

                                    items.length == 1 ?
                                        // LEN JEDNA VARIACIA - CENA
                                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : 300 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.price}: {GetPrice(GetCost(props.b2b == true ? 0 : 1, props.b2b == true ? items[0].cost : items[0].cost_dph, props.coeficient))} {lang.money} {props.b2b == true ? lang.dph_false : ''}</p>
                                        </div>
                                        : null
                            }
                            <div style={{ ...styles.BlockRow, width: isSmall ? '95%' : 300, marginTop: 20 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: 'bold', color: global.theme_black, marginTop: 8 }}>{lang.quantity}</p>
                                <div style={{ ...styles.BlockRight }}>
                                    <PlusMinus quantity={quantity} id={props.item.id} func={PlusMinusPress.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* DETAIL, VLOZIT DO KOSIKA */}
                    <div style={{ ...styles.Block, flexDirection: isSmall ? 'column' : 'row', marginTop: 20, marginBottom: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%' }}>
                            <Button onClick={() => GotoProduct()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black }} variant="outlined">{lang.product_details}</Button>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <Button onClick={() => Buy()} variant="contained" style={{ ...variation == null ? styles.ButtonDisabled : styles.ButtonDark, width: 200 }}>{lang.add_to_card}</Button>
                        </div>
                    </div>
                </div>
                :

                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', minWidth: 260, width: isSmall ? '100%' : boxWidth, height: isSmall ? undefined : boxHeight, paddingTop: isSmall ? 10 : 0, paddingBottom: isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                        <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                    </div>
                    <p style={{ ...isSmall ? styles.TextLarge : styles.Label, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.added_to_card}</p>
                </div>

            }
        </Dialog >
    );
}

export const ProductAsk = (props) => {
    /*
            USAGE:
            <ProductAsk user={} item={item} func={ProductAskPress.bind(this)} />

            const ProductAskPress = (value) => {
            }


            */

    const [isBusy, setBusy] = useState(false);
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [text, setText] = useState('');
    const [textErr, setTextErr] = useState('');
    const [error, setError] = useState('');
    const [status, setStatus] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 500;
    const boxHeight = 670;
    const labelHeight = 70;
    const footerHeight = 90;
    const productHeight = 120;
    const bodyHeight = boxHeight - labelHeight - footerHeight - productHeight;

    const navigate = useNavigate();

    let { func } = props;
    var lang = sk;

    useEffect(() => {
        if (props.user != null) {
            setName(props.user.meno);
            setEmail(props.user.email);
            setMobil(props.user.tel);
        }

        return () => {
        };

    }, []);

    const db_ask = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_ask', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    item: props.item,
                    name: name,
                    mobil: mobil,
                    email: email,
                    text: text
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setStatus(1);
            } else {
                setError(lang.db_error);
            }

            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const Press = (type) => {
        func(type);
    }

    const Send = () => {
        var error = false;
        setError('');
        setNameErr('');
        setEmailErr('');
        setTextErr('');

        if (name.trim() == '') {
            error = lang.required;
            setNameErr(lang.required);
        }
        if (text.trim() == '') {
            error = lang.required;
            setTextErr(lang.required);
        }
        if (email.trim() == '') {
            error = lang.required;
            setEmailErr(lang.required);
        } else {
            if (ValidateEmail(email) == false) {
                error = lang.email_error;
                setEmailErr(lang.email_error);
            }
        }

        if (error != '') {
            setError(error);
        } else {
            db_ask();
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(false)}>
            {status == 0 ?
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: boxWidth, height: boxHeight }}>
                    {/* HLAVICKA - NAZOV */}
                    <div style={{ ...styles.BlockLeft, flexDirection: 'row', width: '100%', height: labelHeight, backgroundColor: global.theme_gray }}>
                        <div style={{ ...styles.BlockLeft, width: '100%', height: labelHeight, justifyContent: 'center', alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextNormal, fontWeight: 'bold', color: global.theme_black, marginLeft: 10 }}>{lang.product_ask}</p>
                            <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10, marginTop: 5 }}>{lang.product_ask_text}</p>
                        </div>
                        <div style={{ ...styles.BlockRight, width: 50, height: labelHeight, justifyContent: 'center', alignItems: 'flex-end' }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10, backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>

                    {/* PRODUKT */}
                    <div style={{ ...styles.BlockRow, width: '100%', height: productHeight, backgroundColor: global.theme_white }}>
                        <div style={{ ...styles.Block, width: 140, height: '100%' }}>
                            <img src={global.product_image + props.item.id + '/' + props.item.image} style={{ maxWidth: 100, maxHeight: 100 }}></img>
                        </div>
                        <div style={{ ...styles.Block, width: '80%', height: '100%' }}>
                            <p style={{ ...styles.TextSmall, fontWeight: 'bold', color: global.theme_black }}>{props.item.title}</p>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '100%', height: bodyHeight, backgroundColor: global.theme_white }}>
                        <div style={{ width: '90%' }}>
                            {/* MENO */}
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.name_surname} (*)</p>
                                <TextField size='small' error={nameErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={name} onInput={e => setName(e.target.value)} inputProps={{ maxLength: 128 }} />
                            </div>

                            {/* MOBIL */}
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.mobil}</p>
                                <TextField size='small' style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} inputProps={{ maxLength: 64 }} />
                            </div>

                            {/* EMAIL */}
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.email} (*)</p>
                                <TextField size='small' error={emailErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} inputProps={{ maxLength: 128 }} />
                            </div>

                            {/* TEXT */}
                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.question} (*)</p>
                                <TextField size='small' multiline={true} rows={4} error={textErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={text} onInput={e => setText(e.target.value)} inputProps={{ maxLength: 2048 }} />
                            </div>
                        </div>
                    </div>

                    {/* DETAIL, VLOZIT DO KOSIKA */}
                    <div style={{ ...styles.Block, height: footerHeight }}>
                        <div style={{ ...styles.Block, height: 20, marginBottom: 10 }}>
                            <p style={{ ...styles.TextTiny, fontWeight: 'bold', color: global.theme_dark_red }}>{error}</p>
                        </div>
                        <div style={{ ...styles.Block, marginBottom: 10 }}>
                            <Button onClick={() => Send()} style={{ ...styles.ButtonGreen }} variant="outlined">{lang.send_question}</Button>
                        </div>
                    </div>
                </div>
                :
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: boxWidth, height: boxHeight }}>
                    <div style={{ ...styles.Block }}>
                        <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                        </div>
                        <p style={{ ...styles.Label, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.product_ask_ok1}</p>
                        <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.product_ask_ok2}</p>

                        <div style={{ ...styles.Block, marginTop: 100 }}>
                            <Button onClick={() => Press(false)} style={{ ...styles.ButtonGreen }} variant="outlined">{lang.close}</Button>
                        </div>
                    </div>
                </div>

            }
        </Dialog >
    );
}


export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
        </div >
    );
}


export const ProductFavorites = (props) => {
    /*
            USAGE:
            <ProductFavorites item={} user={user} func={ProductFavoritesPress.bind(this)} />

            const ProductFavoritesPress = (value) => {
            }

            */

    const [isBusy, setBusy] = useState(false);
    const [status, setStatus] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 500;
    const boxHeight = 300;
    const labelHeight = 70;

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_favorites_add = async () => {
        setBusy(true);
        var data = {
            user_id: props.user.id,
            product_id: props.item.id
        }
        try {
            const response = await fetch(
                global.db_url + 'favorites_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setStatus(1);
                const timer = setTimeout(() => {
                    Press(true);
                }, 1000);
            } else {
                Press(true);
            }

            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const Press = (value) => {
        func(value);
    }


    return (
        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(false)}>
            {props.user == null ?
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: boxWidth, height: 200 }}>
                    {/* BODY */}
                    <div style={{ ...styles.Block, width: '100%', height: '100%', backgroundColor: global.theme_white }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center', color: global.theme_black, marginLeft: 10 }}>{lang.favorite_login}</p>
                    </div>

                    {/* BUTTON */}
                    <div style={{ ...styles.Block, flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <Button onClick={() => Press(false)} variant="contained" style={{ ...styles.ButtonDark, width: 200 }}>{lang.close}</Button>
                        </div>
                    </div>
                </div>

                :
                status == 0 ?
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: boxWidth, height: boxHeight }}>
                        {/* HLAVICKA - NAZOV */}
                        <div style={{ ...styles.BlockLeft, flexDirection: 'row', width: '100%', height: labelHeight, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.BlockLeft, width: '100%', height: labelHeight, justifyContent: 'center', alignItems: 'flex-start' }}>
                                <p style={{ ...styles.TextNormal, color: global.theme_black, marginLeft: 10 }}>{props.item.title}</p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: 50, height: labelHeight, justifyContent: 'center', alignItems: 'flex-end' }}>
                                <IconButton onClick={() => Press(false)} style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10, backgroundColor: global.theme_black }}>
                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                                </IconButton>
                            </div>
                        </div>

                        {/* BODY */}
                        <div style={{ ...styles.Block, width: '100%', height: '100%', backgroundColor: global.theme_white }}>
                            <img src={global.product_image + props.item.id + '/th_' + props.item.image} style={{ maxWidth: 130, maxHeight: 130 }}></img>
                        </div>

                        {/* BUTTONS */}
                        <div style={{ ...styles.Block, flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                                <Button onClick={() => db_favorites_add()} variant="contained" style={{ ...styles.ButtonDark, width: 200 }}>{lang.favorites_add}</Button>
                            </div>
                        </div>
                    </div>
                    :

                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: boxWidth, height: boxHeight }}>
                        <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                        </div>
                        <p style={{ ...styles.TextLarge, color: global.theme_black, marginTop: 50 }}>{lang.favorites_added}</p>
                    </div>

            }
        </Dialog >
    );
}


export const GetUser = () => {

    var data = LoadLogin();

    var user_data = {
        id: 0,
        logged: false,
        b2b: false,
        dph: false,
        user: null
    };

    if (data != null) {
        if (data.logged == true) {
            user_data.logged = true;
            var user = LoadUser();
            user_data.id = user.id;
            if (user.icdph == null || user.icdph.trim() == '') {
                user_data.dph = false;
                Debug('Nie je platcom DPH');
            } else {
                user_data.dph = true;
                Debug('Je platcom DPH');
            }
            if (user.trieda == 0) {
                user_data.b2b = true;
                Debug('Prihlásený B2B zákazník');
            } else {
                user_data.b2b = false;
                Debug('Prihlásený zákazník');
            }
            user_data.user = user;
        } else {
            Debug('Nepríhlasený zákazník');
        }
    }

    return user_data;
}

export const GoHome = () => {
    window.scrollTo(0, 0);
}


export const ProductWatchdog = (props) => {
    /*
            USAGE:
            <ProductWatchdog email={user.email} item={item} func={ProductWatchdogPress.bind(this)} />

            const ProductWatchdogPress = (value) => {
            }


            */

    const [isBusy, setBusy] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [error, setError] = useState('');
    const [status, setStatus] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const boxWidth = 500;
    const boxHeight = 300;
    const labelHeight = 80;

    const navigate = useNavigate();

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        setEmail(props.email);

        return () => {
        };

    }, []);



    const db_watchdog_put = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'watchdog_set', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    product_item: props.variation,
                    product: props.item
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setStatus(1);
                const timer = setTimeout(() => {
                    Press(true);
                }, 1000);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const Press = (type) => {
        func(type);
    }

    const Watchdog = () => {
        var error = '';
        if (email.trim() == '') {
            error = lang.required;
            setEmailErr(lang.required);
        } else {
            if (ValidateEmail(email) == false) {
                error = lang.email_error;
                setEmailErr(lang.email_error);
            }
        }
        if (error != '') {
            setError(error);
        } else {
            db_watchdog_put();
        }
    }

    return (
        <Dialog open={true} maxWidth={'lg'} onBackdropClick={() => Press(false)}>
            {status == 0 ?
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: boxWidth, height: boxHeight }}>
                    {/* HLAVICKA - NAZOV */}
                    <div style={{ ...styles.BlockLeft, flexDirection: 'row', width: '100%', height: labelHeight, backgroundColor: global.theme_gray }}>
                        <div style={{ ...styles.BlockLeft, width: '100%', height: labelHeight, justifyContent: 'center', alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextNormal, color: global.theme_black, marginLeft: 10 }}>{props.item.title}</p>
                            <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10 }}>{props.variation.description}</p>
                        </div>
                        <div style={{ ...styles.BlockRight, width: 50, height: labelHeight, justifyContent: 'center', alignItems: 'flex-end' }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10, backgroundColor: global.theme_black }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_white }} icon={faClose} />
                            </IconButton>
                        </div>
                    </div>

                    {/* BODY */}
                    <div style={{ ...styles.BlockRow, width: '100%', height: '100%', backgroundColor: global.theme_white }}>
                        <div style={{ ...styles.Block, width: 140 }}>
                            <img src={global.product_image + props.item.id + '/' + props.item.image} style={{ maxWidth: 100, maxHeight: 100 }}></img>
                        </div>
                        {/* EMAIL */}
                        <div style={{ ...styles.BlockLeft, width: '80%', marginTop: 10 }}>
                            <p style={{ ...styles.TextTiny, fontWeight: 'bold' }}>{lang.watchdog_text}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 20 }}>{lang.your_email}</p>
                            <TextField size='small' helperText={error} error={emailErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '90%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} inputProps={{ maxLength: 128 }} />
                        </div>
                    </div>

                    {/* DETAIL, VLOZIT DO KOSIKA */}
                    <div style={{ ...styles.Block, marginTop: 20, marginBottom: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall ? '100%' : '50%', marginTop: isSmall ? 20 : 0 }}>
                            <Button onClick={() => Watchdog()} variant="contained" style={{ ...styles.ButtonDark, width: 200 }}>{lang.watchdog_button}</Button>
                        </div>
                    </div>
                </div>
                :

                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_gray, padding: 0, justifyContent: 'center', alignItems: 'center', width: boxWidth, height: boxHeight }}>
                    <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                        <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                    </div>
                    <p style={{ ...styles.Label, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.watchdog_set}</p>
                </div>

            }
        </Dialog >
    );
}

export const DivideTextBold = (txt) => {
    var str = txt.split('\n');

    var result = [];
    str.forEach(item => {
        var data = item.split('#');
        result.push(data);
    });
    return result;
}

export const DivideText = (txt) => {
    var result = txt.split('\n');
    return result;
}


export const DivideProducts = (txt) => {
    var result = txt.split(',');

    return result;
}

export const CookiesInfo = (props) => {
    /*
            USAGE:
            <CookiesInfo lang = {lang} func={CookiesInfoPress.bind(this)} />

            const CookiesInfoPress = (value) => {
                Debug(value)
            }

    */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div style={{ position: 'fixed', bottom: 0, width: '100%', display: 'flex', flexDirection: isSmall ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: global.theme_black, padding: 10 }}>
            <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: isSmall ? '95%' : '50%' }}>
                <p style={{ ...styles.TextNormal, fontSize: isSmall ? 14 : 16, color: global.theme_white, textAlign: isSmall ? 'center' : 'left' }}>{lang.cookies} <a style={{ ...styles.TextNormal, marginRight: 0, color: global.theme_white }} href='/cookies'>{lang.cookies_info}</a></p>
            </div>
            <Button onClick={() => Press(false)} variant="outlined" style={{ fontSize: isSmall ? 10 : 14, marginLeft: isSmall ? 0 : 30, height: 35, color: global.theme_white, borderColor: global.theme_white, marginTop: isSmall ? 20 : 0 }}>
                {lang.agree}
            </Button>
        </div >
    );
}

export const Line = (props) => {
    return (
        <div style={{ width: 100, height: 2, backgroundColor: props.color != undefined ? props.color : global.theme_light, marginTop: 15, marginBottom: 15 }} />
    )
}

export const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = React.useState('up');

    const blocking = React.useRef(false);
    const prevScrollY = React.useRef(0);
    const THRESHOLD = 150;

    React.useEffect(() => {
        prevScrollY.current = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
                const newScrollDirection =
                    scrollY > prevScrollY.current ? 'down' : 'up';

                setScrollDirection(newScrollDirection);

                prevScrollY.current = scrollY > 0 ? scrollY : 0;
            }

            blocking.current = false;
        };

        const onScroll = () => {
            if (!blocking.current) {
                blocking.current = true;
                window.requestAnimationFrame(updateScrollDirection);
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollDirection]);

    return scrollDirection;
};


export const FormError = (props) => {
    /*

            <FormError theme={props.theme} error={error} small />
            -----------------------------------------------------
        small   -> menšia verzia
        margin  -> marginfrom top
            */
    const height = props.small == true ? 28 : 40;

    return (
        <div style={{ ...styles.Block, width: props.small == true ? undefined : '100%', height: height, backgroundColor: props.backgroundColor == undefined ? global.theme_error : props.backgroundColor, borderRadius: 8, marginTop: props.margin == undefined ? 20 : props.margin }}>
            {props.error != '' ?
                <div style={{ ...styles.BlockRowRaw }}>
                    {props.isSmall ? null :
                        <FontAwesomeIcon style={{ height: 18, color: global.theme_white, marginLeft: 15 }} icon={faWarning} />
                    }
                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextXSmall, color: global.theme_white, marginLeft: 10, marginRight: 15 }}>{props.error}</p>
                </div>
                : null}
        </div>
    );
}


export const SelectInput = (props) => {
    /* USAGE

        SINGLE SELECT:
            <SelectInput default={lang.choose} enabled={true} lang={lang} value={-1} items={currencies} field={'currency'} label={lang.mobil} radius={global.radius} func={(txt) => Debug(txt)} />

        MULTIPLE SELECT:
            <SelectInput multiple default={lang.choose} enabled={true} lang={lang} value={[]} items={currencies} field={'currency'} label={lang.mobil} radius={global.radius} func={(txt) => Debug(txt)} />

            items = [{ id: 1, label: 'Option 1 }, ... ]

            PROPS:
            -------------------------------------------------------------------------------
            default     -> disabled nadpis
            field       -> názov stĺpca, z ktorého sa berie názov do Select items
            multiple    -> multi výber hodnôt, POZOR: value musí byť Array []
            require     -> oznacvený cervený pásik na ľavo
            width       -> šírka
            disabled    -> prvá hodnota - default aktívna / neaktívna
    */


    const [value, setValue] = useState(props.value);
    const [redraw, setRedraw] = useState(false);

    let { func } = props;
    var lang = props.lang;
    let radius = props.radius;

    useEffect(() => {
        setValue(props.value);

        return () => {
        };

    }, [props.value, props.items]);

    const ChangeValue = (id) => {
        setValue(id);
        func(id);
    }

    return (
        <div style={{ ...props.center == undefined ? styles.BlockLeft : styles.Block, width: props.width == undefined ? '100%' : props.width }}>
            <Select
                value={value}
                onChange={event => ChangeValue(event.target.value)}
                multiple={props.multiple == undefined ? false : props.multiple}
                size='small'
                style={{ textAlign: 'left', fontSize: global.font_xsmall, backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: props.radius == undefined ? 19 : props.radius }}
                MenuProps={{
                    PaperProps: { sx: { ".MuiList-root": { backgroundColor: '#FFFFFF', }, } },
                    sx: {
                        width: 200, "& .MuiMenuItem-root.Mui-selected": { backgroundColor: props.multiple ? global.theme_blue : global.theme_lighter_blue, color: props.multiple ? global.theme_white : undefined, borderRadius: 2, margin: 1 },
                        width: 200, "& .MuiMenuItem-root.Mui-selected:hover": { backgroundColor: props.multiple ? global.theme_blue : undefined, color: props.multiple ? global.theme_white : undefined },
                        zIndex: 30000,
                    }
                }}
                sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: global.theme_gray, borderLeft: props.require ? props.multiple ? value.length == 0 ? '3px solid #FF0000' : undefined : value == 0 ? '3px solid #FF0000' : undefined : undefined } }}>

                {props.default != undefined ?
                    <MenuItem disabled={props.disabled == undefined ? true : props.disabled} value={0}><p style={{ ...styles.TextXSmall }}><em>{props.default}</em></p></MenuItem>
                    : null}

                {props.all != undefined ?
                    <MenuItem value={0}><p style={{ ...styles.TextXSmall }}>{props.all}</p></MenuItem>
                    : null}

                {props.items != false ?
                    props.items.map((item, index) => (
                        <MenuItem style={{ fontSize: global.font_xsmall }} key={item.id} value={item.id}>{item[props.field]}</MenuItem>
                    ))
                    : null}
            </Select>

        </div >
    );
}



export const DialogYesNo = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogYesNo radius={radius} title={'Otázka'} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={color.dark_red} no_color={color.button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            buttonWidth     -> šírka buttonov
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
            */

    const [width, setWidth] = useState(window.innerWidth);

    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dialogWidth = isSmall ? width : 600;
    const dialogHeight = props.sub_text == '' && (props.warning_text == undefined || props.warning_text == '') ? 220 : props.warning_text == undefined || props.warning_text == '' ? 250 : 300;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.theme_back, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.window_bar_color }}>
                    <div style={{ ...styles.Block, width: dialogWidth - 60, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        {props.hide_close == undefined ?
                            <IconButton onClick={() => Press(false)} style={{ ...styles.ButtonClose }}>
                                <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                            </IconButton>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '96%' }}>
                        <p style={{ ...styles.TextXSmall, textAlign: 'center' }}>{props.text}</p>
                        {props.sub_text != undefined ?
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 10, color: global.theme_dark_gray }}>{props.sub_text}</p>
                            : null}
                        {props.warning_text != undefined ?
                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red, textAlign: 'center', marginTop: 12, fontWeight: '500' }}>{props.warning_text}</p>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonThemed, width: props.buttonWidth == undefined ? 160 : props.buttonWidth, backgroundColor: props.yes_color == undefined ? global.theme_dark : props.yes_color }} > {props.yes}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonThemed, width: props.buttonWidth == undefined ? 160 : props.buttonWidth, backgroundColor: props.no_color == undefined ? global.theme_dark : props.no_color }}>{props.no}</Button>
                    </div>

                </div>
            </Paper>
        </Backdrop >
    );
}


export const TextLine = (props) => {

    /*
    
        <TextLine text={item.text} fontSize={global.font_tiny} fontWeight={'600'} marginLeft={5} color={color.black} />

    */

    return (
        <div style={{ display: 'block', alignContent: 'flex-start', maxWidth: '98%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', marginTop: props.marginTop == undefined ? 0 : props.marginTop }}>
            <span style={{
                ...styles.TextXSmall,
                fontSize: props.fontSize,
                textAlign: 'left',
                color: props.color,
                fontWeight: props.fontWeight != undefined ? props.fontWeight : '400',
                marginLeft: props.marginLeft != undefined ? props.marginLeft : 0,
                marginLeft: props.marginTop != undefined ? props.marginTop : 0,
                marginLeft: props.marginBottom != undefined ? props.marginBottom : 0,
            }}>
                {props.text}
            </span>
        </div>
    );
}


export const DialogInfo = (props) => {
    /*
            OKNO - full screen

            USAGE:
            <DialogInfo title={'Informácia'} text={'Text informácie'} sub_text={'Sub text informácie'} button={lang.close}  radius={radius} func={DialogInfoResult.bind(this)} />

            const DialogInfoResult = (value) => {
                console.log(value)
            }
            */

    const [width, setWidth] = useState(window.innerWidth);

    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const dialogWidth = isSmall ? width : 650;
    const dialogHeight = props.sub_text != undefined ? 280 : 250;

    let { func } = props;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Close = () => {
        func(false);
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.window_bar_color }}>
                    <div style={{ ...styles.Block, width: dialogWidth - 60, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{props.title}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ ...styles.ButtonClose }}>
                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <p style={{ ...styles.TextXSmall, textAlign: 'center' }}><b>{props.text}</b></p>
                    {props.sub_text != undefined ?
                        <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'center' }}>{props.sub_text}</p>
                        : null}
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <Button onClick={() => Close()} style={{ ...styles.ButtonThemed, backgroundColor: global.theme_dark, color: global.theme_white }}>{props.button}</Button>
                </div>
            </Paper>
        </Backdrop >
    );
}



export const GetPages = (count, itemsMax) => {
    // PAGIMATION - výpočet počtu strán
    return Math.ceil(count / itemsMax);
}


export const GetDatum2 = (date_) => {
    // ------------------------------------
    // RETURN DD.MM.YYYY z dátumu
    // ------------------------------------

    var d = '01';
    var m = '01';
    var y = '2023';

    if (date_ != null && date_ != undefined) {

        var date = date_.toString().replaceAll('-', '/');
        var datum = new Date(date);

        var d = String(datum.getDate()).padStart(2, '0');
        var m = String(datum.getMonth() + 1).padStart(2, '0');
        var y = String(datum.getFullYear());
    }

    return d + '.' + m + '.' + y;
}

export const DialogVideo = (props) => {
    /*
                PREHRAVANIE VIDEA

                USAGE:
                <DialogVideo file={global.web + "/public/images/..."} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogVideoResult.bind(this)} />

                --------------------------------------------------------------------------------------------------------------------
            file    => celá cesta aj s doménov
            crop    => TRUE = orezanie obrázku a rozstiahnutie v celom okne <div>
                    --------------------------------------------------------------------------------------------------------------------

            const DialogVideoResult = () => {
                    }

                    */

    //const [isBusy, setBusy] = useState(false);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width > 1280 ? 1280 : width;
    const dialogHeight = (720 / 1280 * dialogWidth) + global.dialog_bar_height > height ? height : (720 / 1280 * dialogWidth) + global.dialog_bar_height;


    let { func } = props;
    var lang = sk;

    useEffect(() => {
        Debug(dialogWidth);
        Debug(dialogHeight);

        return () => {
        };

    }, []);


    const Press = (typ) => {
        func(typ);
    }

    const Close = () => {
        Press(0);
    }

    return (
        <Dialog
            open={true}
            
            PaperProps={{
                sx: { borderRadius: props.isSmall ? 0 : '10px', margin: 0, minHeight: dialogHeight, minWidth: dialogWidth },
                elevation: 0
            }}        >
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.theme_gray, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.theme_back }}>
                    <div style={{ ...styles.Block, width: dialogWidth - 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, marginLeft: 10 }}>{props.title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, backgroundColor: props.background, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <video controls style={{ maxWidth: '100%', maxHeight: dialogHeight - global.dialog_bar_height }}>
                        <source src={props.file} type="video/mp4" />
                    </video>
                </div>
            </Paper>
        </Dialog >
    );
}