import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper, Alert } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, GetPrice, GetPriceRound, GetCost, Loading, ProductBuy, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, ValidateEmail, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faCheck, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import skLocale from 'date-fns/locale/sk';
import { styles } from './styles.js';
import ReCAPTCHA from 'react-google-recaptcha'
import { green } from '@mui/material/colors';
import { style } from '@mui/system';
import { loadingButtonClasses } from '@mui/lab';


export const Favorites = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [isBusy, setBusy] = useState(false);

    const [items, setItems] = useState([]);
    const [start, setStart] = useState(0);
    const [over, setOver] = useState(0);

    const [coeficient, setCoeficient] = useState(0.00);
    const [showProductBuy, setShowProductBuy] = useState(false);
    const [buyItem, setBuyItem] = useState(null);

    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);
    const [isDPH, setDPH] = useState(false); // Je platitel DPH

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/reserve_date_icon.png');

    const boxHeight = 70;



    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        db_get(data.id, 0);

        GoHome();

        return () => {
        };

    }, []);

    const db_get = async (user_id, start) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'favorites', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: user_id,
                    start: start,
                    count: global.items_max
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
                setCoeficient(parseFloat(json.coeficient));
            }
            setLoading(false);

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const db_delete = async (id) => {
        if (isBusy == false) {

            try {
                const response = await fetch(
                    global.db_url + 'favorites_delete', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: id,
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    var tmp = items.filter(x => x.id != id);
                    setItems(tmp);

                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const GotoBack = () => {
        navigate(-1);
    }

    const DeleteItem = (item) => {
        db_delete(item.id);
    }

    const ShowProduct = (item) => {
        navigate('/product/' + item.tid)
    }

    const Buy = (item) => {
        var data = item;
        data.id = item.product_id;
        setBuyItem(data);
        setShowProductBuy(true);
    }

    const ProductBuyPress = (type, quantity) => {
        if (type == false) {
            setShowProductBuy(false);
        } else {
            //setShoppingCardQuantity(shoppingCardQuantity + quantity);
            func(2, quantity);
            setShowProductBuy(false);
        }
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>

            <div ref={homeRef} style={{ ...styles.BlockMaxLarge, marginTop: 30 }} >
                <div style={{ ...isSmall ? styles.Block : styles.BlockLeft }}>
                    <p style={{ ...styles.TextLarge, fontWeight: 'bold' }}>{lang.favorite_items}</p>

                    {/* ZOZNAM POLOZIEK */}
                    {items.length > 0 ?
                        <div style={{ ...isSmall ? styles.Block : styles.BlockLeft, width: '100%', marginBottom: 50 }}>
                            {isSmall ? null :
                                <div style={{ ...styles.BlockLeft, marginTop: 40 }}>
                                    <div style={{ ...styles.BlockRow, alignItems: 'center', marginTop: 5, marginBottom: 5 }}>
                                        <div style={{ ...styles.Block, width: boxHeight }}>                                </div>
                                        <div style={{ ...styles.BlockLeft, width: '50%', justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextXXTiny, marginLeft: 20 }}>{lang.product_name}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 180 }}>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 120 }}>
                                            <p style={{ ...styles.TextXXTiny, marginLeft: 20 }}>{b2b == true ? lang.without_dph : lang.with_dph}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 80 }}>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: 60 }}>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div style={{ ...styles.Block, width: '95%' }}>
                                {items.map(item => {
                                    return (
                                        <Paper key={item.id} elevation={over == item.id ? 2 : 0} style={{ ...styles.BlockRow, height: isSmall ? undefined : boxHeight, alignItems: 'center', marginTop: 5, marginBottom: 5, backgroundColor: global.theme_white }} onMouseEnter={() => setOver(item.id)} onMouseLeave={() => setOver(0)}>
                                            <div style={{ ...styles.Block, width: isSmall ? 40 : boxHeight }}>
                                                <img onClick={() => ShowProduct(item)} src={global.product_image + item.path + '/th_' + item.image} style={{ maxWidth: isSmall ? 40 : boxHeight - 10, maxHeight: isSmall ? 40 : boxHeight - 10, cursor: 'pointer' }} />
                                            </div>
                                            <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockLeft, width: '50%', height: boxHeight, justifyContent: 'center', cursor: 'pointer' }}>
                                                <p style={{ ...isSmall ? styles.TextXXTiny : styles.TextTiny, marginLeft: 20, textAlign: 'left' }}>{item.title}</p>
                                            </div>
                                            {isSmall ? null :
                                                <div style={{ ...styles.BlockRight, width: 180 }}>
                                                    <div style={{ ...styles.BlockRow, justifyContent: 'flex-end' }}>
                                                        <p style={{ ...styles.TextTiny, fontWeight: 'normal', color: global.theme_dark_gray }}>{lang.stock}:</p>
                                                        <p style={{ ...styles.TextTiny, fontWeight: 'bold', marginLeft: 5, color: item.quantity > 0 ? global.theme_green : global.theme_dark_red }}>{item.quantity} {lang.pieces}</p>
                                                    </div>
                                                </div>
                                            }
                                            <div style={{ ...styles.BlockRight, width: 120 }}>
                                                <p style={{ ...styles.TextTiny, marginLeft: isSmall ? 5 : 20, fontWeight: 'bold' }}>{item.more_prices == true ? 'od' : ''} {GetPrice(GetPriceRound(GetCost(b2b == true ? 0 : 1, b2b == true ? item.cost : item.cost_dph, coeficient)))} {lang.money}</p>
                                                {isSmall ?
                                                    <p style={{ ...styles.TextXXXTiny, marginLeft: isSmall ? 5 : 20 }}>{b2b == true ? lang.without_dph : lang.with_dph}</p>
                                                    : null}
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: 80 }}>
                                                <IconButton onClick={() => Buy(item)} style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10, backgroundColor: global.theme_gray }}>
                                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_dark_green }} icon={faShoppingCart} />
                                                </IconButton>
                                            </div>
                                            <div style={{ ...styles.BlockRight, width: isSmall ? 40 : 60 }}>
                                                <IconButton onClick={() => DeleteItem(item)} style={{ width: 35, height: 35, borderRadius: '50%', marginRight: isSmall ? 0 : 10, backgroundColor: global.theme_white }}>
                                                    <FontAwesomeIcon style={{ width: 16, height: 16, color: global.theme_dark_gray }} icon={faXmark} />
                                                </IconButton>
                                            </div>
                                        </Paper>
                                    )
                                })}
                            </div>
                        </div>
                        :
                        <div style={{ ...style.BlockLeft, width: '100%', marginBottom: 50, marginTop: 30 }}>
                            <p style={{ ...styles.TextSmall }}>{lang.favorite_empty}</p>
                        </div>

                    }
                </div>

                <div style={{ height: 20 }}></div>

                {
                    isLoading == true ?
                        <Loading></Loading>
                        : null
                }
            </div >

            {showProductBuy == true ?
                <ProductBuy user={user} logged={logged} b2b={b2b} isDPH={isDPH} coeficient={coeficient} item={buyItem} func={ProductBuyPress.bind(this)} />
                : null}

        </div >
    )
};


