import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { ShoppingDeleteAll } from './items.js';

export const Reset = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        console.log('RESET');
        ShoppingDeleteAll();
        navigate('/');
    }, []);


    return null;
       
};
