import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, Debug, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome, DialogVideo } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';


export const Reklamacia = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);
    const [showVideo, setShowVideo] = useState(false);

    const [web, setWeb] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        db_web();

        GoHome();

        return () => {
        };

    }, []);

    const db_web = async (user_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'main_page', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: user_id,
                    banners_typ: 0,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setWeb(json.web);
                setLoading(false);
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }



    return (
        <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_back }}>

            <div style={{ ...styles.BlockCenter, paddingTop: 20, paddingBottom: 30 }}>
                <h1>Reklamačný poriadok</h1>
                <p style={{ ...styles.TextXLarge, marginTop: 10 }}><b>Reklamácie, alternatívne riešenie sporov, vrátenie tovaru a záručná doba</b></p>

                <div style={{ ...styles.BlockCenter }}>
                    <div style={{ ...styles.BlockCenter, padding: 20, maxWidth: global.screen_max }}>
                        <p style={{ ...styles.TextXLarge, textAlign: 'left', marginTop: 20 }}><b>1. Reklamácia tovaru</b></p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.1. V zmysle zákona § 3 ods. 1. zákona č. 250/2007 Z.z. má zákazník právo na reklamáciu, pokiaľ nami dodaný tovar nespĺňa deklarované parametre alebo nespĺňa očakávania zákazníka.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.2. Spotrebiteľ je povinný reklamáciu nahlásiť bezodkladne po zistení závadového stavu v balíku, v časovom rozmedzí do 3 dní. Dodatočné reklamácie t.j. reklamácie podané oneskorene predajca neakceptuje.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.3. V prípade reklamácie tovaru v súvislosti s jeho zjavným poškodením (poškodením obalu, v ktorom bol zabalený), alebo znehodnotením počas prepravy je zákazník povinný túto reklamáciu oznámiť bezodkladne, ihneď po prevzatí zásielky od kuriéra. Môže tak urobiť telefonicky, v ideálnom prípade však e-mailom, s priložením fotodokumentácie skutočného stavu zásielky. Reklamácie tohto typu oznámené s oneskorením, nie v deň doručenia zásielky, nebudú akceptované.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.4. Reklamovať sa môže len tovar zakúpený u predávajúceho Polplast-SK.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.5. Reklamácia sa vzťahuje len na zjavné poškodenie tovaru pri jeho preprave, alebo na preukázateľné výrobné chyby spôsobené výrobcom, ktoré boli zistené po dodávke tovaru alebo počas jeho používania.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.6. Reklamácia sa nevzťahuje na poškodenia spôsobené užívateľom počas bežného používania, prevádzkou v nevhodných podmienkach, zanedbaním starostlivosti, neodbornou manipuláciou prípadne zásahom do častí alebo konštrukcie výrobku.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.7. Záručná doba na tovar zaniká v prípadoch, že k chybe došlo nesprávnym zaobchádzaním s výrobkom, mechanickým poškodením, prevádzkou v nevhodných podmienkach alebo neoprávneným zásahom do výrobku. Zo záruky sú tiež vyňaté chyby výrobku spôsobené živelnou pohromou.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.8. Zákazník je povinný reklamáciu podať písomne a to buď formou listu poslaným na adresu dodávateľa, alebo formou elektronickej komunikácie. Vtedy však dodávateľ nezodpovedá za nedoručenie e-mailovej správy do schránky predajcu, prípadne za stratu reklamácie prostredníctvom pošty, t.j. nedoručenie obyčajnej poštovej obálky.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.9. Spotrebiteľ je pri uplatnení reklamácie povinný postupovať v zmysle § 34 a následne Zákona č. 40/1964 Z.z.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.10. Žiadosť o reklamáciu musí obsahovať tieto náležitosti:</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>- Číslo dodacej faktúry</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>- Názov a počet reklamovaných položiek, ich dodací kód prípadne inú špecifikáciu</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>- Meno, priezvisko, adresu telefonický a e-mailový kontakt zákazníka.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>- Popis závady / dôvod reklamácie</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>1.11. Pre uplatnenie reklamácie je spotrebiteľ povinný doručiť tovar na vlastné náklady, spolu s kópiou daňového dokladu na adresu predajcu.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.12. Reklamovaný tovar musí byť kompletný, s príslušenstvom, s návodom a v originálnom obale.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.13. Reklamačné konanie začína plynúť dňom kompletným prevzatím reklamovaného tovaru, kópie daňového dokladu a žiadosti o reklamáciu predávajúcim a končí dňom odoslania vybavenej reklamácie kupujúcemu.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.14. Pri spätnom zasielaní je spotrebiteľ povinný zabaliť tovar tak, aby sa predišlo jeho poškodeniu alebo znehodnoteniu.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.15. Poškodenie tovaru pri spätnom zaslaní má za následok neuznanie reklamácie.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.16. Pri zaslaní tovaru dobierkou nebude táto zásielka prijatá.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.17. Vyriešenie reklamácie v záručnej dobe je bezplatné. Pokiaľ však bude reklamácia neoprávnená, prepravné náklady spojené s vrátením reklamovanému tovaru kupujúcemu sa nevracajú.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.18. Oprávnené reklamácie sú vyriešené na náklady predávajúceho najneskôr do 30 dní od prevzatia reklamovaného tovaru predávajúcim.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.19. O oprávnenosti reklamácie rozhodne predajca najneskôr do 3 pracovných dní od obdržania reklamovaného tovaru. O výsledku bude spotrebiteľ informovaný prostredníctvom e-mailu.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.20. V prípade neoprávnenej reklamácie bude tovar vrátený späť spotrebiteľovi spolu s príslušný odôvodnením.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.21. Zodpovednosť za škody spôsobené pri preprave nesie prepravná spoločnosť.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.22. Kupujúci nemá právo uplatniť si právo zodpovednosti za vady, o ktorých bol predávajúcim v dobe uzatvárania zmluvy upozornený, alebo o ktorých s prihliadnutím k okolnostiam, za ktorých bola kúpna zmluva uzatvorená, musel vedieť.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.23. Predávajúci si vyhradzuje právo nahradiť poškodený tovar za iný nový bezchybný tovar s rovnakými alebo lepšími technickými parametrami, ak to kupujúcemu nespôsobí závažné ťažkosti.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>1.24. Povinnosťou predávajúceho je vybaviť reklamáciu a ukončiť reklamačné konanie jedným z nasledujúcich spôsobov:</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>a) výmenou tovaru,</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>b) vrátením kúpnej ceny tovaru,</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>c) odovzdaním opraveného tovaru,</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>d) vyplatením primeranej zľavy z ceny tovaru,</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>e) písomnou výzvou na prevzatie predávajúcim určeného plnenia,</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>f) odôvodneným zamietnutím reklamácie tovaru.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>1.25. Pokiaľ sa jedná o vadu, ktorú nemožno odstrániť, alebo o jednu viackrát opakovanú odstrániteľnú vadu, alebo o väčší počet rôznych odstrániteľných vád, ktoré bránia tomu, aby mohol byť tovar riadne užívaný ako bez vady, predávajúci vybaví v závislosti od rozhodnutia kupujúceho reklamáciu nasledujúcim spôsobom:</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>- výmenou tovaru za iný funkčný tovar rovnakých alebo lepších technických parametrov, alebo</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>- v prípade, že nemôže predávajúci vykonať výmenu tovaru za iný, vybaví reklamáciu vrátením kúpnej ceny za tovar.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>1.26. Reklamácie sú vybavované v súlade s Obchodným zákonníkom SR.</p>

                        <p style={{ ...styles.TextXLarge, textAlign: 'left', marginTop: 40 }}><b>2. Alternatívne riešenie sporov</b></p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>2.1. Po využití všetkých dostupných prostriedkov ako je reklamácia so zamietavým výsledkom, žiadosť spotrebiteľa alebo obojstranná dohoda medzi spotrebiteľom a predajcom, prípadne ak spotrebiteľ do 30 kalendárnych dní neobdrží žiadne stanovisko, je možné situáciu riešiť aj alternatívnym spôsobom cez subjekt ARS, v zmysle zákona č. 391/2015 Z.z. Alternatívne riešenie sporov (ďalej len ARS) môže využiť len spotrebiteľ – fyzická osoba, ktorá pri uzatváraní a plnení spotrebiteľskej zmluvy nekoná v rámci svojej podnikateľskej činnosti. ARS je odplatná služba, kde výška poplatku predstavuje maximálne 5 EUR s DPH. Týka sa len zmlúv uzatvorených na diaľku a ich hodnota musí prevyšovať 20 EUR.</p>

                        <p style={{ ...styles.TextXLarge, textAlign: 'left', marginTop: 40 }}><b>3. Vrátenie tovaru</b></p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>3.1. Spotrebiteľ má v súlade s ustanovením §7 odst. 1a Zákona o ochrane spotrebiteľa právo odstúpiť od zmluvy a to vyplnením „Formuláru na odstúpenie od zmluvy“ a zakúpený tovar do 14 kalendárnych dní odo dňa prevzatia zásielky, bez udania dôvodu vrátiť na adresu predajcu. Urobiť tak musí na vlastné náklady, nie formou dobierky.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>3.2. Ak je predmetom kúpnej zmluvy niekoľko druhov tovaru, v súlade s ustanovením §7 odst. 4 Zákona o ochrane spotrebiteľa, sa tovar považuje za prevzatý okamihom prevzatia tovaru, ktorý bol dodaný ako posledný.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>3.3. V súlade s ustanovením §7 odst. 6 Zákona o ochrane spotrebiteľa, spotrebiteľ si je vedomý toho, že od kúpnej zmluvy nie je možné ustúpiť v prípade že:</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>- Predaný tovar bol upravený podľa špecifických požiadaviek spotrebiteľa</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>- Dodaný tovar bol neoddeliteľne zmiešaný s iným tovarom</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify', marginLeft: 10 }}>- Po dodaní tovaru bol porušený uzavretý ochranný obal tovaru a nie je vhodné tovar vrátiť z hygienických dôvodov.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>3.4. Vrátený tovar musí byť v originálnom balení, nepoužitý, nepoškodený a kompletný.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>3.5. Zákazník je oprávnený vrátiť uvedený tovar v obyčajnom poštovom balíku odoslanom na adresu predajcu.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>3.6. Cena za vrátený tovar bude poskytnutá na účet kupujúceho po vrátení a skontrolovaní tovaru.</p>

                        <p style={{ ...styles.TextXLarge, textAlign: 'left', marginTop: 40 }}><b>4. Záručná doba</b></p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>4.1. Pokiaľ to na obale produktu nie je uvedené inak, na predávaný tovar je poskytovaná zákonná záručná doba 24 mesiacov. Dokladom o predaji je faktúra, slúžiaca zároveň aj ako dodací a záručný list.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>4.2. Záručná doba začína plynúť v deň doručenia tovaru spotrebiteľovi.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 30, textAlign: 'justify' }}>Tento RP nadobúda platnosť a účinnosť od 15.8.2022. Prevádzkovateľ si vyhradzuje právo zmeniť RP aj bez predchádzajúceho upozornenia.</p>

                    </div>

                </div>
                <p style={{ ...styles.TextXSmall, marginTop: 50, marginBottom: 10 }}><b>{lang.video_tutorial}</b></p>
                <Button onClick={() => setShowVideo(true)} style={{ color: global.theme_black, borderColor: global.theme_black }} variant='outlined'>{lang.reklamacny_poriadok_video}</Button>


                <Button onClick={() => GotoBack()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 50 }} variant='outlined'>{lang.back}</Button>

            </div>

            {showVideo == true ?
                <DialogVideo file={global.reklamacie_video} radius={props.radius} lang={props.lang} title={lang.reklamacny_poriadok_video} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={() => setShowVideo(false)} />
                : null}

            {isLoading == true ?
                <Loading></Loading>
                : null}

        </div >
    )
};
