import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, MenuTop, Menu, Footer, Loading, ProductBox, GetPrice, GetCost, LoadUser, LoadLogin } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';


export const Search = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);

    const [web, setWeb] = useState([]);
    
    const [allCategories, setAllCategories] = useState([]);
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subProducts, setSubProducts] = useState([]);
    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);

    const [products, setProducts] = useState([]);
    const [productsFiltered, setProductsFiltered] = useState([]);
    const [page, setPage] = useState(0);
    const [settingsShow, setSettingsShow] = useState(global.show_1);
    const [productsMax, setProductsMax] = useState(0);
    const [showMore, setShowMore] = useState(false);

    const [coeficient, setcoeficient] = useState(1);
    const [isDPH, setDPH] = useState(false); // Je platitel DPH
    const [count, setCount] = useState(0); // pocet nájdených produktov

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0,true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);        

        //db_web(data.id);
        db_products(0);

        GoHome();
        
        return () => {
        };

    }, []);

    
    const db_products = async (page_) => {
        setLoading(true);
        setProducts([]);
        setProductsFiltered([]);


        var search = params.search.trim();
        search = search.replaceAll('/', '');
        search = search.replaceAll('+', '');
        search = search.replaceAll('#', '');
        //search = search.replaceAll(' ', '+');


        try {
            const response = await fetch(
                global.db_url + 'search_products2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    search: search
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.products;
                tmp = tmp.slice(0, (page_ * settingsShow) + settingsShow);
                setProductsFiltered(tmp);

                setProductsMax(json.products.length);
                setProducts(json.products);
                if ((page_ * settingsShow) + settingsShow < json.products.length) {
                    setShowMore(true);
                } else {
                    setShowMore(false);
                }

                setCount(json.products_count);
                
                setcoeficient(parseFloat(json.coeficient));
            }
            setLoading(false);

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }



    const MenuTopPress = (type, value) => {
        if (type == 0) {
            // registracia
            navigate('/user_register');
        }
    }
    const MenuLogoPress = (type, value) => {
        if (type == 0) {
            // KLIKNUTIE NA LOGO            
            navigate('/');
        }
    }

    const MenuPress = (type, id) => {
        if (type == 0) {
            //KLIKNUTIE NA KATEGÓRIU
            //ChangeSubsection(subsections, categories, id);

            var tmp = subsections.filter(x => x.id == id);
            if (tmp.length > 0) {
                var slug = tmp[0].slug;

                var tmp = sections.filter(x => x.id == tmp[0].parent);
                if (tmp.length > 0) {
                    slug = tmp[0].slug + '/' + slug;
                    DeleteScroll();
                    navigate('/catalog/' + slug);
                }
            }
        }
        if (type == 1) {
            // KLIKNUTIE NA SEKCIU
            //ChangeSection(id);
            var tmp = sections.filter(x => x.id == id);
            if (tmp.length > 0) {
                DeleteScroll();
                navigate('/catalog/' + tmp[0].slug);
            }
        }
        if (type == 2) {
            // DOMOV
            navigate('/');
        }
    }

    const DeleteScroll = () => {
        sessionStorage.removeItem('scrollPosition')
    }

    const DeletePage = () => {
        sessionStorage.removeItem('page')
    }


    const GotoBack = () => {
        navigate(-1);
    }


    const SelectProduct = (id) => {
        var tmp = products.filter(x => x.id == id);
        if (tmp.length > 0) {
            Debug(tmp[0].tid);
            navigate('/product/' + tmp[0].tid);
        }

    }

    const ProductPress = (item) => {
        SelectProduct(item.id)
    }

    const GoHome = () => {
        homeRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    const ShowMore = () => {

        var page_ = page;

        if (page_ * settingsShow < (productsMax - settingsShow)) {
            page_ += 1;
        }
        setPage(page_);

        RedrawItems(page_, settingsShow);
    }

    const RedrawItems = (page_, maxitems_) => {
        if ((page_ * maxitems_ + maxitems_) < productsMax) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }
        var tmp = products;
        tmp = tmp.slice(0, (maxitems_ * page_) + maxitems_);
        setProductsFiltered(tmp);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef}>
                {/*
                <MenuTop loged={false} mobil={'0905'} email={'info'} name={''} surname={''} func={MenuTopPress.bind(this)} />
                <MenuLogo func={MenuLogoPress.bind(this)} />
                
                <Menu sections={sections} subsections={subsections} func={MenuPress.bind(this)} />
                */}
                {isLoading == true ? <div style={{ height: height }}></div> : null}

                <div style={{ ...styles.Block }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 40, maxWidth: 1000 }}>
                        <div style={{ ...styles.BlockLeft }}>
                            <p style={{ ...styles.Label }}>{lang.search_text}: "{params.search}"</p>
                            {count > 0 ?
                                <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.search_count}: {count}</p>
                                :
                                null
                            }
                            {isLoading == false ?
                                productsFiltered.length == 0 ?
                                    <div style={{ paddingTop: 40, paddingBottom: 40 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>{lang.search_none}</p>
                                    </div>
                                    : null
                                : null
                            }
                        </div>

                        {productsFiltered.length > 0 ?
                            <div style={{ width: '100%', marginTop: 20 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                    {productsFiltered.map(item => {
                                        return (
                                            <ProductBox price={GetPrice(GetCost(b2b == true ? 0 : 1, b2b == true ? isDPH == true ? item.cost : item.cost_dph : item.cost_dph, coeficient))} dph={b2b == true ? false : true} key={item.id} typ={0} item={item} func={ProductPress.bind(this)} />
                                        )
                                    })}
                                </div>
                            </div>

                            : null}

                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                {productsFiltered.length > 4 ?
                                    <Button onClick={() => GoHome()} style={{ textTransform: 'none', color: global.theme_dark_gray, fontWeight: 'bold' }}>
                                        <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 5, color: global.theme_dark_gray }} icon={faAngleUp} />
                                        {lang.go_upper}
                                    </Button>
                                    : null}
                            </div>
                            <div style={{ width: '100%' }}>
                                {showMore == true ?
                                    <Button onClick={() => ShowMore()} variant="outlined" style={{ margin: 20, width: 200, height: 40, color: global.theme_black, borderColor: global.theme_black }}>{lang.show_more}</Button>
                                    : null}
                            </div>

                        </div>
                    </div>




                    <div style={{ height: 20 }}></div>
                </div>

                {/* FOOTER */}
                {/*isLoading == false ?
                    <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                                : null*/}

                {isLoading == true ?
                    <Loading></Loading>
                    : null}
            </div>

        </div >
    )
};

