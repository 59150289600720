import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, Footer, Loading, Debug, GoHome, DivideTextBold } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronCircleRight, faChevronUp, faCircle, faComment, faComments, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis'
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


export const Asking = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [openAcc, setOpenAcc] = useState(-1);
    const [web, setWeb] = useState([]);
    const [logged, setLogged] = useState(false);

    const [items, setItems] = useState([]);

    const textWidth = '70%';

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;
    const boxHeight = 25;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setLogged(data.logged);

        GoHome();
        db_Items();

        return () => {
        };

    }, []);

    const db_Items = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'questions_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({

                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }

    const SetOpenAcc = (id) => {
        if (openAcc == id) {
            setOpenAcc(-1);
        } else {
            setOpenAcc(id);
        }
    }

    return (
        <div style={{ ...styles.Block, backgroundColor: global.theme_back }}>
            {isBusy == false ?
                <div style={{ ...styles.BlockMaxLarge, paddingBottom: 40 }}>

                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_gray, marginTop: 40, marginBottom: 40 }}>
                        <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left' }}>{'Vy sa pýtate, my odpovedáme!'}</h1>
                                <div style={{ ...styles.BlockRow, marginTop: 30 }}>
                                    <div style={{ ...styles.BlockLeft, width: 80 }}>
                                        <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faComments} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                        <p style={{ ...styles.TextLarge, fontWeight: 'lighter', marginTop: 0, textAlign: 'left', color: global.theme_black, textAlign: 'justify' }}>Tu nájdete riešenia a všetky informácie, ktoré ušetria Váš čas a zefektívnia našu spoluprácu.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: isSmall ? '100%' : '30%' }}>
                            <img src={global.images + 'app/questions.jpg'} style={{ width: '100%', height: '100%' }} />
                        </div>

                    </div>

                    {items.map(item => (
                        <Accordion key={item.id} elevation={0} expanded={openAcc == item.id ? true : false} onChange={() => SetOpenAcc(item.id)} style={{ width: '100%', backgroundColor: global.theme_gray }}>
                            <AccordionSummary expandIcon={<FontAwesomeIcon style={{ width: 14, height: 14, color: global.theme_black }} icon={faChevronUp} />}>
                                <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: isSmall ? undefined : boxHeight, paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold' }}>{item.question}</p>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                    <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_xxgray, marginRight: 10, marginTop: 0 }} icon={faComment} />
                                    <div>
                                        {DivideTextBold(item.answer).map((txt, index) => (
                                            <p key={index} style={{ ...styles.TextSmall, color: global.theme_dark_gray, textAlign: 'left', marginTop: txt[0].charAt(0) == '-' || (txt[0].charAt(0) >= '0' && txt[0].charAt(0) <= '9') ? 5 : 0, marginBottom: txt.length == 1 && txt[0] == '' ? global.font_normal : 0, marginLeft: txt[0].charAt(0) == '-' || (txt[0].charAt(0) >= '0' && txt[0].charAt(0) <= '9') ? 15 : 0 }}>
                                                {txt.map((str, index) => (
                                                    <span key={index} style={{ fontWeight: index % 2 == 1 ? 'bold' : 'normal' }}>{str}</span>
                                                ))}
                                            </p>
                                        ))}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        /*
                        <div key={item.id} style={{ ...styles.BlockLeft, marginTop: 10, marginBottom: 10, paddingBottom: 20, ...styles.BorderBottom }}>
                            <p style={{ ...styles.TextNormal, textAlign: 'left', fontWeight: 'bold' }}>{item.question}</p>
                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                <FontAwesomeIcon style={{ width: 6, height: 6, color: global.theme_black, marginRight: 10, marginTop: 7 }} icon={faCircle} />
                                <div>
                                    {DivideText(item.answer).map((txt, index) => (
                                        <p key={index} style={{ ...styles.TextSmall, color: global.theme_dark_gray, textAlign: 'left', marginTop: 0, marginBottom: txt == '' ? global.font_normal : 0, }}>{txt}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        */
                    ))}
                </div>
                :
                <div style={{ width: '100%', height: 800 }}></div>
            }

            {isBusy == true ?
                <Loading></Loading>
                : null}

        </div>

    )
};

