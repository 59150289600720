import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { Debug, MenuLogo, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, PlusMinus, LoadUser, LoadLogin } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faCheck, faHome, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';


export const NewsletterRegister = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [isOK, setOK] = useState(false);
    const [isError, setError] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');

    useEffect(() => {
        console.log(params.id);
        if (params.id != undefined) {
            if (params.id.trim() != '') {
                db_check(params.id);
            }
        }
        return () => {
        };

    }, []);

    const db_check = async (id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'newsletter_check_email', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    id: id
                })
            })

            const json = await response.json();
            console.log(json);
            setLoading(false);
            if (json.ok > 0) {
                if (json.error == false) {
                    setOK(true);
                } else {
                    setError(true);
                }
            } else {
                setOK(false);
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }




    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_white, width: '100%' }}>

            <div style={{ paddingTop: 40, paddingBottom: 40 }}>
                <img src={logo} height={80} />
                <p style={{ fontSize: global.font_xxlarge, color: global.theme_black }}>{lang.newsletter}</p>
                {isLoading == false ?
                    <div>
                        {isOK == true ?
                            <div>
                                <FontAwesomeIcon style={{ marginRight: 10, width: 50, height: 50, color: global.theme_dark_green }} icon={faCheck} />
                                <p style={{ fontSize: global.font_large, color: global.theme_black }}>{lang.newsletter_receive_ok}</p>
                            </div>
                            : null}
                        {
                            isError == true ?
                                <div>
                                    <FontAwesomeIcon style={{ marginRight: 10, width: 50, height: 50, color: global.theme_dark_red }} icon={faXmark} />
                                    <p style={{ fontSize: global.font_large, color: global.theme_black }}>{lang.newsletter_receive_err}</p>
                                </div>
                                : null
                        }
                    </div>
                    : null}
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                    <Button onClick={() => navigate('/')} style={{ display: 'flex', width: 250, padding: 0, height: 50, textTransform: 'none', backgroundColor: global.theme_medium, color: global.theme_white }}>
                        <FontAwesomeIcon style={{ marginRight: 10, width: 16, height: 16, color: global.theme_white, marginRight: 10 }} icon={faHome} />
                        {lang.main_page}
                    </Button>
                </div>
                {isLoading == true ?
                    <Loading></Loading>
                    : null}
            </div>

        </div >
    )
};

const styles = {
    ButtonLink: {
        color: global.theme_dark_gray,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0
    },
    ButtonBack: {
        color: global.theme_white,
        backgroundColor: global.theme_light,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        width: 100,
        height: 30,
    }
};
