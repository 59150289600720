import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, Footer, Loading, DivideProducts, DivideText, GalleryView, PlusMinus, LoadUser, Debug, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronCircleRight, faCircle, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis'
import { styles } from './styles.js';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';



export const Blogs = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(true);

    const [web, setWeb] = useState([]);
    const [logged, setLogged] = useState(false);

    const [items, setItems] = useState([]);

    // PAGINATION
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);

    const textWidth = '70%';

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setLogged(data.logged);

        GoHome();
        db_items(0);

        return () => {
        };

    }, []);

    const db_items = async (start) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'blog_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    start: start,
                    max: global.items_max
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);

                // pagination
                var tmp = json.count;
                tmp = Math.ceil(tmp / global.items_max);
                setCount(tmp);

            }
            setBusy(false);

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }

    const PageChange = (event, value) => {
        // PAGINATION        
        setPage(value);
        var page = (value - 1) * global.items_max;
        db_items(page);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', backgroundColor: global.theme_white }}>


            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isSmall ? 320 : global.screen_max, width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 40, paddingBottom: 40 }}>

                <p style={{ ...styles.TextXXLarge, color: global.theme_black }}>{lang.blog_title}</p>


                {/* BODY */}
                {isBusy == false ?
                    <div style={{ ...styles.BlockRow, flexWrap: 'wrap', marginTop: 40 }}>

                        {/* BLOG CARD */}
                        {items.map(item => (

                            <div key={item.id} onClick={() => navigate('/blog/' + item.tid)} style={{ ...styles.Block, width: 300, margin: 5, marginBottom: 80, cursor: 'pointer' }}>
                                <img src={global.blog_image + item.image} style={{ width: 300, height: 300, borderRadius: 20 }}></img>
                                <div style={{ ...styles.Block }}>
                                    <div style={{ ...styles.BlockLeft, width: 280 }}>
                                        <p style={{ ...styles.TextXXTiny, fontWeight: 'normal', marginTop: 5, color: global.theme_medium_gray }}>{item.datum}</p>
                                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 15 }}>{item.title}</p>
                                        <div style={{ margin: 0, fontSize: global.font_normal, color: global.theme_dark_gray, fontWeight: 'normal', textAlign: 'left', lineHeight: 1.2, marginTop: 15 }}>
                                            <LinesEllipsis
                                                text={item.description}
                                                maxLine={3}
                                                ellipsis='...'
                                                trimRight
                                                basedOn='letters'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}

                        {count > 1 ?
                            <div style={{ ...styles.Block, maxWidth: 900, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                <div style={{ height: 30 }}>
                                    {isBusy == true ?
                                        <CircularProgress color="inherit" size={20} />
                                        : null}
                                </div>
                                <Pagination count={count} size={'medium'} page={page} onChange={PageChange} showFirstButton showLastButton />
                            </div>
                            : null}

                    </div>
                    :
                    <div style={{ ...styles.BlockCenter, height: 700 }}>
                    </div>
                }

            </div>

            {
                isBusy == true ?
                    <Loading></Loading>
                    : null
            }
        </div >

    )
};

