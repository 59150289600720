import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, MenuTop, Footer, ShoppingChangeQuantity, Loading, ShoppingQuantity, ShoppingDeleteID, GetPrice, PlusMinus, LoadUser, LoadLogin, ShoppingList, BannerImage, GetCost, GetSume, GoHome, GetDiscount, SaveUser } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputLabel } from '@mui/material';
import { faExclamationCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { is } from 'date-fns/locale';
import { loadingButtonClasses } from '@mui/lab';
import { styles } from './styles.js';

export const ShoppingCard = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [shoppingCardQuantity, setShoppingCardQuantity] = useState(0);
    const [user, setUser] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [isDPH, setDPH] = useState(false); // Je platitel DPH
    const [banner, setBanner] = useState(null);
    const [redraw, setRedraw] = useState(false);
    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [coeficient, setcoeficient] = useState(1);

    const [list, setList] = useState([]);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        if (data.logged == true) {
            setDiscount(data.user.discount);
            db_shopping_card(data, data.id);
        } else {
            var data_list = ShoppingList();
            if (data_list != null) {
                db_shopping_card(data_list, 0);
            }
            setDiscount(0);
        }

        GoHome();

        return () => {
        };

    }, []);



    const db_shopping_card = async (data_, user_id) => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'shopping_card_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    list: data_,
                    user_id: parseInt(user_id)
                })
            })

            const json = await response.json();
Debug(json);
            if (json.ok > 0) {
                setList(json.products);

                if (user_id > 0) {
                    SaveUser(json.user);

                    var data = GetUser();
                    setDPH(data.dph);
                    setB2B(data.b2b);
                    setLogged(data.logged);
                    setUser(data.user);
                }

                // --------------------------------------
                // POCET POLOZIEK V KOSIKU AK JE PRIHLASENY
                // --------------------------------------
                if (user_id > 0) {
                    //setShoppingCardQuantity(json.card_quantity);
                    // pocet poloziek v kosiku
                    func(1, json.card_quantity);
                }

                setDiscount(json.discount);
                setcoeficient(parseFloat(json.coeficient));

                setBanner(json.banner);
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const db_shopping_card_update = async (item_id, quantity) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'shopping_card_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: parseInt(user.id),
                    item_id: item_id,
                    quantity: quantity
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {
                GetQuantity();
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const db_shopping_card_delete = async (item_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'shopping_card_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: parseInt(user.id),
                    item_id: item_id
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok != 0) {
                db_shopping_card('', user.id);
                //GetQuantity();
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const MenuTopPress = (type, value) => {
        if (type == 0) {
            // registracia
            navigate('/user_register');
        }
    }
    const MenuLogoPress = (type, value) => {
        if (type == 0) {
            // KLIKNUTIE NA LOGO            
            navigate('/');
        }
    }

    const GotoBack = () => {
        navigate(-1);
    }

    const Delete = (item) => {
        if (logged == true) {
            db_shopping_card_delete(item.id);
        } else {
            var data = ShoppingDeleteID(item.id);
            if (data != null) {
                db_shopping_card(data);
                GetQuantity();
            }
        }
    }

    const GetQuantity = () => {
        // pocet poloziek v kosiku
        if (logged == true) {
            var data = list;
            var q = 0;
            data.forEach(item => {
                q = q + parseInt(item.quantity);
            });
            //console.log(q);
            //setShoppingCardQuantity(q);
            // pocet poloziek v kosiku
            func(1, q);
        } else {
            //setShoppingCardQuantity(ShoppingQuantity());
            // pocet poloziek v kosiku
            func(1, ShoppingQuantity());
        }
    }


    const PlusMinusPress = (value, id) => {
        // ZMENA POCTU KUSOV
        var data = list
        if (data != null) {
            data = data.filter(x => x.id == id);
            if (data.length > 0) {
                data[0].quantity = value;
                if (logged == true) {
                    db_shopping_card_update(id, value);
                } else {
                    ShoppingChangeQuantity(id, value);
                    GetQuantity();
                }
            }
        }

        setRedraw(!redraw);
    }

    const Checkout = () => {
        navigate('/checkout');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef}>
                {/*
                <MenuTop loged={false} mobil={web.mobil} email={'info'} name={''} surname={''} func={MenuTopPress.bind(this)} />
                <MenuLogo quantity={shoppingCardQuantity} func={MenuLogoPress.bind(this)} />
                */}
                {banner != null ?
                    <BannerImage banner={banner} />
                    : null}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', backgroundColor: global.theme_white }}>
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: isSmall ? '100%' : global.screen_max, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ fontSize: global.font_label }}>{lang.shopping_card}</p>

                        {list.length > 0 ?
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end', paddingTop: 10, paddingBottom: 5 }}>
                                <div style={{ display: 'flex', width: isSmall ? '100%' : '50%', flexDirection: 'row' }}>
                                    <p style={{ margin: 0, color: global.theme_xxgray, fontSize: 13, fontWeight: 'normal', marginLeft:10 }}>{lang.card_data}</p>
                                </div>
                                {isSmall ? null :
                                    <div style={{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                        <p style={{ margin: 0, color: global.theme_xxgray, fontSize: 13, fontWeight: 'normal' }}>{b2b == true ? isDPH == true ? lang.without_dph : lang.with_dph : lang.with_dph}</p>
                                    </div>
                                }
                            </div>
                            : null}

                        {list.map(item => {
                            return (
                                <div key={item.id} style={{ display: 'flex', alignSelf: 'flex-start', width: '100%', paddingTop: 10, paddingBottom: 10, borderTop: '1px solid ' + global.theme_gray }}>
                                    {isLarge ?
                                        <div style={{ ...styles.BlockRow }}>
                                            <Button onClick={() => navigate('/product/' + item.tid)} style={{ display: 'flex', alignItems: 'center', width: 140, height: 100, justifyContent: 'center', backgroundColor: global.theme_white }}>
                                                <img src={global.product_image + item.path + '/th_' + item.image} style={{ maxWidth: 100, maxHeight: 100 }} />
                                            </Button>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20, paddingLeft: 20 }}>
                                                <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal' }}>{lang.code}: {item.code}</p>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'normal', textAlign: 'left' }}>{item.title}</p>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 13, fontWeight: 'normal', textAlign: 'left' }}>{item.description}</p>
                                                <p style={{ margin: 0, color: parseInt(item.count) > 0 ? item.quantity > parseInt(item.count) ? global.theme_red : global.theme_dark_green : global.theme_dark_red, fontSize: 12, fontWeight: item.count > 0 ? item.quantity > item.count ? 'bold' : 'normal' : 'bold', marginTop: 5 }}>
                                                    {parseInt(item.count) > 0 ? item.quantity > parseInt(item.count) ?
                                                        <FontAwesomeIcon style={{ color: global.theme_red, fontSize: 16, marginRight: 5 }} icon={faExclamationCircle} />
                                                        : null : null}
                                                    {lang.stock}: {parseInt(item.count)} {lang.pieces}
                                                </p>
                                                {isDPH == true ?
                                                    <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal', marginTop: 5 }}>{lang.DPH}: {parseInt(item.dph)}%</p>
                                                    :
                                                    null
                                                }
                                            </div>


                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', width: 100, paddingTop: 10 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'normal' }}>{GetPrice(GetCost(b2b == true ? 0 : 1, isDPH == true ? item.cost : item.cost_dph, coeficient))} {lang.money}</p>
                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: 200 }}>
                                                <PlusMinus quantity={item.quantity} id={item.id} func={PlusMinusPress.bind(this)} />
                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', width: 100, paddingTop: 10 }}>
                                                <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold' }}>{GetPrice(GetCost(b2b == true ? 0 : 1, isDPH == true ? item.cost * item.quantity : item.cost_dph * item.quantity, coeficient))} {lang.money}</p>
                                            </div>

                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-end', width: 50 }}>
                                                <IconButton onClick={() => Delete(item)} style={{ height: 35 }}>
                                                    <FontAwesomeIcon style={{ color: global.theme_dark_gray, fontSize: 16 }} icon={faTrashAlt} />
                                                </IconButton>
                                            </div>
                                        </div>
                                        :
                                        // SMALL
                                        <div style={{ ...styles.Block, width: '96%' }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <Button onClick={() => navigate('/product/' + item.tid)} style={{ display: 'flex', alignItems: 'center', width: 140, height: 120, justifyContent: 'center', backgroundColor: global.theme_white }}>
                                                    <img src={global.product_image + item.path + '/th_' + item.image} style={{ maxWidth: 100, maxHeight: 100 }} />
                                                </Button>
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: '50%', paddingRight: 20, paddingLeft: 20 }}>
                                                    <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal' }}>{lang.code}: {item.code}</p>
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'normal', textAlign: 'left' }}>{item.title}</p>
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 13, fontWeight: 'normal', textAlign: 'left' }}>{item.description}</p>
                                                    <p style={{ margin: 0, color: parseInt(item.count) > 0 ? item.quantity > parseInt(item.count) ? global.theme_red : global.theme_dark_green : global.theme_dark_red, fontSize: 12, fontWeight: item.count > 0 ? item.quantity > item.count ? 'bold' : 'normal' : 'bold', marginTop: 5 }}>
                                                        {parseInt(item.count) > 0 ? item.quantity > parseInt(item.count) ?
                                                            <FontAwesomeIcon style={{ color: global.theme_red, fontSize: 16, marginRight: 5 }} icon={faExclamationCircle} />
                                                            : null : null}
                                                        {lang.stock}: {parseInt(item.count)} {lang.pieces}
                                                    </p>
                                                    {isDPH == true ?
                                                        <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal', marginTop: 5 }}>{lang.DPH}: {parseInt(item.dph)}%</p>
                                                        :
                                                        null
                                                    }
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 12, fontWeight: 'normal', marginTop:5 }}>Cena: {GetPrice(GetCost(b2b == true ? 0 : 1, isDPH == true ? item.cost : item.cost_dph, coeficient))} {lang.money}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50 }}>
                                                    <IconButton onClick={() => Delete(item)} style={{ height: 35 }}>
                                                        <FontAwesomeIcon style={{ color: global.theme_dark_gray, fontSize: 16 }} icon={faTrashAlt} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: 10, paddingBottom: 10 }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                        <PlusMinus quantity={item.quantity} id={item.id} func={PlusMinusPress.bind(this)} />
                                                    </div>
                                                    <div style={{ ...styles.BlockRight }}>
                                                        <p style={{ ...styles.TextSmall, marginTop: 20, fontWeight: 'bold' }}>{'Spolu:'} {GetPrice(GetCost(b2b == true ? 0 : 1, isDPH == true ? item.cost * item.quantity : item.cost_dph * item.quantity, coeficient))} {lang.money}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }




                                </div>
                            )
                        })}

                        {list.length > 0 && b2b == true && GetDiscount(GetSume(list, false, b2b, false, coeficient, -1))[1] > 0 ?
                            <div style={{ ...styles.Block, width: '100%', backgroundColor: global.theme_light_red, paddingTop: 25, paddingBottom: 25 }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>Poskytnutá zľava vo výške {discount == 0 ? GetDiscount(GetSume(list, false, b2b, false, coeficient, -1))[1] : discount}% </p>
                            </div>
                            : null}

                        {list.length > 0 ?
                            isDPH == true ?
                                // ZOBRAZENIE SUMY - PLATCA DPH
                                <div style={{ display: 'flex', flexDirection: 'column', width: isLarge ? '100%' : '96%', justifyContent: 'flex-end', alignItems: 'flex-end', paddingRight: 0, backgroundColor: global.theme_back, paddingTop: 20, paddingBottom: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 0, marginRight: isLarge ? 40 : 10 }}>
                                        <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_medium_gray }}>{lang.sum_dph_}:</p>
                                        <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium_gray }}>{GetPrice(GetSume(list, true, b2b, true, coeficient, discount))} {lang.money}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginRight: isLarge ? 40 : 10  }}>
                                        <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_medium_gray }}>{lang.sum_with} {lang.dph}% {lang.DPH}:</p>
                                        <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium_gray }}>{GetPrice(GetSume(list, true, b2b, false, coeficient, discount))} {lang.money}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginRight: isLarge ? 40 : 10  }}>
                                        <p style={{ margin: 0, fontSize: global.font_normal, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_black }}>{lang.sum_without_dph}:</p>
                                        <p style={{ margin: 0, fontSize: global.font_large, fontWeight: 'bold', color: global.theme_black }}>{GetPrice(GetSume(list, false, b2b, false, coeficient, discount))} {lang.money}</p>
                                    </div>
                                </div>
                                :
                                // ZOBRAZENIE SUMY - NEPLATCA DPH / NEPRIHLASENY
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end', paddingRight: 0, backgroundColor: global.theme_back, paddingTop: 20, paddingBottom: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: isLarge ? 40 : 10  }}>
                                        <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_medium_gray }}>{lang.sum_without_dph}:</p>
                                        <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium_gray }}>{GetPrice(GetSume(list, false, b2b, false, coeficient, discount))} {lang.money}</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginRight: isLarge ? 40 : 10  }}>
                                        <p style={{ margin: 0, fontSize: global.font_normal, fontWeight: 'normal', marginRight: 20, marginTop: 2 }}>{lang.sum_with_dph}:</p>
                                        <p style={{ margin: 0, fontSize: global.font_large, fontWeight: 'bold' }}>{GetPrice(GetSume(list, true, b2b, false, coeficient, discount))} {lang.money}</p>
                                    </div>
                                </div>

                            :
                            <p style={{ fontSize: global.font_large }}>{lang.card_empty}</p>
                        }

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '95%', paddingTop: 20, paddingBottom: 150, borderTop: '1px solid ' + global.theme_gray }}>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: list.length == 0 ? '100%' : '50%', justifyContent: list.length == 0 ? 'center' : 'flex-start' }}>
                                    <Button onClick={() => GotoBack()} style={{ backgroundColor: global.theme_white, width: isSmall ? 150 : 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black }} variant="outlined">{lang.back_to_shop}</Button>
                                </div>
                                {list.length > 0 ?
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>

                                        <Button onClick={() => Checkout()} variant="contained" style={{ ...styles.ButtonDarkGreen, width: isSmall ? 150 : 200 }}>{lang.to_checkout}</Button>

                                    </div>
                                    : null}
                            </div>
                        </div>

                        <div style={{ height: 20 }}></div>

                    </div>
                </div>

                {/* FOOTER */}
                {/*isBusy == false ?
                    <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                                    : null*/}

                {isLoading == true ?
                    <Loading></Loading>
                    : null}
            </div>
        </div >
    )
};


