import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper, stepLabelClasses } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, CookiesInfo, Menu, ShoppingQuantity, Loading, LoadUser, LoadLogin, BannerSlider, ProductBox, Footer, UniqueID, LoadUniqueID, SaveUniqueID, GetCost, GetPrice, ProductBuy, BannerServices, BannerReserveDate, GoHome, LoadCookies, SaveCookies, DivideText2 } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import LinesEllipsis from 'react-lines-ellipsis'
import { styles } from './styles.js';

export const Main = (props) => {
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [web, setWeb] = useState([]);

    // FOOTER    
    const [subcompanies, setSubcompanies] = useState([]);
    const [blogLabel, setBlogLabel] = useState('');
    const [catalogueLabel, setCatalogueLabel] = useState('');
    const [actionLabel, setActionLabel] = useState('');
    const [newsletterSended, setNewsletterSended] = useState('');

    const [shoppingCardQuantity, setShoppingCardQuantity] = useState(0);
    const [coeficient, setcoeficient] = useState(1);
    const [isDPH, setDPH] = useState(false); // Je platitel DPH
    const [user, setUser] = useState(null);
    const [redraw, setRedraw] = useState(false);
    const [showProductBuy, setShowProductBuy] = useState(false);
    const [buyItem, setBuyItem] = useState([]);

    const [allCategories, setAllCategories] = useState([]);
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [subProducts, setSubProducts] = useState([]);
    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [banners, setBanners] = useState([]);
    const [newProducts, setNewProducts] = useState([]);
    const [newProductsLabel, setNewProductsLabel] = useState('');
    const [mainSections, setMainSections] = useState([]);
    const [sectionsLabel, setSectionsLabel] = useState('');
    const [sectionAction, setSectionAction] = useState(null);

    const [reserve1, setReserve1] = useState('');
    const [reserve2, setReserve2] = useState('');

    const [catalogueImage, setCatalogueImage] = useState(null);
    const [blogImage, setBlogImage] = useState(null);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)
    const sliderRef = useRef(null)

    //const logo = require('./react/app/logo.png');
    //const img_back1 = require('./react/app/back_1.jpg');
    //const img_ask = require('./react/app/ask.png');
    const img_discount = require('./react/app/discount.png');

    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        db_web(data.id);

        GoHome();

        
        return () => {
        };

    }, []);

    const db_web = async (user_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'main_page', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: user_id,
                    banners_typ: 0,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setWeb(json.web);

                // --------------------------------------
                // BANNERS
                // --------------------------------------
                var tmp = json.banners;
                tmp.sort((a, b) => {
                    if (a.priority > b.priority) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setBanners(tmp);

                // --------------------------------------
                // NOVINKY
                // --------------------------------------
                var tmp = json.new_products;
                tmp.sort((a, b) => {
                    if (parseInt(a.id) > parseInt(b.id)) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setNewProducts(tmp);


                // --------------------------------------
                // SEKCIE
                // --------------------------------------
                var tmp = json.main_sections;
                tmp.sort((a, b) => {
                    if (a.priority > b.priority) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setMainSections(tmp);

                // --------------------------------------
                // SEKCIA - AKCIA
                // --------------------------------------
                var tmp = json.section_action;
                setSectionAction(tmp);

                // --------------------------------------
                // KOEFICIENT PREPOCTU CIEN
                // --------------------------------------
                var tmp = json.coeficient;
                setcoeficient(parseFloat(tmp));

                // --------------------------------------
                // POCET POLOZIEK V KOSIKU AK JE PRIHLASENY
                // --------------------------------------
                if (user_id > 0) {
                    setShoppingCardQuantity(json.shopping_quantity);
                } else {
                    setShoppingCardQuantity(ShoppingQuantity());
                }

                // --------------------------------------
                // WEB - PREVADZKY - FOOTER
                // --------------------------------------
                //setSubcompanies(json.subcompanies);

                // --------------------------------------
                // TEXTY
                // --------------------------------------
                setSectionsLabel(json.txt_sections.string);
                setNewProductsLabel(json.txt_new_products.string);
                setActionLabel(json.txt_action.string);
                setBlogLabel(json.txt_blog.string);
                setBlogImage(json.txt_blog.image);
                setCatalogueLabel(json.txt_catalogue.string);
                setCatalogueImage(json.txt_catalogue.image);
                setNewsletterSended(json.txt_newsletter_ok.string);
                setReserve1(json.txt_reserve_1.string);
                setReserve2(json.txt_reserve_2.string);

                setLoading(false);
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const reloadPage = () => {
        window.location.reload(false);
    }

    const MenuTopPress = (type, value) => {
        if (type == 0) {
            // registracia
            navigate('/user_register');
        }
        if (type == 1 || type == 2) {
            // prihlásenie / odhlásenie
            reloadPage();
        }
    }

    const MenuLogoPress = (type, value) => {
        if (type == 0) {
            // KLIKNUTIE NA LOGO            
            navigate('/');
        }
    }


    const DeleteScroll = () => {
        //sessionStorage.removeItem('scrollPosition')
        sessionStorage.setItem('scrollPosition', 0);
    }

    const NextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, top: '40%' }}
                onClick={onClick}
            >
                <FontAwesomeIcon style={{ color: global.theme_light, fontSize: 22 }} icon={faChevronRight} />
            </div>
        );
    }

    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 30, top: '40%' }}
                onClick={onClick}
            >
                <FontAwesomeIcon style={{ color: global.theme_light, fontSize: 22 }} icon={faChevronLeft} />
            </div>
        );
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: isSmall == true ? 1 : isMedium ? 2 : 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        autoplay: true
    }

    const SelectProduct = (id) => {
        var tmp = newProducts.filter(x => x.id == id);
        if (tmp.length > 0) {
            navigate('/product/' + tmp[0].tid);
        }

    }

    const ProductPress = (item, type) => {
        if (type == false) {
            // zobrazit produkt
            SelectProduct(item.id)
        } else {
            // kupit produkt
            setBuyItem(item);
            setShowProductBuy(true);
        }
    }

    const ActionPress = () => {
        sessionStorage.removeItem('scrollPosition')
        navigate('/catalog/akcia/akcie-a-zlavy');
    }

    const BlogPress = () => {
        navigate('/blogs');
    }

    const CataloguePress = () => {
        navigate('/downloads');
    }

    const ProductBuyPress = (type, quantity) => {
        if (type == false) {
            setShowProductBuy(false);
        } else {
            setShoppingCardQuantity(shoppingCardQuantity + quantity);
            setShowProductBuy(false);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef}>
                {/*
                <MenuTop loged={false} mobil={web.mobil} email={'info'} name={''} surname={''} func={MenuTopPress.bind(this)} />
                <MenuLogo quantity={shoppingCardQuantity} func={MenuLogoPress.bind(this)} />
                
                <Menu sections={sections} subsections={subsections} func={MenuPress.bind(this)} />
                */}
                {isLoading == true ? <div style={{ height: height }}></div> : null}

                <BannerSlider banners={banners} />

                {user != null ? user.discount == 0 && b2b == true ?
                    <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 0, backgroundColor: global.theme_light_green, marginTop: 20, marginBottom: 0 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ paddingTop: 20 }}>
                                <p style={{ ...styles.TextLarge }}>{lang.discount_text}</p>
                            </div>
                            <img src={img_discount} style={{ width: 120 }}></img>
                        </div>
                    </div>
                    : null : null}
                <BannerServices />

                {/*
                isLoading == false ?
                    <div style={{ width: '100%', position: 'relative', height: 100 }}>
                        <div style={{ width: '100%' }}>
                            <img src={img_back1} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        </div>
                        <div style={{ position: 'absolute', left: 0, top: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 0 }}>
                            <p style={{ color: global.theme_dark, fontSize: global.font_label }}>{sectionsLabel}</p>
                        </div>
                    </div>
                    : null
                */}

                {/* ZOZNAM SEKCII */}
                {isLoading == false ?
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', paddingTop: 0 }}>
                            <p style={{ color: global.theme_dark, fontSize: global.font_label, marginTop: 70, marginBottom: 40 }}>{sectionsLabel}</p>
                        </div>
                        <div style={{ ...styles.Block, marginBottom: 60 }}>
                            <div style={{ ...styles.BlockMaxLarge, flexDirection: 'row', maxWidth: 1000, flexWrap: 'wrap' }}>

                                {mainSections.map(item => {
                                    return (
                                        <div key={item.id} style={{ marginBottom: isSmall ? 10 : 20 }}>
                                            <Button onClick={() => { DeleteScroll(); navigate('/catalog/' + item.slug); }} style={{ width: isSmall ? '95%' : 480, height: isSmall ? 'auto' : 240, marginLeft: isSmall ? 0 : 10, marginRight: isSmall ? 0 : 10, textTransform: 'none', border: isSmall ? '0px' : '1px solid ' + global.theme_light_gray, borderRadius: 10 }} >
                                                <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                                    <img src={global.images + 'catalog/' + item.image + '?id=sub-' + item.id} height={isSmall ? 'auto' : 240} width={isSmall ? '100%' : 480} style={{ borderRadius: 10 }} />
                                                    <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '0%', width: isSmall ? '70%' : '50%', left: 0, paddingLeft: 20, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                        <p style={{ margin: 0, fontSize: isSmall ? global.font_large : global.font_xxlarge, color: global.theme_black, fontWeight: 'bold' }}>{item.label}</p>
                                                        {/*
                                                            <p style={{ margin: 0, fontSize: global.font_normal, color: global.theme_black, fontWeight: 'normal', textAlign: 'left', lineHeight: 1.2 }}>{item.text}</p>
                                                        */}
                                                        <div style={{ margin: 0, fontSize: isSmall ? global.font_small : global.font_normal, color: global.theme_black, fontWeight: 'normal', textAlign: 'left', lineHeight: 1.2, marginTop: 10 }}>
                                                            <LinesEllipsis
                                                                text={item.text}
                                                                maxLine={8}
                                                                ellipsis='...'
                                                                trimRight
                                                                basedOn='letters'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    : null}

                {/* NOVE PRODUKTY V ESHOPE */}
                {
                    isLoading == false ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 0, marginBottom: 30, backgroundColor: global.theme_new_products, paddingBottom: 10 }}>
                            <div style={{ padding: 10, maxWidth: isSmall == true ? 240 : isMedium ? 500 : global.screen_max }}>
                                <p style={{ fontSize: global.font_label, color: global.theme_dark }}>{newProductsLabel}</p>
                                <Slider ref={sliderRef}  {...sliderSettings}>
                                    {newProducts.map(item => {
                                        return (
                                            <div key={item.id}>
                                                <ProductBox price={GetPrice(GetCost(b2b == true ? 0 : 1, b2b == true ? isDPH == true ? item.cost : item.cost_dph : item.cost_dph, coeficient))} dph={b2b == true ? false : true} typ={2} item={item} func={ProductPress.bind(this)} />
                                            </div>
                                        )
                                    })}

                                </Slider>
                            </div>
                        </div >
                        : null
                }

                {/* AKCIA, ... */}
                {
                    isLoading == false ?
                        <div style={{ display: 'flex', flexDirection: isSmall || isMedium ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', marginTop: isSmall || isMedium ? 50 : 100, marginBottom: isSmall || isMedium ? 50 : 100 }}>
                            {sectionAction != null ?
                                <Button onClick={() => ActionPress()} style={{ width: 310, height: 310, marginLeft: 10, marginRight: 10, marginBottom: 10, textTransform: 'none', border: '1px solid ' + global.theme_light_gray, borderRadius: global.radius }} >
                                    <Paper elevation={6} style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: global.radius }}>
                                        <img src={global.images + 'catalog/' + sectionAction.image} height={310} width={310} style={{ borderRadius: global.radius }} />
                                        <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '70%', width: '85%', left: 0, paddingLeft: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#FFFFFFEE', alignContent: 'flex-start', justifyItems: 'flex-start', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                                            <p style={{ margin: 0, fontSize: global.font_xlarge, color: global.theme_black, fontWeight: 'lighter', textAlign: 'center' }}>{actionLabel}</p>
                                        </Paper>
                                    </Paper>
                                </Button>
                                : null}
                            <Button onClick={() => BlogPress()} style={{ width: 310, height: 310, marginLeft: 10, marginRight: 10, marginBottom: 10, textTransform: 'none', border: '1px solid ' + global.theme_light_gray, borderRadius: global.radius, marginTop: isSmall || isMedium ? 50 : 0 }} >
                                <Paper elevation={6} style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: global.radius }}>
                                    <img src={global.images + 'catalog/' + blogImage} height={310} width={310} style={{ borderRadius: global.radius }} />
                                    <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '70%', width: '85%', left: 0, paddingLeft: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#FFFFFFEE', alignContent: 'flex-start', justifyItems: 'flex-start', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                                        <p style={{ margin: 0, fontSize: global.font_xlarge, color: global.theme_black, fontWeight: 'lighter', textAlign: 'center' }}>{blogLabel}</p>
                                    </Paper>
                                </Paper>
                            </Button>
                            <Button onClick={() => CataloguePress()} style={{ width: 310, height: 310, marginLeft: 10, marginRight: 10, marginBottom: 10, textTransform: 'none', border: '1px solid ' + global.theme_light_gray, borderRadius: global.radius, marginTop: isSmall || isMedium ? 50 : 0 }} >
                                <Paper elevation={6} style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: global.radius }}>
                                    <img src={global.images + 'catalog/' + catalogueImage} height={310} width={310} style={{ borderRadius: global.radius }} />
                                    <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '70%', width: '85%', left: 0, paddingLeft: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: '#FFFFFFEE', alignContent: 'flex-start', justifyItems: 'flex-start', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}>
                                        <p style={{ margin: 0, fontSize: global.font_xlarge, color: global.theme_black, fontWeight: 'lighter', textAlign: 'center' }}>{catalogueLabel}</p>
                                    </Paper>
                                </Paper>
                            </Button>
                        </div>
                        : null
                }



                {/* FACEBOOK, ... */}
                {
                    isLoading == false ?
                        <div style={{ ...styles.Block, marginTop: 0, marginBottom: 0, backgroundColor: global.theme_back, paddingTop: 20, paddingBottom: 60 }}>
                            <p style={{ ...styles.TextLabel, color: global.theme_black, fontWeight: 'normal' }}>{'Sledujte nás'}</p>
                            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                <a href={web.facebook} target="_blank" style={{ ...styles.Block, width: isSmall ? 70 : 150, height: isSmall ? 70 : 150, margin: isSmall ? 5 : 20, border: '2px solid ' + global.theme_dark_blue, borderRadius: 100 }} >
                                    <img src={global.images + 'app/facebook2.png'} width={isSmall ? 60 : 125} />
                                </a>
                                <a href={web.instagram} target="_blank" style={{ ...styles.Block, width: isSmall ? 70 : 150, height: isSmall ? 70 : 150, margin: isSmall ? 5 : 20, border: '2px solid #CC0088', borderRadius: 100 }} >
                                    <img src={global.images + 'app/instagram2.png'} width={isSmall ? 60 : 125} />
                                </a>
                                <a href={web.youtube} target="_blank" style={{ ...styles.Block, width: isSmall ? 70 : 150, height: isSmall ? 70 : 150, margin: isSmall ? 5 : 20, border: '2px solid #AA0000', borderRadius: 100 }} >
                                    <img src={global.images + 'app/youtube2.png'} width={isSmall ? 60 : 125} />
                                </a>
                                <Button onClick={() => navigate('/pytate_sa')} style={{ ...styles.Block, width: 150, height: 150, margin: 20, border: '2px solid #00aa00', borderRadius: 100, backgroundColor: '#eeffee' }} >
                                    <img src={global.images + 'app/questions.png'} width={125} />
                                </Button>
                            </div>
                        </div>
                        : null
                }

                {/* OBJEDNAJTE SA K NAM */}
                {
                    isLoading == false ?
                        <BannerReserveDate reserve1={reserve1} reserve2={reserve2} />
                        : null
                }


                <div style={{ ...styles.Block, height: 50, backgroundColor: global.theme_gray }}>
                </div>

                {/* FOOTER */}
                {/*
                isLoading == false ?
                    <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                    : null
                */}

                {
                    isLoading == true ?
                        <Loading></Loading>
                        : null
                }

                {redraw == true ? null : null}

                {
                    showProductBuy == true ?
                        <ProductBuy user={user} logged={logged} b2b={b2b} isDPH={isDPH} coeficient={coeficient} item={buyItem} func={ProductBuyPress.bind(this)} />
                        : null
                }

            </div >

        </div >
    )
};


