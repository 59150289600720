import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, ValidateEmail, Loading, ShoppingQuantity, GetPrice, ShoppingList, GetCost, GetSume, GetPriceRound, ShoppingDeleteAll, GoHome, GetDiscountPayment, GetDiscount } from './items.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Stepper } from '@mui/material';
import { Step, Paper, Alert } from '@mui/material';
import { RadioGroup } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Radio } from '@mui/material';
import { styles } from './styles.js';
import { Checkbox } from '@mui/material';


export const Checkout = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [shoppingCardQuantity, setShoppingCardQuantity] = useState(0);
    const [web, setWeb] = useState([]);
    const [user, setUser] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [banner, setBanner] = useState(null);
    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [coeficient, setcoeficient] = useState(1);
    const [activeStep, setActiveStep] = useState(0);
    const [isDPH, setDPH] = useState(false); // Je platitel DPH

    const [trade, setTrade] = useState([]);
    const [tradeSelected, setTradeSelected] = useState([]);
    const [tradeID, setTradeID] = useState(0);
    const [tradeSum, setTradeSum] = useState(0);

    const [payment, setPayment] = useState([]);
    const [paymentSelected, setPaymentSelected] = useState(null);
    const [paymentID, setPaymentID] = useState(0);
    const [paymentDiscount, setPaymentDiscount] = useState(0);

    const [sum, setSum] = useState(0.00);
    const [rawSum, setRawSum] = useState(0.00); // suma bez dph za produkty a bez zlavy
    const [sumProducts, setSumProducts] = useState(0.00); // suma bez dph za produkty aj so zľavou
    const [sumProductsDPH, setSumProductsDPH] = useState(0.00); // suma s dph za produkty aj so zľavou
    const [sumDPH, setSumDPH] = useState(0.00);
    const [sumItemDPH, setSumItemDPH] = useState(0.00);
    const [productSum, setProductSum] = useState(0.00);
    const [productSumDPH, setProductSumDPH] = useState(0.00);
    const [sumProductItemDPH, setProductSumItemDPH] = useState(0.00);
    const [gdpr, setGDPR] = useState(true);
    const [gdprErr, setGDPRErr] = useState(false);
    const [list, setList] = useState([]);

    const [cName, setCName] = useState('');
    const [errcName, setErrCName] = useState(false);
    const [company, setCompany] = useState('');
    const [cStreet, setCStreet] = useState('');
    const [errcStreet, setErrCStreet] = useState(false);
    const [cStreetNumber, setCStreetNumber] = useState('');
    const [cPSC, setCPSC] = useState('');
    const [errcPSC, setErrCPSC] = useState(false);
    const [cTown, setCTown] = useState('');
    const [errcTown, setErrCTown] = useState(false);
    const [cState, setCState] = useState('');
    const [errcState, setErrCState] = useState(false);
    const [ico, setICO] = useState('');
    const [errico, setErrICO] = useState(false);
    const [dic, setDIC] = useState('');
    const [errdic, setErrDIC] = useState(false);
    const [icdph, setICDPH] = useState('');
    const [mobil, setMobil] = useState('');
    const [errMobil, setErrMobil] = useState(false);
    const [email, setEmail] = useState('');
    const [errEmail, setErrEmail] = useState(false);
    const [errEmailText, setErrEmailText] = useState('');
    const [note, setNote] = useState('');

    const [dCompany, setDCompany] = useState('');
    const [dName, setDName] = useState('');
    const [errdName, setErrDName] = useState(false);
    const [dStreet, setDStreet] = useState('');
    const [dStreetNumber, setDStreetNumber] = useState('');
    const [errdStreet, setErrDStreet] = useState(false);
    const [dPSC, setDPSC] = useState('');
    const [errdPSC, setErrDPSC] = useState(false);
    const [dTown, setDTown] = useState('');
    const [errdTown, setErrDTown] = useState(false);
    const [dState, setDState] = useState('');
    const [errdState, setErrDState] = useState(false);
    const [delivery, setDelivery] = useState(true);
    const [newsletter, setNewsletter] = useState(false);
    const [orderID, setOrderID] = useState(0);

    const [voucher, setVoucher] = useState('');
    const [voucherText, setVoucherText] = useState('');
    const [voucherPrice, setVoucherPrice] = useState(0); // cena bez DPH
    const [voucherPriceDPH, setVoucherPriceDPH] = useState(0); // cena s DPH
    const [voucherError, setVoucherError] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const paymentRef = useRef(null)
    const continueRef = useRef(null)
    const sumarRef = useRef(null)

    const logo = require('./react/app/logo.png');
    const steps = [lang.checkout_step_1, lang.checkout_step_2, lang.checkout_step_3];



    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        if (data.logged == true) {
            setDiscount(data.user.discount);
            setPaymentDiscount(data.user.discount);
            setCName(data.user.meno != null ? data.user.meno : '');
            setCompany(data.user.fname != null ? data.user.fname : '');
            setCStreet(data.user.fulica != null ? data.user.fulica : '');
            setCPSC(data.user.fpsc != null ? data.user.fpsc : '');
            setCTown(data.user.fmesto != null ? data.user.fmesto : '');
            setCState(data.user.fstat != null ? data.user.fstat : '');
            setICO(data.user.ico != null ? data.user.ico : '');
            setDIC(data.user.dic != null ? data.user.dic : '');
            setICDPH(data.user.icdph != null ? data.user.icdph : '');
            setEmail(data.user.email != null ? data.user.email : '');
            setMobil(data.user.tel != null ? data.user.tel : '');
            setDCompany(data.user.dfirma != null ? data.user.dfirma : '');
            setDName(data.user.dmeno != null ? data.user.dmeno : '');
            setDStreet(data.user.dulica != null ? data.user.dulica : '');
            setDPSC(data.user.dpsc != null ? data.user.dpsc : '');
            setDTown(data.user.dmesto != null ? data.user.dmesto : '');
            setDState(data.user.dstat != null ? data.user.dstat : '');
            setDelivery(parseInt(data.user.dadresa) == 0 ? false : true);
        } else {
            setDiscount(0);
            setPaymentDiscount(0);
        }

        GoHome();
        db_trade(data.b2b, data.dph);

        setCState(lang.state_sk);
        setDState(lang.state_sk);

        window.scrollTo(0, 0);
        return () => {
        };

    }, []);

    const db_voucher_check = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'voucher_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    voucher: voucher.trim()
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {
                let voucher = json.voucher;
                
                if (voucher != null) {
                    if (parseFloat(sumProducts) >= parseFloat(json.voucher.min_price)) {
                        setVoucherText(voucher.text);
                        setVoucherPrice(voucher.price);
                        setVoucherPriceDPH(GetPriceRound(voucher.price * 1.2));
                        CalculateSum(list, coeficient, voucher.price, GetPriceRound(voucher.price * 1.2));
                    } else {
                        setVoucher('');
                        setVoucherText('');
                        setVoucherPrice(0);
                        setVoucherPriceDPH(0);
                        if (isDPH == false) {
                            // konecný spotrebitel
                            setVoucherError(lang.voucher_error_sum + ' ' + GetPrice(GetPriceRound(json.voucher.min_price * 1.2)) + ' ' + lang.money + ' s DPH');
                        } else {
                            // platca dph
                            setVoucherError(lang.voucher_error_sum + ' ' + GetPrice(json.voucher.min_price) + ' ' + lang.money + ' bez DPH');
                        }

                        CalculateSum(list, coeficient, 0, 0);
                    }
                } else {
                    setVoucher('');
                    setVoucherText('');
                    setVoucherPrice(0);
                    setVoucherPriceDPH(0);                    
                    setVoucherError(lang.voucher_error);
                    CalculateSum(list, coeficient, 0, 0);
                }

            }
        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const db_shopping_card = async (data, user_id) => {
        setLoading(true);
        
        try {
            const response = await fetch(
                global.db_url + 'shopping_card_list', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    list: data,
                    user_id: parseInt(user_id)
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.discount > 0) {
                    setDiscount(json.discount);
                    setPaymentDiscount(json.discount);
                }

                var list_ = json.products;
                var coeficient_ = json.coeficient;

                setcoeficient(coeficient_);
                GetQuantity();

                // *********************************************
                // VYPOCET SUMY
                // *********************************************

                // suma bez DPH za produkty bez zlavy

                var raw_sum = GetPriceRound(GetSume(list_, false, b2b, isDPH, coeficient_, -1));
                setRawSum(raw_sum);
                
                /*
                // suma bez DPH
                var sum = GetPriceRound(GetSume(list_, false, b2b, isDPH, coeficient_, discount));
                // suma zlavenej dph
                var sum_item_dph = GetPriceRound(GetSume(list_, true, b2b, isDPH, coeficient_, discount));
                // suma s dph
                var sum_dph = GetPriceRound(GetSume(list_, true, b2b, false, coeficient_, discount));

                setProductSum(parseFloat(sum));
                setProductSumDPH(parseFloat(sum_dph));
                setProductSumItemDPH(parseFloat(sum_item_dph));

                setSum(GetPriceRound(parseFloat(sum) + parseFloat(tradeSelected.cost) + parseFloat(paymentSelected.cost)));
                setSumDPH(GetPriceRound(parseFloat(sum_dph) + parseFloat(tradeSelected.cost_dph) + parseFloat(paymentSelected.cost_dph)));
                setSumItemDPH(GetPriceRound(parseFloat(sum_item_dph) + parseFloat(tradeSelected.cost_dph) + parseFloat(paymentSelected.cost_dph)));
                */

                CalculateSum(list_, coeficient_, voucherPrice, voucherPriceDPH);

                // *********************************************
                // VYPOCET CIEN PRODUKTOV
                // *********************************************

                list_.forEach(item => {
                    // jednotkova cena za produkt - konecna cena
                    var cost_item = GetPrice(GetCost(b2b == true ? 0 : 1, isDPH == true ? item.cost : item.cost_dph, coeficient_))
                    item.cost_item = parseFloat(cost_item.replace(',', '.'));
                    // cena celkom s poctom kusov za produkt - konecna suma bez DPH
                    var cost_sum = GetPrice(GetCost(b2b == true ? 0 : 1, isDPH == true ? item.cost * item.quantity : item.cost * item.quantity, coeficient_))
                    item.cost_sum = parseFloat(cost_sum.replace(',', '.'));
                    // cena celkom s poctom kusov za produkt - konecna suma s DPH
                    var cost_sum_dph = GetPrice(GetCost(b2b == true ? 0 : 1, isDPH == true ? item.cost_dph * item.quantity : item.cost_dph * item.quantity, coeficient_))
                    item.cost_sum_dph = parseFloat(cost_sum_dph.replace(',', '.'));

                    if (b2b == false) {
                        // CENY S KOEFICIENTOM
                        //item.cost = GetPriceRound(GetCost(true, item.cost, coeficient_));
                    }
                });

                setList(list_);
                
                if (sumarRef != null) {
                    sumarRef.current.scrollIntoView({ behavior: 'smooth' });
                }


            }
            setLoading(false);


        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const CalculateSum = (list_, coeficient_, voucher_price, voucher_price_dph) => {

        var sum = GetPriceRound(GetSume(list_, false, b2b, isDPH, coeficient_, discount));
        // suma zlavenej dph
        var sum_item_dph = GetPriceRound(GetSume(list_, true, b2b, isDPH, coeficient_, discount));
        // suma s dph
        var sum_dph = GetPriceRound(GetSume(list_, true, b2b, false, coeficient_, discount));

        setSumProducts(sum);
        setSumProductsDPH(sum_dph);

        setProductSum(parseFloat(sum));
        setProductSumDPH(parseFloat(sum_dph));
        setProductSumItemDPH(parseFloat(sum_item_dph));

        setSum(GetPriceRound(parseFloat(sum) + parseFloat(tradeSelected.cost) + parseFloat(paymentSelected.cost) - voucher_price));
        setSumDPH(GetPriceRound(parseFloat(sum_dph) + parseFloat(tradeSelected.cost_dph) + parseFloat(paymentSelected.cost_dph) - voucher_price_dph));
        setSumItemDPH(GetPriceRound(parseFloat(sum_item_dph) + parseFloat(tradeSelected.cost_dph) + parseFloat(paymentSelected.cost_dph) - voucher_price_dph));
    }


    const db_trade = async (b2b, isDPH) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'trade', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    b2b: b2b
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var trade = json.trade;
                trade.forEach(item => {
                    item.cost_sum = b2b == true ? isDPH == true ? item.cost : item.cost_dph : item.cost_dph
                });
                setTrade(trade);
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const db_payment = async (trade_id) => {
        setLoading(true);

        if (logged == true) {
            db_shopping_card(null, user.id);
        } else {
            var data = ShoppingList();
            if (data != null) {
                db_shopping_card(data, 0);
            }
        }

        try {
            const response = await fetch(
                global.db_url + 'payment', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    trade_id: trade_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var payment_ = json.payment;
                payment_.forEach(item => {
                    item.cost_sum = b2b == true ? isDPH == true ? item.cost : item.cost_dph : item.cost_dph
                });
                setPayment(payment_);
                const timer = setTimeout(() => {
                    if (paymentRef != null) {
                        paymentRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 100);
            }
            setLoading(false);
        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }

    const db_checkout_finish = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'checkout_finish_v2', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: logged == true ? parseInt(user.id) : 0,
                    meno: cName,
                    fname: company,
                    fulica: cStreet,
                    fpsc: cPSC,
                    fmesto: cTown,
                    fstat: cState,
                    ico: ico,
                    dic: dic,
                    icdph: icdph,
                    email: email,
                    tel: mobil,
                    dfirma: dCompany,
                    dmeno: dName,
                    dulica: dStreet,
                    dpsc: dPSC,
                    dmesto: dTown,
                    dstat: dState,
                    dadresa: delivery,
                    b2b: b2b,
                    is_dph: isDPH,
                    list: list,
                    trade: tradeSelected,
                    payment: paymentSelected,
                    sum: sum,
                    sum_item_dph: sumItemDPH,
                    sum_dph: sumDPH,
                    note: note,
                    voucher: voucher,
                    voucher_text: voucherText,
                    voucher_price: voucherPrice,
                    newsletter: newsletter,
                    discount: paymentDiscount,
                    voucher: voucher,
                    voucher_price: voucherPrice,
                    voucher_price_dph: voucherPriceDPH,
                    voucher_text: voucherText
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {

                if (logged == false) {
                    ShoppingDeleteAll();
                }
                setOrderID(json.order_id);
                setShoppingCardQuantity(0);
                func(1, 0);
                Continue();

            } else {
                setActiveStep(4);
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
            console.log(error);
        }

    }


    const GetQuantity = () => {
        // pocet poloziek v kosiku
        if (logged == true) {
            var data = list;
            var q = 0;
            data.forEach(item => {
                q = q + item.quantity;
            });
            setShoppingCardQuantity(q);
        } else {
            setShoppingCardQuantity(ShoppingQuantity());
        }
    }


    const tradeChange = (event) => {
        var tmp = trade.filter(x => x.id == event.target.value);
        if (tmp.length > 0) {
            setTradeSelected(tmp[0]);
            setTradeID(tmp[0].id);
            db_payment(tmp[0].id);
            setPaymentSelected(null);
            setPaymentID(0);
        }
    }

    const paymentChange = (event) => {
        var tmp = payment.filter(x => x.id == event.target.value);
        if (tmp.length > 0) {
            setPaymentSelected(tmp[0]);
            setPaymentID(tmp[0].id);

            if (b2b == true && user.discount == 0) {
                if (tmp[0].discount == true) {
                    var tmp = GetDiscount(rawSum)[1];
                    tmp = tmp + GetDiscountPayment(rawSum)[1];
                    setDiscount(tmp);
                    setPaymentDiscount(tmp);
                } else {
                    var tmp = GetDiscount(rawSum)[1];
                    setDiscount(0);
                    setPaymentDiscount(tmp);
                }
            }

            /*
            const timer = setTimeout(() => {
                if (continueRef != null) {
                    continueRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
            */
        }
    }


    const Card = () => {
        navigate('/card');
    }

    const Back = () => {
        setActiveStep(activeStep - 1);
    }

    const Continue = () => {
        var value = activeStep + 1;

        if (value == 3) {
            setActiveStep(value);
        }
        if (value == 2) {
            // ZHRNUTIE
            if (Check() == true) {
                setActiveStep(value);
                if (logged == true) {
                    db_shopping_card(data, user.id);
                } else {
                    var data = ShoppingList();
                    if (data != null) {
                        db_shopping_card(data, 0);
                    }
                }
            }
        }
        if (value == 1) {
            // ADRESA DODANIA            
            setErrCName(false);
            setErrCPSC(false);
            setErrCState(false);
            setErrCStreet(false);
            setErrCTown(false);
            setErrEmail(false);
            setErrMobil(false);
            setErrDName(false);
            setErrDPSC(false);
            setErrDState(false);
            setErrDStreet(false);
            setErrDTown(false);
            setError('');
            setActiveStep(activeStep + 1);
        }

        window.scrollTo(0, 0);
    }

    const ChangeStep = (index) => {
        if (activeStep > index) {
            setActiveStep(index);
        }
    }

    const ChangeGDPR = (event) => {
        setGDPR(event.target.checked);
        if (event.target.checked == true) {
            setGDPRErr(false);
        }
    }

    const ChangeNewsletter = (event) => {
        setNewsletter(event.target.checked);
    }

    const ChangeDelivery = (event) => {
        setDelivery(event.target.checked);
    }

    const Check = (id) => {
        // registrovanie zakaznika
        var err = '';

        setErrCName(false);
        setErrCPSC(false);
        setErrCState(false);
        setErrCStreet(false);
        setErrCTown(false);
        setErrEmail(false);
        setErrMobil(false);
        setErrDName(false);
        setErrDPSC(false);
        setErrDState(false);
        setErrDStreet(false);
        setErrDTown(false);
        setError('');
        if (cName.trim() == '') {
            err = lang.required_data;
            setErrCName(true);
        }
        if (cStreet.trim() == '') {
            err = lang.required_data;
            setErrCStreet(true);
        }
        if (cPSC.trim() == '') {
            err = lang.required_data;
            setErrCPSC(true);
        }
        if (cTown.trim() == '') {
            err = lang.required_data;
            setErrCTown(true);
        }
        if (cState.trim() == '') {
            err = lang.required_data;
            setErrCState(true);
        }
        if (mobil.trim() == '') {
            err = lang.required_data;
            setErrMobil(true);
        }

        // DELIVERY ADRESS
        if (tradeSelected.address == true) {
            if (delivery == false) {
                if (dName.trim() == '') {
                    err = lang.required_data;
                    setErrDName(true);
                }
                if (dStreet.trim() == '') {
                    err = lang.required_data;
                    setErrDStreet(true);
                }
                if (dPSC.trim() == '') {
                    err = lang.required_data;
                    setErrDPSC(true);
                }
                if (dTown.trim() == '') {
                    err = lang.required_data;
                    setErrDTown(true);
                }
                if (dState.trim() == '') {
                    err = lang.required_data;
                    setErrDState(true);
                }
            }
        }
        if (email.trim() == '') {
            err = lang.required_data;
            setErrEmail(true);
        } else {
            if (ValidateEmail(email.trim()) == false) {
                setErrEmail(true);
                err = lang.email_error;
            }
        }

        if (err != '') {
            // chybne vyplnený formulár
            setError(err);
            return false;
            // vsetko správne vyplnené
        } else {
            return true;
        }
    }

    const Finish = () => {
        if (gdpr == true) {
            setGDPRErr(false);
            db_checkout_finish();
        } else {
            setGDPRErr(true);
        }
    }

    const GotoMain = () => {
        navigate('/');
    }

    const VoucherCheck = () => {
        setVoucherError('');
        if (voucher.trim() != '') {
            db_voucher_check();
        }
    }

    const VoucherCancel = () => {
        setVoucherError('');
        setVoucher('');
        setVoucherText('');
        setVoucherPrice(0);
        CalculateSum(list, coeficient, 0, 0);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_white, width: '100%' }}>
            <div>

                {
                    isSmall == false ?
                        activeStep < 3 ?
                            <div style={styles.Block}>
                                <div style={styles.BlockMaxLarge}>

                                    <div style={{ width: '100%', paddingTop: 25, paddingBottom: 25 }}>
                                        <Stepper activeStep={activeStep} style={{ width: '100%' }}>
                                            {steps.map((label, index) => {
                                                const stepProps = {};
                                                const labelProps = { color: 'red' };

                                                return (
                                                    <Step key={index} {...stepProps}>
                                                        <IconButton onClick={() => ChangeStep(index)} style={{ display: 'flex', backgroundColor: index == activeStep ? global.theme_black : global.theme_white, width: 40, height: 40, padding: 0, alignItems: 'center', justifyContent: 'center', borderRadius: '50%', border: '1px solid ' + global.theme_black, marginLeft: 30 }}>
                                                            <p style={{ margin: 0, fontSize: 16, fontFamily: 'roboto', color: index == activeStep ? global.theme_white : global.theme_black }}>{index + 1}</p>
                                                        </IconButton>
                                                        <p style={{ margin: 0, marginTop: 5, color: global.theme_black, fontSize: global.font_tiny, fontFamily: 'roboto' }}>{label}</p>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                    </div>
                                </div>
                            </div>
                            : null
                        : null}

                {
                    // ************************************************************************************
                    //
                    // DOPRAVA A PLATBA
                    //
                    // ************************************************************************************
                    activeStep == 0 ?
                        <div>
                            {/* DOPRAVA */}
                            < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <p style={{ ...styles.Label, marginBottom: 10 }}>{lang.trade}</p>
                                    <RadioGroup onChange={tradeChange} defaultValue={0} value={tradeID} name="radio-buttons-group" style={{ width: '100%' }}>
                                        {
                                            trade.map(item => {
                                                return (
                                                    <div key={item.id} style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                        <div style={{ display: 'flex', width: '75%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                            <FormControlLabel value={item.id} control={<Radio />} label={<p style={{ fontWeight: 'bold', margin: 0, textAlign: 'left' }}>{item.name}</p>} />
                                                            <p style={{ margin: 0, marginLeft: 30, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{item.description}</p>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '25%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingTop: 10 }}>
                                                            {parseFloat(item.cost) > '0.00' ?
                                                                <p style={{ margin: 0, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{GetPrice(item.cost_sum)} {lang.money}</p>
                                                                :
                                                                <p style={{ margin: 0, color: global.theme_green, fontFamily: 'roboto', textAlign: 'left' }}>{lang.free}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </RadioGroup>
                                </div>
                            </div>

                            {/* PLATBA */}
                            {payment.length > 0 ?
                                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div ref={paymentRef} style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                        <p style={{ ...styles.Label, marginBottom: 10 }}>{lang.payment}</p>
                                        <RadioGroup onChange={paymentChange} defaultValue={0} value={paymentID} name="radio-buttons-group" style={{ width: '100%' }}>
                                            {
                                                payment.map(item => {
                                                    return (
                                                        <div key={item.id} style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: 25, paddingLeft: 10, paddingRight: 10 }}>
                                                            <div style={{ display: 'flex', width: '75%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                                                <FormControlLabel value={item.id} control={<Radio />} label={<p style={{ fontWeight: 'bold', margin: 0, textAlign: 'left' }}>{item.name} {b2b == true && item.discount == true && user.discount == 0 && GetDiscountPayment(rawSum)[1] > 0 ? 'zľava +' + GetDiscountPayment(rawSum)[1] + '%' : ''}</p>} />
                                                                <p style={{ margin: 0, marginLeft: 30, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{item.description}</p>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '25%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', paddingTop: 10 }}>
                                                                {
                                                                    parseFloat(item.cost) > 0.00 ?
                                                                        <p style={{ margin: 0, color: global.theme_black, fontFamily: 'roboto', textAlign: 'left' }}>{GetPrice(item.cost_sum)} {lang.money}</p>
                                                                        :
                                                                        <p style={{ margin: 0, color: global.theme_green, fontFamily: 'roboto', textAlign: 'left' }}>{lang.free}</p>

                                                                }
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </RadioGroup>
                                    </div>
                                </div>
                                : null}

                            {/* POKRACOVAT */}
                            {isSmall ?
                                <div ref={continueRef} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxSmall, justifyContent: 'center', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                        {paymentSelected != null ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <Button onClick={() => Continue()} variant="contained" style={{ ...styles.ButtonNext }}>{lang.continue}</Button>
                                            </div>
                                            : null}
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                            <Button onClick={() => Card()} style={{ ...styles.ButtonBack }} variant="outlined">{lang.shopping_card}</Button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div ref={continueRef} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 0, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxLarge, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', borderTop: '1px solid ' + global.theme_gray, paddingTop: 20 }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                            <Button onClick={() => Card()} style={{ ...styles.ButtonBack }} variant="outlined">{lang.shopping_card}</Button>
                                        </div>
                                        {paymentSelected != null ?
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                                <Button onClick={() => Continue()} variant="contained" style={{ ...styles.ButtonNext }}>{lang.continue}</Button>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            }
                        </div >
                        : null}


                {
                    // ************************************************************************************
                    //
                    // ADRESA DODANIA
                    //
                    // ************************************************************************************
                    activeStep == 1 ?
                        <div>
                            {/* ADRESA DODANIA */}
                            < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <p style={{ ...styles.Label, marginBottom: 10 }}>{lang.checkout_step_2}</p>
                                </div>
                            </div>

                            {/* ÚDAJE */}
                            < div style={{ ...styles.Block, paddingTop: 0, paddingBottom: 40 }}>
                                <div style={{ ...styles.Block, maxWidth: isSmall ? '95%' : 400, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <p style={{ ...styles.TextNormal, fontWeight: 'bold', marginBottom: 10 }}>{lang.contact_data}:</p>

                                    {/* MENO, PRIEZVISKO */}
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.name + ' a ' + lang.surname.toLocaleLowerCase()} (*)</p>
                                    <TextField error={errcName} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={cName} onInput={e => setCName(e.target.value)} />

                                    {/* MOBIL */}
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.mobil} (*)</p>
                                    <TextField error={errMobil} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} />

                                    {/* EMAIL */}
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.email} (*)</p>
                                    <TextField error={errEmail} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />

                                    {/* FAKTURACNE UDAJE */}
                                    <p style={{ ...styles.TextNormal, fontWeight: 'bold', marginTop: 40 }}>{lang.facture_data}:</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 20 }} >
                                        {b2b == true ?
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.company}</p>
                                                <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={company} onInput={e => setCompany(e.target.value)} />
                                            </div>
                                            : null}
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.street} (*)</p>
                                        <TextField error={errcStreet} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={cStreet} onInput={e => setCStreet(e.target.value)} />
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '25%', marginRight: 10 }}>
                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.psc} (*)</p>
                                                <TextField error={errcPSC} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={cPSC} onInput={e => setCPSC(e.target.value)} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '75%' }}>
                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.town} (*)</p>
                                                <TextField error={errcTown} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={cTown} onInput={e => setCTown(e.target.value)} />
                                            </div>
                                        </div>
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3 }}>{lang.state}</p>
                                        <TextField error={errcState} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={cState} onInput={e => setCState(e.target.value)} />

                                        {b2b == true ?
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3, marginTop: 20 }}>{lang.ico} (*)</p>
                                                <TextField error={errico} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={ico} onInput={e => setICO(e.target.value)} />
                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3, marginTop: 0 }}>{lang.dic} (*)</p>
                                                <TextField error={errdic} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dic} onInput={e => setDIC(e.target.value)} />
                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 3, marginTop: 0 }}>{lang.ic_dph}</p>
                                                <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={icdph} onInput={e => setICDPH(e.target.value)} />
                                            </div>
                                            : null}

                                    </div>

                                    {/* ADRESA DODANIA */}
                                    {tradeSelected.address == true ?
                                        <div style={{ ...styles.BlockLeft, maxWidth: 380, marginTop: 40 }}>
                                            <Paper elevation={3} style={{ ...styles.Block, padding: 0, borderRadius: global.radius, marginBottom: 20, backgroundColor: global.theme_gray, padding: 10 }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 0 }} >
                                                    <p style={{ fontSize: global.font_large, color: global.theme_black }}>{lang.delivery_address}</p>
                                                </div>
                                                <FormControlLabel
                                                    style={{ textAlign: 'left' }}
                                                    label={lang.delivery_address_same}
                                                    control={<Checkbox checked={delivery} onChange={ChangeDelivery} />}
                                                />

                                                {delivery == false ?
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginTop: 20 }} >
                                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.name + ' a ' + lang.surname.toLowerCase()}</p>
                                                        <TextField error={errdName} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dName} onInput={e => setDName(e.target.value)} />
                                                        {props.b2b == true ?
                                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.company}</p>
                                                                <TextField style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dCompany} onInput={e => setDCompany(e.target.value)} />
                                                            </div>
                                                            : null}
                                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.street}</p>
                                                        <TextField error={errdStreet} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dStreet} onInput={e => setDStreet(e.target.value)} />
                                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '25%', marginRight: 10 }}>
                                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.psc}</p>
                                                                <TextField error={errdPSC} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={dPSC} onInput={e => setDPSC(e.target.value)} />
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '75%' }}>
                                                                <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.town}</p>
                                                                <TextField error={errdTown} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={dTown} onInput={e => setDTown(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, margin: 0 }}>{lang.state}</p>
                                                        <TextField error={errdState} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none', marginBottom: 10 }} autoCapitalize='none' variant="outlined" value={dState} onInput={e => setDState(e.target.value)} />
                                                    </div>
                                                    : null}
                                            </Paper>

                                        </div>
                                        : null}

                                </div>
                            </div>

                            {error != '' ?
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                    <Alert severity="error">{error}</Alert>
                                </div>
                                : null}
                            {/* POKRACOVAT */}
                            {isSmall ?
                                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxSmall, justifyContent: 'center', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                        {paymentSelected != null ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <Button onClick={() => Continue()} variant="contained" style={{ ...styles.ButtonNext }}>{lang.continue}</Button>
                                            </div>
                                            : null}
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                            <Button onClick={() => Back()} style={{ ...styles.ButtonBack }} variant="outlined">{lang.back}</Button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 0, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxLarge, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', borderTop: '1px solid ' + global.theme_gray, paddingTop: 20 }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                            <Button onClick={() => Back()} style={{ ...styles.ButtonBack }} variant="outlined">{lang.back}</Button>
                                        </div>
                                        {paymentSelected != null ?
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                                <Button onClick={() => Continue()} variant="contained" style={{ ...styles.ButtonNext }}>{lang.continue}</Button>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            }
                        </div>
                        : null}


                {
                    // ************************************************************************************
                    //
                    // ZHRNUTIE OBJEDNÁVKY
                    //
                    // ************************************************************************************
                    activeStep == 2 ?
                        <div>
                            {/* ZHRNUTIE */}
                            < div ref={sumarRef} style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <p style={{ ...styles.Label, marginBottom: 10 }}>{lang.checkout_step_3}</p>
                                </div>
                            </div>

                            {/* ZOZNAM OBJ. PRODUKTOV + DOPRAVA + PLATBA */}
                            < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                {/* PRODUKTY */}
                                {list.map(item => {
                                    return (
                                        <div key={item.id} style={{ ...styles.BlockMaxLarge, flexDirection: isLarge ? 'row' : 'column', paddingTop: 10, paddingBottom: 10, ...styles.BorderTop, width: '95%' }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <Button onClick={() => navigate('/product/' + item.tid)} style={{ display: 'flex', alignItems: 'center', width: isSmall ? 100 : 140, height: isLarge ? 100 : 130, justifyContent: 'center', backgroundColor: global.theme_white }}>
                                                    <img src={global.product_image + item.path + '/th_' + item.image} style={{ maxWidth: 100, maxHeight: 100 }} />
                                                </Button>
                                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'flex-start', width: isLarge ? '50%' : '90%', paddingRight: 20, paddingLeft: 20 }}>
                                                    <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal' }}>{lang.code}: {item.code}</p>
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'normal', textAlign: 'left' }}>{item.title} {global.testing == true ? ' (' + item.cost + ')' : null}</p>
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 13, fontWeight: 'normal', textAlign: 'left' }}>{item.description}</p>
                                                    <p style={{ margin: 0, color: item.count > 0 ? global.theme_dark_green : global.theme_dark_red, fontSize: 12, fontWeight: 'normal', marginTop: 5 }}>{lang.stock}: {parseInt(item.count)} {lang.pieces}</p>
                                                    {isDPH == true ?
                                                        <p style={{ margin: 0, color: global.theme_dark_gray, fontSize: 12, fontWeight: 'normal', marginTop: 5 }}>{lang.DPH}: {parseInt(item.dph)}%</p>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRowRaw, width: isLarge ? '45%' : 320 }}>
                                                {isLarge ? null :
                                                    <div style={{ ...styles.Block, width: 80 }}></div>
                                                }
                                                <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                    <p style={{ ...styles.TextSmall }}>{item.quantity} {lang.pieces}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '50%' }}>
                                                    <p style={{ margin: 0, color: global.theme_black, fontSize: 16, fontWeight: 'bold' }}>{GetPrice(isDPH == true ? item.cost_sum : item.cost_sum_dph)} {lang.money}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {/* SUMA ZA PRODUKTY */}
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, flexDirection: 'row', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', marginTop: 0, ...styles.BorderTop, paddingTop: 20 }}>
                                    {isLarge ?
                                        <div style={{ ...styles.BlockLeft, width: 140 }}></div>
                                        : null}
                                    <div style={{ ...styles.BlockLeft, width: '75%', paddingLeft: isLarge ? 40 : 0 }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.products_sum} <span style={{ ...styles.TextTiny }}>{isDPH == false ? lang.dph_true : lang.dph_false}</span></p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '25%' }}>
                                        <p style={{ ...styles.TextSmall }}>{isDPH == false ? GetPrice(sumProductsDPH) : GetPrice(sumProducts)} {lang.money}</p>
                                    </div>
                                </div>

                                {/* DOPRAVA */}
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, flexDirection: 'row', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', marginTop: 0, paddingTop: 30 }}>
                                    {isLarge ?
                                        <div style={{ ...styles.BlockLeft, width: 140 }}></div>
                                        : null}

                                    <div style={{ ...styles.BlockLeft, width: '75%', paddingLeft: isLarge ? 40 : 0 }}>
                                        <p style={{ ...styles.TextSmall }}>{tradeSelected.name}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '25%' }}>
                                        <p style={{ ...styles.TextSmall }}>{GetPrice(tradeSelected.cost_sum)} {lang.money}</p>
                                    </div>
                                </div>
                                {/* PLATBA */}
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, flexDirection: 'row', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', marginTop: 15 }}>
                                    {isLarge ?
                                        <div style={{ ...styles.BlockLeft, width: 140 }}></div>
                                        : null}

                                    <div style={{ ...styles.BlockLeft, width: '75%', paddingLeft: isLarge ? 40 : 0 }}>
                                        <p style={{ ...styles.TextSmall }}>{paymentSelected.name}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '25%' }}>
                                        <p style={{ ...styles.TextSmall }}>{GetPrice(paymentSelected.cost_sum)} {lang.money}</p>
                                    </div>
                                </div>

                                {/* VOUCHER */}
                                {voucherPrice > 0 ?
                                    <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, flexDirection: 'row', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', marginTop: 30 }}>
                                        {isLarge ?
                                            <div style={{ ...styles.BlockLeft, width: 140 }}></div>
                                            : null}

                                        <div style={{ ...styles.BlockLeft, width: '75%', paddingLeft: isLarge ? 40 : 0 }}>
                                            <p style={{ ...styles.TextSmall, fontWeight:'bold' }}>{lang.voucher}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '25%' }}>
                                            <p style={{ ...styles.TextSmall, fontWeight:'bold' }}>{isDPH ? GetPrice(voucherPrice) : GetPrice(voucherPriceDPH)} {lang.money}</p>
                                        </div>
                                    </div>
                                    : null}

                                {/* SUMA */}
                                {b2b == true && (GetDiscount(GetSume(list, false, b2b, false, coeficient, -1))[1] > 0 || (discount > 0 && GetSume(list, false, b2b, false, coeficient, -1) > 50)) ?
                                    <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, flexDirection: 'row', justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', marginTop: 15 }}>
                                        <div style={{ ...styles.Block, width: '100%', backgroundColor: global.theme_light_red, paddingTop: 25, paddingBottom: 25 }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: 'bold' }}>Poskytnutá zľava vo výške {paymentDiscount}%</p>
                                        </div>
                                    </div>
                                    : null}

                                {isDPH == true ?
                                    // ZOBRAZENIE SUMY - PLATCA DPH
                                    <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, marginTop: 25, borderTop: '1px solid ' + global.theme_gray, paddingTop: 20 }}>
                                        <div style={{ ...styles.BlockRight, flexDirection: 'row', marginTop: 0 }}>
                                            <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_medium_gray }}>{lang.sum_dph_}:</p>
                                            <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium_gray }}>{GetPrice(sumItemDPH)} {lang.money}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, flexDirection: 'row', marginTop: 5 }}>
                                            <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_medium_gray }}>{lang.sum_with} {lang.dph}% {lang.DPH}:</p>
                                            <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium_gray }}>{GetPrice(sumDPH)} {lang.money}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, flexDirection: 'row', marginTop: 10 }}>
                                            <p style={{ margin: 0, fontSize: global.font_normal, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_black }}>{lang.sum_without_dph}:</p>
                                            <p style={{ margin: 0, fontSize: global.font_large, fontWeight: 'bold', color: global.theme_dark_red }}>{GetPrice(sum)} {lang.money}</p>
                                        </div>
                                    </div>
                                    :
                                    // ZOBRAZENIE SUMY - NEPLATCA DPH / NEPRIHLASENY
                                    <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, marginTop: 25, borderTop: '1px solid ' + global.theme_gray, paddingTop: 20 }}>
                                        <div style={{ ...styles.BlockRight, flexDirection: 'row' }}>
                                            <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'normal', marginRight: 20, marginTop: 2, color: global.theme_medium_gray }}>{lang.sum_without_dph}:</p>
                                            <p style={{ margin: 0, fontSize: global.font_small, fontWeight: 'bold', color: global.theme_medium_gray }}>{GetPrice(sum)} {lang.money}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, flexDirection: 'row' }}>
                                            <p style={{ margin: 0, fontSize: global.font_normal, fontWeight: 'normal', marginRight: 20, marginTop: 2 }}>{lang.sum_with_dph}:</p>
                                            <p style={{ margin: 0, fontSize: global.font_large, fontWeight: 'bold' }}>{GetPrice(sumDPH)} {lang.money}</p>
                                        </div>
                                    </div>
                                }

                            </div>

                            {/* ZĽAVOVY KUPON */}
                            < div style={{ ...styles.Block, paddingTop: 40, paddingBottom: 0 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.BlockLeft, width: 180 }}>
                                            <p style={{ fontSize: global.font_tiny, color: global.theme_black, marginBottom: 2 }}>{lang.voucher}</p>
                                            <TextField
                                                size='small'
                                                style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }}
                                                autoCapitalize='none'
                                                variant="outlined"
                                                value={voucher}
                                                onInput={e => setVoucher(e.target.value)}
                                            />
                                        </div>
                                        {voucherText != '' ?
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, width: 40, paddingTop: 40 }}>
                                                    <FontAwesomeIcon style={{ height: 22, color: global.theme_green }} icon={faCheck} />
                                                </div>
                                                <div style={{ ...styles.Block, width: 80, paddingTop: 32 }}>
                                                    <Button onClick={() => VoucherCancel()} style={{ ...styles.ButtonLink, height: 40, width: '100%' }}>{lang.cancel}</Button>
                                                </div>
                                            </div>
                                            :
                                            <div style={{ ...styles.Block, width: 80, paddingTop: 32 }}>
                                                <Button onClick={() => VoucherCheck()} style={{ ...styles.ButtonBack, height: 40, width: 80, marginLeft: 20 }} variant="outlined">{lang.voucher_use}</Button>
                                            </div>
                                        }
                                    </div>
                                    {voucherText != '' ?
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_black, marginBottom: 2, textAlign: 'left' }}>{voucherText}</p>
                                        : null}
                                    {voucherError != '' ?
                                        <p style={{ fontSize: global.font_tiny, color: global.theme_dark_red, marginBottom: 2 }}>{voucherError}</p>
                                        : null}

                                </div>
                            </div>



                            {/* POZNAMKA K OBJEDNAVKE */}
                            < div style={{ ...styles.Block, paddingTop: 40, paddingBottom: 0 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <p style={{ fontSize: global.font_tiny, color: global.theme_black, marginBottom: 2 }}>{lang.order_note}</p>
                                    <TextField size='small' style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' variant="outlined" value={note} onInput={e => setNote(e.target.value)} />
                                </div>
                            </div>

                            {/* NEWSLETTER */}
                            < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: isSmall ? 20 : 0 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    <FormControlLabel control={<Checkbox checked={newsletter} onChange={ChangeNewsletter} style={{ color: global.theme_black, textAlign: 'left' }} />} label={<p style={{ fontWeight: 'normal', margin: 0, textAlign: 'left' }}>{lang.newsletter_register}</p>} />
                                </div>
                            </div>

                            {/* SUHLAS GDPR */}
                            < div style={{ ...styles.Block, paddingTop: 0, paddingBottom: 50 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: isSmall ? 'center' : 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                    {/*
                                    <FormControlLabel control={<Checkbox checked={gdpr} onChange={ChangeGDPR} style={{ color: global.theme_black, textAlign: 'left' }} />} label={<p style={{ fontWeight: 'normal', margin: 0, textAlign: 'left' }}>{lang.gdpr_agree}</p>} />
                                    */}
                                    <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: 'bold', textAlign: 'left' }}>{lang.gdpr_agree_}</p>
                                </div>
                            </div>

                            {gdprErr != '' ?
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                                    <Alert severity="error">{lang.gdpr_error}</Alert>
                                </div>
                                : null}

                            {/* POKRACOVAT */}
                            {isSmall ?
                                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxSmall, justifyContent: 'center', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                        {paymentSelected != null ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <Button onClick={() => Finish()} variant="contained" style={{ ...gdpr == false ? styles.ButtonDisabled : styles.ButtonDark, width: 200 }}>{lang.send_order}</Button>
                                            </div>
                                            : null}
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                            <Button onClick={() => Back()} style={{ ...styles.ButtonBack }} variant="outlined">{lang.back}</Button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', paddingTop: 0, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxLarge, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', borderTop: '1px solid ' + global.theme_gray, paddingTop: 20 }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', width: '50%' }}>
                                            <Button onClick={() => Back()} style={{ ...styles.ButtonBack }} variant="outlined">{lang.back}</Button>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%' }}>
                                            <Button onClick={() => Finish()} variant="contained" style={{ ...styles.ButtonDarkGreen, width: 220 }}>{lang.send_order}</Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        : null}


                {
                    // ************************************************************************************
                    //
                    // UKONCENIE OBJEDNAVKY
                    //
                    // ************************************************************************************
                    activeStep == 3 ?
                        <div>
                            {/* ZHRNUTIE */}
                            < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <div style={{ ...styles.Block, justifyContent: 'flex-start', alignItems: isSmall ? 'center' : 'flex-start', flexDirection: isSmall ? 'column' : 'row' }}>
                                        <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                                        </div>
                                        <p style={{ ...styles.Label, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.checkout_step_4}</p>
                                    </div>
                                    <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.order_number}: {orderID}</p>
                                    <p style={{ ...styles.TextSmall, marginTop: 40, fontWeight: 'bold' }}>{lang.order_finish_email}: {email}</p>
                                    <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.order_finish_text}</p>
                                </div>
                            </div>


                            {/* PRECHOD NA HLAVNU STRANKU */}
                            {isSmall ?
                                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxSmall, justifyContent: 'center', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                            <Button onClick={() => GotoMain()} style={{ ...styles.ButtonBack, width: 220 }} variant="outlined">{lang.main_page}</Button>
                                        </div>
                                    </div>
                                </div>
                                :
                                < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <Button onClick={() => GotoMain()} variant="contained" style={{ ...styles.ButtonDark, width: 220 }}>{lang.main_page}</Button>
                                    </div>
                                </div>
                            }
                        </div>
                        : null}

                {
                    // ************************************************************************************
                    //
                    // UKONCENIE OBJEDNAVKY - NASTALA CHYBA
                    //
                    // ************************************************************************************
                    activeStep == 4 ?
                        <div>
                            {/* ERROR */}
                            < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                    <div style={{ ...styles.Block, justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row' }}>
                                        <div style={{ ...styles.BlockRound, backgroundColor: global.theme_dark_red, marginTop: 11 }}>
                                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faXmark} />
                                        </div>
                                        <p style={{ ...styles.Label, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.checkout_error}</p>
                                    </div>
                                    <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.checkout_error_text}</p>
                                </div>
                            </div>


                            {/* PRECHOD NA PRVY KROK */}
                            {isSmall ?
                                <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div style={{ ...styles.BlockMaxSmall, justifyContent: 'center', alignItems: isSmall ? 'center' : 'flex-start' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 20 }}>
                                            <Button onClick={() => GotoMain()} style={{ ...styles.ButtonBack }} variant="outlined">{lang.main_page}</Button>
                                        </div>
                                    </div>
                                </div>
                                :
                                < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                                    <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <Button onClick={() => setActiveStep(2)} variant="contained" style={{ ...styles.ButtonDark }}>{lang.back}</Button>
                                    </div>
                                </div>
                            }
                        </div>
                        : null}


                < div style={{ height: 20 }}></div>

                {isLoading == true ?
                    <Loading></Loading>
                    : null}
            </div>
        </div>

    )
};
