import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, MenuLogo, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faBox, faCar, faMinus, faMinusCircle, faMinusSquare, faPaperPlane, faPersonWalking, faPlus, faPlusCircle, faTruck } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';
import { style } from '@mui/system';
import GoogleMapReact from 'google-map-react';
import { layer } from '@fortawesome/fontawesome-svg-core';


export const Delivery = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const lat = 48.382249;
    const lng = 18.395264;

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const img1 = require('./react/app/img_1.jpg');
    const pin = require('./react/app/pin.png');

    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        GoHome();

        return () => {
        };

    }, []);



    const GotoBack = () => {
        navigate(-1);
    }

    const AnyReactComponent = ({ text }) => (
        <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
            <img src={pin} height={40} />
            <p style={{ ...styles.TextSmall, marginTop: 12, fontWeight: 'bold', color: global.theme_dark_red }}>Polplast</p>
        </div>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef} style={{ ...styles.Block, marginTop: 30 }}>
                <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: isSmall ? 'center' : 'left' }}>Doručenie tovaru zdarma</h1>

                <div style={{ ...styles.BlockMaxLarge, justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 40 }}>
                    <div style={{ ...styles.BlockLeft, backgroundColor: global.theme_gray }}>
                        <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                                        <img src={global.images + 'app/zahradnik_round.png'} style={{ width: 60, height: 60 }}></img>
                                        <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left', color: global.theme_dark_blue, marginTop: 15, marginLeft: 10 }}>B2B veľkoobchodný zákazník</h1>
                                    </div>
                                    <div style={{ ...styles.BlockRow, marginTop: 30 }}>
                                        {isSmall ? null :
                                            <div style={{ ...styles.BlockLeft, width: 80 }}>
                                                <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faTruck} />
                                            </div>
                                        }
                                        <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '80%' }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0 }}>Doručenie zdarma </p>
                                            <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left', textAlign: 'justify' }}>Aká doprava je najlepšia? Predsa tá, ktorá je pravidelná a zdarma! Objednajte si online s -2 % úvodnou zľavou za Vašu registráciu v min. hodnote 50,00 € bez DPH a Vašu objednávku vybavíme prednostne s dopravou grátis.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ ...styles.Block, width: isSmall ? '100%' : '30%' }}>
                                <img src={global.images + 'app/delivery_1.jpg'} style={{ width: '100%', height: '100%' }} />
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: undefined, padding: 20 }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 10 }}>Ušetríte peniaze</p>
                            <ul style={{ ...style.TextSmall, textAlign: 'left' }}>
                                <li>Nemusíte objednávať veľké množstvo z jedného typu, postačí väčší sortiment v menších objemoch. Môžete celoročne dopĺňať ponuku Vašej predajne o novinky a tým byť zaujímavým pre svojich zákazníkov.</li>
                                <li>Pravidelne každých 14 dní vám zdarma privezieme vašu objednávku po celej SR.</li>
                                <li>Expresné zásielky doručíme kuriérom do 48 hod. Táto služba je spoplatnená cenníkom prepravcu.</li>
                            </ul>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: undefined, padding: 20 }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 10 }}>Ušetríte skladové priestory a znížite náklady</p>
                            <ul style={{ ...style.TextSmall, textAlign: 'left' }}>
                                <li>Nemusíte uhrádzať vysoké faktúry, držať tovar skladom a zmraziť tak Vaše financie v sezónnych zásobách.</li>
                                <li>Ušetrite svoje peniaze a skladové priestory. Tie máte predsa v našom sklade! Obchodný zástupca Vám privezie čo potrebujete každých 14 dní.</li>
                                <li>Expresné zásielky doručíme kuriérom do 48 hod. Táto služba je spoplatnená cenníkom prepravcu.</li>
                            </ul>
                            <div style={{ ...styles.Block, marginto: 20 }}>
                                <Button onClick={() => navigate('/rozvoz')} style={{ ...styles.ButtonGreen }}>{lang.trades}</Button>
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: undefined, padding: 20 }}>
                            <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 10 }}>Ušetríte svoj čas</p>
                            <ul style={{ ...style.TextSmall, textAlign: 'left' }}>
                                <li>Objednávajte pohodlne a cielene tovar, ktorý potrebujú vaši zákazníci. Objednávajte cez e-shop a ušetríte svoj čas. Vašu objednávku obchodný zástupca privezie a vyloží v predajni a vy sa môžete zatiaľ venovať svojej práci a zákazníkom.</li>
                                <li>Prípadné reklamácie vieme vyriešiť do 14 dní v rámci dovozu vašej objednávky.</li>
                            </ul>
                        </div>
                        <div style={{ ...style.BlockLeft, backgroundColor: global.theme_light_red }}>
                            <div style={{ ...styles.BlockLeft, width: undefined, padding: 20, paddingBottom: 30 }}>
                                <p style={{ ...styles.TextLarge, fontWeight: 'normal', marginTop: 10 }}>Notifikacia</p>
                                <div style={{ ...styles.BlockRow, marginTop: 15 }}>
                                    <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_medium_gray, marginRight: 20 }} icon={faPaperPlane} />
                                    <p style={{ ...styles.TextSmall, fontWeight: 'bold', textAlign: 'left' }}>Registrovaným zákazníkom posielame e-mailom akciové ponuky, novinky a informácie o rozvoze cez SMS vždy v predstihu.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...isLarge ? styles.BlockRow : styles.Block, backgroundColor: global.theme_lighter, marginTop: 50, marginBottom: 50 }}>
                        <div style={{ ...styles.Block, width: '70%', padding: 20 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <div style={{ ...isSmall ? styles.Block : styles.BlockRow }}>
                                    <img src={global.images + 'app/customer_round.png'} style={{ width: 60, height: 60 }}></img>
                                    <h1 style={{ ...styles.TextXXLarge, fontWeight: 'bold', textAlign: 'left', color: global.theme_dark_blue, marginTop: 15, marginLeft: 10 }}>B2C koncový zákazník</h1>
                                </div>
                                <div style={{ ...styles.BlockRow, marginTop: 30 }}>
                                    {isSmall ? null :
                                        <div style={{ ...styles.BlockLeft, width: 80 }}>
                                            <FontAwesomeIcon style={{ width: 40, height: 40, color: global.theme_medium_gray }} icon={faBox} />
                                        </div>
                                    }
                                    <div style={{ ...styles.BlockLeft, width: isSmall ? '100%' : '80%' }}>
                                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 0, textAlign: 'left' }}>Expresná doprava kuriérom</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left', textAlign: 'justify' }}>Objednaný tovar, ktorý je skladom, Vám doručíme do 48 hod. kuriérskou službou Geis alebo 123Kuriér. Služba je spoplatnená podľa aktuálneho cenníka prepravcu. Keramiku a sklo kuriérom kvôli krehkosti materiálu neposielame. Rozvoz tohto tovaru zabezpečujeme našim obchodným zástupcom za príplatok. Výnimku tvorí exteriérová keramika, ktorú kuriér Geis dovezie na palete.</p>

                                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 20, textAlign: 'left' }}>Vyzdvihnutie v prevádzke v mieste Vášho bydliska</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left', textAlign: 'justify' }}>Chcete ušetriť za kuriéra? Po vzájomnej dohode objednávku doručíme do prevádzky našich obchodných partnerov vo Vašom bydlisku. Zabalený tovar si preberiete v kvetinárstve, alebo v záhradnom centre, ktoré bude informované o Vašej návšteve. Adresu a kontakt prevádzky zašleme vopred na Váš e-mail.</p>

                                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', marginTop: 20, textAlign: 'left' }}>Tovar, ktorý ste chceli objednať nie je skladom. Máme riešenie!</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: 'normal', marginTop: 10, textAlign: 'left', textAlign: 'justify' }}>Potrebujete si objednať tovar, ktorý nie je aktuálne skladom? Vložte ho do košíka a odošlite nám svoju objednávku aj napriek tomu. Budeme Vás kontaktovať a informovať termíne dodania. Tovar po jeho naskladnení (cca do 14 dní) doručíme do prevádzky vo Vašom bydlisku, alebo na Vašu dodaciu adresu.</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                        < div style={{ ...styles.Block, width: isSmall ? '100%' : '30%' }}>
                            <img src={global.images + 'app/delivery_2.jpg'} style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>




                    {isLoading == true ?
                        <Loading></Loading>
                        : null}

                </div >
            </div >
        </div >
    )
};
