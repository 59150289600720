import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { Debug, MenuLogo, MenuTop, Menu, ShoppingQuantity, Loading, GetPrice, GetCost, GalleryView, PlusMinus, LoadUser, LoadLogin, ShoppingAdd, ProductBox, GetUser, LoadUniqueID, SaveUniqueID, Footer, ProductBuy, ProductAsk, ProductFavorites, GoHome, ProductWatchdog } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faCheck, faChevronRight, faHeart, faLayerGroup, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { Dialog } from '@mui/material';
import { loadingButtonClasses } from '@mui/lab';
import { styles } from './styles.js';
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';

export const Product = (props) => {
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(true);
    const [isLoading, setLoading] = useState(false);

    const [showProductBuy, setShowProductBuy] = useState(false);
    const [buyItem, setBuyItem] = useState([]);

    const [showWatchdog, setShowWatchdog] = useState(false);

    const [redraw, setRedraw] = useState(false);
    const [user, setUser] = useState(null);
    const [product, setProduct] = useState(null);
    const [shoppingCardQuantity, setShoppingCardQuantity] = useState(0);
    const [showAdded, setShowAdded] = useState(false);
    const [coeficient, setcoeficient] = useState(1);
    const [isDPH, setDPH] = useState(false); // Je platitel DPH

    const [sections, setSections] = useState([]);
    const [section, setSection] = useState('');
    const [sectionID, setSectionID] = useState('');

    const [subsections, setSubsections] = useState([]);
    const [subsection, setSubsection] = useState('');
    const [subsectionID, setSubsectionID] = useState('');

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [categoryID, setCategoryID] = useState('');

    const [subCategories, setSubCategories] = useState([]);
    const [subCategory, setSubCategory] = useState('');
    const [subCategoryID, setSubCategoryID] = useState('');

    const [subProducts, setSubProducts] = useState([]);
    const [subProductID, setSubProductID] = useState('');
    const [subProduct, setSubProduct] = useState('');
    const [accessories, setAccessories] = useState([]);

    const [products, setProducts] = useState([]);
    const [variation, setVariation] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [productCategory, setProductCategory] = useState('');
    const [gallery, setGallery] = useState([]);
    const [showGallery, setShowGallery] = useState(false);
    const [image, setImage] = useState('');
    const [showDetails, setShowDetails] = useState(false);

    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);

    const [pictograms, setPictograms] = useState(null);

    const [showAsk, setShowAsk] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const pictoSize = 40;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);
        if (data.logged == false) {
            setShoppingCardQuantity(ShoppingQuantity());
        }

        db_product(params.id, data.id);

        GoHome();

        return () => {
        };

    }, []);


    const db_product = async (id, user_id) => {
        setBusy(true);
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'product3', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    id: id,
                    user_id: user_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.accessories;
                tmp.sort((a, b) => {
                    if (a.priority > b.priority) {
                        return true;
                    } else {
                        return false;
                    }
                })
                setAccessories(tmp);


                var tmp = json.images;
                /*
                tmp.sort((a, b) => {
                    if (a > b) {
                        return true;
                    } else {
                        return false;
                    }
                })
                */
                setGallery(tmp);

                setProduct(json.product);
                setImage(json.product.image);

                var tmp = json.status;

                if (tmp.length > 1) {
                    tmp.sort((a, b) => {
                        if (a.description > b.description) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    //tmp.shift();
                }

                if (tmp.length == 1) {
                    setVariation(tmp[0]);
                }
                setProducts(tmp);

                var allcategories = json.all;

                setcoeficient(parseFloat(json.coeficient));
                setShoppingCardQuantity(json.shopping_quantity);

                // PIKTOGRAMY
                var picto = json.product.pictograms;
                if (picto == null) {
                    setPictograms(picto);
                } else {
                    var pictoDefault = json.pictograms;
                    var pictoProduct = JSON.parse(picto);

                    var n = 0;
                    while (n < pictoProduct.length) {
                        var result = pictoDefault.find(x => x.id == pictoProduct[n].id);
                        
                        if (result != undefined) {
                            pictoProduct[n].enabled = result.enabled;
                            pictoProduct[n].name = result.name;
                        } else {
                            pictoProduct[n].enabled = false;
                        }
                        n++;
                    }                    
                    pictoProduct = pictoProduct.filter(x => x.enabled == true);

                    setPictograms(pictoProduct);
                }


                CreateURL(allcategories, json.product.parent);
            }

            setBusy(false);
            setLoading(false);
        } catch (error) {
            Debug.log(error);
            setBusy(false);
            setLoading(false);
        }
    }

    const db_shopping_card_add = async (product_id, item_id, quantity) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'shopping_card_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: user.id,
                    product_id: product_id,
                    item_id: item_id,
                    quantity: quantity
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setShoppingCardQuantity(shoppingCardQuantity + quantity);
                func(1, shoppingCardQuantity + quantity);
                setShowAdded(true);
            }

        } catch (error) {
            Debug(error);
            setBusy(false);
        }
    }

    const MenuTopPress = (type, value) => {
        if (type == 0) {
            // registracia
            navigate('/user_register');
        }
    }
    const MenuLogoPress = (type, value) => {
        if (type == 0) {
            // KLIKNUTIE NA LOGO            
            navigate('/');
        }
    }

    const MenuPress = (type, id) => {
        if (type == 0) {
            //KLIKNUTIE NA KATEGÓRIU
            //ChangeSubsection(subsections, categories, id);

            var tmp = subsections.filter(x => x.id == id);
            if (tmp.length > 0) {
                var slug = tmp[0].slug;

                var tmp = sections.filter(x => x.id == tmp[0].parent);
                if (tmp.length > 0) {
                    slug = tmp[0].slug + '/' + slug;
                    DeleteScroll();
                    navigate('/catalog/' + slug);
                }
            }
        }
        if (type == 1) {
            // KLIKNUTIE NA SEKCIU
            //ChangeSection(id);
            var tmp = sections.filter(x => x.id == id);
            if (tmp.length > 0) {
                DeleteScroll();
                navigate('/catalog/' + tmp[0].slug);
            }
        }
        if (type == 2) {
            // DOMOV
            navigate('/');
        }
    }

    const DeleteScroll = () => {
        sessionStorage.removeItem('scrollPosition')
    }

    const DeletePage = () => {
        sessionStorage.removeItem('page')
    }

    const CreateURL = (allcategories, id) => {
        // HLADANIE CESTY K PRODUKTU V KATEGORIACH
        var arr = [];
        var tmp = allcategories.filter(x => x.id == id);
        if (tmp.length > 0) {
            arr.push({ id: tmp[0].slug, label: tmp[0].label });
            var n = 10;
            while (n > 0) {
                var tmp = allcategories.filter(x => x.id == tmp[0].parent);
                if (tmp.length > 0) {
                    arr.push({ id: tmp[0].slug, label: tmp[0].label });
                    if (tmp[0].parent == 0) {
                        break;
                    }
                }
                n--;
            }
        }
        var n = arr.length;
        var i = 0;

        setProductCategory(arr[0].label);
        while (n > 0) {
            if (i == 0) {
                // sekcia
                setSection(arr[n - 1].label);
                setSectionID(arr[n - 1].id);
            }
            if (i == 1) {
                // subsekcia
                setSubsection(arr[n - 1].label);
                setSubsectionID(arr[n - 1].id);
            }
            if (i == 2) {
                // kategoria
                setCategory(arr[n - 1].label);
                setCategoryID(arr[n - 1].id);
            }
            if (i == 3) {
                // subkategoria
                setSubCategory(arr[n - 1].label);
                setSubCategoryID(arr[n - 1].id);
            }
            if (i == 4) {
                // subprodukt
                setSubProduct(arr[n - 1].label);
                setSubProductID(arr[n - 1].id);
            }

            n--;
            i++
        }
    }

    const GotoBack = () => {
        navigate(-1);
    }

    const Goto = (id) => {        
        var path = '';
        if (id == 0) {
            path = '/';
        }
        if (id == 1) {
            path = '/catalog/' + sectionID;
        }
        if (id == 2) {
            path = '/catalog/' + sectionID + '/' + subsectionID;
        }
        if (id == 3) {
            path = '/catalog/' + sectionID + '/' + subsectionID + '/' + categoryID;
        }
        if (id == 4) {
            path = '/catalog/' + sectionID + '/' + subsectionID + '/' + categoryID + '/' + subCategoryID;
        }
        if (id == 5) {
            path = '/catalog/' + sectionID + '/' + subsectionID + '/' + categoryID + '/' + subCategoryID + '/' + subProductID;
        }
        DeleteScroll();
        DeletePage();
        navigate(path);
    }

    const ChangeVariation = (event) => {
        var tmp = products.filter(x => x.id == event.target.value);
        if (tmp.length > 0) {
            setVariation(tmp[0]);
        }

    };


    const PlusMinusPress = (q) => {
        setQuantity(q);
    }

    const Buy = () => {
        if (logged == true) {
            db_shopping_card_add(variation.product_id, variation.id, quantity);
        } else {
            var data = {
                id: parseInt(variation.id),
                item_id: parseInt(variation.id),
                product_id: parseInt(variation.product_id),
                quantity: parseInt(quantity),
            };
            ShoppingAdd(data);
            setShowAdded(true);
            setShoppingCardQuantity(ShoppingQuantity());
            func(1, ShoppingQuantity());
        }
        setRedraw(!redraw);
    }

    const GalleryViewPress = (value) => {
        setShowGallery(false);
    }

    const CloseAdded = () => {
        setShowAdded(false);
    }

    const GotoCard = () => {
        navigate('/card');
    }

    const ProductPress = (item, type) => {
        if (type == false) {
            // zobrazit produkt
            navigate('/product/' + item.tid);
            window.location.reload(false);
        } else {
            // kupit produkt
            setBuyItem(item);
            setShowProductBuy(true);
        }
    }

    const ProductBuyPress = (type, quantity) => {
        if (type == false) {
            setShowProductBuy(false);
        } else {
            if (logged == true) {
                setShoppingCardQuantity(shoppingCardQuantity + quantity);
                func(1, shoppingCardQuantity + quantity);
            } else {
                setShoppingCardQuantity(ShoppingQuantity());
                func(1, ShoppingQuantity());
            }
            setShowProductBuy(false);

        }
    }

    const ProductAskPress = (value) => {
        setShowAsk(false);
    }

    const ProductFavoritesPress = (value) => {
        if (value == true) {
            var tmp = product;
            tmp.favorite = true;
        }
        setShowFavorites(false);
    }

    const Watchdog = () => {
        setShowWatchdog(true);
    }

    const ProductWatchdogPress = (value) => {
        setShowWatchdog(false);
    }

    const ShowGallery = (large) => {
        if (large == true) {
            setShowGallery(true)
        }
    }

    const StyledBadge = styled(Badge)({
        "& .MuiBadge-badge": {
            color: global.theme_white,
            backgroundColor: global.theme_product_new,
            top: 20,
            right: 60,
            width: 100,
            height: 20
        }
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%', alignItems: 'center' }}>

            {isLoading == true ? <div style={{ height: height }}></div> : null}

            {product != null ?
                <div style={{ display: 'flex', width: isSmall || isMedium ? '95%' : '90%', flexDirection: 'column', paddingLeft: isSmall || isMedium ? 0 : global.menu_padding, paddingRight: isSmall || isMedium ? 0 : global.menu_padding, backgroundColor: global.theme_white, paddingBottom: 40 }}>
                    <div style={{ display: 'flex', flexDirection: isSmall || isMedium ? 'column' : 'row', width: '100%' }}>
                        <div style={{ marginTop: 8, marginRight: isSmall || isMedium ? 0 : 20 }}>
                            <Button fontSize={global.font_small} style={{ ...styles.ButtonMenuBack }} onClick={() => GotoBack()} >
                                <FontAwesomeIcon style={{ width: 14, height: 14, marginRight: 10, color: global.theme_white }} icon={faAngleLeft} />
                                {lang.back}
                            </Button>
                        </div>
                        {isSmall || isMedium ? null :
                            <div>
                                {section != '' ?
                                    <Breadcrumbs style={{ marginTop: 10 }}>
                                        <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => Goto(0)} >
                                            {lang.home}
                                        </Button>
                                        <Breadcrumbs>
                                            <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => Goto(1)} >
                                                {section}
                                            </Button>
                                            {subsection != '' ?
                                                <Breadcrumbs>
                                                    <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => Goto(2)} >
                                                        {subsection}
                                                    </Button>
                                                    {category != '' ?
                                                        <Breadcrumbs>
                                                            <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => Goto(3)}>
                                                                {category}
                                                            </Button>
                                                            {subCategory != '' ?
                                                                <Breadcrumbs>
                                                                    <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => Goto(4)}>
                                                                        {subCategory}
                                                                    </Button>
                                                                    {subProduct != '' ?
                                                                        <Breadcrumbs>
                                                                            <Button fontSize={global.font_tiny} style={styles.ButtonLink} onClick={() => Goto(5)}>
                                                                                {subProduct}
                                                                            </Button>
                                                                        </Breadcrumbs>
                                                                        : null}
                                                                </Breadcrumbs>
                                                                : null}
                                                        </Breadcrumbs>
                                                        : null}
                                                </Breadcrumbs>
                                                : null}
                                        </Breadcrumbs>
                                    </Breadcrumbs>
                                    : null}
                            </div>
                        }
                    </div>

                    <div style={{ display: 'flex', width: '100%', flexDirection: isSmall || isMedium ? 'column' : 'row', paddingTop: 20 }}>

                        {/* OBRAZOK PRODUKTU A GALERIA */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: isSmall || isMedium ? '100%' : '45%', backgroundColor: global.theme_white, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            {gallery.length > 1 ?
                                <div style={{ ...styles.BlockRow, height: 90, overflowX: 'scroll' }}>
                                    {gallery.length > 1 ?
                                        gallery.map(item => (
                                            <Button key={item} onClick={() => setImage(item)} style={{ display: 'flex', width: 60, justifyContent: 'center', alignItems: 'center', border: item == image ? '1px solid ' + global.theme_light : '1px solid ' + global.theme_white }}>
                                                <img src={global.product_image + product.path + '/' + item + '?id=' + product.tid} style={{ maxWidth: 60, maxHeight: 60 }} />
                                            </Button>
                                        )
                                        )
                                        : null}
                                    {/*
                                    <div style={{ ...styles.Block, height: 90, width: 10 }}>
                                        <FontAwesomeIcon style={{ color: global.theme_light_gray }} icon={faChevronRight} />
                                    </div>
                                        */}
                                </div>
                                : null}
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                {image != '' ?
                                    <div style={{ ...styles.BlockLeft, position: 'relative', }}>
                                        <div style={{ ...styles.BlockLeft, width: undefined, position: 'absolute' }}>

                                            {/* VYHODNY NAKUP */}
                                            {product.action == true ?
                                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.theme_blue, borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.theme_white }}>{'Výhodný nákup'}</p>
                                                </div>
                                                : null}

                                            {/* NOVINKA */}
                                            {product.new == true ?
                                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.theme_green, borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.theme_white }}>{lang.product_new}</p>
                                                </div>
                                                : null}

                                            {/* FLAG-TEXT */}
                                            {product.flag_text != '' ?
                                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.theme_dark_red, borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.theme_white }}>{product.flag_text}</p>
                                                </div>
                                                : null}

                                            {/* ZNAČKY */}
                                            {product.flag > 0 ?
                                                <div style={{ ...styles.Block, width: undefined, height: 22, backgroundColor: global.flags_color[product.flag], borderRadius: 11, marginTop: 10, marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, marginLeft: 8, marginRight: 8, color: global.flags_text_color[product.flag] }}>{global.flags[product.flag]}</p>
                                                </div>
                                                : null}

                                        </div>
                                        <div style={{ ...styles.Block }}>
                                            <img src={global.product_image + product.path + '/' + image + '?id=' + product.tid} style={{ maxWidth: isSmall || isMedium ? '90%' : 400, maxHeight: 400, cursor: isLarge ? 'pointer' : 'default' }} onClick={() => ShowGallery(isLarge)} />
                                        </div>
                                    </div>
                                    : null}

                            </div>
                        </div>


                        {/* POPIS PRODUKTU */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: isSmall || isMedium ? '100%' : '55%', justifyContent: 'flex-start', alignItems: isSmall ? 'center' : 'flex-start' }}>

                            {/* NAZOV PRODUKTU */}
                            <div style={{ paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10 }}>
                                <h1 style={{ margin: 0 }}>{product.title} {global.testing == true ? product.id : ''}</h1>
                            </div>


                            <div style={{ ...isSmall || isMedium ? styles.Block : styles.BlockLeft, paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10 }}>
                                <p style={{ margin: 0, color: global.theme_light, fontSize: global.font_tiny }}>{lang.category}: {productCategory}</p>
                                {/*
                                    <p style={{ margin: 0, color: global.theme_light, fontSize: global.font_tiny, marginTop: 5 }}>{lang.manufacture}: {product.manufacture_name}</p>
                                    */}
                            </div>

                            {/* ANNOTATION - POZNAMKA */}
                            <div style={{ ...styles.BlockLeft, paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 20, fontWeight: '600', color: global.theme_dark_red, textAlign: 'left' }}>{product.annotation} {global.testing == true ? product.id : ''}</p>
                            </div>


                            {/* POPIS PRODUKTU */}
                            <div style={{ margin: 0, fontSize: global.font_small, textAlign: 'left', paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10, color: global.theme_medium, width: '95%' }} dangerouslySetInnerHTML={{ __html: product.content }} />

                            {/* TECHNICKE PARAMETRE */}
                            {product.details != '' && product.details != '<p></p>' ?
                                <div style={{ ...styles.BlockLeft, paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10 }}>
                                    <Button onClick={() => setShowDetails(!showDetails)} style={{ ...styles.ButtonSelect, width: 220, marginLeft: 0, marginRight: 20 }}>
                                        {lang.product_details}
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 20 }} icon={showDetails == true ? faAngleDown : faAngleRight} />
                                    </Button>
                                    {showDetails == true ?
                                        <div style={{ margin: 0, fontSize: global.font_small, textAlign: 'left', paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10, color: global.theme_medium, width: '95%' }} dangerouslySetInnerHTML={{ __html: product.details }} />
                                        : null}
                                </div>
                                : null}

                            {/* PIKTOGRAMY */}
                            {pictograms != null ?
                                <div style={{ ...styles.BlockLeft, paddingLeft: isSmall || isMedium ? 0 : 10, paddingRight: isSmall || isMedium ? 0 : 10, flexDirection: 'row', flexWrap: 'wrap', marginTop: 20 }}>
                                    {pictograms.map((item, index) => (
                                        <div key={item.id} style={{ ...styles.BlockLeft, width: 300 }}>
                                            <div style={{ ...styles.BlockRowRaw, height: pictoSize + 10 }}>
                                                <img src={global.pictograms + item.image} style={{ width: '100%', height: '100%', maxWidth: pictoSize, maxHeight: pictoSize, objectFit: 'contain' }}></img>
                                                <div style={{ ...styles.BlockLeft, height: pictoSize, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginLeft: 10, color: global.theme_medium }}>{item.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                : null}

                            {/* OBLUBENE, OTAZKA, ... */}
                            <div style={{ ...styles.BlockRow, width: '100%', backgroundColor: global.theme_gray, paddingTop: 5, paddingBottom: 5, marginTop: 40 }}>
                                {/* OBLUBENE */}
                                <Button onClick={() => setShowFavorites(product.favorite == false ? true : false)} style={{ ...styles.ButtonEmpty, marginLeft: 20, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ width: 18, height: 18, marginRight: 8, color: product.favorite == true ? global.theme_favorite : global.theme_dark_gray }} icon={faHeart} />
                                    <p style={{ ...isSmall || isMedium ? styles.TextXXTiny : styles.TextTiny, textAlign: 'left' }}>
                                        {product.favorite == true ? lang.favorite : lang.product_favorite}
                                    </p>
                                </Button>
                                {/* OTAZKA PREDAJCOVI */}
                                <Button onClick={() => setShowAsk(true)} style={{ ...styles.ButtonEmpty, marginLeft: 20, marginRight: 20 }}>
                                    <FontAwesomeIcon style={{ width: 18, height: 18, marginRight: 8, color: global.theme_dark_gray }} icon={faQuestionCircle} />
                                    <p style={{ ...isSmall || isMedium ? styles.TextXXTiny : styles.TextTiny, textAlign: 'left' }}>
                                        {lang.product_ask}
                                    </p>
                                </Button>
                                {/* STRAZIT DOSTUPNOST */}
                                {variation != null ?
                                    variation.count_units == 0 ?
                                        <Button onClick={() => Watchdog()} style={{ ...styles.ButtonEmpty, marginLeft: 20, marginRight: 20 }}>
                                            <FontAwesomeIcon style={{ width: 18, height: 18, marginRight: 8, color: global.theme_dark_gray }} icon={faLayerGroup} />
                                            <p style={{ ...isSmall || isMedium ? styles.TextXXTiny : styles.TextTiny, textAlign: 'left' }}>
                                                {lang.product_watch}
                                            </p>
                                        </Button>
                                        : null : null}
                            </div>

                            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 10 }}>
                                {products.length == 1 ?
                                    // LEN JEDNA VARIACIA
                                    <div style={{ display: 'flex', width: '100%', flexDirection: isSmall || isMedium ? 'column' : 'row', justifyContent: 'flex-start', alignItems: isSmall || isMedium ? 'center' : 'flex-start', marginTop: 30, paddingBottom: 20, borderBottom: '1px solid ' + global.theme_dark }}>
                                        {variation != null ?
                                            <div style={{ display: 'flex', flexDirection: isSmall || isMedium ? 'column' : 'row', width: isSmall || isMedium ? '100%' : '33%', justifyContent: isSmall || isMedium ? 'center' : 'flex-start' }}>
                                                {variation.description == '' || variation.description == null ?
                                                    <p style={{ ...styles.TextNormal, marginTop: 10 }}>{lang.product_buy}</p>
                                                    :
                                                    <p style={{ ...styles.TextNormal }}>{variation.description}</p>
                                                }
                                            </div>
                                            : null}
                                        {variation != null ?
                                            <div style={{ display: 'flex', flexDirection: 'row', width: isSmall || isMedium ? '100%' : '33%', justifyContent: isSmall || isMedium ? 'center' : 'flex-end', marginTop: isSmall || isMedium ? 10 : 0 }}>
                                                <PlusMinus quantity={quantity} func={PlusMinusPress.bind(this)} />
                                            </div>
                                            : null}
                                        {variation != null ?
                                            <div style={{ display: 'flex', flexDirection: 'row', width: isSmall || isMedium ? '100%' : '33%', justifyContent: isSmall || isMedium ? 'center' : 'flex-end', marginTop: isSmall || isMedium ? 10 : 0 }}>
                                                <Button onClick={() => Buy()} variant="contained" style={{ ...styles.ButtonDarkGreen }}>{lang.buy}</Button>
                                            </div>
                                            : null}
                                    </div>

                                    :
                                    // PRIDUKT Z VARIACIAMI
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', borderBottom: '1px solid ' + global.theme_dark }}>
                                            <p style={{ fontWeight: 'bold', fontSize: global.font_normal }}>{lang.choose_variant}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: isSmall || isMedium ? 'column' : 'row', marginTop: 20, width: '100%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', width: isSmall || isMedium ? '100%' : '33%', justifyContent: isSmall || isMedium ? 'center' : 'flex-start' }}>
                                                <div style={{ display: 'flex', height: 40, alignItems: 'center', justifyContent: 'center' }}>
                                                    <InputLabel style={{ marginRight: 10, color: global.theme_black }}>{lang.variant}:</InputLabel>
                                                </div>
                                                <Select
                                                    displayEmpty
                                                    style={{ textAlign: 'left', color: global.theme_black, width: 200, height: 40 }}
                                                    value={variation == null ? '' : variation.id}
                                                    onChange={ChangeVariation}
                                                    size={'small'}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>Kliknutím vyberte</em>
                                                    </MenuItem>
                                                    {products.map(item => {

                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            {variation != null ?
                                                <div style={{ display: 'flex', flexDirection: 'row', width: isSmall || isMedium ? '100%' : '33%', justifyContent: isSmall || isMedium ? 'center' : 'flex-end', marginTop: isSmall || isMedium ? 10 : 0 }}>
                                                    <PlusMinus quantity={quantity} func={PlusMinusPress.bind(this)} />
                                                </div>
                                                : null}
                                            {variation != null ?
                                                <div style={{ display: 'flex', flexDirection: 'row', width: isSmall || isMedium ? '100%' : '33%', justifyContent: isSmall || isMedium ? 'center' : 'flex-end', marginTop: isSmall || isMedium ? 10 : 0 }}>
                                                    <Button onClick={() => Buy()} variant="contained" style={{ ...styles.ButtonDarkGreen }}>{lang.buy}</Button>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                }
                                {variation != null ?
                                    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: isSmall || isMedium ? 'center' : 'flex-start', marginTop: 20 }}>
                                        <p style={{ margin: 0, fontSize: global.font_large, color: global.theme_black, fontWeight: 'bold' }}>{isDPH == true ? lang.without_dph : lang.with_dph}: {GetPrice(GetCost(b2b == true ? 0 : 1, b2b == true ? isDPH == true ? variation.cost : variation.cost_dph : variation.cost_dph, coeficient))} {lang.money}</p>
                                        {isDPH == true ?
                                            // PLATCA DPH
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                <p style={{ margin: 0, marginTop: 5, fontSize: global.font_tiny }}>{lang.price_with} {parseInt(product.dph)}% {lang.DPH}: {GetPrice(GetCost(b2b == true ? 0 : 1, variation.cost_item_dph, coeficient))} {lang.money}</p>
                                            </div>
                                            :
                                            // NIE JE PLATCA DPH
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                                <p style={{ margin: 0, marginTop: 5, fontSize: global.font_tiny }}>{lang.without_dph}: {GetPrice(GetCost(b2b == true ? 0 : 1, variation.cost, coeficient))} {lang.money}</p>
                                            </div>
                                        }
                                        <p style={{ margin: 0, marginTop: 20, fontSize: global.font_small, color: variation.count_units == 0 ? global.theme_dark_red : global.theme_green }}>{lang.stock}: {parseInt(variation.count_units)} {lang.pieces}</p>
                                        <p style={{ margin: 0, marginTop: 5, fontSize: global.font_tiny }}>{lang.code}: {variation.code}</p>
                                    </div>
                                    : null}
                            </div>
                        </div>


                    </div>

                    {/* ODPORÚČAME DOKÚPIŤ - ODPORUČANÉ PRÍSLUŠENSTVO */}
                    {accessories.length > 0 ?
                        <div style={{ ...isSmall || isMedium ? styles.Block : styles.BlockLeft, backgroundColor: global.theme_gray, padding: isSmall || isMedium ? 0 : 20, marginTop: 50, paddingTop: 20, paddingBottom: 20 }}>
                            <p style={{ ...styles.TextXXLarge }}>{lang.recomended_items}</p>
                            <div style={{ ...styles.Block, flexDirection: isSmall || isMedium ? 'column' : 'row', flexWrap: 'wrap', marginTop: 20 }}>
                                {accessories.map(item => {
                                    return (
                                        <ProductBox price={GetPrice(GetCost(b2b == true ? 0 : 1, b2b == true ? isDPH == true ? item.cost : item.cost_dph : item.cost_dph, coeficient))} dph={b2b == true ? false : true} key={item.id} typ={0} item={item} func={ProductPress.bind(this)} />
                                    )
                                })}
                            </div>
                        </div>
                        : null}

                    {showGallery == true ?
                        <GalleryView gallery={gallery} image={image} product={product} func={GalleryViewPress.bind(this)} />
                        : null}

                    {/* =============================================================================
                            PRIDANÉ DO KOSIKA
                        ============================================================================= */}
                    {isBusy == false && variation != null ?
                        <Dialog open={showAdded} onClose={CloseAdded} maxWidth={'md'}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: isSmall || isMedium ? 300 : 600 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexDirection: 'column', paddingTop: 10, paddingLeft: 25 }}>
                                        <p style={{ fontSize: isSmall || isMedium ? 20 : 26, margin: 0, fontFamily: 'roboto' }}><FontAwesomeIcon style={{ width: 26, height: 26, marginRight: 10, color: global.theme_black }} icon={faCheck} />{lang.added_to_card}</p>
                                    </div>
                                    <div style={{ display: 'flex', width: 50, justifyContent: 'flex-end', paddingRight: 0 }}>
                                        <IconButton onClick={() => CloseAdded()} style={{ height: 35 }}>
                                            <p style={{ margin: 0, padding: 0 }}>X</p>
                                        </IconButton>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', padding: 20, flexDirection: 'column', justifyItems: 'center', paddingBottom: 30 }}>
                                    <div style={{ display: 'flex', width: '100%', padding: 6, justifyContent: 'center', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: isSmall ? 'column' : 'row' }}>
                                            <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontWeight: 'bold', fontFamily: 'roboto' }}>{product.title}</p>
                                            <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontWeight: 'normal', fontFamily: 'roboto', marginLeft: isSmall ? 0 : 10 }}>{variation.description}</p>
                                            <p style={{ margin: 0, fontSize: 16, color: global.theme_black, fontWeight: 'normal', fontFamily: 'roboto', marginLeft: isSmall ? 0 : 10 }}>{quantity} {lang.pieces}</p>
                                        </div>


                                    </div>
                                    <div style={{ display: 'flex', width: '100%', padding: 0, justifyContent: 'center', flexDirection: isSmall || isMedium ? 'column' : 'row', marginTop: 40 }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall || isMedium ? '100%' : '50%' }}>
                                            <Button onClick={() => CloseAdded()} style={{ backgroundColor: global.theme_white, width: 200, height: 40, textTransform: 'none', color: global.theme_black, borderColor: global.theme_black }} variant="outlined">{lang.continue_to_shop}</Button>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: isSmall || isMedium ? '100%' : '50%', marginTop: isSmall || isMedium ? 20 : 0 }}>
                                            <Button onClick={() => GotoCard()} variant="contained" style={{ ...styles.ButtonDarkGreen }}>{lang.continue_to_card}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                        : null}
                </div>
                : null}

            <div style={{ height: 20 }}></div>

            {/* FOOTER */}
            {/*isBusy == false ?
                    <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                        : null*/}

            {
                isBusy == true ?
                    <Loading></Loading>
                    : null
            }

            {
                showProductBuy == true ?
                    <ProductBuy user={user} logged={logged} b2b={b2b} isDPH={isDPH} coeficient={coeficient} item={buyItem} func={ProductBuyPress.bind(this)} />
                    : null
            }

            {
                showAsk == true ?
                    <ProductAsk user={user} item={product} func={ProductAskPress.bind(this)} />
                    : null
            }

            {
                showFavorites == true ?
                    <ProductFavorites user={user} item={product} func={ProductFavoritesPress.bind(this)} />
                    : null
            }

            {
                showWatchdog == true ?
                    <ProductWatchdog email={logged == true ? user.email : ''} item={product} variation={variation} func={ProductWatchdogPress.bind(this)} />
                    : null
            }


        </div >
    )
};

