import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton, Paper, Alert } from '@mui/material';
import { sk } from './globals.js';
import { Debug, GetUser, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, ValidateEmail, GoHome, TodayDate } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faCheck, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import skLocale from 'date-fns/locale/sk';
import { styles } from './styles.js';
import ReCAPTCHA from 'react-google-recaptcha'


export const Reserve = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [isBusy, setBusy] = useState(false);

    const [reserveOK, setReserveOK] = useState(false);

    const [logged, setLogged] = useState(false);
    const [b2b, setB2B] = useState(false);

    const [reserveDate, setReserveDate] = useState(TodayDate());
    const [reserveTime, setReserveTime] = useState(0);
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState('');
    const [note, setNote] = useState('');
    const [error, setError] = useState('');

    const [recaptcha, setRecaptcha] = useState('');

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/reserve_date_icon.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setB2B(data.b2b);
        setLogged(data.logged);

        GoHome();

        return () => {
        };

    }, []);



    const db_reserve = async () => {
        if (isBusy == false) {
            setBusy(true);
            var time = '';
            var tmp = global.time.filter(x => x.id == reserveTime);
            if (tmp.length > 0) {
                time = tmp[0].time;
            }

            try {
                const response = await fetch(
                    global.db_url + 'reserve', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        reserve_date: reserveDate,
                        reserve_time: time,
                        name: name,
                        mobil: mobil,
                        email: email,
                        note: note,
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setReserveOK(true);
                    window.scrollTo(0, 0);
                } else {
                    setError(lang.db_error);
                }
                setBusy(false);

            } catch (error) {
                Debug(error);
                setBusy(false);
            }
        }
    }

    const GotoBack = () => {
        navigate(-1);
    }

    const ChangeTime = (event) => {
        setReserveTime(event.target.value);
    }

    const Reserve = () => {
        var error = false;
        setError('');
        setNameErr('');
        setMobilErr('');
        setEmailErr('');

        if (reserveDate == null) {
            error = lang.reserve_date_error;
        }

        if (name.trim() == '') {
            error = lang.required;
            setNameErr(lang.required);
        }
        if (mobil.trim() == '') {
            error = lang.required;
            setMobilErr(lang.required);
        }
        if (email.trim() == '') {
            error = lang.required;
            setEmailErr(lang.required);
        } else {
            if (ValidateEmail(email) == false) {
                error = lang.email_error;
                setEmailErr(lang.email_error);
            }
        }

        if (error != '') {
            setError(error);
        } else {
            if (recaptcha != '') {
                // VSETKO OK
                db_reserve();
            }
        }
    }

    const onChangeCaptcha = (value) => {
        Debug('Captcha', value == null ? 'NULL' : value);
        if (value == null) {
            setRecaptcha('');
        } else {
            setRecaptcha(value);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div ref={homeRef}>
                {/*
                <MenuTop loged={false} mobil={'0905'} email={'info'} name={''} surname={''} func={MenuTopPress.bind(this)} />
                <MenuLogo func={MenuLogoPress.bind(this)} />
                
                <Menu sections={sections} subsections={subsections} func={MenuPress.bind(this)} />
                */}
                {reserveOK == false ?
                    <div style={{ ...styles.Block, marginTop: 40, marginBottom: 20 }}>
                        <p style={{ ...styles.TextXXLarge }}>{lang.reserve_label}</p>
                        <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.reserve_text}</p>

                        <Paper elevation={3} style={{ ...styles.Block, maxWidth: 500, marginTop: 20 }}>

                            {/* DEN A CAS NAVSTEVY */}
                            <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 20 }}>
                                {/* LOGO */}
                                <div style={{ ...styles.Block, width: '100%' }}>
                                    <img src={logo} style={{ width: 60, height: 60 }}></img>
                                </div>

                                <div style={{ ...styles.Block, width: '100%' }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginTop: 40 }}>{lang.reserve_date_text1}</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', fontWeight: 'normal', marginTop: 5 }}>{lang.reserve_date_text2}</p>
                                </div>

                                <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 40 }}>{lang.reserve_date}</p>
                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                        {/*
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
                                            <DatePicker
                                                label=''
                                                mask={null}
                                                style={{ backgroundColor: global.theme_white }}
                                                value={reserveDate}
                                                onChange={(newValue) => {
                                                    setReserveDate(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, disabled: true, style: { backgroundColor: global.theme_white } }}/>}
                                            />
                                        </LocalizationProvider>
                                        */}
                                        <TextField
                                            value={reserveDate}
                                            onInput={e => setReserveDate(e.target.value)}
                                            size="small"
                                            autoCapitalize="off"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            style={{ width: '100%' }}                                            
                                            type={'date'}
                                            variant="outlined"
                                        />

                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                        <Select
                                            style={{ textAlign: 'left', color: global.theme_black, width: '100%' }}
                                            value={reserveTime}
                                            onChange={ChangeTime}
                                            size={'small'}
                                        >
                                            {global.time.map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>{item.time + ' hod.'}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>

                            {/* KONTAKTNE UDAJE */}
                            <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 40 }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 5 }}>{lang.contact_data}</p>

                                {/* MENO */}
                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <TextField size='small' error={nameErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.name_surname} variant="outlined" value={name} onInput={e => setName(e.target.value)} inputProps={{ maxLength: 128 }} />
                                </div>

                                {/* MOBIL */}
                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <TextField size='small' error={mobilErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.mobil} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} inputProps={{ maxLength: 64 }} />
                                </div>

                                {/* EMAIL */}
                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <TextField size='small' error={emailErr == '' ? false : true} style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={lang.email} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} inputProps={{ maxLength: 128 }} />
                                </div>

                            </div>

                            {/* POZNAMKA */}
                            <div style={{ ...styles.BlockLeft, width: '95%', marginTop: 40 }}>
                                <p style={{ ...styles.TextSmall, fontWeight: 'bold', marginTop: 5 }}>{lang.reserve_note}</p>

                                <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                    <TextField size='small' style={{ marginTop: 5, backgroundColor: global.theme_white, width: '100%', padding: 0, textTransform: 'none', borderTopLeftRadius: global.radius, borderBottomLeftRadius: global.radius }} autoCapitalize='none' label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} inputProps={{ maxLength: 255 }} />
                                </div>
                            </div>

                            <div style={{ ...styles.Block, width: '95%', marginTop: 20 }}>
                                <ReCAPTCHA
                                    sitekey="6LcBgIQeAAAAAMk_qe_tHfZjpY0vOuhtfxP1RtgP"
                                    onChange={onChangeCaptcha}
                                />
                            </div>

                            <div style={{ ...styles.Block, width: '95%', height: 40, marginTop: 20, marginBottom: 20 }}>
                                {error != '' ?
                                    <Alert severity="error">{error}</Alert>
                                    : null}
                            </div>

                            {/* ODOSLAT */}
                            <div style={{ ...styles.Block, width: '95%', marginTop: 10, marginBottom: 20 }}>
                                <Button onClick={() => { Reserve() }} variant="contained" style={{ ...styles.ButtonDarkGreen }}>{lang.reserve_send}</Button>
                            </div>

                        </Paper>
                    </div>

                    :
                    /* POZIADAVKA BOLA USPESNE ODOSLANA */

                    < div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 50 }}>
                        <div style={{ ...isLarge ? styles.BlockMaxLarge : styles.BlockMaxSmall, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <div style={{ ...styles.Block, justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row' }}>
                                <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                                    <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                                </div>
                                <p style={{ ...styles.Label, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>{lang.reserve_thank}</p>
                            </div>
                            <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.reserve_sended}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 40, fontWeight: 'bold' }}>{lang.order_finish_email}: {email}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.reserve_contact}</p>

                            <div style={{ ...styles.BlockLeft, marginTop: 40 }}>
                                <Button onClick={() => navigate('/')} style={{ ...styles.ButtonDark, width: 200 }} variant="outlined">{lang.main_page}</Button>
                            </div>
                        </div>
                    </div>

                }

                <div style={{ height: 20 }}></div>

                {/* FOOTER */}
                {/*
                    isLoading == false ?
                        <Footer web={web} subcompanies={subcompanies} catalogueLabel={catalogueLabel} blogLabel={blogLabel} actionLabel={actionLabel} newsletterSended={newsletterSended} />
                        : null
                */}

                {
                    isLoading == true ?
                        <Loading></Loading>
                        : null
                }
            </div >

        </div >
    )
};


