import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, TextField, Typography, Select, IconButton } from '@mui/material';
import { sk } from './globals.js';
import { GetUser, Debug, MenuTop, Menu, CategoriesMenu, Loading, FilterBar, LoadSettings, GalleryView, Footer, LoadUser, LoadLogin, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleUp, faMinus, faMinusCircle, faMinusSquare, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { InputLabel } from '@mui/material';
import { styles } from './styles.js';


export const Vop = (props) => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [logged, setLogged] = useState(false);
    const [isDPH, setDPH] = useState(false);
    const [b2b, setB2B] = useState(false);
    const [user, setUser] = useState(null);

    const [web, setWeb] = useState([]);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();

    const homeRef = useRef(null)
    let { func } = props;

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        var data = GetUser();
        setDPH(data.dph);
        setB2B(data.b2b);
        setLogged(data.logged);
        setUser(data.user);

        db_web();

        GoHome();

        return () => {
        };

    }, []);

    const db_web = async (user_id) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'main_page', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: language,
                    user_id: user_id,
                    banners_typ: 0,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setWeb(json.web);
                setLoading(false);
            }

        } catch (error) {
            Debug(error);
            setLoading(false);
        }
    }


    const GotoBack = () => {
        navigate(-1);
    }



    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>

            <div style={{ paddingTop: 20, paddingBottom: 30 }}>
                <h1>Všeobecné obchodné podmienky</h1>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 20, alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: global.screen_max }}>
                        <p style={{ ...styles.TextXLarge, textAlign: 'left', marginTop: 10, marginTop: 10 }}><b>B2B zákazník</b></p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, marginTop: 5 }}>po registrovaní sa so svojou živnosťou nakupuje podľa nižšie uvedených podmienok:</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>1. Vymedzenie pojmov</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li><b>predávajúci</b> - prevádzkovateľ internetového obchodu Polplast-sk</li>
                            <li><b>kupujúci</b> - fyzická alebo právnická osoba, ktorá vstúpila do obchodného vzťahu s predávajúcim a nakúpila tovar za účelom ďalšieho predaja</li>
                            <li><b>tovar</b> - produkty alebo služby v ponuke internetového obchodu Polplast-sk</li>
                            <li><b>objednávka</b> - kúpna zmluva na základe ktorej je realizovaný predaj tovaru</li>
                            <li><b>reklamácia</b> - zistenie výrobnej vady, ktorá je dôvodom k vráteniu tovaru predávajúcemu, pretože chybný materiál, alebo chýbajúci komponent bránia v riadnom používaní výrobku.</li>
                        </ul>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>2. Objednávka</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>Objednávka vzniká na základe jej potvrdenia pri procese objednávania tovaru v internetovom obchode, prípadne pri objednaní tovaru telefonicky alebo e-mailom. Kupujúci odoslaním objednávky predávajúcemu potvrdzuje, že súhlasí s obchodnými a nákupnými podmienkami internetového obchodu Polplast-sk. Obchodné podmienky sú neoddeliteľnou súčasťou zrealizovanej objednávky, teda kúpnej zmluvy. <b>Minimálny nákup online je vo výške 50,00 € bez DPH.</b> Pri nesplnení podmienky, objednávka nebude expedovaná.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>3. Dodacie podmienky</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>a) Dodanie objednaného tovaru bude realizované v čo najkratšom termíne, podľa dostupnosti tovaru na sklade a prevádzkových možností predávajúceho. Termín vybavenia objednávky je určený podľa harmonogramu rozvozu ( viď titulná stránka Harmonogram rozvozu), max. však do 14 dní od jej potvrdenia. V prípade dlhšej dodacej doby bude predávajúci kupujúceho o tejto skutočnosti neodkladne informovaťpísomne a telefonicky.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>b) Miesto odberu tovaru je stanovené kupujúcim pri objednávaní tovaru ( dovoz do prevádzky na dodaciu adresu, alebo osobný odber tovaru). Za vybavenie objednávky sa považuje prevzatie tovaru kupujúcim. Dopravu na adresu doručenia zabezpečuje predávajúci. Zásielka s tovarom vždy obsahuje daňový doklad (doklad o kúpe tovaru). Dovoz tovaru je zdarma po splnení podmienky min. odberu 50,00 € bez DPH.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>c) Kupujúci je povinný prevziať tovar v mieste, ktoré uviedol v objednávke ( dodacia adresa). Tovar je mozné prevziať i osobne v mieste sídla spoločnosti v Zlatých Moravciach. O termíne dodania je povinný predávajúci informovať kupujúceho buď emailom alebo telefonicky.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>d) Zákazník je povinný prekontrolovať a prevziať zásielku. V prípade poškodenia tovaru, alebo neúplnosti zásielky, je nutné túto skutočnosť nahlásiť na našich telefónnych číslach alebo emailovej adrese. Ak je reklamácia zistená už na mieste, kupujúci upozorní obchodného zástupcu.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>4. Záruka a reklamácie</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>a) Kupujúci je povinný reklamáciu bezodkladne uplatniť u predávajúceho, a to ihneď po zistení závady, resp. najneskôr do 3 dní. Počas záručnej doby má kupujúci právo na bezplatnú výmenu tovaru po predložení tovaru vrátane príslušenstva oprávnenému zástupcovi predávajúceho spolu s dokladom o zaplatení a posúdení reklamácie. Záruka sa nevzťahuje na bežné opotrebenie veci (alebo ich častí) spôsobené používaním. Kratšiu životnosť výrobku teda nemožno považovať za vadu a nedá sa ani reklamovať.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>b) Práva kupujúceho pri uplatnení reklamácie: Kupujúci môže požadovať výmenu chybnej veci za bezchybnú, ak tým predávajúcemu nevzniknú neprimerané náklady vzhľadom na cenu tovaru alebo závažnosť chyby. Pri výskyte neodstrániteľnej chyby, ktorá bráni riadnemu užívaniu veci na daný účel, má kupujúci právo buď na výmenu veci za novú, alebo na odstúpenie od zmluvy, t.j. dobropis (vrátenie peňazí na účet).</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>c) Reklamácia sa vybavuje spolu s ďalším objednaním tovaru ( do 14 dní) alebo dobropisom a následným zaslaním sumy na účet zákazníka. Reklamácia sa považuje za vybavenú - ak sa ukončí reklamačné konanie odovzdaním nového výrobku, vrátením kúpnej ceny výrobku, vyplatením primeranej zľavy z ceny výrobku, písomná výzva na prevzatie plnenia alebo jej odôvodnené zamietnutie.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>d) Reklamácia bude uznaná iba po telefonickom alebo písomnom oznámení predávajúcemu na kontaktnom čísle 0911/406140 a e-maile: polplast@polplast.sk spolu s číslom faktúry za reklamovaný tovar. V reklamačnom popise zákazník uvedie:</p>
                        <ul style={{ ...styles.TextNormal, marginTop: 10, textAlign: 'left' }}>
                            <li>názov a kód tovaru</li>
                            <li>rozmer a farbu</li>
                            <li>počet reklamovaných kusov</li>
                        </ul>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>e) Reklamovaný tovar musí byť dodaný predávajúcemu v čistom, nepoužitom stave, so všetkým prislúchajúcim príslušenstvom (batérie, vešiak, podmiska a pod.). V opačnom prípade bude reklamovaný tovar vrátený zákazníkovi naspäť k náprave. Až následne sa k riešeniu reklamácie opäť pristúpi.</p>

                        <p style={{ ...styles.TextLarge, fontWeight: 'bold', textAlign: 'left', marginTop: 40 }}>5. Záverečné ustanovenia</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, textAlign: 'justify' }}>Na vzťahy neupravené týmito všeobecnými obchodnými podmienkami sa vzťahujú príslušné ustanovenia Občianskeho zákonníka, Zákona o elektronickom obchode, Zákona o štátnej kontrole vnútorného trhu vo veciach ochrany spotrebiteľa, Zákona o ochrane spotrebiteľa 634/1992 Zb." v znení neskorších zákonných úprav. Prípadné spory vzniknuté z neplnenia týchto podmienok sa riadia príslušnými ustanoveniami Obchodného alebo Občianskeho zákonníka.</p>
                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 20, textAlign: 'justify' }}>Kupujúci prehlasuje, že sa pred vyplnením objednávky oboznámil s týmito obchodnými podmienkami a že s nimi v plnom rozsahu súhlasí.</p>

                        {/*
                        <p style={{ ...styles.TextXLarge, textAlign: 'left', marginTop: 10, marginTop: 40 }}><b>B2C koncový zákazník bez živnosti</b></p>
                        */}
                    </div>

                </div>

                <Button onClick={() => GotoBack()} style={{ color: global.theme_black, width: 200, borderColor: global.theme_black, marginTop: 50 }} variant='outlined'>{lang.back}</Button>

            </div>


            {isLoading == true ?
                <Loading></Loading>
                : null}

        </div >
    )
};
