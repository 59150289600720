import React, { useEffect, useState, useRef } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from 'react-responsive'
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { Button, } from '@mui/material';
import { sk } from './globals.js';
import { Debug, Loading, UserData, GoHome } from './items.js';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faShoppingCart } from '@fortawesome/free-solid-svg-icons';


export const UserRegister = (props) => {
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [web, setWeb] = useState([]);
    const [user, setUser] = useState(null);
    const [index, setIndex] = useState(0);
    const [registerOK, setRegisterOK] = useState(false);


    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    var language = 0;
    var lang = sk;

    let params = useParams();
    let { func } = props;

    const homeRef = useRef(null)

    const logo = require('./react/app/logo.png');


    useEffect(() => {
        func(0, true);

        setRegisterOK(false); // prepina registraciu, alebo registracia uspesne dokoncena
        return () => {
        };

    }, []);

    const MenuLogoPress = (type, value) => {
        if (type == 0) {
            // KLIKNUTIE NA LOGO            
            navigate('/');
        }
    }

    const DeleteScroll = () => {
        sessionStorage.removeItem('scrollPosition')
    }

    const MenuTopPress = (type, value) => {
        // KLINKUNITE NA VRCHNÚ MENU
        Debug(type, value)
    }

    const ChangeScreen = (id) => {
        setIndex(id);

    }

    const MenuBottomPress = (value) => {
        Debug(value)
    }

    const UserDataPress = (value) => {
        if (value == 0) {
            // navrat bez ukladania zmien
            navigate('/');
        }
        if (value == 1) {
            // registrácia OK    
            setRegisterOK(true);
            GoHome();
        }

    }

    const GotoBack = () => {
        navigate('/');
        window.location.reload();
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: global.theme_back, width: '100%' }}>
            <div ref={homeRef}>
                {/*
                <MenuTop loged={false} mobil={web.mobil} email={web.email} func={MenuTopPress.bind(this)} />
                <MenuLogo func={MenuLogoPress.bind(this)} />
                */}

                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: global.font_label, color: global.theme_black }}>{lang.register}</p>
                    {registerOK == false ?
                        <UserData typ={0} register={true} user={null} b2b={null} func={UserDataPress.bind(this)} />
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginBottom: 40 }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, height: 50, borderRadius: 25, backgroundColor: global.theme_light }}>
                                <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                            </div>
                            <p style={{ fontSize: global.font_normal }}>{lang.register_ok}</p>
                            <Button onClick={() => GotoBack()} style={{ display: 'flex', width: 200, height: 40, textTransform: 'none', color: index == -1 ? global.theme_white : global.theme_white, backgroundColor: index == -1 ? global.theme_light : global.theme_dark, borderRadius: 0 }}>
                                <FontAwesomeIcon style={{ width: 16, height: 16, marginRight: 10, color: global.theme_white }} icon={faShoppingCart} />
                                {lang.start_eshop}
                            </Button>
                        </div>
                    }
                </div>
                <div style={{ height: 20 }}></div>

                {isBusy == true ?
                    <Loading></Loading>
                    : null}
            </div>
        </div >
    )
};

const styles = {
    ButtonLink: {
        color: global.theme_dark_gray,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0
    },
    ButtonBack: {
        color: global.theme_white,
        backgroundColor: global.theme_light,
        textTransform: 'none',
        fontWeight: 'normal',
        margin: 0,
        padding: 0,
        width: 100,
        height: 30,
    }
};
